/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { purge } from 'src/store/action'
import { formatSimpleDate } from 'src/utils/date'

export const DEFAULT_DATE = formatSimpleDate(dayjs().add(-1, 'day'))

export interface ConfirmSalesReportState {
  selectedTenantCodes: string[]
  selectedTenantIndex: number
  date: string
}

export const initialState: ConfirmSalesReportState = {
  selectedTenantCodes: [],
  selectedTenantIndex: 0,
  date: DEFAULT_DATE,
}

const slice = createSlice({
  name: 'confirmSalesReports',
  initialState,
  reducers: {
    deleteState: (state): ConfirmSalesReportState => {
      if (state.selectedTenantCodes.length > 0) {
        return state
      }
      return {
        date: '',
        selectedTenantIndex: 0,
        selectedTenantCodes: [],
      }
    },
    deselectedTenants: (state): ConfirmSalesReportState => {
      return {
        ...state,
        selectedTenantCodes: initialState.selectedTenantCodes,
        selectedTenantIndex: initialState.selectedTenantIndex,
      }
    },
    setSelectedTenantCodes: (
      state,
      { payload }: PayloadAction<string[]>
    ): ConfirmSalesReportState => {
      return {
        ...state,
        selectedTenantCodes: payload,
      }
    },
    setConfirmationDate: (
      state,
      { payload }: PayloadAction<string>
    ): ConfirmSalesReportState => {
      return {
        ...state,
        date: payload,
      }
    },
    incrementSelectedTenantIndex: (state): ConfirmSalesReportState => {
      return {
        ...state,
        selectedTenantIndex: state.selectedTenantIndex + 1,
      }
    },
    decrementSelectedTenantIndex: (state): ConfirmSalesReportState => {
      return {
        ...state,
        selectedTenantIndex: state.selectedTenantIndex - 1,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
  },
})

export default slice.reducer
export const {
  deleteState,
  deselectedTenants,
  setSelectedTenantCodes,
  setConfirmationDate,
  incrementSelectedTenantIndex,
  decrementSelectedTenantIndex,
} = slice.actions
