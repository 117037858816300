import React from 'react'
import clsx from 'clsx'
import { Box, Table, TableBody, TableHead } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CalculationTableBodyCellSize } from 'src/components/atoms'
import CalculationTableBodyRow, {
  Cell as LeftTableCell,
} from 'src/components/molecules/CalculationTableBodyRow'
import CalculationTableHeaderRow, {
  Cell as HeaderCell,
} from 'src/components/molecules/CalculationTableHeaderRow'
import color from 'src/styles/color'
import { TABLE_DIVIDER_THICKNESS } from 'src/styles/constants'
import { injectIndex } from 'src/utils/array'
import { isOdd } from 'src/utils/number'
import EditReadItemBodyRow, {
  Cell as RightTableCell,
} from '../EditReadItemBodyRow'

const LEFT_TABLE_COLUMNS = 3

export type BodyRow = {
  number: string
  title: string
  cells: RightTableCell[]
  disabledRow?: boolean
}

export type EditReadItemTableProps = {
  columns: HeaderCell[]
  rows: BodyRow[]
  onClickAdd: (rowIndex: number) => void
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
  },
  leftTableWrapper: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
  },
  leftTable: {
    borderRight: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
  },
  tableBodyRow: {
    height: 56,
  },
  rightTableWrapper: {
    zIndex: 0,
    width: '100%',
  },
})

const EditReadItemTable: React.StyledFC<EditReadItemTableProps> = ({
  className,
  columns,
  rows,
  onClickAdd,
}: EditReadItemTableProps) => {
  const classes = useStyles()

  const convertedLeftTableCell = (row: BodyRow): LeftTableCell[] => {
    const cells = [
      {
        text: row.number,
        size: 'medium' as CalculationTableBodyCellSize,
      },
      {
        text: row.title,
        size: 'large' as CalculationTableBodyCellSize,
      },
      {
        text: '=',
        size: 'small' as CalculationTableBodyCellSize,
      },
    ]
    return injectIndex(cells)
  }

  const maxCellCount = columns.length - LEFT_TABLE_COLUMNS

  return (
    <Box className={clsx(className, classes.root)}>
      <Box className={classes.leftTableWrapper}>
        <Table stickyHeader className={classes.leftTable}>
          <TableHead>
            <CalculationTableHeaderRow
              cells={columns.slice(0, LEFT_TABLE_COLUMNS)}
            />
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <CalculationTableBodyRow
                className={classes.tableBodyRow}
                key={`${row.title}}`}
                cells={convertedLeftTableCell(row)}
                isGray={isOdd(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.rightTableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <CalculationTableHeaderRow
              cells={columns.slice(LEFT_TABLE_COLUMNS)}
            />
          </TableHead>

          <TableBody>
            {rows.map((row, rowIndex) => (
              <EditReadItemBodyRow
                className={classes.tableBodyRow}
                key={`${row.title}`}
                cells={row.cells}
                isGray={isOdd(rowIndex)}
                emptyCellCount={maxCellCount - row.cells.length}
                onClickAdd={() => onClickAdd(rowIndex)}
                disabledRow={row.disabledRow}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default EditReadItemTable
