import React, { memo, useCallback, useMemo } from 'react'
import clsx from 'clsx'
import { Box, Table, TableBody } from '@mui/material'
import { IconName } from 'src/components/atoms'
import {
  AppTableHead,
  AppTableRow,
  BadgeCell,
  CheckBoxCell,
  Column,
  IconCell,
  TextCell,
} from 'src/components/tables'
import { ConsistencyEnum, getConsistencyIcon } from 'src/domain/consistency'
import {
  ENABLE_SEND_NOTIFICATION_ORG_CODES,
  HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES,
} from 'src/domain/org'
import {
  isReportStatusBeforeReported,
  getReportStatusBadgeColor,
  getReportStatusFillTypeColor,
  getReportStatusTitle,
} from 'src/domain/reportStatus'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  isOneOfRoles,
} from 'src/domain/role'
import {
  CheckStatusList,
  ValueType,
} from 'src/features/tenantSalesReportSummary/type'
import {
  FinishedFunnelEnum,
  ReportStatusEnum,
  RoleEnum,
  TenantSalesReportSummary,
} from 'src/slices/services/api'
import { formatDateTime } from 'src/utils/date'

import style from './styles.module.scss'

export type TenantSalesReportSummaryTableProps = {
  summaries: TenantSalesReportSummary[]
  checkStatusList: CheckStatusList
  isAllChecked?: boolean
  role?: RoleEnum
  orgCode: string
  onChangeCheckStatus: (tenantCode: string) => void
  onChangeAllCheckStatus: () => void
} & React.StyledProps

const TenantSalesReportSummaryTable: React.StyledFC<TenantSalesReportSummaryTableProps> =
  memo(
    ({
      className,
      summaries,
      checkStatusList,
      isAllChecked,
      role,
      orgCode,
      onChangeCheckStatus,
      onChangeAllCheckStatus,
    }: TenantSalesReportSummaryTableProps) => {
      const lastActionTime = useCallback(
        (summary: TenantSalesReportSummary): string => {
          if (
            summary.reportStatus !== ReportStatusEnum.Waiting &&
            summary.lastActionTime
          ) {
            return formatDateTime(summary.lastActionTime)
          }
          return '-'
        },
        []
      )

      const netSales = useCallback(
        (summary: TenantSalesReportSummary, valueType: ValueType): string => {
          if (
            summary.reportStatus === ReportStatusEnum.Waiting ||
            !summary.totals
          )
            return '-'
          const foundValue = summary.totals.find((value) => {
            return value.calculationItemCategoryName === 'net_sales'
          })
          if (!foundValue) return '-'
          const value =
            valueType === 'manual'
              ? foundValue.manualValue
              : foundValue.finalizedValue
          if (value !== 0 && !value) return '-'
          return value.toLocaleString()
        },
        []
      )

      const visitor = useCallback(
        (summary: TenantSalesReportSummary): string => {
          if (
            summary.reportStatus === ReportStatusEnum.Waiting ||
            !summary.totals
          )
            return '-'
          const foundValue = summary.totals.find((value) => {
            return value.calculationItemCategoryName === 'visitor'
          })
          if (
            !foundValue ||
            (foundValue.manualValue !== 0 && !foundValue.manualValue)
          )
            return '-'
          return foundValue.manualValue.toLocaleString()
        },
        []
      )

      const consistencyIcon = useCallback(
        (summary: TenantSalesReportSummary): IconName => {
          if (!summary.extractedConsistencyResult)
            return getConsistencyIcon(ConsistencyEnum.misMatch)

          if (summary.isAllManualInputValuesZero)
            return getConsistencyIcon(ConsistencyEnum.allMatchIncludingAllZero)

          return getConsistencyIcon(ConsistencyEnum.allMatchExcludingAllZero)
        },
        []
      )

      const handleClickRow = (tenantCode: string) => {
        onChangeCheckStatus(tenantCode)
      }

      const isActiveCheckbox = useCallback(
        (summary: TenantSalesReportSummary) => {
          return !isReportStatusBeforeReported(summary.reportStatus)
        },
        []
      )

      const showConsistencyAndSalesValuesByRole = useCallback((): boolean => {
        return isOneOfRoles(
          AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
          role
        )
      }, [role])

      const getColumns: Column[] = useMemo(() => {
        const columns: Column[] = [
          { title: 'ステータス' },
          { title: 'テナントコード' },
          { title: 'テナント名称', isCenter: false },
        ]

        if (
          showConsistencyAndSalesValuesByRole() &&
          !HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES.includes(orgCode)
        ) {
          columns.push({ title: '完全一致', isCenter: true })
        }
        columns.push(
          { title: '補足事項', isCenter: true },
          { title: '管理者メモ', isCenter: true }
        )

        if (ENABLE_SEND_NOTIFICATION_ORG_CODES.includes(orgCode)) {
          columns.push({ title: 'メッセージ', isCenter: true })
        }

        if (showConsistencyAndSalesValuesByRole()) {
          columns.push(
            { title: '純売上(報告値)' },
            { title: '純売上(確定値)' },
            { title: '客数(報告値)' }
          )
        }

        columns.push({ title: '最終作業時間' }, { title: '備考' })
        return columns
      }, [showConsistencyAndSalesValuesByRole, orgCode])

      const getExitPageDescription = (
        summary: TenantSalesReportSummary
      ): string => {
        if (summary.reportStatus !== ReportStatusEnum.LeftHalfway) return '-'

        const funnel = summary.lastFinishedFunnel
        const countRegister = summary.countRegisters
        switch (funnel) {
          case FinishedFunnelEnum.SelectingSalesDate:
          case FinishedFunnelEnum.AddingRegister:
            return `${countRegister}台目がレシート画像登録画面で中断`
          case FinishedFunnelEnum.UploadingImages:
            return `${countRegister}台目が売上入力画面で中断`
          case FinishedFunnelEnum.SendingManualInputValues:
            return `${countRegister}台目がレジ分岐画面で中断`
          default:
            return '-'
        }
      }

      return (
        <Box className={clsx(className, style.root)}>
          <Table className={clsx(className, style.table)} stickyHeader>
            <AppTableHead
              isChecked={isAllChecked}
              checkable
              onChange={onChangeAllCheckStatus}
              columns={getColumns}
            />
            <TableBody>
              {summaries.map((summary) => {
                return (
                  <AppTableRow
                    key={summary.id}
                    onClick={() => {
                      if (isActiveCheckbox(summary)) {
                        handleClickRow(summary.tenant.code)
                      }
                    }}
                    className={clsx(
                      style.row,
                      isActiveCheckbox(summary) && style.activeRow
                    )}
                  >
                    {isActiveCheckbox(summary) ? (
                      <CheckBoxCell
                        isChecked={Boolean(
                          checkStatusList[summary.tenant.code]
                        )}
                      />
                    ) : (
                      <TextCell text="" />
                    )}

                    <BadgeCell
                      className={style.badge}
                      label={getReportStatusTitle(summary.reportStatus)}
                      color={getReportStatusBadgeColor(summary.reportStatus)}
                      fillType={getReportStatusFillTypeColor(
                        summary.reportStatus
                      )}
                      ellipse
                    />
                    <TextCell
                      text={summary.tenant.code}
                      className={style.text}
                    />
                    <TextCell
                      text={summary.tenant.name}
                      className={style.text}
                      width={180}
                    />
                    {showConsistencyAndSalesValuesByRole() &&
                      !HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES.includes(orgCode) &&
                      (isReportStatusBeforeReported(summary.reportStatus) ? (
                        <TextCell
                          className={clsx(style.center, style.text)}
                          text={
                            summary.reportStatus === ReportStatusEnum.Waiting
                              ? ''
                              : '-'
                          }
                        />
                      ) : (
                        <IconCell
                          className={clsx(
                            style.center,
                            style.icon,
                            style.green
                          )}
                          icon={consistencyIcon(summary)}
                        />
                      ))}
                    {Boolean(summary.reportMessage) ? (
                      <IconCell
                        className={clsx(style.center, style.icon)}
                        icon="memo"
                      />
                    ) : (
                      <TextCell
                        text="-"
                        className={clsx(style.center, style.text)}
                      />
                    )}
                    {Boolean(summary.manageMessage) ? (
                      <IconCell
                        className={clsx(style.center, style.icon, style.blue)}
                        icon="eye"
                      />
                    ) : (
                      <TextCell
                        text="-"
                        className={clsx(style.center, style.text)}
                      />
                    )}
                    {ENABLE_SEND_NOTIFICATION_ORG_CODES.includes(orgCode) &&
                      (Boolean(summary.isNotificationSent) ? (
                        <IconCell
                          className={clsx(style.center, style.icon)}
                          icon="exclamation"
                        />
                      ) : (
                        <TextCell
                          text="-"
                          className={clsx(style.center, style.text)}
                        />
                      ))}
                    {showConsistencyAndSalesValuesByRole() && (
                      <>
                        <TextCell
                          text={netSales(summary, 'manual')}
                          className={style.number}
                          width={112}
                          unit={
                            netSales(summary, 'manual') !== '-'
                              ? '円'
                              : undefined
                          }
                        />
                        <TextCell
                          text={netSales(summary, 'finalized')}
                          className={style.number}
                          width={112}
                          unit={
                            netSales(summary, 'finalized') !== '-'
                              ? '円'
                              : undefined
                          }
                        />
                        <TextCell
                          text={visitor(summary)}
                          className={style.number}
                          width={112}
                          unit={visitor(summary) !== '-' ? '人' : undefined}
                        />
                      </>
                    )}
                    <TextCell
                      text={lastActionTime(summary)}
                      className={style.text}
                      width={120}
                    />
                    <TextCell
                      text={getExitPageDescription(summary)}
                      className={style.text}
                      width={220}
                    />
                  </AppTableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      )
    }
  )

TenantSalesReportSummaryTable.displayName = 'TenantSalesReportSummaryTable'
export default TenantSalesReportSummaryTable
