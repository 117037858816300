/* eslint-disable react/prop-types */
import React from 'react'
import { MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Select, SelectProps } from 'src/components/atoms'
import color from 'src/styles/color'

export type Option = {
  value: string | number
  title: string
}

export type SelectWithOptionProps = {
  options: Option[]
  placeholder: string
  onChange?: (option: Option) => void
} & React.StyledProps &
  SelectProps

const useStyles = makeStyles({
  item: {
    padding: '12px 20px',
    fontSize: '14px',
    '&.Mui-selected': {
      backgroundColor: color.lightGray,
      '&:hover': {
        backgroundColor: color.lightGray,
      },
    },
  },
  placeholder: {
    display: 'none',
  },
  menuPaper: {
    boxSizing: 'border-box',
    padding: '8px 0',
    borderRadius: '6px',
    boxShadow: `0px 4px 14px ${color.black100}`,
  },
})

const SelectWithOption: React.StyledFC<SelectWithOptionProps> = ({
  className,
  name,
  placeholder,
  defaultValue,
  variant,
  options,
  onOpen,
  onChange,
  disabled,
}) => {
  const classes = useStyles()

  const renderValue = (value: unknown): React.ReactNode => {
    const selectValue = value as string
    const renderTitle = options.find((option) => option.value === selectValue)
      ?.title
    return <span>{renderTitle ?? placeholder}</span>
  }

  return (
    <Select
      className={className}
      name={name}
      menuClassName={classes.menuPaper}
      defaultValue={defaultValue}
      variant={variant}
      onOpen={onOpen}
      disabled={disabled}
      renderValue={renderValue}
    >
      <MenuItem className={classes.placeholder} value="">
        {placeholder}
      </MenuItem>
      {options.map((option) => {
        return (
          <MenuItem
            className={classes.item}
            key={option.title}
            value={option.value}
            onClick={() => (onChange ? onChange(option) : null)}
          >
            {option.title}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default SelectWithOption
