import { Color } from 'src/AppTypes'
import { Column } from 'src/components/tables/AppTableHead'
import { Option } from 'src/components/tables/Cells/SelectCell'
import { TenantStatusEnum } from 'src/slices/services/api'

export const TABLE_COLUMNS: Column[] = [
  {
    title: 'テナントコード',
    labeledMessage: '必須',
    message: '※ 重複不可',
  },
  {
    title: 'テナント名称',
    labeledMessage: '必須',
    width: 284,
  },
]

export type TenantStatusMap = {
  [key in TenantStatusEnum]: {
    title: string
    color: Color
  }
}

export const tenantStatusMap: TenantStatusMap = {
  [TenantStatusEnum.Ready]: {
    title: '利用中',
    color: 'green',
  },
  [TenantStatusEnum.Closed]: {
    title: '利用停止',
    color: 'red',
  },
  [TenantStatusEnum.NotReady]: {
    title: '準備中',
    color: 'gray',
  },
}

export const getTenantStatusTitle = (status: TenantStatusEnum): string => {
  return tenantStatusMap[status].title
}

export const getTenantStatusColor = (status: TenantStatusEnum): Color => {
  return tenantStatusMap[status].color
}

export const TABLE_COLUMNS_FOR_OPA: Column[] = [
  {
    title: 'テナントコード',
    message: '※ 重複不可',
    width: 180,
  },
  {
    title: 'テナント名称',
    labeledMessage: '必須',
    width: 280,
  },
  {
    title: '契約者コード',
    labeledMessage: '必須',
    width: 250,
    isCombined: true,
  },
  {
    title: '区画',
    labeledMessage: '必須',
    width: 110,
  },
  {
    title: '代表レジNo',
    labeledMessage: '必須',
    width: 120,
  },
]
export const TABLE_UPDATE_COLUMNS: Column[] = [
  {
    title: '利用ステータス',
  },
  ...TABLE_COLUMNS,
]
export const TABLE_UPDATE_COLUMNS_FOR_OPA: Column[] = [
  {
    title: '利用ステータス',
  },
  {
    title: 'テナントコード',
    message: '※ 重複不可',
    width: 180,
  },
  {
    title: 'テナント名称',
    labeledMessage: '必須',
    width: 280,
  },
  {
    title: '契約者コード',
    labeledMessage: '必須',
    width: 250,
    isCombined: true,
  },
  {
    title: '区画',
    labeledMessage: '必須',
    width: 110,
  },
  {
    title: '代表レジNo',
    width: 120,
  },
  {
    title: '契約開始日',
    width: 100,
  },
  {
    title: '契約終了日',
    width: 100,
  },
]

export const HIDE_CELL_WIDTH = 72

export const STATUS_PLACEHOLDER = '利用開始前'
export const STATUS_OPTIONS: Option[] = [
  {
    title: getTenantStatusTitle(TenantStatusEnum.Ready),
    value: TenantStatusEnum.Ready,
    color: getTenantStatusColor(TenantStatusEnum.Ready),
  },
  {
    title: getTenantStatusTitle(TenantStatusEnum.Closed),
    value: TenantStatusEnum.Closed,
    color: getTenantStatusColor(TenantStatusEnum.Closed),
  },
]

export const STATUS_OPTIONS_WITH_NOT_READY: Option[] = [
  ...STATUS_OPTIONS,
  {
    title: getTenantStatusTitle(TenantStatusEnum.NotReady),
    value: TenantStatusEnum.NotReady,
    color: getTenantStatusColor(TenantStatusEnum.NotReady),
  },
]

export const PREFIX_OPTIONS: Option[] = [
  { title: 'TT', value: 'TT' },
  { title: 'TF', value: 'TF' },
  { title: 'AA', value: 'AA' },
]

export const NAME_PLACEHOLDER = 'テナント名'
export const CODE_PLACEHOLDER = 'テナントコード'
export const CONTRACTOR_CODE_PLACEHOLDER = '契約者コード'
export const SECTION_PLACEHOLDER = '区画'
export const REGISTER_NUMBER_PLACEHOLDER = 'レジNo'

export enum TenantFormTypeEnum {
  name = 'name',
  code = 'code',
  status = 'status',
  contractorCode = 'contractorCode',
  prefix = 'prefix',
  contractorCodeNumber = 'contractorCodeNumber',
  section = 'section',
  tenantRegisterNumber = 'tenantRegisterNumber',
  contractStartDate = 'contractStartDate',
  contractEndDate = 'contractEndDate',
}

export type TenantFormType = {
  tenants: {
    [TenantFormTypeEnum.name]: string
    [TenantFormTypeEnum.code]: string
    [TenantFormTypeEnum.status]: TenantStatusEnum
    [TenantFormTypeEnum.prefix]?: string
    [TenantFormTypeEnum.contractorCodeNumber]?: string
    [TenantFormTypeEnum.contractorCode]?: string
    [TenantFormTypeEnum.section]?: string
    [TenantFormTypeEnum.tenantRegisterNumber]?: string
    [TenantFormTypeEnum.contractStartDate]?: string
    [TenantFormTypeEnum.contractEndDate]?: string
  }[]
}

export type TenantRow = {
  index: number
  name: string
  code: string
  status: TenantStatusEnum
  contractorCode?: string
  section?: string
  prefix?: string
  tenantRegisterNumber?: string
  contractStartDate?: string
  contractEndDate?: string
}

// NOTE: フォームの入力値からcodeを生成
export const createTenantCodeAndContractorCode = (
  key: number,
  value: TenantFormType,
  storeCode: string
) => {
  const prefix = value.tenants?.[key]?.prefix || ''
  const contractorCodeNumber = value.tenants?.[key]?.contractorCodeNumber || ''
  const section = value.tenants?.[key]?.section || ''
  const contractorCode = `${prefix}${contractorCodeNumber}`
  // NOTE: tenantCodeの作成には、storeCodeの先頭4桁と末尾1桁を除いたものを使用する
  const trimmedStoreCode = storeCode.slice(4, -1)
  const tenantCode = `${trimmedStoreCode}${contractorCodeNumber}${section}`
  return { tenantCode, contractorCode }
}
