import React, { memo, useReducer, useRef } from 'react'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { Box, Table } from '@mui/material'
import TenantCreateTableRowDefault from 'src/components/molecules/TenantCreateTableRowDefault'
import TenantCreateTableRowForOpa from 'src/components/molecules/TenantCreateTableRowForOpa'
import { AppTableAddRowButton, AppTableHead } from 'src/components/tables'
import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import {
  TABLE_COLUMNS,
  HIDE_CELL_WIDTH,
  TABLE_COLUMNS_FOR_OPA,
} from 'src/domain/tenant'
import { InitialRowsState, reducerFunc } from 'src/utils/tableReducer'
import tenantUpdateStyles from '../TenantUpdateTable/styles.module.scss'

export type TenantCreateTableProps = {
  orgCode: string
  storeCode: string
} & React.StyledProps

const TenantCreateTable: React.StyledFC<TenantCreateTableProps> = memo(
  ({ className, style, orgCode, storeCode }: TenantCreateTableProps) => {
    const formMethods = useFormContext()
    const isOpa =
      orgCode && VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode)
    const columns = isOpa ? TABLE_COLUMNS_FOR_OPA : TABLE_COLUMNS
    const scrollBottomRef = useRef<HTMLTableRowElement>(null)

    const [rowState, dispatch] = useReducer(
      reducerFunc,
      InitialRowsState(1),
      (init) => init
    )

    const addRow = () => {
      dispatch({ type: 'ADD_ROW' })
      setTimeout(() => {
        scrollBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }

    const removeRow = (key: number) => {
      dispatch({ type: 'REMOVE_ROW', key })
      formMethods.unregister(`tenants.${key}`)
    }

    return (
      <Box className={clsx(className, tenantUpdateStyles.root)} style={style}>
        <Box className={tenantUpdateStyles.tableWrapper}>
          <Table stickyHeader className={tenantUpdateStyles.table}>
            <AppTableHead columns={columns} hideCellWidth={HIDE_CELL_WIDTH} />
            {isOpa ? (
              <TenantCreateTableRowForOpa
                rowState={rowState}
                removeRow={removeRow}
                storeCode={storeCode}
                scrollBottomRef={scrollBottomRef}
              />
            ) : (
              <TenantCreateTableRowDefault
                orgCode={orgCode}
                rowState={rowState}
                removeRow={removeRow}
                scrollBottomRef={scrollBottomRef}
              />
            )}
          </Table>
        </Box>
        <Box className={tenantUpdateStyles.addRowButtonWrapper}>
          <AppTableAddRowButton onClick={addRow} />
        </Box>
      </Box>
    )
  }
)

TenantCreateTable.displayName = 'TenantCreateTable'
export default TenantCreateTable
