import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { Button } from 'src/components/atoms'
import Modal from 'src/components/molecules/Modal'
import TenantCreateTable, {
  TenantCreateTableProps,
} from 'src/components/organisms/TenantCreateTable'
import Loading from 'src/components/templates/Loading'
import stylesTenantUpdate from 'src/components/templates/TenantUpdate/styles.module.scss'
import { TenantFormType } from 'src/domain/tenant'
import { findDuplicates } from 'src/utils/array'
import {
  AGREE_BUTTON,
  BACK_BUTTON,
  CHANCEL_BUTTON,
  MODAL_TITLE,
} from './constants'

export type TenantCreateProps = {
  orgCode?: string
  title: string
  descriptions: string[]
  submitButtonText: string
  loading?: boolean
  storeName: string
  storeCode: string
  onClickBack: () => void
  onSubmit: (values: TenantFormType) => void
} & Omit<TenantCreateTableProps, 'onChangeRow' | 'codeRegex'>

const TenantCreate: React.StyledFC<TenantCreateProps> = ({
  orgCode,
  title,
  descriptions,
  submitButtonText,
  loading,
  storeName,
  storeCode,
  onClickBack,
  onSubmit,
}: TenantCreateProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [duplicateTenantCodes, setDuplicateTenantCodes] = useState<string[]>([])

  const formMethods = useForm<TenantFormType>()

  const checkDuplicateTenantCodes = useCallback(
    (value: TenantFormType): void => {
      const tenants = value.tenants || []
      const tenantCodes = tenants
        .filter((t) => t)
        .map((tenant) => tenant?.code)
        .filter((code) => code)
      const duplicateCodes = findDuplicates(tenantCodes)
      setDuplicateTenantCodes(duplicateCodes)
    },
    []
  )

  useEffect(() => {
    const subscription = formMethods.watch((value) =>
      checkDuplicateTenantCodes(value as TenantFormType)
    )

    return () => subscription.unsubscribe()
  }, [formMethods, formMethods.watch, checkDuplicateTenantCodes])

  const handleClickAgree = () => {
    onClickBack()
    setModalOpen(false)
  }
  const formSubmit = (data: TenantFormType) => {
    if (duplicateTenantCodes.length > 0) {
      return
    }
    onSubmit({
      tenants: data.tenants.filter((t) => t),
    })
  }
  return (
    <>
      <Box className={stylesTenantUpdate.root}>
        <Box className={stylesTenantUpdate.header}>
          <Box className={stylesTenantUpdate.title}>
            <Typography variant="h1">{title}</Typography>
            <Box className={stylesTenantUpdate.subTitle}>
              <Typography
                variant="h2"
                className={clsx(
                  stylesTenantUpdate.item,
                  stylesTenantUpdate.code
                )}
              >
                {storeCode}
              </Typography>
              <Typography variant="h2" className={stylesTenantUpdate.item}>
                {storeName}
              </Typography>
            </Box>
          </Box>
          <Box className={stylesTenantUpdate.description}>
            {descriptions.map((description) => (
              <Typography key={description} variant="body2" component="li">
                {description}
              </Typography>
            ))}
          </Box>
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(formSubmit)}>
              <Box className={stylesTenantUpdate.main}>
                <TenantCreateTable orgCode={orgCode} storeCode={storeCode} />
              </Box>
              <Box className={stylesTenantUpdate.footer}>
                <Box className={stylesTenantUpdate.buttonWrapper}>
                  {duplicateTenantCodes.length > 0 && (
                    <Typography className={stylesTenantUpdate.errorMessage}>
                      {`テナントコード${duplicateTenantCodes.join(
                        ', '
                      )}は重複しています`}
                    </Typography>
                  )}
                  <Button
                    type="button"
                    className={stylesTenantUpdate.button}
                    color="secondary"
                    size="large"
                    title={BACK_BUTTON}
                    onClick={() => setModalOpen(true)}
                  />
                  <Button
                    type="submit"
                    className={stylesTenantUpdate.button}
                    color="primary"
                    size="large"
                    title={submitButtonText}
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>
        )}
      </Box>
      <Modal
        open={modalOpen}
        title={MODAL_TITLE}
        agreeButtonTitle={AGREE_BUTTON}
        disagreeButtonTitle={CHANCEL_BUTTON}
        onClickAgree={handleClickAgree}
        onClickDisagree={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default TenantCreate
