import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import * as dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  DateValidationError,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  PickerChangeHandlerContext,
  PickersActionBarProps,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import color from 'src/styles/color'
import { formatDateWithDayOfWeek } from 'src/utils/date'
import { Button } from '../Button'
import { Icon } from '../Icon'

// NOTE: see: https://mui.com/x/react-date-pickers/timezone
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ja')

// NOTE: see: https://mui.com/x/api/date-pickers/date-picker/#props
export type DatePickerProps = {
  date?: string
  defaultDate?: string
  maxDate?: string
  label?: string
  disableArrowIcon?: boolean
  placeholder?: string
  onChange: (date: string) => void
} & React.StyledProps

const DateFormat = 'YYYY-MM-DD'

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: '8px 0',
      borderRadius: 8,
      transition: '100ms',
      '&::before, &::after': {
        content: 'none', // NOTE: hide underline
      },
      '&:hover': {
        backgroundColor: color.lightGray02,
      },
    },
    '& .MuiInputBase-input': {
      padding: 0,
      cursor: 'pointer',
      textAlign: 'center',
      '&::selection': {
        // FIXME: slotProps.textField.inputProps.readOnly = true と組み合わせて、input 要素ではないように見せている
        backgroundColor: 'transparent',
      },
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
    },
    '& .MuiInputLabel-root': {
      width: '100%',
      color: color.black500,
      textAlign: 'center',
      '&.Mui-focused': {
        color: color.black500,
      },
    },
  },
  actionBar: {
    display: 'flex',
    gridColumn: '1/4',
  },
  actionButton: {
    height: '32px',
    margin: '16px 20px 0',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: color.lightGray02,
    },
  },
  calendarHeader: {
    paddingLeft: '20px',
    '& .MuiPickersCalendarHeader-labelContainer': {
      paddingLeft: '16px',
      border: `1px solid ${color.black200}`,
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: color.lightGray02,
      },
    },
  },
  icon: {
    fill: color.black500,
    transition: 'transform 300ms',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
})

const CustomActionBar = (props: PickersActionBarProps) => {
  const classes = useStyles()
  const { onSetToday } = props
  return (
    <Box className={classes.actionBar}>
      <Button
        className={classes.actionButton}
        color="secondary"
        size="small"
        title="今日"
        onClick={onSetToday}
      />
    </Box>
  )
}

export const DatePicker: React.StyledFC<DatePickerProps> = ({
  className,
  date,
  defaultDate,
  label,
  maxDate,
  disableArrowIcon,
  placeholder,
  onChange,
}: DatePickerProps) => {
  const classes = useStyles()

  const defaultValue = useCallback(
    () => dayjs.default(defaultDate),
    [defaultDate]
  )

  const isError = useRef(false)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(
    defaultDate ? dayjs.default(defaultDate) : null
  )

  useEffect(() => {
    setValue(date ? dayjs.default(date) : null)
  }, [date])

  const handleChange = (
    newDate: dayjs.Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    isError.current = !!context.validationError
    if (newDate && onChange) {
      setValue(newDate)
      onChange(newDate.format(DateFormat))
    }
  }

  const handleBlurInput = () => {
    if (isError.current) {
      setValue(defaultValue)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <MuiDatePicker
        openTo="day"
        views={['year', 'month', 'day']}
        className={clsx(className, classes.root)}
        defaultValue={defaultValue()}
        value={value}
        open={open}
        maxDate={dayjs.default(maxDate)}
        label={label}
        format="YYYY-MM-DD(dd)"
        timezone="Asia/Tokyo"
        onChange={handleChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slots={{
          actionBar: CustomActionBar,
          openPickerButton: () => null, // NOTE: hide calendar icon
        }}
        slotProps={{
          textField: {
            variant: 'standard',
            onBlur: handleBlurInput,
            onClick: () => setOpen(true),
            inputProps: {
              readOnly: true,
              placeholder,
              value: value ? formatDateWithDayOfWeek(value) : '',
            },
          },
          calendarHeader: {
            className: classes.calendarHeader,
          },
        }}
      />
      {!disableArrowIcon && (
        <Icon
          icon="arrowDropDown"
          className={clsx(classes.icon, open && classes.rotate)}
        />
      )}
    </LocalizationProvider>
  )
}
