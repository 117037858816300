import React, { useEffect, useCallback } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import clsx from 'clsx'
import { FormProvider, useForm, useFormState } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { Badge, Card, RadioGroupWithFormControl } from 'src/components/atoms'
import DatePickerWithFormControl from 'src/components/molecules/DatePickerWithFormControl'
import TextareaFieldWithCount from 'src/components/molecules/TextareaFieldWithCount'
import TextField from 'src/components/molecules/TextField'
import {
  OPA_DEPARTMENT_CODE_DIGIT,
  VIEWABLE_CONTRACT_DATE_CODES,
  VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES,
} from 'src/domain/org'
import { TenantDetail } from 'src/slices/services/api'
import {
  RegexNoSpaceOnly,
  InvalidInputErrorMessage,
  RegexNumeric,
  NumericRegexErrorMessage,
} from 'src/utils/regularExpression'
import commonStyles from '../../common.module.scss'
import { reportTypeOption } from '../../constants'
import { TenantDetailFormTypes, TenantDetailFormNameEnum } from '../../type'
import LabeledTextFieldWithCount from '../LabeledTextFieldWithCount'
import styles from './styles.module.scss'

type TenantDetailFormProps = {
  tenantDetail?: TenantDetail
  orgCode: string
  showAllError: boolean
  onTenantDetailValidate: (isValid: boolean) => void
  onTenantDetailFormDataChange: (data: TenantDetail) => void
} & React.StyledProps

const contractDateErrorMessage = '契約開始日よりも早い終了日が選択されています'

const TenantDetailForm: React.StyledFC<TenantDetailFormProps> = ({
  className,
  tenantDetail,
  orgCode,
  showAllError,
  onTenantDetailValidate,
  onTenantDetailFormDataChange,
}: TenantDetailFormProps) => {
  const methods = useForm<TenantDetailFormTypes>({
    defaultValues: tenantDetail,
    mode: 'onChange',
  })
  const { isValid } = useFormState({ control: methods.control })
  const { formState, watch, trigger, getValues } = methods
  const watchedContractStartDate = watch(
    TenantDetailFormNameEnum.contractStartDate
  )
  const watchedContractEndDate = watch(TenantDetailFormNameEnum.contractEndDate)
  // FIXME: 契約日のエラー判定をこのTenantDetailFormコンポーネント内で完結するように修正する
  const validateWhetherStartDateIsBeforeEndDate = useCallback(() => {
    return (
      // NOTE: 日付が片方でも未選択の場合、バリデーションを通す
      !(watchedContractStartDate && watchedContractEndDate) ||
      watchedContractStartDate < watchedContractEndDate ||
      contractDateErrorMessage
    )
  }, [watchedContractEndDate, watchedContractStartDate])

  useEffect(() => {
    onTenantDetailValidate(isValid)
  }, [isValid, onTenantDetailValidate])

  // NOTE: 未入力エラーは一度も触れていないフィールドには表示されていないので、送信時にtriggerして未入力エラーを設定する
  useEffect(() => {
    if (showAllError) {
      trigger()
    }
  }, [showAllError, trigger])

  // 契約開始日または終了日に変更があったタイミングでバリデーションを実行
  useEffect(() => {
    trigger([
      TenantDetailFormNameEnum.contractStartDate,
      TenantDetailFormNameEnum.contractEndDate,
    ])
  }, [watchedContractStartDate, watchedContractEndDate, trigger])

  const formValues = watch()

  // 最新のフォームの値をEditTenantDetailに渡す
  useEffect(() => {
    onTenantDetailFormDataChange(getValues())
  }, [getValues, formValues, onTenantDetailFormDataChange])

  return (
    <FormProvider {...methods}>
      <Box className={clsx(className, commonStyles.container)}>
        <Box className={commonStyles.leftColumn}>
          <Box className={commonStyles.leftCard}>
            <Card className={commonStyles.cardTitle} title="テナント情報">
              <Box className={styles.textFieldContainer}>
                <LabeledTextFieldWithCount
                  label="代表者氏名"
                  name={TenantDetailFormNameEnum.staffName}
                  type="text"
                  defaultValue={tenantDetail?.staffName}
                  placeholder="テナント 太郎"
                  validations={[
                    {
                      regex: RegexNoSpaceOnly,
                      message: InvalidInputErrorMessage,
                    },
                  ]}
                />
                <LabeledTextFieldWithCount
                  label="電話番号"
                  name={TenantDetailFormNameEnum.staffTel}
                  type="text"
                  defaultValue={tenantDetail?.staffTel}
                  placeholder="090-1234-5678"
                  maxLength={15}
                  validations={[
                    {
                      regex: RegexNoSpaceOnly,
                      message: InvalidInputErrorMessage,
                    },
                  ]}
                />
              </Box>
              {VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(
                orgCode
              ) && (
                <>
                  <Box className={commonStyles.flexContainer}>
                    <Typography variant="h3" className={styles.label}>
                      報告形態
                    </Typography>
                    <Badge
                      className={styles.badge}
                      label="必須"
                      size="small"
                      color="red"
                      fillType="color"
                    />
                  </Box>
                  <RadioGroupWithFormControl
                    className={styles.radioGroup}
                    name={TenantDetailFormNameEnum.reportType}
                    defaultValue={tenantDetail?.reportType}
                    options={reportTypeOption}
                    required
                  />
                  <Box className={commonStyles.errorContainer}>
                    <ErrorMessage
                      errors={formState.errors}
                      name={TenantDetailFormNameEnum.reportType}
                      render={({ message }) => (
                        <Typography
                          component="p"
                          className={commonStyles.errorMessage}
                        >
                          {message}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box>
                    <Box className={styles.labelAndDigitContainer}>
                      <Typography component="h3" className={styles.label}>
                        部門コード
                      </Typography>
                      <Typography className={commonStyles.digit}>
                        <span className={commonStyles.digitNumber}>
                          {OPA_DEPARTMENT_CODE_DIGIT}
                        </span>
                        桁
                      </Typography>
                    </Box>
                    <Box className={styles.textFieldWrapper}>
                      <TextField
                        className={clsx(
                          styles.textField,
                          formState.errors[
                            TenantDetailFormNameEnum.departmentCode
                          ] && styles.error
                        )}
                        name={TenantDetailFormNameEnum.departmentCode}
                        type="text"
                        defaultValue={tenantDetail?.departmentCode || ''}
                        placeholder="123"
                        validations={[
                          {
                            regex: RegexNoSpaceOnly,
                            message: InvalidInputErrorMessage,
                          },
                          {
                            regex: RegexNumeric,
                            message: NumericRegexErrorMessage,
                          },
                        ]}
                        maxLength={3}
                        minLength={3}
                        onBlur={() =>
                          trigger(TenantDetailFormNameEnum.departmentCode)
                        }
                      />
                    </Box>
                    <Box className={commonStyles.errorContainer}>
                      <ErrorMessage
                        errors={formState.errors}
                        name={TenantDetailFormNameEnum.departmentCode}
                        render={({ message }) => (
                          <Typography
                            component="p"
                            className={commonStyles.errorMessage}
                          >
                            {message}
                          </Typography>
                        )}
                      />
                    </Box>
                  </Box>
                  <Box className={styles.uneditableItemContainer}>
                    <Box>
                      <Typography component="h3" className={styles.label}>
                        契約者コード
                      </Typography>
                      <p className={commonStyles.value}>
                        {tenantDetail?.contractorCode || '-'}
                      </p>
                    </Box>
                    <Box>
                      <Typography component="h3" className={styles.label}>
                        区画
                      </Typography>
                      <p className={commonStyles.value}>
                        {tenantDetail?.section || '-'}
                      </p>
                    </Box>
                  </Box>
                </>
              )}
              {VIEWABLE_CONTRACT_DATE_CODES.includes(orgCode) && (
                <>
                  <Box className={commonStyles.flexContainer}>
                    <Box className={styles.flexItemContainer}>
                      <Typography className={styles.dateLabel} component="h3">
                        契約開始日
                      </Typography>
                      <DatePickerWithFormControl
                        name={TenantDetailFormNameEnum.contractStartDate}
                        validate={validateWhetherStartDateIsBeforeEndDate}
                        hasError={
                          !!formState.errors[
                            TenantDetailFormNameEnum.contractStartDate
                          ]
                        }
                      />
                    </Box>
                    <Box className={styles.flexItemContainer}>
                      <Typography className={styles.dateLabel} component="h3">
                        契約終了日
                      </Typography>
                      <DatePickerWithFormControl
                        name={TenantDetailFormNameEnum.contractEndDate}
                        hasError={
                          !!formState.errors[
                            TenantDetailFormNameEnum.contractStartDate
                          ]
                        }
                      />
                    </Box>
                  </Box>
                  <Box className={commonStyles.errorContainer}>
                    <ErrorMessage
                      errors={formState.errors}
                      name={TenantDetailFormNameEnum.contractStartDate}
                      render={({ message }) => (
                        <Typography
                          component="p"
                          className={commonStyles.errorMessage}
                        >
                          {message}
                        </Typography>
                      )}
                    />
                  </Box>
                </>
              )}
            </Card>
          </Box>
        </Box>
        <Box className={commonStyles.rightColumn}>
          <Box className={commonStyles.rightCard}>
            <Card
              className={commonStyles.cardTitle}
              title="管理者メモ（テナントごと）"
            >
              <TextareaFieldWithCount
                name={TenantDetailFormNameEnum.note}
                maxLength={100}
                defaultValue={tenantDetail?.note}
              />
            </Card>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default TenantDetailForm
