import {
  ReportTypeEnum,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'

export enum TenantDetailFormNameEnum {
  staffName = 'staffName',
  staffTel = 'staffTel',
  contractStartDate = 'contractStartDate',
  contractEndDate = 'contractEndDate',
  reportType = 'reportType',
  contractorCode = 'contractorCode',
  departmentCode = 'departmentCode',
  section = 'section',
  note = 'note',
}

export enum RegisterFormNameEnum {
  registerNumber = 'registerNumber',
  registerCategory = 'registerCategory',
}

export type TenantDetailFormTypes = TenantDetailFormTypesBase &
  TenantDetailFormTypesForOpa

type TenantDetailFormTypesBase = {
  [TenantDetailFormNameEnum.staffName]?: string
  [TenantDetailFormNameEnum.staffTel]?: string
  [TenantDetailFormNameEnum.contractStartDate]?: string
  [TenantDetailFormNameEnum.contractEndDate]?: string
  [TenantDetailFormNameEnum.reportType]?: ReportTypeEnum
  [TenantDetailFormNameEnum.contractorCode]?: string
  [TenantDetailFormNameEnum.departmentCode]?: string
  [TenantDetailFormNameEnum.section]?: string
  [TenantDetailFormNameEnum.note]?: string
}

type TenantDetailFormTypesForOpa = {
  [TenantDetailFormNameEnum.reportType]?: ReportTypeEnum
  [TenantDetailFormNameEnum.contractorCode]?: string
  [TenantDetailFormNameEnum.departmentCode]?: string
  [TenantDetailFormNameEnum.section]?: string
}

export type RegisterFormTypes = {
  [RegisterFormNameEnum.registerNumber]?: string
  [RegisterFormNameEnum.registerCategory]?: TenantRegisterCategoryEnum
}
