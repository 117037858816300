import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import styles from './styles.module.scss'

export type ReportMessage = {
  id: string
  date: string
  reportType: reportTypeEnum
  reportIndex?: number
  messageBody?: string
}

export enum reportTypeEnum {
  Normal = 'normal',
  Resent = 'resent',
}

export type ReportMessagesProps = {
  reportMessages: ReportMessage[]
} & React.StyledProps

const reportTitle = (type: reportTypeEnum, reportIndex?: number) => {
  if (type === reportTypeEnum.Normal) {
    return '通常報告'
  }
  if (type === reportTypeEnum.Resent && typeof reportIndex === 'number') {
    return `再送信${reportIndex + 1}回目`
  }
  return ''
}

const ReportMessages: React.StyledFC<ReportMessagesProps> = ({
  className,
  reportMessages,
}: ReportMessagesProps) => {
  return reportMessages.map((message) => (
    <Box className={className} key={message.id}>
      <Box className={styles.reportMessage}>
        <Typography className={styles.fontInfo}>
          {reportTitle(message.reportType, message.reportIndex)}
        </Typography>
        <Typography className={clsx(styles.date, styles.fontInfo)}>
          {message.date}
        </Typography>
      </Box>
      <Typography className={clsx(styles.messageBody, styles.fontInfo)}>
        {message.messageBody}
      </Typography>
    </Box>
  ))
}
export default ReportMessages
