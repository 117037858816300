import React, { memo } from 'react'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { useMount } from 'react-use'
import { Box, Table } from '@mui/material'
import TenantUpdateTableRowDefault from 'src/components/molecules/TenantUpdateTableRowDefault'
import TenantUpdateTableRowForOpa from 'src/components/molecules/TenantUpdateTableRowForOpa'
import { AppTableHead } from 'src/components/tables/AppTableHead'
import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import {
  TABLE_UPDATE_COLUMNS,
  TABLE_UPDATE_COLUMNS_FOR_OPA,
  TenantRow,
} from 'src/domain/tenant'
import { TenantStatusEnum } from 'src/slices/services/api'
import styles from './styles.module.scss'

export type TenantFormType = {
  tenants: {
    name: string
    code: string
    status: TenantStatusEnum
    contractorCode?: string
    section?: string
  }[]
}

export type TenantUpdateTableProps = {
  tenants: TenantRow[]
  orgCode: string
  storeCode: string
} & React.StyledProps

const TenantUpdateTable: React.StyledFC<TenantUpdateTableProps> = memo(
  ({
    className,
    style,
    tenants,
    orgCode,
    storeCode,
  }: TenantUpdateTableProps) => {
    const formMethods = useFormContext<TenantFormType>()
    const { setValue } = formMethods

    useMount(() => {
      // 初期値を設定
      tenants.forEach((tenant, index) => {
        setValue(`tenants.${index}.contractorCode`, tenant.contractorCode || '')
        setValue(`tenants.${index}.code`, tenant.code || '')
      })
    })

    const isOpa =
      orgCode && VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode)

    const columns = isOpa ? TABLE_UPDATE_COLUMNS_FOR_OPA : TABLE_UPDATE_COLUMNS

    return (
      <Box className={clsx(className, styles.root)} style={style}>
        <Box className={styles.tableWrapper}>
          <Table stickyHeader className={styles.table}>
            <AppTableHead columns={columns} />
            {isOpa ? (
              <TenantUpdateTableRowForOpa
                tenants={tenants}
                storeCode={storeCode}
              />
            ) : (
              <TenantUpdateTableRowDefault
                tenants={tenants}
                orgCode={orgCode}
              />
            )}
          </Table>
        </Box>
      </Box>
    )
  }
)

TenantUpdateTable.displayName = 'TenantUpdateTable'
export default TenantUpdateTable
