import React from 'react'
import clsx from 'clsx'
import { Box, Table, TableBody } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Column, AppTableHead } from 'src/components/tables'
import StoreSalesReportSummaryTableRow, {
  StoreSalesReportSummaryTableRowProps,
} from '../StoreSalesReportSummaryTableRow'

export type StoreSalesReportSummaryTableProps = {
  columns: Column[]
  rows: Row[]
  onClick: (rowIndex: number) => void
} & React.StyledProps

export type Row = {
  key: string
} & StoreSalesReportSummaryTableRowProps

const useStyles = makeStyles({
  root: {
    overflow: 'auto',
    height: '100%',
  },
  table: {
    width: 'auto',
  },
})

const StoreSalesReportSummaryTable: React.StyledFC<
  StoreSalesReportSummaryTableProps
> = ({
  className,
  columns,
  rows,
  onClick,
}: StoreSalesReportSummaryTableProps) => {
  const classes = useStyles()
  return (
    <Box className={clsx(classes.root, className)}>
      <Table
        stickyHeader
        classes={{
          root: classes.table,
        }}
      >
        <AppTableHead columns={columns} />
        <TableBody>
          {rows.map((row, index) => (
            <StoreSalesReportSummaryTableRow
              key={row.key}
              cells={row.cells}
              disabled={row.disabled}
              onClick={() => onClick(index)}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default StoreSalesReportSummaryTable
