import { TenantFormType } from 'src/components/organisms/TenantUpdateTable'
import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import { TenantRow } from 'src/domain/tenant'
import { UpdateTenant } from 'src/slices/editTenants/editTenantsSlice'
import { StoreTenant } from 'src/slices/services/api'
import { fullToHalf } from 'src/utils/string'

interface EditTenantPresenter {
  tableRows(tenants: StoreTenant[]): TenantRow[]
  requestBody(
    formValue: TenantFormType,
    tenants: StoreTenant[],
    orgCode: string
  ): UpdateTenant[]
}

export const Presenter: EditTenantPresenter = {
  tableRows(tenants) {
    return tenants.map((tenant, index) => {
      return {
        index,
        name: tenant.name,
        code: tenant.code,
        status: tenant.status,
        contractorCode: tenant.contractorCode,
        section: tenant.section,
        contractStartDate: tenant.contractStartDate,
        contractEndDate: tenant.contractEndDate,
        tenantRegisterNumber: tenant.tenantRegisterNumber,
      }
    })
  },
  requestBody(formValue, tenants, orgCode) {
    return formValue.tenants
      .map((value, index) => {
        const originalTenant = tenants[index]
        const changed =
          value.name !== originalTenant.name ||
          value.code !== originalTenant.code ||
          value.status !== originalTenant.status ||
          value.contractorCode !== originalTenant.contractorCode ||
          value.section !== originalTenant.section
        const isOpa =
          VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode)

        const baseTenant = {
          id: changed ? originalTenant.id : null,
          name: fullToHalf(value.name),
          code: fullToHalf(value.code),
          status: value.status,
        }

        if (isOpa) {
          return {
            ...baseTenant,
            contractorCode: value.contractorCode,
            section: value.section,
          }
        }

        return baseTenant
      })
      .filter((tenant) => tenant.id) as UpdateTenant[]
  },
}
