import React from 'react'
import clsx from 'clsx'
import { Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'src/components/atoms'
import colors from 'src/styles/color'

export type QRCodeDownloadButtonProps = {
  title: string
  onClick?: () => void
} & React.StyledProps

const useStyles = makeStyles(() => {
  return {
    button: {
      width: 168,
      height: 48,
      border: `1px solid ${colors.black200}`,
      color: colors.black,
      '&:hover': {
        borderColor: colors.gray03,
        backgroundColor: colors.white,
      },
      '&:active .addButton': {
        background: colors.whiteHover,
      },
      borderRadius: 16,
    },
    addButton: {
      '&:active': {
        background: 'none',
      },
      border: 'solid rgba(0,0,0,0.2)',
      borderRadius: '50%',
      padding: '2px',
    },
    typography: {
      paddingLeft: 8,
      fontSize: 16,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  }
})

const QRCodeDownloadButton: React.StyledFC<QRCodeDownloadButtonProps> = ({
  className,
  title,
  onClick,
}: QRCodeDownloadButtonProps) => {
  const classes = useStyles()
  return (
    <Button className={clsx(className, classes.button)} onClick={onClick}>
      <div className={classes.addButton}>
        <span className={classes.iconWrapper}>
          <Icon icon="plus" />
        </span>
      </div>
      <Typography className={classes.typography}>{title}</Typography>
    </Button>
  )
}

export default QRCodeDownloadButton
