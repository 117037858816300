export type OrgPathParams = {
  orgCode: string
}

export type StorePathParams = OrgPathParams & {
  storeCode: string
}

export type TenantPathParams = StorePathParams & {
  tenantCode: string
}

export type TenantOwnerCompanyPathParams = OrgPathParams & {
  tenantOwnerCompanyCode: string
}

type AllPathParams = TenantPathParams & {
  tenantOwnerCompanyCode: string
}

const dynamicPath = <P extends Partial<AllPathParams>>(
  path: string,
  pathParams: P
): string => {
  let result = path
  Object.entries(pathParams).forEach((v) => {
    if (v[1]) {
      result = result.replace(`:${v[0]}`, v[1])
    }
  })
  return result
}

const TemplateParams: AllPathParams = {
  orgCode: ':orgCode',
  storeCode: ':storeCode',
  tenantCode: ':tenantCode',
  tenantOwnerCompanyCode: ':tenantOwnerCompanyCode',
}

export const PathTemplate = {
  login: '/login',
  loginSentEmail: '/login/sent_email',
  loginAction: '/login/action',
  profile: `/orgs/${TemplateParams.orgCode}/profile`,
  stores: `/orgs/${TemplateParams.orgCode}/stores`,
  store: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}`,
  storeSalesReportSummaries: `/orgs/${TemplateParams.orgCode}/sales_report_summaries`,
  tenant: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}`,
  tenantCalculationTab: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}?tab=calculation`,
  tenants: `/orgs/${TemplateParams.orgCode}/tenants`,
  members: `/orgs/${TemplateParams.orgCode}/members`,
  membersCreate: `/orgs/${TemplateParams.orgCode}/members_create`,
  membersUpdate: `/orgs/${TemplateParams.orgCode}/members_update`,
  tenantSalesReport: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}/sales_report`,
  tenantSalesReportSummaries: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/sales_report_summaries`,
  startEditCalculation: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}/edit/start`,
  createTenants: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/create`,
  updateTenants: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/update`,
  editReadItem: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}/edit/step/1`,
  editOCRFormat: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}/edit/step/2`,
  editCalculationDSL: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}/edit/step/3`,
  completeEditCalculation: `/orgs/${TemplateParams.orgCode}/stores/${TemplateParams.storeCode}/tenants/${TemplateParams.tenantCode}/edit/complete`,
  tenantOwnerCompanies: `/orgs/${TemplateParams.orgCode}/tenant_owner_companies`,
  tenantOwnerCompaniesCreate: `/orgs/${TemplateParams.orgCode}/tenant_owner_companies_create`,
  tenantOwnerCompaniesUpdate: `/orgs/${TemplateParams.orgCode}/tenant_owner_companies_update`,
  tenantsOfTenantOwnerCompanyList: `/orgs/${TemplateParams.orgCode}/tenant_owner_companies/${TemplateParams.tenantOwnerCompanyCode}/tenant_list`,
  tenantsOfTenantOwnerCompanyUpdate: `/orgs/${TemplateParams.orgCode}/tenant_owner_companies/${TemplateParams.tenantOwnerCompanyCode}/tenant_list_update`,
}

const Path = {
  login: PathTemplate.login,
  loginSentEmail: PathTemplate.loginSentEmail,
  loginAction: PathTemplate.loginAction,
  profile: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.profile, { orgCode })
  },
  stores: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.stores, { orgCode })
  },
  store: (orgCode: string, storeCode: string): string => {
    return dynamicPath<StorePathParams>(PathTemplate.store, {
      orgCode,
      storeCode,
    })
  },
  storeSalesReportSummaries: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.storeSalesReportSummaries, {
      orgCode,
    })
  },
  tenant: (orgCode: string, storeCode: string, tenantCode: string): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.tenant, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  tenantCalculationTab: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.tenantCalculationTab, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  tenants: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.tenants, { orgCode })
  },
  members: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.members, { orgCode })
  },
  membersCreate: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.membersCreate, { orgCode })
  },
  membersUpdate: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.membersUpdate, { orgCode })
  },
  tenantSalesReport: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.tenantSalesReport, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  tenantSalesReportSummaries: (orgCode: string, storeCode: string): string => {
    return dynamicPath<StorePathParams>(
      PathTemplate.tenantSalesReportSummaries,
      {
        orgCode,
        storeCode,
      }
    )
  },
  startEditCalculation: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.startEditCalculation, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  createTenants: (orgCode: string, storeCode: string): string => {
    return dynamicPath<StorePathParams>(PathTemplate.createTenants, {
      orgCode,
      storeCode,
    })
  },
  updateTenants: (orgCode: string, storeCode: string): string => {
    return dynamicPath<StorePathParams>(PathTemplate.updateTenants, {
      orgCode,
      storeCode,
    })
  },
  editReadItem: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.editReadItem, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  editOCRFormat: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.editOCRFormat, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  editCalculationDSL: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.editCalculationDSL, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  completeEditCalculation: (
    orgCode: string,
    storeCode: string,
    tenantCode: string
  ): string => {
    return dynamicPath<TenantPathParams>(PathTemplate.completeEditCalculation, {
      orgCode,
      storeCode,
      tenantCode,
    })
  },
  tenantOwnerCompanies: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.tenantOwnerCompanies, {
      orgCode,
    })
  },
  tenantOwnerCompaniesCreate: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.tenantOwnerCompaniesCreate, {
      orgCode,
    })
  },
  tenantOwnerCompaniesUpdate: (orgCode: string): string => {
    return dynamicPath<OrgPathParams>(PathTemplate.tenantOwnerCompaniesUpdate, {
      orgCode,
    })
  },
  tenantsOfTenantOwnerCompanyList: (
    orgCode: string,
    tenantOwnerCompanyCode: string
  ): string => {
    return dynamicPath<TenantOwnerCompanyPathParams>(
      PathTemplate.tenantsOfTenantOwnerCompanyList,
      {
        orgCode,
        tenantOwnerCompanyCode,
      }
    )
  },
  tenantsOfTenantOwnerCompanyUpdate: (
    orgCode: string,
    tenantOwnerCompanyCode: string
  ): string => {
    return dynamicPath<TenantOwnerCompanyPathParams>(
      PathTemplate.tenantsOfTenantOwnerCompanyUpdate,
      {
        orgCode,
        tenantOwnerCompanyCode,
      }
    )
  },
}

export default Path
