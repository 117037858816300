import React, { memo } from 'react'
import { Box } from '@mui/material'
import { EllipseIcon } from 'src/components/atoms'
import { TenantOwnerCompany } from 'src/slices/services/api'
import { formatDateTime } from 'src/utils/date'
import styles from './styles.module.scss'

export type HeaderProps = {
  tenantOwnerCompany?: TenantOwnerCompany
} & React.StyledProps

const Header: React.StyledFC<HeaderProps> = memo(
  ({ tenantOwnerCompany }: HeaderProps) => {
    return (
      <Box>
        <Box>
          <span className={styles.code}>{tenantOwnerCompany?.code}</span>
          <span className={styles.title}>{tenantOwnerCompany?.name}</span>
        </Box>
        <Box className={styles.statusContainer}>
          <EllipseIcon color={tenantOwnerCompany?.active ? 'green' : 'gray'} />
          <span className={styles.status}>
            {tenantOwnerCompany?.active ? '利用中' : '停止中'}
          </span>
          <span className={styles.label}>最終更新日時</span>
          <span className={styles.updateAt}>
            {tenantOwnerCompany?.updatedAt
              ? formatDateTime(tenantOwnerCompany?.updatedAt)
              : '-'}
          </span>
        </Box>
      </Box>
    )
  }
)

Header.displayName = 'Header'
export default Header
