/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import { Selector } from 'react-redux'
import { PaginatedRequestState, RequestStatus } from 'src/domain/request'
import {
  NormalizedTenantSalesReportSummary,
  tenantSalesReportSummaryEntity,
} from 'src/domain/schema'
import {
  ProgressStatusEnum,
  Tenant,
  TenantSalesReportSummary,
  UpdateStatuses,
} from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import serializeError from 'src/slices/services/error'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from 'src/store'
import { purge } from 'src/store/action'
import toKey from 'src/utils/key'

interface TenantSalesReportSummariesState
  extends EntityState<NormalizedTenantSalesReportSummary> {
  query: {
    [code: string]: PaginatedRequestState
  }
  revertReportedStatus: RequestStatus
  bulkFinalizationStatus: RequestStatus
  syncStatus: RequestStatus
}

const tenantSalesReportSummariesAdapter =
  createEntityAdapter<NormalizedTenantSalesReportSummary>()

export const initialState: TenantSalesReportSummariesState =
  tenantSalesReportSummariesAdapter.getInitialState({
    query: {},
    revertReportedStatus: 'idle',
    bulkFinalizationStatus: 'idle',
    syncStatus: 'idle',
  })

type GetTenantSalesReportSummariesParams = {
  orgCode: string
  storeCode: string
  date: string
}

interface GetTenantSalesReportSummariesEntities {
  tenantSalesReportSummaries: Record<string, NormalizedTenantSalesReportSummary>
  tenants: Record<string, Tenant>
}

interface GetTenantSalesReportSummariesReturned {
  entities: GetTenantSalesReportSummariesEntities
}

export const getTenantSalesReportSummaries = createAsyncThunk<
  GetTenantSalesReportSummariesReturned,
  GetTenantSalesReportSummariesParams,
  AppThunkConfig
>(
  'tenantSalesReportSummaries/getTenantSalesReportSummaries',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      params.orgCode,
      params.storeCode,
      params.date,
      params.date
    )

    const normalized = normalize<
      NormalizedTenantSalesReportSummary,
      GetTenantSalesReportSummariesEntities
    >(response.data, [tenantSalesReportSummaryEntity])
    return {
      entities: normalized.entities,
    }
  },
  { serializeError }
)

type RevertReportedStatusParams = {
  orgCode: string
  storeCode: string
  salesDateIds: string[]
  progressStatus: ProgressStatusEnum
}

type PostBulkFinalizationOfSalesDatesParams = {
  orgCode: string
  storeCode: string
  salesDateIds: string[]
}

type PostSalesReportsCSVParams = {
  orgCode: string
  storeCode: string
  salesDateIds: string[]
}

export const revertReportedStatus = createAsyncThunk<
  void,
  RevertReportedStatusParams,
  AppThunkConfig
>('tenantSalesReport/revertReportedStatus', async (params, { getState }) => {
  const { auth } = getState()
  const updateStatuses: UpdateStatuses = {
    salesDateIds: params.salesDateIds,
    progressStatus: params.progressStatus,
  }
  await new AuthenticatedApi(
    auth.token
  ).patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
    params.orgCode,
    params.storeCode,
    updateStatuses
  )
})

export const postBulkFinalizationOfSalesDates = createAsyncThunk<
  void,
  PostBulkFinalizationOfSalesDatesParams,
  AppThunkConfig
>(
  'tenantSalesReport/postBulkFinalizationOfSalesDates',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(
      auth.token
    ).postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      params.orgCode,
      params.storeCode,
      params.salesDateIds
    )
  }
)

export const postSalesReportsCSV = createAsyncThunk<
  void,
  PostSalesReportsCSVParams,
  AppThunkConfig
>(
  'tenantSalesReport/postSalesReportsCSV',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(
      auth.token
    ).postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
      params.orgCode,
      params.storeCode,
      params.salesDateIds
    )
  },
  { serializeError }
)

const slice = createSlice({
  name: 'tenantSalesReportSummaries',
  initialState,
  reducers: {
    clearTenantSalesReportSummaries: (): TenantSalesReportSummariesState => {
      return initialState
    },
    clearGetTenantSalesReportSummariesByParams: (
      state,
      { payload }: PayloadAction<GetTenantSalesReportSummariesParams>
    ): TenantSalesReportSummariesState => {
      const key = toKey(payload)
      const ids = state.query[key]?.ids ?? []
      tenantSalesReportSummariesAdapter.removeMany(state, ids)

      const deletedState = state
      delete deletedState.query[key]

      return deletedState
    },
    resetRevertReportedStatus: (state): TenantSalesReportSummariesState => {
      return {
        ...state,
        revertReportedStatus: 'idle',
      }
    },
    resetBulkFinalizationStatus: (state): TenantSalesReportSummariesState => {
      return {
        ...state,
        bulkFinalizationStatus: 'idle',
      }
    },
    resetSyncStatus: (state): TenantSalesReportSummariesState => {
      return {
        ...state,
        syncStatus: 'idle',
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(
      getTenantSalesReportSummaries.pending,
      (state, { meta }) => {
        return {
          ...state,
          query: {
            ...state.query,
            [toKey(meta.arg)]: {
              status: 'loading',
            },
          },
        }
      }
    )
    builder.addCase(
      getTenantSalesReportSummaries.fulfilled,
      (state, { payload, meta }) => {
        const { entities } = payload
        const key = toKey(meta.arg)

        if (entities.tenantSalesReportSummaries === undefined) {
          state.query[key] = {
            status: 'succeeded',
          }
          return
        }

        tenantSalesReportSummariesAdapter.upsertMany(
          state,
          entities.tenantSalesReportSummaries ?? {}
        )

        state.query[key] = {
          status: 'succeeded',
          ids: Object.keys(entities.tenantSalesReportSummaries),
        }
      }
    )
    builder.addCase(
      getTenantSalesReportSummaries.rejected,
      (state, { error, meta }) => {
        return {
          ...state,
          query: {
            ...state.query,
            [toKey(meta.arg)]: {
              status: 'failed',
              error,
            },
          },
        }
      }
    )
    builder.addCase(revertReportedStatus.pending, (state) => {
      return {
        ...state,
        revertReportedStatus: 'loading',
      }
    })
    builder.addCase(revertReportedStatus.fulfilled, () => {
      return {
        ...initialState,
        revertReportedStatus: 'succeeded',
      }
    })
    builder.addCase(revertReportedStatus.rejected, (state, { error }) => {
      return {
        ...state,
        revertReportedStatus: 'failed',
        error,
      }
    })
    builder.addCase(postBulkFinalizationOfSalesDates.pending, (state) => {
      return {
        ...state,
        bulkFinalizationStatus: 'loading',
      }
    })
    builder.addCase(postBulkFinalizationOfSalesDates.fulfilled, () => {
      return {
        ...initialState,
        bulkFinalizationStatus: 'succeeded',
      }
    })
    builder.addCase(
      postBulkFinalizationOfSalesDates.rejected,
      (state, { error }) => {
        return {
          ...state,
          bulkFinalizationStatus: 'failed',
          error,
        }
      }
    )
    builder.addCase(postSalesReportsCSV.pending, (state) => {
      return {
        ...state,
        syncStatus: 'loading',
      }
    })
    builder.addCase(postSalesReportsCSV.fulfilled, () => {
      return {
        ...initialState,
        syncStatus: 'succeeded',
      }
    })
    builder.addCase(postSalesReportsCSV.rejected, (state, { error }) => {
      return {
        ...state,
        syncStatus: 'failed',
        error,
      }
    })
  },
})

export default slice.reducer
export const {
  clearTenantSalesReportSummaries,
  resetRevertReportedStatus,
  resetBulkFinalizationStatus,
  resetSyncStatus,
} = slice.actions

export const {
  selectById: selectTenantSalesReportSummaryById,
  selectEntities: selectTenantSalesReportSummaryEntities,
  selectAll: selectAllTenantSalesReportSummaries,
} = tenantSalesReportSummariesAdapter.getSelectors<TenantSalesReportSummariesState>(
  (state) => state
)

export const selectTenantSalesReportSummariesByParams = (
  params: GetTenantSalesReportSummariesParams
): Selector<RootState, NormalizedTenantSalesReportSummary[] | undefined> => {
  const key = toKey(params)
  return createSelector(
    [(state) => state.entities.tenantSalesReportSummaries],
    (state) => {
      if (!state.query[key]) {
        return undefined
      }
      const entities = selectTenantSalesReportSummaryEntities(state)
      /* eslint-disable @typescript-eslint/no-explicit-any */
      return state.query[key].ids
        ?.map((id: any) => entities[id]) // eslint-disable-line @typescript-eslint/no-explicit-any
        .filter(
          (
            entity: any
          ): entity is NormalizedTenantSalesReportSummary => // eslint-disable-line @typescript-eslint/no-explicit-any
            entity !== undefined
        )
      /* eslint-enable */
    }
  )
}

export const selectStoreSalesReportSummariesRequestStateByParams = (
  params: GetTenantSalesReportSummariesParams
): Selector<RootState, PaginatedRequestState> => {
  return createSelector(
    [(state) => state.entities.tenantSalesReportSummaries],
    (state) => {
      return (
        state.query[toKey(params)] ?? {
          status: 'idle',
        }
      )
    }
  )
}

export const selectDenormalizedTenantSalesReportSummariesByParams = (
  params: GetTenantSalesReportSummariesParams
): Selector<RootState, TenantSalesReportSummary[] | undefined> => {
  return createSelector(
    [
      (state) => state.entities.tenantSalesReportSummaries,
      (state) => state.entities.tenants,
    ],
    (state, tenants) => {
      const ids = state.query[toKey(params)]?.ids
      if (!ids) {
        return undefined
      }
      return denormalize(ids, [tenantSalesReportSummaryEntity], {
        tenantSalesReportSummaries: state.entities,
        tenants: tenants.entities,
      })
    }
  )
}
