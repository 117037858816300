import React from 'react'
import { Box } from '@mui/material'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import Pagination from 'src/components/molecules/Pagination'
import SmallGuide from 'src/components/molecules/SmallGuide'
import { RoleEnum } from 'src/slices/services/api'
import Headline from '../../components/Headline'
import SalesReports from '../../components/SalesReports'
import SalesReportsFilter from '../../components/SalesReportsFilter'
import {
  DateRange,
  DefaultFilterValues,
  FilterOptions,
  SalesReportType,
} from '../../type'
import style from './styles.module.scss'

export type SalesReportsTabProps = {
  isLoaded: boolean
  currentPage: number
  rowsPerPage: number
  role?: RoleEnum
  orgCode: string
  stickyTop?: number
  salesReports: SalesReportType[][]
  totalCount: number
  defaultFilterValues: DefaultFilterValues
  date: DateRange
  filterForMismatch: boolean
  onClickTenantName: (code: string) => void
  onClickPagination: (page: number) => void
  onChangeDate: (date: DateRange) => void
  onChangeFilter: (filterOptions: FilterOptions) => void
  onChangeFilterForMismatch: (value: boolean) => void
}

const SalesReportsTab: React.StyledFC<SalesReportsTabProps> = ({
  isLoaded,
  currentPage,
  rowsPerPage,
  role,
  orgCode,
  stickyTop = 0,
  salesReports,
  totalCount,
  defaultFilterValues,
  date,
  filterForMismatch,
  onClickTenantName,
  onClickPagination,
  onChangeDate,
  onChangeFilter,
  onChangeFilterForMismatch,
}: SalesReportsTabProps) => {
  const handleChangeFilterParam = (filterOptions: FilterOptions) => {
    onChangeFilter(filterOptions)
  }

  const renderList = () => {
    return totalCount === 0 ? (
      <SmallGuide
        className={style.guide}
        icon="pray"
        message="該当の結果はありません"
        description="検索キーワードを確認して再度試してください"
      />
    ) : (
      <Box className={style.listWrapper}>
        {salesReports.map((salesReport) => (
          <SalesReports
            key={salesReport[0].id}
            role={role}
            stickyTop={stickyTop}
            salesReports={salesReport}
            filterForMismatch={filterForMismatch}
            onClickTenantName={onClickTenantName}
          />
        ))}
        <Pagination
          className={style.pagination}
          currentPage={currentPage}
          pageCount={Math.ceil(totalCount / rowsPerPage)}
          onClick={onClickPagination}
        />
      </Box>
    )
  }

  return (
    <Box className={style.root}>
      <Headline className={style.header} headline="売上報告リスト" />
      <Box
        sx={{ position: 'sticky', top: stickyTop }}
        className={style.filterWrapper}
      >
        <SalesReportsFilter
          totalCount={totalCount}
          defaultFilterValues={defaultFilterValues}
          orgCode={orgCode}
          date={date}
          onChangeDate={onChangeDate}
          onChangeFilterParams={handleChangeFilterParam}
          onChangeFilterForMismatch={onChangeFilterForMismatch}
        />
      </Box>
      {isLoaded ? renderList() : <CenteringLoading />}
    </Box>
  )
}

export default SalesReportsTab
