/* eslint-disable no-irregular-whitespace */

export const RegexNoSpaceOnly = /^(?![　\s]+$)|^$.+/
export const RegexEmail =
  /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
export const RegexAlphanumeric = /^[a-zA-Z0-9]*$/
export const RegexNumeric = /^[0-9]*$/
export const RegexThreeDigitsSeparator = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g
export const RegexTrailingZerosAndDot = /(\.\d*?[1-9])0+$|\.0+$|\.$/g

export const AlphanumericRegexErrorMessage = '半角英数字で入力してください'
export const NumericRegexErrorMessage = '半角数字で入力してください'
export const InvalidInputErrorMessage = '無効な文字が入力されています'
