import { TenantRegister } from 'src/slices/services/api'
import {
  Cell,
  ChildRow,
  InputCell,
} from './components/TenantSalesReportTableRow'

export enum FormNameEnum {
  registers = 'registers',
  selectSalesDate = 'selectSalesDate',
  manageMessage = 'manageMessage',
  filterForMismatch = 'filterForMismatch',
}

type InputRow = {
  value?: number | string
}
type ParentRow = InputRow & {
  children?: InputRow[]
}

export type FormTypesTenantSalesReport = {
  [FormNameEnum.registers]: {
    rows: ParentRow[]
  }[]
  [FormNameEnum.selectSalesDate]: string
  [FormNameEnum.manageMessage]: string
  [FormNameEnum.filterForMismatch]: boolean
}

type Row = {
  key: string
  cells: Cell[]
  childRows: ChildRow[]
  inputCell: InputCell
  tooltipText?: string
  error?: boolean
}

export type RowWithSum = Row & {
  sumCell?: number
}

export type Register = {
  key: string
  rows: Row[]
  concatOriginal?: string
  separatedOriginal?: Array<string>
  resized?: string
  isPdf?: boolean
  isFirst?: boolean
  tenantRegister?: TenantRegister
}

export type RegisterWithSum = {
  rows: RowWithSum[]
} & Omit<Register, 'rows'>

export type DateRange = {
  start: string
  end: string
}
