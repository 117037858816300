import React from 'react'
import clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import styles from './styles.module.scss'

export type RadioOption = {
  label: string
  value: string
}

export type RadioGroupWithFormControlProps = {
  name: string
  options: RadioOption[]
  required?: boolean
  defaultValue?: string
} & React.StyledProps

const requiredErrorMessage = '必須項目です'

export const RadioGroupWithFormControl: React.StyledFC<
  RadioGroupWithFormControlProps
> = ({
  className,
  name,
  options,
  required,
  defaultValue,
}: RadioGroupWithFormControlProps) => {
  const { control } = useFormContext()

  return (
    <Box className={clsx(styles.root, className)}>
      <Controller
        control={control}
        name={name}
        rules={{ required: required && requiredErrorMessage }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup
            name={name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value)
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  )
}
