import React from 'react'
import QR from 'qrcode.react'

export type QRCodeProps = {
  src: string
  size?: number
} & React.StyledProps

export const QRCode: React.StyledFC<QRCodeProps> = ({
  className,
  src,
  size,
}: QRCodeProps) => {
  return <QR value={src} renderAs="canvas" size={size} className={className} />
}
