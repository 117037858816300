/* eslint-disable react/prop-types */
import React, { ChildrenProps } from 'react'
import clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import { MenuProps, Select as MaterialSelect } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import colors from 'src/styles/color'
import { Icon } from '../Icon'

export type Color = 'primary' | 'green' | 'red' | 'gray'

export type SelectProps = {
  name: string
  menuClassName?: string
  defaultValue?: string | number
  variant: 'standard' | 'outlined'
  onOpen?: () => void
  disabled?: boolean
  renderValue?: (value: unknown) => React.ReactNode
  color?: Color
} & React.StyledProps &
  ChildrenProps

const useStyles = makeStyles({
  select: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    alignItems: 'center',
    '&:focus': {
      background: 'none',
      outline: 'none',
    },
    '& .MuiInput-input': {
      marginRight: 0,
      '& *': {
        overflow: 'hidden',
        margin: '0 12px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '&:focus': {
        background: 'none',
        outline: 'none',
      },
    },
  },
  standard: {
    height: 28,
    borderRadius: '60px',
    color: colors.black,
    fontSize: 14,
    '&:hover': {
      background: colors.black50,
    },
  },
  outlined: {
    height: 36,
    border: `1px solid`,
    borderRadius: '4px',
    background: colors.white,
    fontSize: 15,
    '&:hover': {
      background: colors.lightGray,
    },
  },
  primary: {
    borderColor: colors.lightGray,
    color: colors.black,
    fill: colors.black,
  },
  green: {
    borderColor: colors.green,
    color: colors.green,
    fill: colors.green,
  },
  red: {
    borderColor: colors.red,
    color: colors.red,
    fill: colors.red,
  },
  gray: {
    borderColor: colors.gray03,
    color: colors.gray03,
    fill: colors.gray03,
  },
  menuPaper: {
    boxSizing: 'border-box',
    padding: '8px 0',
    borderRadius: '6px',
    boxShadow: `0px 4px 14px ${colors.black100}`,
  },
  icon: {
    top: 'unset',
    right: '11px',
  },
  disabled: {
    backgroundColor: '#f0f0f0',
    '&:hover': {
      background: 'inherit',
    },
  },
})

export const Select: React.StyledFC<SelectProps> = ({
  className,
  name,
  menuClassName = '',
  defaultValue = '',
  onOpen,
  children,
  variant,
  disabled,
  renderValue,
  color = 'primary',
  style,
}) => {
  const { control } = useFormContext()
  const classes = useStyles()

  const menuProps: Partial<MenuProps> = {
    classes: {
      paper: menuClassName,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  }

  // NOTE: MUIのinput要素をuse-formで制御するにはControllerを用いる。
  // https://scrapbox.io/mrsekut-p/react-hook-form%E3%81%A7register%E3%81%A8Controller%E3%81%AE%E3%81%A9%E3%81%A1%E3%82%89%E3%82%92%E4%BD%BF%E3%81%86%E3%81%8B
  // https://react-hook-form.com/docs/usecontroller/controller

  // NOTE: muiのカスタマイズ方法
  // https://mui.com/material-ui/customization/how-to-customize/

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <MaterialSelect
          {...field}
          className={clsx(
            className,
            classes.select,
            classes[variant],
            variant === 'outlined' && classes[color],
            disabled ? classes.disabled : ''
          )}
          style={style}
          disabled={disabled}
          renderValue={renderValue}
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent={(props) => (
            <Icon
              className={clsx(
                classes.icon,
                classes[color],
                // SelectのデフォルトのIconにはスタイルが適用されており、
                // 自前のIconを指定したことで、そのスタイルが適用されない。
                // makeStylesで同じようにJSSを書くより、MaterialUIのスタイルをそのまま適用したいから、こう書いている。
                `material-icons ${props.className}`
              )}
              icon="arrowDropDown"
            />
          )}
          variant="standard"
          onOpen={onOpen}
          MenuProps={menuProps}
          displayEmpty
          disableUnderline
        >
          {children}
        </MaterialSelect>
      )}
    />
  )
}
