import { ApiResponse } from 'src/domain/api'
import { EmailExistence } from 'src/slices/services/api'
import { axiosErrorMessage } from 'src/slices/services/error'
import NoAuthApi from 'src/slices/services/noAuthApi'

export const useApiEmailExistence = () => {
  const getEmailExistence = async (
    email: string
  ): Promise<ApiResponse<EmailExistence>> => {
    try {
      const res = await new NoAuthApi().getMembersEmailExistence(email)
      return { success: true, data: res.data }
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  return { getEmailExistence }
}
