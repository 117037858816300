import React from 'react'
import clsx from 'clsx'
import { Box, IconButton } from '@mui/material'
import { Card, Icon } from 'src/components/atoms'
import commonStyles from '../../common.module.scss'
import { TenantRegisterRow } from '../../registerReducer'
import RegisterRow from '../RegisterRow'
import styles from './styles.module.scss'

export type TenantRegistersFormProps = {
  registers: TenantRegisterRow[]
  showAllError: boolean
  onRemoveRegister: (register: TenantRegisterRow) => void
  onAddRegister: () => void
  onValidate: (validStatus: {
    isValid: boolean
    rowNum: number
    id?: string
  }) => void
  onRegisterNumberChange: (
    register: TenantRegisterRow,
    currentRegister: string
  ) => void
  onRegisterCategoryChange: (currentRegister: TenantRegisterRow) => void
} & React.StyledProps

const TenantRegistersForm: React.StyledFC<TenantRegistersFormProps> = ({
  className,
  registers,
  showAllError,
  onRemoveRegister,
  onAddRegister,
  onValidate,
  onRegisterNumberChange,
  onRegisterCategoryChange,
}: TenantRegistersFormProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      <Box className={commonStyles.leftColumn}>
        <Box className={commonStyles.leftCard}>
          <Card title="レジ情報" className={styles.card}>
            {registers.map((register) => (
              <RegisterRow
                className={styles.registerRow}
                key={register.rowNum}
                rowNum={register.rowNum}
                register={register}
                showAllError={showAllError}
                onRemoveRegister={(deletedRegister) =>
                  onRemoveRegister(deletedRegister)
                }
                onValidate={onValidate}
                onRegisterNumberChange={onRegisterNumberChange}
                onRegisterCategoryChange={onRegisterCategoryChange}
              />
            ))}
            <Box className={commonStyles.flexContainer}>
              <IconButton className={styles.addButton} onClick={onAddRegister}>
                <Icon icon="plus" className={styles.addIcon} />

                <span className={styles.addButtonText}>レジを追加する</span>
              </IconButton>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box className={commonStyles.rightColumn} />
    </Box>
  )
}

export default TenantRegistersForm
