import React, { memo, useCallback } from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { EllipseIcon, IconName } from 'src/components/atoms'
import {
  TextCell,
  AppTableRow,
  IconCell,
  ChildCell,
} from 'src/components/tables'
import { getTenantStatusTitle, getTenantStatusColor } from 'src/domain/tenant'
import { ShownTenant } from '../../updateReducer'
import styles from './styles.module.scss'

export type UpdateTableRowProps = {
  shownTenant: ShownTenant
  onAddAssociation: (tenantId: string) => void
  onRemoveAssociation: (tenantId: string) => void
} & React.StyledProps

const UpdateTableRow: React.StyledFC<UpdateTableRowProps> = memo(
  ({
    shownTenant,
    onAddAssociation,
    onRemoveAssociation,
  }: UpdateTableRowProps) => {
    const {
      alreadyAssociated,
      associatedToOtherTenantOwnerCompany,
      associationAdded,
      associationRemoved,
    } = shownTenant

    const disabled = associatedToOtherTenantOwnerCompany
    const notAssociated =
      !disabled &&
      ((!alreadyAssociated && !associationAdded) ||
        (alreadyAssociated && associationRemoved))
    const associated =
      !disabled &&
      ((alreadyAssociated && !associationRemoved) ||
        (!alreadyAssociated && associationAdded))

    const handleClickRow = () => {
      if (notAssociated) onAddAssociation(shownTenant.tenant.id)
      if (associated) onRemoveAssociation(shownTenant.tenant.id)
    }

    const iconName = useCallback((): IconName | undefined => {
      if (disabled) return undefined
      if (associated) return 'checked'
      return 'plus'
    }, [disabled, associated])

    return (
      <AppTableRow
        disabled={disabled}
        className={clsx({
          [styles.disabled]: disabled,
          [styles.notAssociated]: notAssociated,
          [styles.associated]: associated,
          [styles.row]: true,
        })}
        key={shownTenant.tenant.id}
        onClick={() => handleClickRow()}
      >
        {iconName() ? (
          <IconCell
            className={clsx(styles.iconCel, {
              [styles.iconDisabled]: disabled,
              [styles.iconNotAssociated]: notAssociated,
              [styles.iconAssociated]: associated,
            })}
            icon={iconName() as IconName}
            onClick={() => handleClickRow()}
          />
        ) : (
          <TextCell className={styles.iconCell} text="" width={30} />
        )}
        <ChildCell>
          <Typography className={styles.statusWord}>
            <EllipseIcon
              color={getTenantStatusColor(shownTenant.tenant.status)}
            />
            <span className={styles.status}>
              {getTenantStatusTitle(shownTenant.tenant.status)}
            </span>
          </Typography>
        </ChildCell>
        <TextCell text={shownTenant.tenant.code} width={100} />
        <TextCell text={shownTenant.tenant.name} width={150} />
        <TextCell text={shownTenant.store.code} width={100} />
        <TextCell text={shownTenant.store.name} width={150} />
        <TextCell
          text={shownTenant.tenantOwnerCompany?.code || '-'}
          width={100}
        />
        <TextCell
          text={shownTenant.tenantOwnerCompany?.name || '-'}
          width={180}
        />
      </AppTableRow>
    )
  }
)

UpdateTableRow.displayName = 'UpdateTableRow'
export default UpdateTableRow
