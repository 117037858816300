import React, { memo } from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import Breadcrumbs, { Path } from '../Breadcrumbs'
import style from './styles.module.scss'

export type AppHeaderProps = {
  breadcrumbs: Path[]
  title: string
} & React.StyledProps

const AppHeader: React.StyledFC<AppHeaderProps> = memo(
  ({ className, breadcrumbs, title }: AppHeaderProps) => {
    return (
      <Box className={clsx(className, style.wrapper)}>
        <Breadcrumbs className={style.breadcrumbs} paths={breadcrumbs} />
        <Box>
          <Typography className={style.title}>{title}</Typography>
        </Box>
      </Box>
    )
  }
)
AppHeader.displayName = 'AppHeader'
export default AppHeader
