import React from 'react'
import clsx from 'clsx'
import { Button as MaterialButton } from '@mui/material'
import style from './styles.module.scss'

export type ButtonProps = {
  color: 'primary' | 'secondary'
  disabled?: boolean
  size: 'small' | 'medium' | 'large'
  title: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'normal' | 'light'
  shape?: 'square' | 'round'
  onClick?: () => void
} & React.StyledProps

export const Button: React.StyledFC<ButtonProps> = ({
  className,
  color = 'primary',
  disabled,
  size = 'medium',
  title,
  type = 'button',
  variant = 'normal',
  shape = 'square',
  onClick,
}: ButtonProps) => {
  return (
    <MaterialButton
      classes={{
        root: clsx(style.root, shape === 'round' && style.round),
        outlinedSecondary: style.secondary,
        containedPrimary: style.primary,
      }}
      className={clsx(className, style[size], style[variant])}
      color={color}
      disableRipple
      disabled={disabled}
      variant={color === 'primary' ? 'contained' : 'outlined'}
      type={type}
      onClick={onClick}
    >
      {title}
    </MaterialButton>
  )
}
