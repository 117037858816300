import { TenantWithStoreAndTenantOwnerCompany } from 'src/slices/services/api'

export type NetworkDataWithAlreadyAssociated =
  TenantWithStoreAndTenantOwnerCompany & {
    alreadyAssociated: boolean
    associatedToOtherTenantOwnerCompany: boolean
  }
export interface ShownTenant extends NetworkDataWithAlreadyAssociated {
  associationRemoved: boolean
  associationAdded: boolean
}

type Payload = {
  networkData?: TenantWithStoreAndTenantOwnerCompany[]
  tenantOwnerCompanyId?: string
  totalCount?: number
  tenantId?: string
  networkAssociatedTenantsCount?: number
}

export type Action = {
  type:
    | 'SET_TENANT_OWNER_COMPANY_ID'
    | 'UPDATE_SEARCH_CONDITION'
    | 'ADD_NETWORK_DATA'
    | 'LOAD_MORE'
    | 'SHOW_UPDATED'
    | 'SHOW_ALL'
    | 'ADD_ASSOCIATION'
    | 'REMOVE_ASSOCIATION'
  payload?: Payload
}

export type TenantOfTenantOwnerCompanyUpdateState = {
  networkData: NetworkDataWithAlreadyAssociated[]
  tenantOwnerCompanyId: string
  isLoading: boolean
  isLoadingMore: boolean
  currentCount: number
  totalCount: number
  hasMoreContent: boolean
  shownTenants: ShownTenant[]
  hasUpdate: boolean
  updatedData: { [tenantId: string]: ShownTenant }
  showUpdated: boolean
  associatedTenantsCount: number
  networkAssociatedTenantsCount: number // serverから取得した初期値
}

export const INITIAL_STATE: TenantOfTenantOwnerCompanyUpdateState = {
  networkData: [],
  tenantOwnerCompanyId: '',
  isLoading: true,
  isLoadingMore: false,
  currentCount: 0,
  totalCount: 0,
  hasMoreContent: false,
  shownTenants: [],
  hasUpdate: false,
  updatedData: {},
  showUpdated: false,
  associatedTenantsCount: 0,
  networkAssociatedTenantsCount: 0,
}

export const getCurrentAssociatedTenantsCount = (
  initialCount: number,
  updatedData: { [tenantId: string]: ShownTenant }
): number => {
  let count = initialCount

  Object.values(updatedData).forEach((tenant) => {
    if (tenant.associationAdded && !tenant.alreadyAssociated) {
      count++
    }
    if (tenant.associationRemoved && tenant.alreadyAssociated) {
      count--
    }
  })

  return count
}

export const reducerFunc = (
  state: TenantOfTenantOwnerCompanyUpdateState,
  action: Action
): TenantOfTenantOwnerCompanyUpdateState => {
  let newState: TenantOfTenantOwnerCompanyUpdateState
  switch (action.type) {
    case 'SET_TENANT_OWNER_COMPANY_ID':
      if (action.payload?.tenantOwnerCompanyId) {
        const newNetworkData = state.networkData.map((data) => {
          let alreadyAssociated = false
          let associatedToOtherTenantOwnerCompany = false
          if (data.tenantOwnerCompany?.id) {
            if (
              data.tenantOwnerCompany?.id ===
              action.payload?.tenantOwnerCompanyId
            ) {
              alreadyAssociated = true
            } else {
              associatedToOtherTenantOwnerCompany = true
            }
          }
          return {
            ...data,
            alreadyAssociated,
            associatedToOtherTenantOwnerCompany,
          }
        })
        newState = {
          ...state,
          networkData: newNetworkData,
          tenantOwnerCompanyId: action.payload.tenantOwnerCompanyId,
        }
      } else {
        newState = {
          ...state,
        }
      }
      break
    case 'UPDATE_SEARCH_CONDITION':
      newState = {
        ...state,
        networkData: [],
        shownTenants: [],
        isLoading: true,
        totalCount: 0,
        currentCount: 0,
        hasMoreContent: false,
      }
      break
    case 'ADD_NETWORK_DATA':
      {
        const originalContentCount = action.payload?.networkData?.length
        const newNetworkData: TenantWithStoreAndTenantOwnerCompany[] =
          action.payload?.networkData?.filter((data) => {
            return !state.networkData.find(
              (d) => d.tenant.id === data.tenant.id
            )
          }) || []
        let nextCurrentCount = state.currentCount
        if (
          newNetworkData.length > 0 &&
          typeof originalContentCount === 'number'
        ) {
          nextCurrentCount += originalContentCount
        }
        const totalCount = action.payload?.totalCount
        const networkAssociatedTenantsCount =
          action.payload?.networkAssociatedTenantsCount
        let hasMoreContent = false
        if (totalCount && nextCurrentCount < totalCount) {
          hasMoreContent = true
        }
        const newNetworkDataWithPreAssociation = newNetworkData.map((data) => {
          let alreadyAssociated = false
          let associatedToOtherTenantOwnerCompany = false
          if (data.tenantOwnerCompany?.id) {
            if (data.tenantOwnerCompany?.id === state.tenantOwnerCompanyId) {
              alreadyAssociated = true
            } else {
              associatedToOtherTenantOwnerCompany = true
            }
          }
          return {
            ...data,
            alreadyAssociated,
            associatedToOtherTenantOwnerCompany,
          }
        })
        newState = {
          ...state,
          networkData: [
            ...state.networkData,
            ...newNetworkDataWithPreAssociation,
          ],
          currentCount: nextCurrentCount,
          hasMoreContent,
          isLoading: false,
          isLoadingMore: false,
          totalCount: totalCount || 0,
          networkAssociatedTenantsCount: networkAssociatedTenantsCount || 0,
          associatedTenantsCount: getCurrentAssociatedTenantsCount(
            networkAssociatedTenantsCount || 0,
            state.updatedData
          ),
        }
      }
      break

    case 'ADD_ASSOCIATION':
      if (action.payload?.tenantId) {
        const updatedData: { [tenantId: string]: ShownTenant } = {
          ...state.updatedData,
        }

        if (state.updatedData[action.payload?.tenantId]) {
          // 1. updatedDataに存在し。removedされていた場合 → updatedDataを削除
          if (state.updatedData[action.payload?.tenantId].associationRemoved) {
            delete updatedData[action.payload?.tenantId]
          }
          // 2, updatedDataに存在し、addedの場合 → イベントは発生しないし、たとえ発生しても何もしないでいい。
          // 何もしない
        } else {
          // updatedDataに存在せずnetwork dataにあったら、updatedDataにaddで追加する。
          const tenant = state.networkData.find(
            (data) => data.tenant.id === action.payload?.tenantId
          )
          if (tenant) {
            updatedData[action.payload?.tenantId] = {
              ...tenant,
              associationAdded: true,
              associationRemoved: false,
            }
          }
          // updatedDataに存在せず network dataにも存在しない場合 → イベントは発生しないし、たとえ発生しても何もしないでいい。
        }
        newState = {
          ...state,
          updatedData,
          associatedTenantsCount: getCurrentAssociatedTenantsCount(
            state.networkAssociatedTenantsCount,
            updatedData
          ),
        }
      } else {
        newState = { ...state }
      }
      break
    case 'REMOVE_ASSOCIATION':
      if (action.payload?.tenantId) {
        const updatedData: { [tenantId: string]: ShownTenant } = {
          ...state.updatedData,
        }
        if (state.updatedData[action.payload?.tenantId]) {
          if (state.updatedData[action.payload?.tenantId].associationAdded) {
            // 1. updatedDataに存在し。addedされていた場合 → updatedDataを削除
            delete updatedData[action.payload?.tenantId]
          }
          // 2, updatedに存在し、removedの場合 → イベントは発生しないし、たとえ発生しても何もしないでいい。
          // 何もしない
        } else {
          // updatedDataに存在せずnetwork dataにあったら、 updatedDataにremoveで追加する。
          const tenant = state.networkData.find(
            (data) => data.tenant.id === action.payload?.tenantId
          )
          if (tenant) {
            updatedData[action.payload?.tenantId] = {
              ...tenant,
              associationAdded: false,
              associationRemoved: true,
            }
          }
          // updatedDataに存在せず network dataにも存在しない場合 → イベントは発生しないし、たとえ発生しても何もしない
        }
        newState = {
          ...state,
          updatedData,
          associatedTenantsCount: getCurrentAssociatedTenantsCount(
            state.networkAssociatedTenantsCount,
            updatedData
          ),
        }
      } else {
        newState = { ...state }
      }
      break
    case 'SHOW_UPDATED':
      newState = {
        ...state,
        showUpdated: true,
        hasMoreContent: false,
      }
      break
    case 'SHOW_ALL':
      {
        let hasMoreContent = false
        if (state.currentCount < state.totalCount) {
          hasMoreContent = true
        }
        newState = {
          ...state,
          showUpdated: false,
          hasMoreContent,
        }
      }
      break
    case 'LOAD_MORE':
      newState = {
        ...state,
        isLoadingMore: true,
      }
      break
    default:
      newState = {
        ...state,
      }
  }

  const shownTenants: ShownTenant[] = []
  if (newState.showUpdated) {
    shownTenants.push(...Object.values(newState.updatedData))
  } else {
    const newShownTenants = newState.networkData.map((data) => {
      const tenantId = data.tenant.id
      if (newState.updatedData[tenantId]) {
        return newState.updatedData[tenantId]
      }
      return { ...data, associationAdded: false, associationRemoved: false }
    })
    shownTenants.push(...newShownTenants)
  }
  newState.hasUpdate = Object.keys(newState.updatedData).length > 0
  newState.shownTenants = shownTenants

  return newState
}
