/* eslint-disable react/prop-types */
import React, { PropsWithChildren, memo } from 'react'
import clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import makeStyles from '@mui/styles/makeStyles'
import { CancelButton, Icon } from 'src/components/atoms'
import color from 'src/styles/color'

export type SearchTextFieldProps = {
  name: string
  type: string
  defaultValue?: string | number
  placeholder?: string
  maxLength?: number
  onChange?: (value: string) => void
  onClickClear: () => void
} & React.StyledProps

const useStyles = makeStyles({
  textField: {
    display: 'flex',
    width: 200,
    height: 28,
    boxSizing: 'border-box',
    borderRadius: 30,
    background: color.lightGray02,
    cursor: 'text',
  },
  errorInput: {
    border: `1px solid ${color.red}`,
  },
  input: {
    width: '100%',
    border: 'none',
    margin: '6px 0',
    background: 'transparent',
    fontSize: 14,
    lineHeight: '17px',
    outline: 'none',
    '&::placeholder': {
      color: color.black300,
    },
  },
  icon: {
    margin: '6px 5px 6px 12px',
  },
  button: {
    margin: '6px 11px',
  },
})

const SearchTextField: React.StyledFC<SearchTextFieldProps> = memo(
  ({
    className,
    name,
    type,
    defaultValue,
    placeholder,
    maxLength,
    onChange,
    onClickClear,
  }: PropsWithChildren<SearchTextFieldProps>) => {
    const classes = useStyles()
    const {
      trigger,
      register,
      resetField,
      formState: { errors },
    } = useFormContext()
    const showClear = useWatch({ name, defaultValue })

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (onChange) onChange(e.currentTarget.value)
      }
    }

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === '' && onChange)
        onChange(e.currentTarget.value)
    }

    const handleClickClear = () => {
      resetField(name)
      onClickClear()
    }

    return (
      <div
        className={clsx(
          className,
          classes.textField,
          errors[name] && classes.errorInput
        )}
      >
        <Icon className={classes.icon} icon="search" fontSize="small" />
        <input
          // NOTE: maxLength:30をPropsで受け取ったときに、useFormのmaxLengthは31文字目からエラーになってしまうため、30文字目からエラーを出すために-1をしている
          {...register(name, { maxLength: maxLength && maxLength - 1 })}
          className={classes.input}
          name={name}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          maxLength={maxLength}
          onInput={(e) => handleInput(e)}
          onKeyUp={() => trigger(name)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        {showClear && (
          <CancelButton
            className={classes.button}
            onClick={handleClickClear}
            fontSize="small"
          />
        )}
      </div>
    )
  }
)
SearchTextField.displayName = 'SearchTextField'
export default SearchTextField
