import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import ErrorBoundary from './containers/Bugsnag'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import store, { persistor } from './store'

// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const container = document.getElementById('root')

if (process.env.REACT_APP_USE_MSW === 'TRUE') {
  ;(async () => {
    const { worker } = await import('./mocks/browser')
    worker.start()
  })()
}

if (container instanceof Element) {
  const root = createRoot(container)
  root.render(
    <ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </ErrorBoundary>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
