import { Option } from 'src/components/tables/Cells/SelectCell'
import { ENABLE_ATTACH_EDITOR_STORE_MANAGER_IN_OPERATION_CENTER_ROLE } from '../../domain/org'
import { RoleEnum } from '../../slices/services/api'
import {
  editorSalesReportManagerIndex,
  getRoleTitle,
  roleOptions,
} from './type'

export const getRoleOptions = (orgCode: string): Option[] => {
  if (
    !ENABLE_ATTACH_EDITOR_STORE_MANAGER_IN_OPERATION_CENTER_ROLE.includes(
      orgCode
    )
  )
    return roleOptions

  const newRoleOptions = [...roleOptions]

  const editorStoreManagerInOperationCenter =
    RoleEnum.EditorStoreManagerInOperationCenter
  newRoleOptions.splice(editorSalesReportManagerIndex + 1, 0, {
    value: String(editorStoreManagerInOperationCenter),
    title: getRoleTitle(editorStoreManagerInOperationCenter),
  })
  return newRoleOptions
}
