import React from 'react'
import clsx from 'clsx'
import { TableCell } from '@mui/material'
import { CheckBox } from 'src/components/atoms'
import cellStyles from 'src/components/tables/Cells/styles.module.scss'

export type CheckBoxCellProps = {
  isChecked: boolean
} & React.StyledProps

export const CheckBoxCell = React.memo<CheckBoxCellProps>(
  ({ className, style, isChecked }: CheckBoxCellProps) => {
    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <CheckBox
          isChecked={isChecked}
          shape="rectangle"
          disableRipple
          disabled
        />
      </TableCell>
    )
  }
)

CheckBoxCell.displayName = 'CheckBoxCell'
