import React, { ReactElement, useContext, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import { ToastTriggerContext } from 'src/context/toast.context'
import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import { useApiTenantDetail } from 'src/hooks/useApiTenantDetail'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import { TenantPathParams } from 'src/routes/path'
import {
  TenantDetail,
  TenantRegister,
  TenantDetailsPostRequest,
  UpdatedTenantRegisters,
} from 'src/slices/services/api'
import { trimEmptyString } from 'src/utils/object'
import EditTenantDetailTemplate from '../../templates/EditTenantDetail'
import TenantDetailTabTemplate from '../../templates/TenantDetailTab'

const checkTenantDetailChanges = (
  updatedTenantDetail: TenantDetail,
  currentTenantDetail: TenantDetail | undefined
): boolean => {
  return Object.keys(updatedTenantDetail).some(
    (key) =>
      updatedTenantDetail[key as keyof TenantDetail] !==
      currentTenantDetail?.[key as keyof TenantDetail]
  )
}

const checkRegisterChanges = (
  updatedRegisters: UpdatedTenantRegisters,
  currentTenantRegisters: TenantRegister[]
): boolean => {
  if (
    !updatedRegisters.updatedRegisters?.length &&
    !updatedRegisters.deletedIds?.length
  ) {
    return false
  }

  const updateResult = !updatedRegisters.updatedRegisters?.every(
    (updatedRegister) =>
      currentTenantRegisters.some(
        (tenantRegister) =>
          tenantRegister.id === updatedRegister.id &&
          tenantRegister.registerNumber === updatedRegister.registerNumber &&
          tenantRegister.registerCategory === updatedRegister.registerCategory
      )
  )

  const checkDeleteResult = () => {
    if (
      !updatedRegisters.deletedIds ||
      updatedRegisters.deletedIds.length === 0
    ) {
      return false
    }
    return !updatedRegisters.deletedIds?.every(
      (deletedId) =>
        !currentTenantRegisters.some(
          (tenantRegister) => tenantRegister.id === deletedId
        )
    )
  }

  return updateResult || checkDeleteResult()
}

const TenantDetailTab: React.FC = (): ReactElement => {
  const [tenantDetail, setTenantDetail] = useState<TenantDetail>()
  const [tenantRegisters, setTenantRegisters] = useState<TenantRegister[]>([])
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const { getTenantDetailAndRegisters, postTenantDetailAndRegisters } =
    useApiTenantDetail()
  const toastContext = useContext(ToastTriggerContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const fetchTenantDetailAndRegisters = useCallback(async () => {
    const result = await getTenantDetailAndRegisters(
      orgCode,
      storeCode,
      tenantCode
    )
    if (!result.success) {
      toastContext.sendToast({
        variant: 'error',
        title: result.message,
      })
      return
    }
    setTenantDetail(result.data?.tenantDetail)
    setTenantRegisters(result.data?.tenantRegisters || [])
    setLoaded(true)
  }, [
    orgCode,
    storeCode,
    tenantCode,
    toastContext,
    setTenantDetail,
    setTenantRegisters,
    setLoaded,
    getTenantDetailAndRegisters,
  ])

  const handlePost = useCallback(
    async (formData: TenantDetail, registers?: UpdatedTenantRegisters) => {
      const updatedTenantDetail = trimEmptyString(formData)
      const updatedRegisters: UpdatedTenantRegisters = {
        updatedRegisters: registers?.updatedRegisters?.length
          ? registers.updatedRegisters
          : undefined,
        deletedIds: registers?.deletedIds?.length
          ? registers.deletedIds
          : undefined,
      }

      const hasTenantDetailChanges = checkTenantDetailChanges(
        updatedTenantDetail,
        tenantDetail
      )
      const hasRegisterChanges = checkRegisterChanges(
        updatedRegisters,
        tenantRegisters
      )

      if (!hasTenantDetailChanges && !hasRegisterChanges) {
        toastContext.sendToast({
          variant: 'error',
          title: '変更された内容がありません',
        })
        return
      }

      let updatedTenantDetailAndRegister: TenantDetailsPostRequest
      if (
        VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode) &&
        (updatedRegisters.updatedRegisters || updatedRegisters.deletedIds)
      ) {
        updatedTenantDetailAndRegister = {
          tenantDetail: updatedTenantDetail,
          tenantRegisters: updatedRegisters,
        }
      } else {
        updatedTenantDetailAndRegister = {
          tenantDetail: updatedTenantDetail,
        }
      }
      const result = await postTenantDetailAndRegisters(
        orgCode,
        storeCode,
        tenantCode,
        updatedTenantDetailAndRegister
      )
      if (!result.success) {
        toastContext.sendToast({
          variant: 'error',
          title: result.message,
        })
        return
      }
      toastContext.sendToast({
        variant: 'success',
        title: `テナント詳細情報の更新が完了しました`,
      })
      setIsEditing(false)
      fetchTenantDetailAndRegisters()
    },
    [
      tenantDetail,
      tenantRegisters,
      orgCode,
      storeCode,
      tenantCode,
      toastContext,
      postTenantDetailAndRegisters,
      fetchTenantDetailAndRegisters,
      setIsEditing,
    ]
  )

  useMount(fetchTenantDetailAndRegisters)
  useBeforeUnload(isEditing)

  return isEditing ? (
    <EditTenantDetailTemplate
      tenantDetail={tenantDetail}
      tenantRegisters={tenantRegisters}
      orgCode={orgCode}
      onCancelEdit={() => setIsEditing(false)}
      onSubmit={handlePost}
    />
  ) : (
    <TenantDetailTabTemplate
      tenantDetail={tenantDetail}
      tenantRegisters={tenantRegisters}
      isLoaded={isLoaded}
      orgCode={orgCode}
      onStartEdit={() => setIsEditing(true)}
    />
  )
}

export default TenantDetailTab
