import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestState } from 'src/domain/request'
import { Authorization } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import serializeError from 'src/slices/services/error'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RejectedAction } from 'src/store'
import { purge } from 'src/store/action'

// FIXME: token(firebase auth)とauthorization(データベースの値)は
// 関連性の全くないデータなので、同一のsliceの中で管理する必要が無いので分離する
export interface AuthState extends RequestState {
  token: string
  authorizations: Authorization[]
  currentAuthorization: Authorization | null
}

export const initialState: AuthState = {
  status: 'idle',
  token: '',
  authorizations: [],
  currentAuthorization: null,
}

export const postAuth = createAsyncThunk<Authorization[], void, AppThunkConfig>(
  'auth/postAuth',
  async (_, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(auth.token).postAuth()
    return response.data
  },
  { serializeError }
)

type Token = string
type OrganizationId = string

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken: (state, { payload }: PayloadAction<Token>): AuthState => {
      return {
        ...state,
        token: payload,
      }
    },
    removeToken: (state): AuthState => {
      return {
        ...state,
        token: '',
      }
    },
    setCurrentAuthorization: (
      state,
      { payload }: PayloadAction<OrganizationId>
    ): AuthState => {
      const auth = state.authorizations.find(
        (item) => item.organization.id === payload
      )
      return {
        ...state,
        currentAuthorization: auth ?? null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(postAuth.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(
      postAuth.fulfilled,
      (state, { payload }: PayloadAction<Authorization[]>) => {
        let authorizationForOrg: Authorization | undefined
        const currentOrganizationId =
          state.currentAuthorization?.organization?.id
        if (currentOrganizationId) {
          authorizationForOrg = payload.find(
            (item) => item.organization.id === currentOrganizationId
          )
        }
        return {
          ...state,
          status: 'succeeded',
          authorizations: payload,
          currentAuthorization: authorizationForOrg || payload[0],
        }
      }
    )
    builder.addCase(postAuth.rejected, (state) => {
      return {
        ...state,
        status: 'failed',
      }
    })
    builder.addMatcher<RejectedAction>(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        return state
      }
    )
  },
})

export const { updateToken, removeToken, setCurrentAuthorization } =
  authSlice.actions

export default authSlice.reducer
