import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import { Badge } from 'src/components/atoms'
import { Option } from 'src/components/molecules/SelectWithOption'
import {
  getReportStatusBadgeColor,
  getReportStatusFillTypeColor,
  getReportStatusTitle,
} from 'src/domain/reportStatus'
import SalesReportDateOptions from 'src/features/salesReport/components/DateOptions'
import { SalesReportType } from 'src/features/salesReport/type'
import {
  TenantRegister,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'
import styles from './styles.module.scss'

export type SalesReportHeaderProps = {
  salesReport: SalesReportType
  selectOptions: Option[]
  tenantRegister?: TenantRegister
  onClickName: () => void
  onSelectDateChanged: (selectedDate: string) => void
} & React.StyledProps

const SalesReportHeader: React.StyledFC<SalesReportHeaderProps> = ({
  className,
  salesReport,
  selectOptions,
  tenantRegister,
  onClickName,
  onSelectDateChanged,
}: SalesReportHeaderProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      {salesReport.registerIndex === 0 && (
        <SalesReportDateOptions
          selectOptions={selectOptions}
          registerIndex={salesReport.registerIndex}
          onSelectDateChanged={onSelectDateChanged}
        />
      )}
      <Box
        className={clsx(styles.centerLine, {
          [styles.centerLineLeftMargin]: salesReport.registerIndex > 0,
        })}
      >
        <Box className={styles.statusAndTitle}>
          <Badge
            className={styles.badge}
            label={getReportStatusTitle(salesReport.reportStatus)}
            color={getReportStatusBadgeColor(salesReport.reportStatus)}
            fillType={getReportStatusFillTypeColor(salesReport.reportStatus)}
            ellipse
          />
          <Typography className={styles.title} variant="subtitle2">
            <Typography
              className={styles.code}
              color="textSecondary"
              display="inline"
              variant="inherit"
            >
              {salesReport.code}
            </Typography>
            <Typography
              className={styles.name}
              display="inline"
              variant="inherit"
              onClick={onClickName}
            >
              {salesReport.name}
            </Typography>
          </Typography>
        </Box>
        {tenantRegister && (
          <Box className={styles.tenantRegister}>
            {tenantRegister.registerCategory ===
              TenantRegisterCategoryEnum.RepresentativeRegister && (
              <Badge
                className={styles.registerBadge}
                label="代表レジ"
                color="lightYellow"
                fillType="color"
                ellipse
              />
            )}
            <Box className={styles.registerNumberWrapper}>
              <Typography className={styles.registerNumberTitle}>
                レジNo
              </Typography>
              <Typography className={styles.registerNumber}>
                {tenantRegister.registerNumber}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={styles.rightLine}>
        <Typography component="span" className={styles.salesReportDate}>
          <span className={styles.salesReportDateTitle}>売上報告日</span>
          <span className={styles.date}>{salesReport.salesReportDate}</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default SalesReportHeader
