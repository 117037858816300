import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Chip } from '@mui/material'
import colors from 'src/styles/color'
import style from './styles.module.scss'

export type BadgeProps = {
  label: string
  color: BadgeColor
  ellipse?: boolean
  size?: BadgeSize
  fillType?: FillType
} & React.StyledProps

export type BadgeColor =
  | 'red'
  | 'green'
  | 'green04'
  | 'lightGreen'
  | 'yellow'
  | 'lightYellow'
  | 'gray'
  | 'vermilion'
export type BadgeSize = 'small' | 'medium'
export type FillType = 'white' | 'color' | 'transparent'

const colorMapping = {
  red: colors.red,
  green: colors.green,
  green04: colors.green04,
  lightGreen: colors.green03,
  yellow: colors.yellow,
  lightYellow: colors.lightYellow,
  gray: colors.lightGray04,
  vermilion: colors.vermilion02,
}

const fontColorMapping = {
  red: colors.white,
  green: colors.white,
  green04: colors.white,
  lightGreen: colors.darkGreen,
  yellow: colors.brown,
  lightYellow: colors.darkYellow,
  gray: colors.gray04,
  vermilion: colors.white,
}

export const Badge: React.StyledFC<BadgeProps> = ({
  className,
  label,
  color,
  size = 'medium',
  fillType,
  ellipse,
}: BadgeProps) => {
  const getBackgroundColor = useCallback((): string => {
    switch (fillType) {
      case 'white':
        return colors.white
      case 'color':
        return colorMapping[color]
      case 'transparent':
      default:
        return 'transparent'
    }
  }, [color, fillType])

  const getFontColor = useCallback((): string => {
    switch (fillType) {
      case 'color':
        return fontColorMapping[color]
      case 'white':
      case 'transparent':
      default:
        return colorMapping[color]
    }
  }, [fillType, color])

  return (
    <Chip
      className={clsx(
        className,
        style.chip,
        ellipse && style.ellipseChip,
        size === 'small' && style.smallChip
      )}
      style={{
        backgroundColor: getBackgroundColor(),
        color: getFontColor(),
        borderColor: colorMapping[color],
      }}
      classes={{
        label: clsx(style.label, size === 'small' && style.smallLabel),
      }}
      label={label}
    />
  )
}
