import React from 'react'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { Icon } from 'src/components/atoms'
import ReportMessages, {
  ReportMessage,
} from 'src/components/molecules/ReportMessages'
import { FormNameEnum } from '../../type'
import styles from './styles.module.scss'

export type TenantSalesReportMessageProps = {
  reportMessages: ReportMessage[]
  tenantMessageBody?: string
  manageMessage?: string
  tenantPath: string
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles: boolean
} & React.StyledProps

export const TenantSalesReportMessage: React.StyledFC<
  TenantSalesReportMessageProps
> = ({
  className,
  reportMessages,
  tenantMessageBody,
  manageMessage,
  tenantPath,
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles,
}: TenantSalesReportMessageProps) => {
  const formMethods = useFormContext()

  return (
    <Box className={clsx(className, styles.root)}>
      {reportMessages.length > 0 && (
        <Box className={styles.reportMessageWrapper}>
          <Box className={styles.title}>
            <Icon
              className={clsx(styles.icon, styles.colorBlack)}
              icon="memo"
            />
            <Typography className={styles.text} variant="h4">
              テナントからの補足事項
            </Typography>
          </Box>
          <ReportMessages reportMessages={reportMessages} />
        </Box>
      )}
      <Box>
        <Box className={styles.eachCategory}>
          <Box className={styles.title}>
            <Icon
              className={clsx(styles.icon, styles.colorBlack)}
              icon="note2"
            />
            <Typography className={styles.text} variant="h4">
              管理者メモ(テナントごと)
            </Typography>
            <MuiLink
              className={styles.pathToDetail}
              component={Link}
              to={tenantPath}
              target="_blank"
            >
              修正する
            </MuiLink>
          </Box>
          <Box className={styles.fixedMemo}>
            <Typography variant="body1" className={styles.messageBody}>
              {tenantMessageBody}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box className={styles.eachCategory}>
            <Box className={styles.title}>
              <Icon
                className={clsx(styles.icon, styles.colorBlack)}
                icon="eye"
              />
              <Typography className={styles.text} variant="h4">
                管理者メモ(報告ごと)
              </Typography>
            </Box>
            <Box className={styles.textareaContainer}>
              <textarea
                {...formMethods.register(FormNameEnum.manageMessage)}
                className={styles.textarea}
                defaultValue={manageMessage}
                placeholder="補足事項を記入する"
                name={FormNameEnum.manageMessage}
                disabled={
                  !isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
