import React, { memo } from 'react'
import { Box } from '@mui/material'
import AppHeader from 'src/components/molecules/AppHeader'
import { Path } from 'src/components/molecules/Breadcrumbs'
import MemberDetail from '../../components/MemberDetail'
import style from './styles.module.scss'

export type ProfileProps = {
  breadcrumbs: Path[]
  title: string
  name: string
  memberRole: string
  email: string
  onClickUpdate: (name: string) => void
}

const Profile: React.StyledFC<ProfileProps> = memo(
  ({
    breadcrumbs,
    title,
    name,
    memberRole,
    email,
    onClickUpdate,
  }: ProfileProps) => {
    return (
      <Box className={style.root}>
        <AppHeader breadcrumbs={breadcrumbs} title={title} />
        <MemberDetail
          className={style.memberDetail}
          name={name}
          memberRole={memberRole}
          email={email}
          onClickUpdate={onClickUpdate}
        />
      </Box>
    )
  }
)

Profile.displayName = 'Profile'
export default Profile
