import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import { Button, Icon, IconName } from 'src/components/atoms'
import style from './styles.module.scss'

export type SmallGuideProps = {
  icon: IconName
  message: string
  description: string
  buttonTitle?: string
  onClick?: () => void
} & React.StyledProps

const SmallGuide: React.StyledFC<SmallGuideProps> = ({
  className,
  icon,
  message,
  description,
  buttonTitle,
  onClick,
}: SmallGuideProps) => {
  const descriptionBottom = (text: string, title?: string): string => {
    if (!title) return '0px'
    return text.split(/\r*\n/).length === 1 ? '48px' : '30px'
  }

  return (
    <Box className={clsx(style.box, className)}>
      <Box className={style.wrapper}>
        <Icon icon={icon} className={style.svg} />
        <Typography className={style.message} variant="h2" component="p">
          {message}
        </Typography>
        <Typography
          className={style.description}
          sx={{ marginBottom: descriptionBottom(description, buttonTitle) }}
          variant="h5"
          component="p"
        >
          {description}
        </Typography>
        {buttonTitle && (
          <Button
            title={buttonTitle}
            onClick={onClick}
            color="primary"
            size="large"
          />
        )}
      </Box>
    </Box>
  )
}

export default SmallGuide
