import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { Icon } from 'src/components/atoms'
import TextField from 'src/components/molecules/TextField'
import {
  InvalidInputErrorMessage,
  RegexNoSpaceOnly,
} from 'src/utils/regularExpression'
import style from './styles.module.scss'

export type Cell = {
  name: string
  placeholder?: string
  defaultValue?: string | number
  showClear?: boolean
}

export type EditReadItemBodyRowProps = {
  cells: Cell[]
  isGray?: boolean
  emptyCellCount?: number
  onClickAdd: () => void
  disabledRow?: boolean
} & React.StyledProps

const EditReadItemBodyRow: React.StyledFC<EditReadItemBodyRowProps> = ({
  className,
  cells,
  isGray,
  emptyCellCount = 0,
  onClickAdd,
  disabledRow,
}: EditReadItemBodyRowProps) => {
  const [disabledAddButton, setDisabledAddButton] = useState(false)
  const formMethods = useFormContext()
  const watchFields = formMethods.watch(cells.map((cell) => cell.name))

  useEffect(() => {
    const isFieldMissing = watchFields.some((value) => !value)
    setDisabledAddButton(isFieldMissing)
  }, [watchFields])

  const emptyCells = (num: number): React.ReactNode => {
    const countArray = Array.from({ length: num }, (_, i) => i + 1)
    return countArray.map((count) => (
      <TableCell key={count} className={style.cell} />
    ))
  }
  return (
    <TableRow className={clsx(className, style.row, isGray && style.grayRow)}>
      {cells.map((cell) => (
        <TableCell key={`${cell.name}`} className={style.cell}>
          <TextField
            disabled={disabledRow}
            className={style.input}
            name={cell.name}
            type="text"
            defaultValue={cell.defaultValue}
            placeholder={cell.placeholder}
            validations={[
              {
                regex: RegexNoSpaceOnly,
                message: InvalidInputErrorMessage,
              },
            ]}
          />
        </TableCell>
      ))}
      <TableCell key="icon" className={clsx(style.cell, style.iconCell)}>
        <IconButton
          onClick={onClickAdd}
          className={style.icon}
          disabled={disabledRow || disabledAddButton}
          size="large"
        >
          <Icon icon="plus" />
        </IconButton>
      </TableCell>
      {emptyCellCount > 0 && emptyCells(emptyCellCount)}
    </TableRow>
  )
}

export default EditReadItemBodyRow
