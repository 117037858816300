import React, { memo } from 'react'
import { FormControlLabel, Box } from '@mui/material'
import { Button, CheckBox } from 'src/components/atoms'
import style from './styles.module.scss'

export type FooterProps = {
  onUpdate?: () => void
  onGoBack?: () => void
  onGoToUpdate?: () => void
  onShowUpdated?: (isChecked: boolean) => void
  hasUpdate?: boolean
  disableGoToUpdateButton?: boolean
} & React.StyledProps

const Footer: React.StyledFC<FooterProps> = memo(
  ({
    onUpdate,
    onGoBack,
    onGoToUpdate,
    onShowUpdated,
    hasUpdate,
    disableGoToUpdateButton,
  }: FooterProps) => {
    const handleChangeUpdated = (isChecked: boolean) => {
      if (onShowUpdated) onShowUpdated(isChecked)
    }

    const handleUpdate = () => {
      if (onUpdate) onUpdate()
    }

    return (
      <Box className={style.container}>
        {onShowUpdated && (
          <FormControlLabel
            className={style.checkText}
            control={
              <CheckBox
                name="filterUpdated"
                defaultChecked={false}
                shape="rectangle"
                onChange={handleChangeUpdated}
              />
            }
            label=" 更新されたものだけを表示"
          />
        )}
        {onGoBack && (
          <Button
            title="もどる"
            size="medium"
            color="secondary"
            onClick={onGoBack}
          />
        )}
        {onGoToUpdate && (
          <Button
            title="テナントリスト編集"
            size="medium"
            color="primary"
            className={style.editButton}
            disabled={disableGoToUpdateButton}
            onClick={onGoToUpdate}
          />
        )}
        {onUpdate && (
          <Button
            title="更新する"
            size="medium"
            color="primary"
            disabled={!hasUpdate}
            onClick={handleUpdate}
          />
        )}
      </Box>
    )
  }
)

Footer.displayName = 'Footer'
export default Footer
