import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import { Path as BreadcrumbPath } from 'src/components/molecules/BreadcrumbsPipe'
import useAppTitle from 'src/hooks/useAppTitle'
import Path, { TenantPathParams } from 'src/routes/path'
import {
  getCalculationDSLs,
  selectDenormalizedCalculationDSLsByParams,
  selectCalculationDSLsRequestStateByParams,
} from 'src/slices/calculationDSLs/calculationDSLsSlice'
import {
  getCalculationGroup,
  selectCalculationGroupStateByParams,
} from 'src/slices/calculationGroups/calculationGroupsSlice'
import { selectCalculationItemsByParams } from 'src/slices/calculationItems/calculationItemsSlice'
import {
  putEditReadItems,
  EditReadItem as EditReadItemPayload,
  clearEditCalculation,
} from 'src/slices/editCalculations/editCalculationsSlice'
import {
  getStore,
  selectStoresRequestStateByParams,
  selectStoreByCode,
} from 'src/slices/stores/storesSlice'
import {
  getTenant,
  selectTenantStateByCode,
  selectTenantByCode,
} from 'src/slices/tenants/tenantsSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import { isEqual } from 'src/utils/array'
import { trimer } from 'src/utils/string'
import { Cell as BodyCell } from '../components/EditReadItemBodyRow'
import { BodyRow } from '../components/EditReadItemTable'
import EditReadItemTemplate from '../templates'
import Presenter, { editReadItemCell } from './presenter'

type FieldValues = {
  rows: string[][]
}

const EditReadItem: React.FC = (): ReactElement => {
  useAppTitle('利用設定 ステップ1')
  const [isLoading, setIsLoading] = useState(true)
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const storeParams = useMemo(() => {
    return {
      orgCode,
      storeCode,
    }
  }, [orgCode, storeCode])
  const tenantParams = useMemo(() => {
    return {
      orgCode,
      storeCode,
      tenantCode,
    }
  }, [orgCode, storeCode, tenantCode])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const formMethods = useForm<FieldValues>()
  const [initialValues, setInitialValues] = useState<string[]>([])
  const [rows, setRows] = useState<BodyRow[]>(Presenter.rows([]))
  const store = useAppSelector(selectStoreByCode(storeParams))
  const tenant = useAppSelector(selectTenantByCode(tenantParams))
  const calculationItems = useAppSelector(
    selectCalculationItemsByParams(storeParams)
  )
  const editReadItems = useAppSelector(
    (state) => state.forms.editCalculations.editReadItems
  )
  const dsls = useAppSelector(
    selectDenormalizedCalculationDSLsByParams(tenantParams)
  )
  const [isDefault, setIsDefault] = useState(false)

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!isLoading && rows && formMethods.getValues().rows) {
      setInitialValues(Object.values(formMethods.getValues().rows.flat()))
    }
  }, [formMethods, isLoading, rows])

  useBeforeUnload(() => {
    const currentValues = Object.values(formMethods.getValues().rows.flat())
    return !isEqual(initialValues, currentValues)
    // FIXME: 入力した文字列は表示されないけど、文字列は入れないと表示されないので、入れてる
  }, 'Alert')

  // NOTE: 初期の header column から、いくつ cell を増やすかを管理
  const [addedCellCount, setAddedCellCount] = useState(0)
  const tenantRequestState = useAppSelector(
    selectTenantStateByCode(tenantParams)
  )
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams(storeParams)
  )
  const calculationGroupRequestState = useAppSelector(
    selectCalculationGroupStateByParams(storeParams)
  )

  const dslRequestState = useAppSelector(
    selectCalculationDSLsRequestStateByParams(tenantParams)
  )

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbPath[]>([])
  useEffect(() => {
    setBreadcrumbs(Presenter.breadcrumbs(store?.name, tenant?.name))
  }, [store?.name, tenant?.name])

  useEffect(() => {
    if (calculationItems && rows.length === 0) {
      setRows(Presenter.rows(calculationItems))
      setIsLoading(false)
    }
  }, [calculationItems, rows.length])

  // API Request
  useEffect(() => {
    if (tenantRequestState.status === 'idle') {
      dispatch(getTenant(tenantParams))
    }
  }, [dispatch, tenantParams, tenantRequestState.status])
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore(storeParams))
    }
  }, [dispatch, store, storeParams, storeRequestState.status])
  useEffect(() => {
    if (calculationGroupRequestState.status === 'idle') {
      dispatch(getCalculationGroup(storeParams))
    }
  }, [dispatch, storeParams, calculationGroupRequestState.status])
  useEffect(() => {
    if (dslRequestState.status === 'idle' && editReadItems.length === 0) {
      dispatch(getCalculationDSLs(tenantParams))
    }
  }, [dispatch, dslRequestState, editReadItems.length, tenantParams])

  useEffect(() => {
    // dsls があり、かつ初期値に ocrFormat がある場合はそれを入力値として保存する
    if (
      dsls?.length &&
      dsls.some((dsl) => dsl.ocrFormats) &&
      editReadItems.length === 0
    ) {
      const restoredEditReadItems = Presenter.convertDSLToForm(dsls)

      dispatch(putEditReadItems(restoredEditReadItems))
    }
  }, [dispatch, dsls, editReadItems.length])

  // utils
  const getEditReadItems = (): EditReadItemPayload[] => {
    if (!calculationItems) {
      return []
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return formMethods
      .getValues()
      .rows.flatMap((values: any, rowIndex: any) => {
        return values.map((value: any) => {
          const calculationId = calculationItems[rowIndex].id
          return {
            id: uuidv4(),
            readItem: trimer(value),
            calculationId,
            calculationIndex: rowIndex,
          }
        })
      })
      .filter((item: any) => Boolean(item.readItem)) // 空の値は除外
    /* eslint-enable */
  }

  // restore state data
  const defaultValuesWithIndex = useMemo(() => {
    type Result = {
      [index: number]: string[]
    }
    let result: Result = {}
    if (editReadItems.length) {
      result = editReadItems.reduce((accumulator: Result, current) => {
        const { calculationIndex } = current
        const number = calculationIndex + 1
        if (!accumulator[number]) {
          accumulator[number] = [current.readItem]
        } else {
          accumulator[number].push(current.readItem)
        }

        return accumulator
      }, [])
    }
    return result
  }, [editReadItems])

  const setDefaultValues = useCallback(() => {
    const updatedRows = rows.slice()
    setAddedCellCount(
      Math.max(
        ...Object.values(defaultValuesWithIndex).map((value) => value.length)
      ) - 1
    )
    Object.entries(defaultValuesWithIndex).forEach(
      ([number, defaultValues]) => {
        const rowIndex = rows.findIndex((row) => row.number === number)

        const updatedCells: BodyCell[] = defaultValues.map(
          (defaultValue, cellIndex) => {
            const name = Presenter.getInputCellName(rowIndex, cellIndex)
            return {
              ...editReadItemCell(name),
              defaultValue,
            }
          }
        )

        const updatedRow: BodyRow = {
          ...rows[rowIndex],
          cells: updatedCells,
        }

        updatedRows.splice(rowIndex, 1, updatedRow)
      }
    )
    setRows(updatedRows)
  }, [defaultValuesWithIndex, rows])

  useEffect(() => {
    if (rows.length && editReadItems.length && !isDefault) {
      setIsDefault(true)
      setDefaultValues()
    }
  }, [editReadItems.length, isDefault, rows.length, setDefaultValues])

  // event handler
  const handleClickClose = () => {
    setShowModal(true)
  }

  const handleClickGoForward = () => {
    dispatch(putEditReadItems(getEditReadItems()))
    navigate(Path.editOCRFormat(orgCode, storeCode, tenantCode))
  }

  const handleClickAdd = (rowIndex: number) => {
    const { cells } = rows[rowIndex]
    if (cells.length - 1 === addedCellCount) {
      setAddedCellCount(addedCellCount + 1)
    }

    const name = Presenter.getInputCellName(rowIndex, cells.length)
    const updatedRow: BodyRow = {
      ...rows[rowIndex],
      cells: [...cells, editReadItemCell(name)],
    }
    const result = rows.slice()
    result.splice(rowIndex, 1, updatedRow)

    setRows(result)
  }

  const handleClickCancel = () => {
    setShowModal(false)
  }

  const handleClickConfirm = () => {
    dispatch(clearEditCalculation())
    navigate(Path.tenantCalculationTab(orgCode, storeCode, tenantCode))
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <EditReadItemTemplate
          columns={Presenter.columns(addedCellCount)}
          rows={rows}
          breadcrumbs={breadcrumbs}
          isLoading={isLoading}
          onClickGoForward={handleClickGoForward}
          onClickClose={handleClickClose}
          onClickAdd={handleClickAdd}
          showModal={showModal}
          onClickCancel={handleClickCancel}
          onClickConfirm={handleClickConfirm}
        />
      </form>
    </FormProvider>
  )
}

export default EditReadItem
