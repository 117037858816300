import React, { useCallback } from 'react'
import { Box, Table, TableBody, Typography } from '@mui/material'
import { EllipseIcon } from 'src/components/atoms'
import {
  AppTableHead,
  TextCell,
  AppTableRow,
  ChildCell,
} from 'src/components/tables'
import { TenantOwnerCompany } from 'src/slices/services/api'
import { formatDateTime } from 'src/utils/date'
import styles from './styles.module.scss'

export type ListTableProps = {
  tenantOwnerCompanies: TenantOwnerCompany[]
  onClickRow: (code: string) => void
}

const ListTable: React.StyledFC<ListTableProps> = ({
  tenantOwnerCompanies,
  onClickRow,
}: ListTableProps) => {
  const getActiveStatus = useCallback((active?: boolean) => {
    return active ? '利用中' : '停止中'
  }, [])

  return (
    <Box className={styles.root}>
      <Table stickyHeader>
        <AppTableHead
          columns={[
            { title: 'ステータス', width: 100 },
            { title: '企業コード', width: 200 },
            { title: '企業名称', width: 300 },
            { title: 'テナント数', width: 100 },
            { title: '最終更新日時', width: 180 },
          ]}
        />
        <TableBody>
          {tenantOwnerCompanies.map(
            (tenantOwnerCompany: TenantOwnerCompany) => {
              return (
                <AppTableRow
                  key={tenantOwnerCompany.id}
                  className={styles.row}
                  onClick={() => onClickRow(tenantOwnerCompany.code)}
                >
                  <ChildCell>
                    <Typography className={styles.statusWord}>
                      <EllipseIcon
                        color={tenantOwnerCompany.active ? 'green' : 'gray'}
                      />
                      <span className={styles.status}>
                        {getActiveStatus(tenantOwnerCompany.active)}
                      </span>
                    </Typography>
                  </ChildCell>
                  <TextCell
                    text={tenantOwnerCompany.code}
                    width={200}
                    className={styles.text}
                  />
                  <TextCell text={tenantOwnerCompany.name} width={300} />
                  <TextCell
                    text={`${tenantOwnerCompany.numberOfTenants}`}
                    unit="テナント"
                    width={100}
                  />
                  <TextCell
                    text={
                      tenantOwnerCompany.updatedAt
                        ? formatDateTime(tenantOwnerCompany.updatedAt)
                        : ''
                    }
                    width={180}
                  />
                </AppTableRow>
              )
            }
          )}
        </TableBody>
      </Table>
    </Box>
  )
}

ListTable.displayName = 'ListTable'
export default ListTable
