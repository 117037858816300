/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
  Selector,
} from '@reduxjs/toolkit'
import { RequestState } from 'src/domain/request'
import { TenantSalesReport } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import serializeError from 'src/slices/services/error'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig, RootState } from 'src/store'
import { purge } from 'src/store/action'
import toKey from 'src/utils/key'

type TenantSalesReportState = EntityState<TenantSalesReport> & {
  keys: {
    [code: string]: string
  }
  query: {
    [key: string]: RequestState
  }
}

const tenantSalesReportsAdapter = createEntityAdapter<TenantSalesReport>()

export const initialState: TenantSalesReportState =
  tenantSalesReportsAdapter.getInitialState({
    keys: {},
    query: {},
  })

type GetTenantSalesReportParams = {
  orgCode: string
  storeCode: string
  tenantCode: string
  date: string
}

type PostNotificationParam = {
  orgCode: string
  storeCode: string
  tenantCode: string
  salesDate: string
  salesDateId: string
  content: string
}

export const getTenantSalesReports = createAsyncThunk<
  TenantSalesReport,
  GetTenantSalesReportParams,
  AppThunkConfig
>(
  'tenantSalesReport/getTenantSalesReports',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      params.orgCode,
      params.storeCode,
      params.tenantCode,
      params.date
    )

    return response.data
  },
  { serializeError }
)

export const postNotificationToTenant = createAsyncThunk<
  { salesDateId: string; content: string; createdAt: string; id: string },
  PostNotificationParam,
  AppThunkConfig
>('message/postNotificationToTenant', async (params, { getState }) => {
  const { auth } = getState()
  const response = await new AuthenticatedApi(
    auth.token
  ).postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
    params.orgCode,
    params.storeCode,
    params.tenantCode,
    {
      salesDateId: params.salesDateId,
      content: params.content,
    }
  )
  return {
    salesDateId: params.salesDateId,
    content: params.content,
    createdAt: response.data.createdAt,
    id: response.data.id,
  }
})

const slice = createSlice({
  name: 'tenantSalesReports',
  initialState,
  reducers: {
    clearGetTenantSalesReports: (): TenantSalesReportState => {
      return initialState
    },
    clearGetTenantSalesReportByParams: (
      state,
      { payload }: PayloadAction<GetTenantSalesReportParams>
    ): TenantSalesReportState => {
      const key = toKey(payload)
      const id = state.keys[key]
      tenantSalesReportsAdapter.removeOne(state, id)

      const deletedState = state
      delete deletedState.keys[key]
      delete deletedState.query[key]

      return deletedState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getTenantSalesReports.pending, (state, { meta }) => {
      const key = toKey(meta.arg)

      return {
        ...state,
        query: {
          ...state.query,
          [key]: {
            status: 'loading',
          },
        },
      }
    })
    builder.addCase(
      getTenantSalesReports.fulfilled,
      (state, { payload, meta }) => {
        tenantSalesReportsAdapter.upsertOne(state, {
          ...payload,
          notifications: payload.notifications || [],
        })

        const key = toKey(meta.arg)
        state.keys[key] = payload.id
        state.query[key] = {
          status: 'succeeded',
        }
      }
    )
    builder.addCase(
      getTenantSalesReports.rejected,
      (state, { error, meta }) => {
        const key = toKey(meta.arg)

        return {
          ...state,
          query: {
            ...state.query,
            [key]: {
              status: 'failed',
              error,
            },
          },
        }
      }
    )
    builder.addCase(
      postNotificationToTenant.fulfilled,
      (state, { payload }) => {
        const existingNotifications =
          state.entities[payload.salesDateId]?.notifications || []

        const newNotification = {
          id: payload.id,
          content: payload.content,
          createdAt: payload.createdAt,
          isReadByTenant: false,
        }

        const newNotifications = [newNotification, ...existingNotifications]

        tenantSalesReportsAdapter.updateOne(state, {
          id: payload.salesDateId,
          changes: {
            notifications: newNotifications,
          },
        })
      }
    )
  },
})

export default slice.reducer
export const { clearGetTenantSalesReports, clearGetTenantSalesReportByParams } =
  slice.actions

// Selector
export const {
  selectById: selectTenantSalesReportById,
  selectEntities: selectTenantSalesReportEntities,
  selectAll: selectAllTenantSalesReports,
} = tenantSalesReportsAdapter.getSelectors<TenantSalesReportState>(
  (state) => state
)

export const selectTenantSalesReportByParams = (
  params: GetTenantSalesReportParams
): Selector<RootState, TenantSalesReport | undefined> => {
  return createSelector(
    [(state) => state.entities.tenantSalesReports],
    (state) => {
      const key = toKey(params)
      const id = state.keys[key]
      return selectTenantSalesReportById(state, id)
    }
  )
}

export const selectTenantSalesReportStateByParams = (
  params: GetTenantSalesReportParams
): Selector<RootState, RequestState> => {
  return createSelector(
    [(state) => state.entities.tenantSalesReports],
    (state) => {
      return (
        state.query[toKey(params)] ?? {
          status: 'idle',
        }
      )
    }
  )
}
