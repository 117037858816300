import React, { useCallback } from 'react'

import clsx from 'clsx'
import { Button as MuiButton, Typography } from '@mui/material'
import {
  AdminAdminSalesReportManagerRoles,
  isOneOfRoles,
} from 'src/domain/role'
import {
  ReportStatusEnum,
  RoleEnum,
  TenantSalesReportSummary,
} from 'src/slices/services/api'
import style from './styles.module.scss'

export type DownloadCSVButtonProps = {
  role?: RoleEnum
  summaries: TenantSalesReportSummary[]
  onClick?: () => void
} & React.StyledProps

const DownloadCSVButton: React.StyledFC<DownloadCSVButtonProps> = ({
  role,
  summaries,
  onClick,
}: DownloadCSVButtonProps) => {
  const isDownloadCSVAvailableByRole = useCallback((): boolean => {
    return isOneOfRoles(AdminAdminSalesReportManagerRoles, role)
  }, [role])
  const isDeny = summaries.every((summary) => {
    return summary.reportStatus !== ReportStatusEnum.FinalizedReportSubmitted
  })
  const isDisabled = !isDownloadCSVAvailableByRole() || isDeny

  return (
    <MuiButton
      className={clsx(
        style.button,
        isDisabled ? style.deactivate : style.active
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Typography className={style.title}>CSVダウンロード</Typography>
    </MuiButton>
  )
}

export default DownloadCSVButton
