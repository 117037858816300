import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import {
  useQueryParams,
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
  createEnumParam,
} from 'use-query-params'
import TenantsTemplate from 'src/components/templates/Tenants'
import { DEFAULT_PAGE } from 'src/constants'
import useAppTitle from 'src/hooks/useAppTitle'
import usePrevious from 'src/hooks/usePrevious'
import { OrgPathParams } from 'src/routes/path'
import { setOrgTenantsParams } from 'src/slices/query/querySlice'
import { TenantStatusEnum } from 'src/slices/services/api/api'
import {
  getOrgTenants,
  selectTenantStateByParams,
  selectOrgTenantsByParams,
} from 'src/slices/tenants/tenantsSlice'
import { useAppSelector, useAppDispatch } from 'src/store'
import Presenter, { optionDefaultValue } from './presenter'

type FilterValues = {
  status: TenantStatusEnum | string
}

const Tenants: React.FC = (): ReactElement => {
  useAppTitle('テナント一覧')
  const dispatch = useAppDispatch()
  const location = useLocation()

  // Query
  const storedParams = useAppSelector((state) => state.query.orgTenantsParams)
  const queryConfig = {
    page: withDefault(NumberParam, DEFAULT_PAGE),
    q: withDefault(StringParam, undefined),
    status: withDefault(
      createEnumParam([
        TenantStatusEnum.Ready,
        TenantStatusEnum.NotReady,
        TenantStatusEnum.Closed,
      ]),
      undefined
    ),
    trained: withDefault(BooleanParam, undefined),
  }
  const [{ page, q, status, trained }, setQuery] = useQueryParams(queryConfig)
  const perPage = 25
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams

  useMount(() => {
    if (location.pathname === storedParams.pathname) {
      setQuery({
        page: storedParams.page,
        q: storedParams.q,
        status: storedParams.status,
        trained: storedParams.trained,
      })
    }
  })

  // API Request
  const params = useMemo(() => {
    return {
      orgCode,
      page,
      perPage,
      q,
      status,
      trained,
    }
  }, [orgCode, page, q, status, trained])
  const paginatedTenants = useAppSelector(selectOrgTenantsByParams(params))

  const templateTenants = useMemo(() => {
    return (
      paginatedTenants?.map((tenant) => {
        return Presenter.mapTemplateStore(orgCode, tenant)
      }) ?? []
    )
  }, [orgCode, paginatedTenants])

  const requestState = useAppSelector(selectTenantStateByParams(params))

  useEffect(() => {
    if (requestState.status === 'idle') {
      dispatch(getOrgTenants(params))
    }
  }, [dispatch, requestState, params])
  useEffect(() => {
    dispatch(
      setOrgTenantsParams({
        page: params.page,
        q: params.q,
        status: params.status,
        pathname: location.pathname,
      })
    )
  }, [dispatch, params.page, params.q, params.status, location.pathname])

  // Form
  const formMethods = useForm<FilterValues>({
    // Query Paramsの値をデフォルト値にする
    defaultValues: { status },
  })
  const watchFilters = formMethods.watch()
  const prevAmount = usePrevious(watchFilters)
  const filters = Presenter.filters()

  // フィルターの変更をQuery Paramsに反映する
  useEffect(() => {
    const filterValue = Presenter.statusQueryParams(watchFilters.status)
    if (filterValue !== status) {
      if (prevAmount?.status === watchFilters.status) {
        formMethods.setValue('status', status ?? optionDefaultValue)
      } else {
        setQuery({ page: DEFAULT_PAGE, status: filterValue })
      }
    }
  }, [watchFilters.status, status, prevAmount?.status, setQuery, formMethods])

  // FIXME: filter と query の関係箇所を一元化したい
  useMount(() => {
    if (location.pathname === storedParams.pathname) {
      formMethods.setValue('status', storedParams.status ?? optionDefaultValue)
    }
  })
  // trained filter
  const [isChecked, setIsChecked] = useState(trained)
  useMount(() => {
    if (location.pathname === storedParams.pathname) {
      setIsChecked(storedParams.trained ?? false)
    }
  })

  // Handler
  const handlePaginationClick = (move: number) => {
    setQuery({ page: move })
  }
  const handleSearchFieldChange = (value: string) => {
    setQuery({ page: DEFAULT_PAGE, q: value })
  }

  const handleChangeCheck = () => {
    setIsChecked(!isChecked)
    // true(=研修実施あり)は使わないため undefined にする
    const trainedValue = isChecked ? undefined : false
    setQuery({ page: DEFAULT_PAGE, trained: trainedValue })
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <TenantsTemplate
          isLoading={requestState.status === 'loading'}
          queryState={location.search}
          currentPage={page}
          filters={filters}
          tenantCount={requestState.totalCount}
          tenants={templateTenants}
          rowsPerPage={perPage}
          defaultSearchValue={storedParams.q}
          isChecked={isChecked}
          paginationOnClick={(move) => handlePaginationClick(move)}
          searchFieldOnChange={(text) => handleSearchFieldChange(text)}
          onChangeCheck={handleChangeCheck}
        />
      </form>
    </FormProvider>
  )
}

export default Tenants
