import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TenantFormType } from 'src/components/organisms/TenantUpdateTable'
import TenantUpdateTemplate from 'src/components/templates/TenantUpdate'
import { STORE_TENANTS_PER_PAGE } from 'src/constants'
import { ToastTriggerContext } from 'src/context/toast.context'
import useAppTitle from 'src/hooks/useAppTitle'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import Path, { StorePathParams } from 'src/routes/path'
import {
  clearEditTenants,
  patchTenants,
} from 'src/slices/editTenants/editTenantsSlice'
import {
  selectStoreByCode,
  selectStoresRequestStateByParams,
  getStore,
} from 'src/slices/stores/storesSlice'
import {
  clearStoreTenants,
  getStoreTenants,
  selectTenantStateByParams,
  selectTenantsByParams,
} from 'src/slices/tenants/tenantsSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import { Presenter } from './presenter'

const EditTenants: React.FC = (): ReactElement => {
  useAppTitle('テナント情報編集')
  useBeforeUnload()
  const { orgCode, storeCode } = useParams() as StorePathParams
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)

  // API Request
  const params = useMemo(() => {
    return {
      orgCode,
      storeCode,
      perPage: 10000,
    }
  }, [orgCode, storeCode])
  const tenants = useAppSelector(selectTenantsByParams(params))
  const tableRows = useMemo(() => {
    return tenants ? Presenter.tableRows(tenants) : []
  }, [tenants])

  useAppSelector((state) => {
    if (selectTenantStateByParams(params)(state).status === 'idle') {
      dispatch(getStoreTenants(params))
    }
  })

  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (tableRows.length > 0) {
      setLoading(false)
    }
  }, [tableRows.length])

  const storedParams = useAppSelector((state) => state.query.storeTenantsParams)
  const storeTenantsParams = {
    orgCode,
    storeCode,
    page: storedParams.page,
    q: storedParams.q,
    status: storedParams.status,
    trained: storedParams.trained,
    perPage: STORE_TENANTS_PER_PAGE,
  }

  const handleSubmit = async (values: TenantFormType) => {
    const updatedTenants = Presenter.requestBody(values, tenants || [], orgCode)

    if (updatedTenants.length === 0) {
      toastContext.sendToast({
        variant: 'error',
        title: '更新するテナントがありません',
      })
      return
    }

    try {
      await dispatch(
        patchTenants({ orgCode, tenants: updatedTenants })
      ).unwrap()
      dispatch(clearEditTenants())
      dispatch(clearStoreTenants(storeTenantsParams))
      navigate(Path.store(orgCode, storeCode))
      toastContext.sendToast({
        variant: 'success',
        title: '更新に成功しました',
      })
    } catch (error) {
      if (typeof error === 'object' && error !== null && 'message' in error) {
        toastContext.sendToast({
          variant: 'error',
          title: String(error.message),
        })
      } else {
        toastContext.sendToast({
          variant: 'error',
          title: '更新が失敗しました',
        })
      }
    }
  }

  return (
    <TenantUpdateTemplate
      tenants={tableRows}
      orgCode={orgCode}
      storeName={store?.name || ''}
      storeCode={store?.code || ''}
      onClickBack={() => navigate(Path.store(orgCode, storeCode))}
      onSubmit={handleSubmit}
      loading={loading}
    />
  )
}

export default EditTenants
