import React from 'react'
import clsx from 'clsx'
import { TableCell, TableRow, Typography } from '@mui/material'
import style from './styles.module.scss'

export type StoreSalesReportSummaryTableRowProps = {
  cells: Cell[]
  disabled?: boolean
  onClick?: () => void
} & React.StyledProps

export type Cell = {
  title: string
  key: string
  unit?: string
  isTotalCell?: boolean
}

const StoreSalesReportSummaryTableRow: React.StyledFC<
  StoreSalesReportSummaryTableRowProps
> = ({
  className,
  cells,
  disabled,
  onClick,
}: StoreSalesReportSummaryTableRowProps) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  return (
    <TableRow
      className={clsx(
        className,
        style.row,
        disabled ? style.gray : style.white
      )}
      onClick={handleClick}
    >
      {cells.map((cell) => (
        <TableCell className={style.cell} key={cell.key} align="left">
          <Typography
            className={
              cell.isTotalCell ? style.rightCellText : style.leftCellText
            }
            color={disabled ? 'textSecondary' : 'textPrimary'}
            variant="subtitle2"
          >
            <Typography className={style.title}>{cell.title}</Typography>
            {cell.unit && (
              <Typography color="textSecondary" className={style.unit}>
                {cell.unit}
              </Typography>
            )}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default StoreSalesReportSummaryTableRow
