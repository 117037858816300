/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import { Selector } from 'react-redux'
import { GetRequestState, RequestStatus } from 'src/domain/request'
import {
  calculationDSLEntity,
  NormalizedCalculationDSL,
} from 'src/domain/schema'
// eslint-disable-next-line import/no-cycle
import { postTenantsIdCalculationDsl } from 'src/slices/editCalculations/editCalculationsSlice'
import {
  CalculationDSL,
  CalculationItem,
  CalculationMethod,
  OCRFormat,
} from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import serializeError from 'src/slices/services/error'
import { AppThunkConfig, RootState } from 'src/store'
import { purge } from 'src/store/action'
import toKey from 'src/utils/key'

interface CalculationDSLsWithUpdateMethodsRequestState extends GetRequestState {
  updateCalculationMethodsStatus: RequestStatus
  lastUpdatedAt?: string
  calculationMethod?: CalculationMethod
}

interface CalculationDSLState extends EntityState<NormalizedCalculationDSL> {
  request: {
    [code: string]: CalculationDSLsWithUpdateMethodsRequestState
  }
}

const calculationDSLsAdapter = createEntityAdapter<NormalizedCalculationDSL>()

export const initialState: CalculationDSLState =
  calculationDSLsAdapter.getInitialState({
    request: {},
  })

export type GetParams = {
  orgCode: string
  storeCode: string
  tenantCode: string
}

export type PathCalculationMethodsParams = {
  orgCode: string
  storeCode: string
  tenantCode: string
  calculationMethods: CalculationMethod[]
}

interface NormalizedEntities {
  calculationDSLs: Record<string, NormalizedCalculationDSL>
  ocrFormats: Record<string, OCRFormat>
  calculationItems: Record<string, CalculationItem>
}

export type DSLReturnParams = {
  dsls: NormalizedEntities
  calculationMethod?: CalculationMethod | null
  lastUpdatedAt?: string | null
}

export const getCalculationDSLs = createAsyncThunk<
  DSLReturnParams,
  GetParams,
  AppThunkConfig
>(
  'calculationDSLs/getCalculationDSLs',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      params.orgCode,
      params.storeCode,
      params.tenantCode
    )

    const normalized = normalize<NormalizedCalculationDSL, NormalizedEntities>(
      response.data.dsls ?? [],
      [calculationDSLEntity]
    )
    return {
      dsls: normalized.entities,
      calculationMethod: response.data.calculationMethod,
      lastUpdatedAt: response.data.lastUpdatedAt,
    }
  },
  { serializeError }
)

export const updateCalculationMethods = createAsyncThunk<
  CalculationMethod[] | null | undefined,
  PathCalculationMethodsParams,
  AppThunkConfig
>(
  'calculationDSLs/updateCalculationMethods',
  async (params, { getState }) => {
    const { auth } = getState()
    const response = await new AuthenticatedApi(
      auth.token
    ).patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      params.orgCode,
      params.storeCode,
      params.calculationMethods
    )

    return response.data
  },
  { serializeError }
)

const slice = createSlice({
  name: 'calculationDSLs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getCalculationDSLs.pending, (state, { meta }) => {
      return {
        ...state,
        request: {
          ...state.request,
          [toKey(meta.arg)]: {
            status: 'loading',
            updateCalculationMethodsStatus: 'idle',
          },
        },
      }
    })
    builder.addCase(
      getCalculationDSLs.fulfilled,
      (state, { meta, payload }) => {
        if (payload.dsls.calculationDSLs) {
          calculationDSLsAdapter.upsertMany(state, payload.dsls.calculationDSLs)
        }
        state.request[toKey(meta.arg)] = {
          status: 'succeeded',
          ids: Object.keys(payload.dsls.calculationDSLs ?? {}),
          calculationMethod: payload.calculationMethod ?? undefined,
          lastUpdatedAt: payload.lastUpdatedAt ?? undefined,
          updateCalculationMethodsStatus: 'idle',
        }
      }
    )
    builder.addCase(getCalculationDSLs.rejected, (state, { meta, error }) => {
      return {
        ...state,
        request: {
          ...state.request,
          [toKey(meta.arg)]: {
            status: 'failed',
            updateCalculationMethodsStatus: 'idle',
            error,
          },
        },
      }
    })
    builder.addCase(postTenantsIdCalculationDsl.fulfilled, () => {
      return initialState
    })
    builder.addCase(updateCalculationMethods.pending, (state, { meta }) => {
      return {
        ...state,
        request: {
          ...state.request,
          [toKey(meta.arg)]: {
            status: 'idle',
            updateCalculationMethodsStatus: 'loading',
          },
        },
      }
    })
    builder.addCase(
      updateCalculationMethods.fulfilled,
      (state, { meta, payload }) => {
        const param = {
          orgCode: meta.arg.orgCode,
          storeCode: meta.arg.storeCode,
          tenantCode: meta.arg.tenantCode,
        }
        state.request[toKey(param)] = {
          ...state.request,
          status: 'idle',
          updateCalculationMethodsStatus: 'succeeded',
          calculationMethod: payload ? payload[0] : undefined,
        }
      }
    )
    builder.addCase(
      updateCalculationMethods.rejected,
      (state, { meta, error }) => {
        return {
          ...state,
          request: {
            ...state.request,
            [toKey(meta.arg)]: {
              status: 'idle',
              updateCalculationMethodsStatus: 'failed',
              error,
            },
          },
          error,
        }
      }
    )
  },
})

export default slice.reducer

// Selector
export const {
  selectById: selectCalculationDSLById,
  selectEntities: selectCalculationDSLEntities,
  selectAll: selectAllCalculationDSLs,
} = calculationDSLsAdapter.getSelectors<CalculationDSLState>((state) => state)

export const selectCalculationDSLsByParams = (
  params: GetParams
): Selector<RootState, NormalizedCalculationDSL[] | undefined> => {
  return createSelector(
    [(state) => state.entities.calculationDSLs],
    (state) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      return state.request[toKey(params)]?.ids
        ?.map((dslId: any) => state.entities[dslId])
        .filter(
          (dsl: any): dsl is NormalizedCalculationDSL => dsl !== undefined
        )
      /* eslint-enable */
    }
  )
}

export const selectDenormalizedCalculationDSLsByParams = (
  params: GetParams
): Selector<RootState, CalculationDSL[] | undefined> => {
  return createSelector(
    [
      (state) => state.entities.calculationDSLs,
      (state) => state.entities.ocrFormats.entities,
      (state) => state.entities.calculationItems.entities,
    ],
    (state, formatEntities, calculationItemEntities) => {
      const dslIds = state.request[toKey(params)]?.ids
      if (!dslIds) {
        return undefined
      }
      return denormalize(dslIds, [calculationDSLEntity], {
        calculationDSLs: state.entities,
        ocrFormats: formatEntities,
        calculationItems: calculationItemEntities,
      })
    }
  )
}

export const selectCalculationDSLsRequestStateByParams = (
  params: GetParams
): Selector<RootState, CalculationDSLsWithUpdateMethodsRequestState> => {
  return createSelector(
    [(state) => state.entities.calculationDSLs],
    (state) => {
      return (
        state.request[toKey(params)] ?? {
          status: 'idle',
          updateCalculationMethodsStatus: 'idle',
        }
      )
    }
  )
}
