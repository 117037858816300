import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SearchTextField from 'src/components/molecules/SearchTextField'

export type StoreListHeaderProps = {
  totalStores: number
  defaultSearchValue?: string
  onChangeSearchText: (text: string) => void
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  typography: {
    lineHeight: '19px',
  },
})

const StoreListHeader: React.StyledFC<StoreListHeaderProps> = ({
  totalStores,
  defaultSearchValue,
  onChangeSearchText,
  className,
}: StoreListHeaderProps) => {
  const classes = useStyles()

  const formMethods = useForm()
  const searchFieldName = 'searchStore'

  const handleClickClear = () => {
    formMethods.setValue(searchFieldName, '')
    onChangeSearchText('')
  }

  useEffect(() => {
    if (!defaultSearchValue) return

    formMethods.setValue(searchFieldName, defaultSearchValue)
  }, [defaultSearchValue, formMethods])

  return (
    <Box className={clsx(className, classes.root)}>
      <Box className={classes.typography}>
        <Typography variant="h2" component="p" display="inline">
          {`${totalStores} `}
        </Typography>
        <Typography variant="subtitle1" component="p" display="inline">
          店舗
        </Typography>
      </Box>
      <FormProvider {...formMethods}>
        <form>
          <SearchTextField
            name={searchFieldName}
            type="text"
            placeholder="店舗名、店舗コード"
            onClickClear={handleClickClear}
            onChange={(text: string) => onChangeSearchText(text)}
          />
        </form>
      </FormProvider>
    </Box>
  )
}

export default StoreListHeader
