import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Path as BreadcrumbsPath } from 'src/components/molecules/Breadcrumbs'
import { INFINITE_SCROLL_CONTENT_LOADING_NUM } from 'src/domain/config'
import { useApiTenantOwnerCompany } from 'src/hooks/useApiTenantOwnerCompany'
import { useApiTenantsOfTenantOwnerCompany } from 'src/hooks/useApiTenantsOfTenantOwnerCompany'
import useAppTitle from 'src/hooks/useAppTitle'
import Path, {
  OrgPathParams,
  TenantOwnerCompanyPathParams,
} from 'src/routes/path'
import {
  TenantWithStoreAndTenantOwnerCompany,
  Store,
  TenantOwnerCompany,
} from 'src/slices/services/api'
import {
  selectStoresByParams,
  selectStoresRequestStateByParams,
  getStores,
} from 'src/slices/stores/storesSlice'
import { useAppSelector, useAppDispatch } from 'src/store'

import TemplatesTenantsOfTenantOwnerCompanyList from '../../templates/List'
import { SearchParam, FilterParam } from '../../type'

const isAllTenantsReturned = false

const PagesTenantsOfTenantOwnerCompanyList: React.FC = (): ReactElement => {
  useAppTitle('取引企業詳細')
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const { tenantOwnerCompanyCode } =
    useParams<TenantOwnerCompanyPathParams>() as TenantOwnerCompanyPathParams
  const [totalCount, setTotalCount] = useState<number>()
  const [tenantOwnerCompany, setTenantOwnerCompany] =
    useState<TenantOwnerCompany>()
  const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbsPath[]>([])
  const dispatch = useAppDispatch()
  const param = useMemo(() => {
    return { orgCode, page: 1, perPage: 1000 }
  }, [orgCode])
  const navigate = useNavigate()
  const stores: Store[] =
    useAppSelector((state) => selectStoresByParams(param)(state)) ?? []
  const { status } = useAppSelector((state) =>
    selectStoresRequestStateByParams(param)(state)
  )

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getStores(param))
    }
  }, [dispatch, status, param])

  const [searchParam, setSearchParam] = useState<SearchParam>({
    tenantStatus: undefined,
    storeCodes: [],
    searchText: '',
    loadOffsetNum: 0,
  })
  const { getTenantsOfTenantOwnerCompany } = useApiTenantsOfTenantOwnerCompany()
  const { getTenantOwnerCompany } = useApiTenantOwnerCompany()
  const [
    tenantsWithStoreAndTenantOwnerCompany,
    setTenantsWithStoreAndTenantOwnerCompany,
  ] = useState<TenantWithStoreAndTenantOwnerCompany[]>()

  useEffect(() => {
    const executeApi = async () => {
      const res = await getTenantsOfTenantOwnerCompany(
        orgCode,
        searchParam.loadOffsetNum,
        INFINITE_SCROLL_CONTENT_LOADING_NUM,
        tenantOwnerCompanyCode,
        isAllTenantsReturned,
        searchParam.searchText,
        searchParam.tenantStatus,
        searchParam.storeCodes
      )
      if (res.success) {
        setTenantsWithStoreAndTenantOwnerCompany(
          res.data?.tenantsAndAssociatedCount
            .tenantsWithStoreAndTenantOwnerCompany || []
        )
        if (typeof res.data?.count === 'number') {
          setTotalCount(res.data?.count)
        }
      }
    }
    executeApi()
  }, [
    getTenantsOfTenantOwnerCompany,
    orgCode,
    searchParam,
    tenantOwnerCompanyCode,
  ])

  useEffect(() => {
    const executeApi = async () => {
      const res = await getTenantOwnerCompany(orgCode, tenantOwnerCompanyCode)
      if (res.success) {
        setBreadcrumbPaths([
          {
            index: 0,
            title: '取引企業',
            to: Path.tenantOwnerCompanies(orgCode),
          },
          {
            index: 1,
            title: res.data?.name || '',
            to: '',
          },
        ])
        setTenantOwnerCompany(res.data)
      }
    }
    executeApi()
  }, [getTenantOwnerCompany, orgCode, tenantOwnerCompanyCode])

  const handleChangeFilterParams = (data: FilterParam) => {
    setSearchParam({ ...data, loadOffsetNum: 0 })
  }

  const handleLoadMore = (offset: number) => {
    setSearchParam({ ...searchParam, loadOffsetNum: offset })
  }

  const handleGoToUpdate = () => {
    navigate(
      Path.tenantsOfTenantOwnerCompanyUpdate(orgCode, tenantOwnerCompanyCode)
    )
  }

  return (
    <TemplatesTenantsOfTenantOwnerCompanyList
      tenantsWithStoreAndTenantOwnerCompany={
        tenantsWithStoreAndTenantOwnerCompany
      }
      tenantOwnerCompany={tenantOwnerCompany}
      onChangeFilterParams={handleChangeFilterParams}
      totalCount={totalCount}
      breadCrumbPaths={breadcrumbPaths}
      stores={stores}
      loadMore={handleLoadMore}
      onGoToUpdate={handleGoToUpdate}
    />
  )
}
export default PagesTenantsOfTenantOwnerCompanyList
