import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import { Path as BreadcrumbsPath } from 'src/components/molecules/Breadcrumbs'
import Loading from 'src/components/templates/Loading'
import { AuthContext } from 'src/context/auth.context'
import { ToastTriggerContext } from 'src/context/toast.context'
import { AdminAdminStoreManagerRoles, isOneOfRoles } from 'src/domain/role'
import { useApiMember } from 'src/hooks/useApiMember'
import useAppTitle from 'src/hooks/useAppTitle'
import Path, { OrgPathParams } from 'src/routes/path'
import { Member, RoleEnum, Store as EntityStore } from 'src/slices/services/api'
import {
  getStores,
  selectStoresByParams,
  selectStoresRequestStateByParams,
} from 'src/slices/stores/storesSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import TemplatesMembersList from '../../templates/List'
import { adjustMembers } from '../Update/presenter'

const PagesMembersList: React.FC = (): ReactElement => {
  useAppTitle('ユーザー管理')
  const { getMembers } = useApiMember()
  const authContext = useContext(AuthContext)
  const toastContex = useContext(ToastTriggerContext)
  const [members, setMembers] = useState<Member[]>([])
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const navigate = useNavigate()
  const param = useMemo(() => {
    return { orgCode, page: 1, perPage: 1000 }
  }, [orgCode])

  const dispatch = useAppDispatch()
  const stores: EntityStore[] =
    useAppSelector((state) => selectStoresByParams(param)(state)) ?? []

  const { status } = useAppSelector((state) =>
    selectStoresRequestStateByParams(param)(state)
  )

  const selfRole: RoleEnum | undefined = useAppSelector(
    (state) => state.auth.currentAuthorization?.role
  )

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getStores(param))
    }
  }, [dispatch, status, param])

  const breadCrumbPaths: BreadcrumbsPath[] = [
    {
      index: 0,
      title: 'ユーザー管理',
      to: Path.members(orgCode),
    },
  ]

  useMount(async () => {
    const result = await getMembers(orgCode)
    if (!result.success) {
      toastContex.sendToast({
        variant: 'error',
        title: `ユーザーの取得に失敗しました: ${result.message}`,
      })
      return
    }
    const selfEmail = authContext.getUserEmail()
    if (!selfRole || !selfEmail) {
      return
    }
    const adjustedMembers = adjustMembers(
      result.data || [],
      selfEmail,
      selfRole
    )
    if (!adjustedMembers) {
      toastContex.sendToast({
        variant: 'error',
        title: `再度ログインしなおしてからお試しください`,
      })
    }
    setMembers(adjustedMembers || [])
    setLoaded(true)
  })

  const onClickUpdateMember = () => {
    navigate(Path.membersUpdate(orgCode))
  }

  const onClickCreateMember = () => {
    navigate(Path.membersCreate(orgCode))
  }

  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(AdminAdminStoreManagerRoles, selfRole)
  }, [selfRole])

  return (
    <div>
      {!showSwitchByRole() && <div>権限がありません</div>}
      {isLoaded && status === 'succeeded' && showSwitchByRole() ? (
        <TemplatesMembersList
          breadCrumbPaths={breadCrumbPaths}
          members={members}
          stores={stores}
          onClickCreateMember={onClickCreateMember}
          onClickUpdateMember={onClickUpdateMember}
        />
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default PagesMembersList
