import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EllipseIcon, EllipseProps } from 'src/components/atoms'
import QRCodeDownloadButton from 'src/components/molecules/QRCodeDownloadButton'

type Status = {
  [status: string]: { title: string; color: EllipseProps['color'] }
}

const statuses: Status = {
  ready: { title: '利用中', color: 'green' },
  not_ready: { title: '利用開始前', color: 'red' },
  closed: { title: '利用不可・閉店', color: 'gray' },
}

export type StoreTenantHeaderProps = {
  tenantName: string
  tenantCode: string
  status: 'ready' | 'not_ready' | 'closed'
  showButtonText: string
  onClickShowModal: () => void
} & React.StyledProps

const useStyles = makeStyles<unknown, StoreTenantHeaderProps>(() => {
  return {
    title: {
      fontSize: 24,
      height: 36,
      display: 'flex',
      justifyContent: 'space-between',
    },
    typographyWrapper: {
      margin: 'auto 0px',
    },
    tenantCode: {
      marginLeft: 17,
    },
    icon: {
      marginRight: 6,
    },
    state: {
      lineHeight: '19px',
    },
    // NOTE: 用意されているボタンのサイズでは、この画面のボタンのサイズに促していないため
    button: {
      marginTop: 3, // 9 - 6
      height: 42,
      width: 88,
      fontSize: 15,
      fontWeight: 400,
      padding: 'unset',
    },
    downloadButton: {
      margin: 'auto 0px',
    },
  }
})

const StoreTenantHeader: React.StyledFC<StoreTenantHeaderProps> = ({
  className,
  tenantName,
  tenantCode,
  status,
  showButtonText,
  onClickShowModal,
}: StoreTenantHeaderProps) => {
  const classes = useStyles({
    tenantName,
    tenantCode,
    status,
    showButtonText,
    onClickShowModal,
  })

  return (
    <Box className={className}>
      <Typography className={classes.title} variant="h1">
        <Box className={classes.typographyWrapper}>
          <Typography display="inline" variant="inherit">
            {tenantName}
          </Typography>
          <Typography
            className={classes.tenantCode}
            color="textSecondary"
            display="inline"
            variant="inherit"
          >
            {tenantCode}
          </Typography>
        </Box>
        <QRCodeDownloadButton
          className={classes.downloadButton}
          title={showButtonText}
          onClick={onClickShowModal}
        />
      </Typography>
      <EllipseIcon className={classes.icon} color={statuses[status].color} />
      <Typography className={classes.state} display="inline" variant="h6">
        <Typography display="inline" variant="inherit">
          {statuses[status].title}
        </Typography>
      </Typography>
    </Box>
  )
}

export default StoreTenantHeader
