import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { TableBody } from '@mui/material'
import tenantUpdateStyles from 'src/components/organisms/TenantUpdateTable/styles.module.scss'
import {
  AppTableRow,
  SelectCell,
  InputCell,
  TextCell,
} from 'src/components/tables'
import {
  NAME_PLACEHOLDER,
  CONTRACTOR_CODE_PLACEHOLDER,
  SECTION_PLACEHOLDER,
  STATUS_OPTIONS,
  STATUS_PLACEHOLDER,
  PREFIX_OPTIONS,
  TenantFormType,
  TenantFormTypeEnum,
  createTenantCodeAndContractorCode,
  TenantRow,
} from 'src/domain/tenant'
import {
  InvalidInputErrorMessage,
  NumericRegexErrorMessage,
  RegexNoSpaceOnly,
  RegexNumeric,
} from 'src/utils/regularExpression'

export type TenantUpdateTableForOpaProps = {
  tenants: TenantRow[]
  storeCode: string
}

const TenantUpdateTableForOpa: React.FC<TenantUpdateTableForOpaProps> = ({
  tenants,
  storeCode,
}) => {
  const formMethods = useFormContext()
  const { watch, setValue } = formMethods
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name) return
      const parts = name?.split('.')
      const key = Number(parts[1])
      const formName = parts[2]
      if (
        formName === TenantFormTypeEnum.prefix ||
        formName === TenantFormTypeEnum.contractorCodeNumber ||
        formName === TenantFormTypeEnum.section
      ) {
        const { tenantCode, contractorCode } =
          createTenantCodeAndContractorCode(
            key,
            value as TenantFormType,
            storeCode
          )
        // NOTE: 以下の2つの値は送信する必要があるが、入力するフィールドは無いのでsetValueで設定している
        setValue(`tenants.${key}.contractorCode`, contractorCode)
        setValue(`tenants.${key}.code`, tenantCode)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue, storeCode])

  return (
    <TableBody>
      {tenants.map((tenant) => (
        <AppTableRow key={tenant.index}>
          <SelectCell
            name={`tenants.${tenant.index}.status`}
            options={STATUS_OPTIONS}
            placeholder={STATUS_PLACEHOLDER}
            defaultValue={
              formMethods.getValues(`tenants.${tenant.index}.status`) ||
              tenant.status
            }
            width={120}
          />
          <TextCell
            className={tenantUpdateStyles.textCell}
            text={
              formMethods.getValues(`tenants.${tenant.index}.code`) ||
              tenant.code
            }
          />
          <InputCell
            name={`tenants.${tenant.index}.name`}
            defaultValue={
              formMethods.getValues(`tenants.${tenant.index}.name`) ||
              tenant.name
            }
            placeholder={NAME_PLACEHOLDER}
            width={260}
            required
          />
          <SelectCell
            className={tenantUpdateStyles.selectCell}
            name={`tenants.${tenant.index}.prefix`}
            options={PREFIX_OPTIONS}
            placeholder={
              formMethods.getValues(`tenants.${tenant.index}.prefix`) ||
              PREFIX_OPTIONS[0]?.title
            }
            defaultValue={tenant.contractorCode?.slice(0, 2) || ''}
            width={80}
          />
          <InputCell
            className={tenantUpdateStyles.contractorCodeCell}
            name={`tenants.${tenant.index}.contractorCodeNumber`}
            defaultValue={tenant.contractorCode?.slice(2) || ''}
            placeholder={CONTRACTOR_CODE_PLACEHOLDER}
            width={150}
            minLength={10}
            maxLength={10}
            required
            validations={[
              {
                regex: RegexNoSpaceOnly,
                message: InvalidInputErrorMessage,
              },
              { regex: RegexNumeric, message: NumericRegexErrorMessage },
            ]}
          />
          <InputCell
            name={`tenants.${tenant.index}.section`}
            defaultValue={tenant.section}
            placeholder={SECTION_PLACEHOLDER}
            width={90}
            required
            minLength={2}
            maxLength={2}
            validations={[
              {
                regex: RegexNoSpaceOnly,
                message: InvalidInputErrorMessage,
              },
              { regex: RegexNumeric, message: NumericRegexErrorMessage },
            ]}
          />
          <TextCell
            className={tenantUpdateStyles.textCell}
            text={tenant.tenantRegisterNumber || '-'}
          />
          <TextCell
            className={tenantUpdateStyles.textCell}
            text={tenant.contractStartDate || '-'}
          />
          <TextCell
            className={tenantUpdateStyles.textCell}
            text={tenant.contractEndDate || '-'}
          />
        </AppTableRow>
      ))}
    </TableBody>
  )
}

export default TenantUpdateTableForOpa
