import React, { useEffect, useState } from 'react'
import { FormControlLabel, Box } from '@mui/material'
import { Button, CheckBox } from 'src/components/atoms'
import style from './styles.module.scss'

export type FooterProps = {
  onUpdate?: () => void
  onCreate?: () => void
  onGoBack?: () => void
  onGoToCreate?: () => void
  onGoToUpdate?: () => void
  onShowUpdated?: (isChecked: boolean) => void
  isAllValid?: boolean
  hasUpdate?: boolean
  disableGoToUpdateButton?: boolean
  duplicatedCodes?: string[]
} & React.StyledProps

const Footer: React.StyledFC<FooterProps> = ({
  onUpdate,
  onCreate,
  onGoBack,
  onGoToCreate,
  onGoToUpdate,
  onShowUpdated,
  hasUpdate,
  disableGoToUpdateButton,
  isAllValid,
  duplicatedCodes,
}: FooterProps) => {
  const [duplicatedMessage, setDuplicatedMessage] = useState<string>()
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    if (duplicatedCodes && duplicatedCodes.length > 0) {
      const codes = duplicatedCodes.join(',')
      setDuplicatedMessage(`コード${codes}は重複しています`)
    } else {
      setDuplicatedMessage('')
    }
  }, [duplicatedCodes])

  const handleChangeUpdated = (isChecked: boolean) => {
    if (onShowUpdated) onShowUpdated(isChecked)
  }

  const handleCreate = () => {
    setShowError(true)
    if (onCreate) onCreate()
  }

  const handleUpdate = () => {
    setShowError(true)
    if (onUpdate) onUpdate()
  }

  return (
    <Box className={style.container}>
      <span className={style.errorWrapper}>
        {duplicatedMessage && (
          <span className={style.errorMessage}>{duplicatedMessage}</span>
        )}
        {isAllValid === false && duplicatedMessage && <br />}
        {isAllValid === false && showError && (
          <span className={style.errorMessage}>不正な項目があります</span>
        )}
      </span>
      {onShowUpdated && (
        <FormControlLabel
          className={style.checkText}
          control={
            <CheckBox
              name="filterUpdated"
              defaultChecked={false}
              shape="rectangle"
              onChange={handleChangeUpdated}
            />
          }
          label=" 更新されたものだけを表示"
        />
      )}
      {onGoToCreate && (
        <Button
          title="企業新規追加"
          size="medium"
          color="secondary"
          onClick={onGoToCreate}
        />
      )}
      {onGoBack && (
        <Button
          title="もどる"
          size="medium"
          color="secondary"
          onClick={onGoBack}
        />
      )}
      {onGoToUpdate && (
        <Button
          title="企業編集"
          size="medium"
          color="primary"
          disabled={disableGoToUpdateButton}
          onClick={onGoToUpdate}
        />
      )}
      {onUpdate && (
        <Button
          title="更新する"
          size="medium"
          color="primary"
          disabled={!hasUpdate}
          onClick={handleUpdate}
        />
      )}
      {onCreate && (
        <Button
          title="追加する"
          size="medium"
          color="primary"
          onClick={handleCreate}
        />
      )}
    </Box>
  )
}

Footer.displayName = 'Footer'
export default Footer
