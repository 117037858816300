import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import { Button, Icon, IconName } from 'src/components/atoms'
import styles from './styles.module.scss'

export type GuideProps = {
  icon?: IconName
  message: string
  description: string
  buttons?: Button[]
} & React.StyledProps

export type Button = {
  index: number
  title: string
  onClick: () => void
}

const Guide: React.StyledFC<GuideProps> = ({
  className,
  icon,
  message,
  description,
  buttons = [],
}: GuideProps) => {
  return (
    <Box className={clsx(styles.box, className)}>
      {icon && <Icon icon={icon} className={styles.icon} fontSize="large" />}
      <Typography className={styles.message}>{message}</Typography>
      <Typography variant="subtitle1" component="p">
        {description}
      </Typography>
      <Box className={styles.buttonWrapper}>
        {buttons.map((button, index) => (
          <Button
            className={clsx(styles.button, index === 0 && styles.firstButton)}
            key={button.index}
            title={button.title}
            onClick={button.onClick}
            color="primary"
            size="large"
          />
        ))}
      </Box>
    </Box>
  )
}

export default Guide
