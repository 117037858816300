import React from 'react'
import clsx from 'clsx'
import { Box, Snackbar, Slide, SlideProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import colors from 'src/styles/color'

export type MessageBarProps = {
  title: string
  isOpen: boolean
  variant: 'success' | 'error'
  onClose: () => void
  onExited?: () => void
} & React.StyledProps

const variantStyles = {
  success: {
    backgroundColor: colors.green02,
    color: colors.black,
  },
  error: {
    backgroundColor: colors.vermilion,
    color: colors.white,
  },
}

const useStyles = makeStyles<unknown, MessageBarProps>(() => {
  return {
    snackbar: {
      top: 0,
      width: '100%',
      height: 56,
    },
  }
})

interface SnackbarBoxProps {
  variant: keyof typeof variantStyles
}
const SnackbarBox = styled(Box)<SnackbarBoxProps>((props) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: variantStyles[props.variant].backgroundColor,
  color: variantStyles[props.variant].color,
}))

const TransitionDown = ({
  children,
  in: boolean,
  ref,
  onEnter,
  onExited,
}: SlideProps) => {
  return (
    <Slide
      {...{
        children,
        in: boolean,
        ref,
        onEnter,
        onExited,
      }}
      direction="down"
    />
  )
}

export const MessageBar: React.StyledFC<MessageBarProps> = ({
  className,
  title,
  isOpen,
  variant,
  onClose,
  onExited,
}: MessageBarProps) => {
  const classes = useStyles({ title, isOpen, variant, onClose })

  return (
    <Snackbar
      className={clsx(classes.snackbar, className)}
      data-testid="messageBar"
      open={isOpen}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={4000}
      onClose={onClose}
      TransitionComponent={TransitionDown}
      TransitionProps={{
        onExited,
      }}
    >
      <SnackbarBox variant={variant}>
        <Typography variant="subtitle1" component="span">
          {title}
        </Typography>
      </SnackbarBox>
    </Snackbar>
  )
}
