import { CheckFormType } from '../../components/molecules/CheckBoxListWithFilter'
import { RoleEnum, Member } from '../../slices/services/api'

type Payload = {
  allMembers?: Member[]
  searchParamText?: string
  role?: RoleEnum
  assignedStores?: CheckFormType
}

type Action = {
  type:
    | 'UPDATE_MEMBERS'
    | 'UPDATE_SEARCH_TEXT'
    | 'UPDATE_SEARCH_ROLE'
    | 'UPDATE_ASSIGNED_STORE'
  payload: Payload
}

export type IndexedMember = Member & { index: number }

type MemberState = {
  count: number
  allIndexedMembers: IndexedMember[]
  searchParam: {
    text: string
    role: RoleEnum | ''
    assignedStores: CheckFormType
  }
  filteredMembers: IndexedMember[]
}

const defaultCheckForm: CheckFormType = {
  checkListValues: [],
  allChecked: false,
}

export const INITIAL_STATE: MemberState = {
  count: 0,
  allIndexedMembers: [],
  searchParam: {
    text: '',
    role: '',
    assignedStores: defaultCheckForm,
  },
  filteredMembers: [],
}

export const reducerFunc = (
  state: MemberState,
  action: Action
): MemberState => {
  let newState: MemberState

  switch (action.type) {
    case 'UPDATE_MEMBERS': {
      const allIndexedMembers = action.payload.allMembers?.map(
        (member: Member, index: number) => {
          return { ...member, index }
        }
      )
      newState = {
        ...state,
        allIndexedMembers: allIndexedMembers ?? [],
        filteredMembers: [],
      }
      break
    }
    case 'UPDATE_SEARCH_TEXT':
      newState = {
        ...state,
        searchParam: {
          ...state.searchParam,
          text: action.payload.searchParamText ?? '',
        },
        filteredMembers: [],
      }
      break
    case 'UPDATE_SEARCH_ROLE':
      newState = {
        ...state,
        searchParam: {
          ...state.searchParam,
          role: action.payload.role ?? '',
        },
        filteredMembers: [],
      }
      break
    case 'UPDATE_ASSIGNED_STORE':
      newState = {
        ...state,
        searchParam: {
          ...state.searchParam,
          assignedStores: action.payload.assignedStores || defaultCheckForm,
        },
        filteredMembers: [],
      }
      break
    default:
      newState = {
        ...state,
        filteredMembers: [],
      }
  }

  newState.allIndexedMembers.forEach((member) => {
    if (!newState.searchParam.text) {
      newState.filteredMembers.push(member)
    } else if (
      (newState.searchParam.text &&
        member.email.includes(newState.searchParam.text)) ||
      (newState.searchParam.text &&
        member.name.includes(newState.searchParam.text))
    ) {
      newState.filteredMembers.push(member)
    }
  })

  if (newState.searchParam.role) {
    newState.filteredMembers = newState.filteredMembers.filter(
      (member) => member.role === newState.searchParam.role
    )
  }
  if (newState.searchParam.assignedStores.checkListValues?.length > 0) {
    newState.filteredMembers = newState.filteredMembers.filter((member) => {
      if (member.hasAllStoresPermission) {
        return true
      }
      return (
        member.assignedStores?.some((store) => {
          return newState.searchParam.assignedStores.checkListValues.includes(
            store.id
          )
        }) || false
      )
    })
  }

  newState.count = newState.filteredMembers.length
  return newState
}
