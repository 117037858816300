import React, {
  useReducer,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react'
import { Box, Typography } from '@mui/material'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import LoadMore from 'src/components/molecules/LoadMore'
import Modal from 'src/components/molecules/Modal'
import SmallGuide from 'src/components/molecules/SmallGuide'
import { TenantOwnerCompany } from 'src/slices/services/api'
import listStyles from 'src/styles/list-styles.module.scss'
import {
  TenantOwnerCompanyFilterBar,
  FilterParam,
} from '../../components/FilterBar'
import Footer from '../../components/Footer'
import UpdateTable from '../../components/UpdateTable'
import { RowDataType, ValidateFormType } from '../../components/UpdateTableRow'
import { reducerFunc, INITIAL_STATE } from '../../updateReducer'
import styles from './styles.module.scss'

export type TemplatesTenantOwnerCompanyUpdateProps = {
  tenantOwnerCompanies: TenantOwnerCompany[]
  totalCount?: number
  onChangeFilterParams: (filterData: FilterParam) => void
  onSubmit: (data: RowDataType[]) => void
  onGoBack: () => void
  loadMore: (currentCount: number) => void
}

const TemplatesTenantOwnerCompanyUpdate: React.StyledFC<
  TemplatesTenantOwnerCompanyUpdateProps
> = ({
  tenantOwnerCompanies,
  totalCount,
  onChangeFilterParams,
  onSubmit,
  onGoBack,
  loadMore,
}: TemplatesTenantOwnerCompanyUpdateProps) => {
  const bottomBoundaryRef = useRef(null)
  const [updateTenantOwnCompanyState, dispatch] = useReducer(
    reducerFunc,
    INITIAL_STATE
  )
  const [showCancelModal, setShowCancelModal] = useState(false)

  const handleChangeFormRowValue = (formData: RowDataType) => {
    dispatch({ type: 'UPDATE_FORM_DATA', payload: { formData } })
  }

  useEffect(() => {
    dispatch({
      type: 'ADD_NETWORK_DATA',
      payload: { networkData: tenantOwnerCompanies, totalCount },
    })
  }, [tenantOwnerCompanies, totalCount])

  const handleChangeFilterParams = (filterData: FilterParam) => {
    dispatch({ type: 'UPDATE_SEARCH_CONDITION' })
    onChangeFilterParams(filterData)
  }

  const handlesSubmit = () => {
    if (updateTenantOwnCompanyState.isAllValid) {
      const updatedData = Object.values(
        updateTenantOwnCompanyState.formUpdatedData
      ).map((data) => data.new)
      onSubmit(updatedData)
    }
  }

  const handleValidate = useCallback((validStatus: ValidateFormType) => {
    dispatch({
      type: 'UPDATE_EACH_VALID_STATUS',
      payload: {
        eachValidStatus: { id: validStatus.id, isValid: validStatus.isValid },
      },
    })
  }, [])

  const handleOnReachToBottom = useCallback(() => {
    if (
      updateTenantOwnCompanyState.hasMoreContent &&
      !updateTenantOwnCompanyState.isLoadingMore
    ) {
      if (typeof updateTenantOwnCompanyState.currentCount === 'number') {
        loadMore(updateTenantOwnCompanyState.currentCount)
        dispatch({ type: 'LOAD_MORE' })
      }
    }
  }, [
    loadMore,
    updateTenantOwnCompanyState.currentCount,
    updateTenantOwnCompanyState.hasMoreContent,
    updateTenantOwnCompanyState.isLoadingMore,
  ])

  useEffect(() => {
    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        handleOnReachToBottom()
      }
    })
    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current)
    }
    return () => observer.disconnect()
  }, [handleOnReachToBottom])

  const handleShowUpdated = (showUpdated: boolean) => {
    if (showUpdated) {
      dispatch({ type: 'SHOW_UPDATED' })
    } else {
      dispatch({ type: 'SHOW_ALL' })
    }
  }

  const renderContent = () => {
    if (updateTenantOwnCompanyState.isLoading) {
      return (
        <Box className={styles.loadingContainer}>
          <CenteringLoading />
        </Box>
      )
    }
    if (totalCount === 0) {
      return (
        <SmallGuide
          icon="pray"
          className={styles.guide}
          message="該当の結果はありません"
          description="検索キーワードを確認して再度試してください"
        />
      )
    }
    return (
      <>
        <UpdateTable
          shownTenantOwnerCompaniesFormData={
            updateTenantOwnCompanyState.shownFormData
          }
          onChangeFormRowValue={handleChangeFormRowValue}
          onValidate={handleValidate}
        />
        <div ref={bottomBoundaryRef} />
      </>
    )
  }
  const handleGoBack = () => {
    if (Object.values(updateTenantOwnCompanyState.formUpdatedData).length > 0) {
      setShowCancelModal(true)
    } else {
      onGoBack()
    }
  }

  return (
    <>
      <Box>
        <Box className={listStyles.container}>
          <Typography className={styles.title} variant="h1">
            取引企業一覧
          </Typography>
          <Box className={styles.filterWrapper}>
            {updateTenantOwnCompanyState.showUpdated && (
              <Box className={styles.warningUpdate}>
                <Typography variant="body1">
                  更新された取引企業のみ表示されています
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              className={
                updateTenantOwnCompanyState.showUpdated ? styles.hidden : '' // Note: html要素は残した上で隠している。
              }
            >
              <TenantOwnerCompanyFilterBar
                onChangeFilterParams={handleChangeFilterParams}
                totalCount={updateTenantOwnCompanyState.totalCount}
              />
            </Box>
          </Box>
          <Box className={styles.mainContent}>
            {renderContent()}
            {updateTenantOwnCompanyState.isLoadingMore && <LoadMore />}
          </Box>
        </Box>
        <Box className={styles.bottom}>
          <Footer
            onUpdate={handlesSubmit}
            hasUpdate={
              Object.keys(updateTenantOwnCompanyState.formUpdatedData).length >
              0
            }
            onGoBack={handleGoBack}
            onShowUpdated={handleShowUpdated}
            isAllValid={updateTenantOwnCompanyState.isAllValid}
          />
        </Box>
      </Box>
      <Modal
        open={showCancelModal}
        title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
        agreeButtonTitle="このまま中断する"
        disagreeButtonTitle="設定に戻る"
        onClickAgree={onGoBack}
        onClickDisagree={() => setShowCancelModal(false)}
      />
    </>
  )
}

export default TemplatesTenantOwnerCompanyUpdate
