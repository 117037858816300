import { RoleEnum } from 'src/slices/services/api'

export const AdminAdminStoreManagerRoles: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminStoreManager,
]

export const AdminAllEditorStoreManagerInOperationCenterEditorStoreManagerRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminStoreManager,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorStoreManagerInOperationCenter,
    RoleEnum.EditorStoreManager,
  ]

export const AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorSalesReportManager,
    RoleEnum.EditorStoreManagerInOperationCenter,
  ]

export const AdminAdminSalesReportManagerEditorSalesReportManagerRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorSalesReportManager,
  ]

export const AdminAdminSalesReportManagerRoles: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminSalesReportManager,
]

export const isOneOfRoles = (availableRoles: RoleEnum[], role?: RoleEnum) => {
  return role ? availableRoles.includes(role) : false
}
