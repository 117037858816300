import { TARGET_SEND_PARAMS } from '../../domain/sendParam'
import {
  SalesReportCalculationResult,
  SalesReportWithTenant,
} from '../../slices/services/api'
import { formatEightDigitDate } from '../../utils/date'

export const generateCSVData = (
  orgCode: string,
  storeCode: string,
  salesReports: SalesReportWithTenant[]
): string => {
  if (!salesReports.length) {
    return ''
  }

  // NOTE: ヘッダーはなしでデータのみを生成
  const csvRows = salesReports.flatMap((salesReport) => {
    const totals: { [sendParameterName: string]: number } = {}

    salesReport.registers.forEach((register) => {
      let calcResults: SalesReportCalculationResult[]
      if (TARGET_SEND_PARAMS[orgCode]) {
        calcResults = register.calculationResults.filter((calcResult) =>
          TARGET_SEND_PARAMS[orgCode].includes(
            calcResult.calculationItem.sendParameterName
          )
        )
      } else {
        calcResults = register.calculationResults
      }

      calcResults.forEach((calcResult) => {
        totals[calcResult.calculationItem.sendParameterName] =
          totals[calcResult.calculationItem.sendParameterName] ?? 0

        if (calcResult.results && calcResult.results.definiteValue) {
          totals[calcResult.calculationItem.sendParameterName] +=
            calcResult.results.definiteValue
        }
      })
    })

    // NOTE: 営業日付,物件コード,店舗コード,報告項目コード,金額or数量
    return Object.entries(totals).map(
      ([key, value]) =>
        `${formatEightDigitDate(salesReport.date)},${storeCode},${
          salesReport.tenant.code
        },${key},${value}`
    )
  })

  return csvRows.join('\r\n')
}

export const downloadCSV = (
  orgCode: string,
  storeCode: string,
  date: string,
  salesReports: SalesReportWithTenant[]
) => {
  const csvData = generateCSVData(orgCode, storeCode, salesReports)
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')

  // NOTE: Reactを利用しないDom Manipulationとなるが影響が限定的なので許容
  link.href = url
  link.setAttribute('download', `NIPPOU_${formatEightDigitDate(date)}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
