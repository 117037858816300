import React, { useEffect } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { CancelButton, DatePicker } from 'src/components/atoms'
import { formatSimpleDate } from 'src/utils/date'
import styles from './styles.module.scss'

interface DatePickerWithFormControlProps {
  name: string
  validate?: (value: string) => boolean | string
  hasError?: boolean
}

const DatePickerWithFormControl: React.FC<DatePickerWithFormControlProps> = ({
  name,
  validate,
  hasError = false,
}) => {
  const { control, setValue, register } = useFormContext()

  const handleClearDate = () => {
    setValue(name, '')
  }

  useEffect(() => {
    register(name, { validate })
  }, [register, name, validate])

  return (
    <Box className={clsx(styles.datePickerContainer, hasError && styles.error)}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <DatePicker
              className={styles.datePicker}
              date={field.value}
              maxDate={formatSimpleDate(dayjs().add(10, 'year'))}
              onChange={(date) => field.onChange(date)}
              placeholder="◯◯年◯◯月◯◯日"
            />
            {field.value && (
              <CancelButton
                className={styles.cancelButton}
                onClick={handleClearDate}
              />
            )}
          </>
        )}
      />
    </Box>
  )
}

export default DatePickerWithFormControl
