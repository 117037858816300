import { RadioOption } from 'src/components/atoms'
import {
  ReportTypeEnum,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'

export const reportTypeOption: RadioOption[] = [
  { label: 'CCT報告', value: ReportTypeEnum.Cct },
  { label: 'オフライン報告', value: ReportTypeEnum.Offline },
]

export const registerCategoryOption: RadioOption[] = [
  {
    label: '代表レジとして登録する',
    value: TenantRegisterCategoryEnum.RepresentativeRegister,
  },
  { label: 'その他レジ', value: TenantRegisterCategoryEnum.OtherRegisters },
]
