import React from 'react'
import clsx from 'clsx'
import { ArrowForwardIosSharp } from '@mui/icons-material'
import {
  Box,
  Button as MuiButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'
import { Icon, IconName } from 'src/components/atoms'
import style from './styles.module.scss'

export type SelectButtonProps = {
  options: Option[]
  disabled?: boolean
} & React.StyledProps

export type Option = {
  title: string
  count: number
  icon: IconName
  disabled?: boolean
  onClick?: () => void
}

const SelectButton: React.StyledFC<SelectButtonProps> = ({
  className,
  options,
  disabled,
}: SelectButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const buttonEl = React.useRef<HTMLButtonElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const open = Boolean(anchorEl)

  const handleClickToggle = () => {
    if (buttonEl.current) {
      setAnchorEl(buttonEl.current)
    }
  }

  const handleClickButton = () => {
    options[selectedIndex].onClick?.()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index)
    handleClose()
  }

  return (
    <Box className={clsx(className, style.root)}>
      {options.length > 1 && (
        <MuiButton
          className={clsx(style.button, style.toggleButton)}
          classes={{
            disabled: style.disabled,
          }}
          disabled={disabled}
          onClick={handleClickToggle}
        >
          <ArrowForwardIosSharp
            className={clsx(
              style.toggleIcon,
              open && style.opened,
              disabled ? style.disableIcon : style.unableIcon
            )}
            sx={{ strokeWidth: 10 }}
          />
        </MuiButton>
      )}
      <MuiButton
        ref={buttonEl}
        className={clsx(style.button, style.menu, style.mainButton)}
        classes={{
          disabled: style.disabled,
        }}
        disabled={disabled}
        onClick={handleClickButton}
      >
        <ListItemIcon className={style.listIcon}>
          <Icon
            className={clsx(
              style.icon,
              disabled ? style.disableIcon : style.unableIcon
            )}
            icon={options[selectedIndex].icon}
          />
        </ListItemIcon>
        <Typography
          className={clsx(
            style.title,
            disabled ? style.disableTitle : style.unableTitle
          )}
        >
          {options[selectedIndex].title}
        </Typography>
        <Typography
          className={clsx(
            style.count,
            disabled ? style.disableTitle : style.unableTitle
          )}
        >{`${options[selectedIndex].count}件`}</Typography>
      </MuiButton>
      <Menu
        classes={{
          paper: style.paper,
        }}
        anchorEl={anchorEl}
        open={open}
        onBackdropClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuList>
          {options.map((option, index) => (
            <MenuItem
              className={clsx(
                style.item,
                style.listItem,
                style.menu,
                index === selectedIndex && style.selectedItem
              )}
              disabled={option.disabled}
              key={option.title}
              onClick={() => handleMenuItemClick(index)}
            >
              <ListItemIcon className={style.listIcon}>
                <Icon className={style.icon} icon={option.icon} />
              </ListItemIcon>
              <Typography className={style.title}>{option.title}</Typography>
              <Typography
                className={style.count}
              >{`${option.count}件`}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default SelectButton
