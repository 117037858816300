import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Path as BreadcrumbPath } from 'src/components/molecules/BreadcrumbsPipe'
import useAppTitle from 'src/hooks/useAppTitle'
import Path, { TenantPathParams } from 'src/routes/path'
import {
  getStore,
  selectStoresRequestStateByParams,
  selectStoreByCode,
} from 'src/slices/stores/storesSlice'
import {
  getTenant,
  selectTenantStateByCode,
  selectTenantByCode,
} from 'src/slices/tenants/tenantsSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import CompleteEditCalculationTemplate from '../templates'
import Presenter from './presenter'

const CompleteEditCalculation: React.FC = (): ReactElement => {
  useAppTitle('利用設定 ステップ0')
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const tenant = useAppSelector(
    selectTenantByCode({ orgCode, storeCode, tenantCode })
  )

  const tenantRequestState = useAppSelector(
    selectTenantStateByCode({ orgCode, storeCode, tenantCode })
  )
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbPath[]>([])

  useEffect(() => {
    setBreadcrumbs(Presenter.breadcrumbs(store?.name, tenant?.name))
  }, [store?.name, tenant?.name])

  // not find store, tenant case
  useEffect(() => {
    if (tenantRequestState.status === 'idle') {
      dispatch(getTenant({ orgCode, storeCode, tenantCode }))
    }
  }, [dispatch, orgCode, storeCode, tenantCode, tenantRequestState.status])
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])

  const handleClickClose = () => {
    navigate(Path.tenantCalculationTab(orgCode, storeCode, tenantCode))
  }

  return (
    <CompleteEditCalculationTemplate
      breadcrumbs={breadcrumbs}
      onClickClose={handleClickClose}
    />
  )
}

export default CompleteEditCalculation
