import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import { TenantFormType } from 'src/domain/tenant'
import { CreateTenant } from 'src/slices/editTenants/editTenantsSlice'
import { TenantStatusEnum } from 'src/slices/services/api'
import { fullToHalf } from 'src/utils/string'

interface CreateTenantPresenter {
  requestBody(values: TenantFormType, orgCode: string): CreateTenant[]
}

export const Presenter: CreateTenantPresenter = {
  requestBody(values, orgCode) {
    return values.tenants.map((tenant) => {
      const isOpa =
        VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode)

      const baseTenant = {
        name: fullToHalf(tenant.name),
        code: fullToHalf(tenant.code),
        status: TenantStatusEnum.NotReady,
      }

      if (isOpa) {
        return {
          ...baseTenant,
          contractorCode: tenant.contractorCode,
          section: tenant.section,
          tenantRegisterNumber: tenant.tenantRegisterNumber,
        }
      }

      return baseTenant
    })
  },
}
