import { useContext } from 'react'
import { AuthContext } from 'src/context/auth.context'
import { ApiResponse } from 'src/domain/api'
import { SalesDateRange } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import { axiosErrorMessage } from 'src/slices/services/error'

export const useApiUpdateTotals = () => {
  const authContext = useContext(AuthContext)

  const patchUpdateTotals = async (
    orgCode: string,
    startDate: string,
    endDate: string,
    storeCode?: string
  ): Promise<ApiResponse<void>> => {
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }

      // NOTE: 三井不のネットワークだと411エラーになるので、暫定対応的にrequestBodyを入れる
      const salesDateRange: SalesDateRange = {
        startDate,
        endDate,
      }
      await new AuthenticatedApi(
        token
      ).patchOrganizationsOrganizationCodeTotalsByStore(
        orgCode,
        storeCode,
        startDate,
        endDate,
        salesDateRange
      )
      return { success: true }
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  return { patchUpdateTotals }
}
