import React from 'react'
import { Box } from '@mui/material'
import { Icon } from 'src/components/atoms'
import Guide, { Button } from 'src/components/molecules/Guide'
import styles from './styles.module.scss'

export type LoginSentEmailProps = {
  guideMessage: string
  guideDescription: string
  onClickBack: () => void
}

const LoginSentEmail: React.StyledFC<LoginSentEmailProps> = ({
  guideMessage,
  guideDescription,
  onClickBack,
}: LoginSentEmailProps) => {
  const buttons: Button[] = [{ index: 0, title: '戻る', onClick: onClickBack }]

  return (
    <Box className={styles.container}>
      <Box className={styles.wrapper}>
        <Icon icon="logo" className={styles.logo} />
        <Guide
          message={guideMessage}
          description={guideDescription}
          buttons={buttons}
        />
      </Box>
    </Box>
  )
}

export default LoginSentEmail
