import React from 'react'
import { TableBody } from '@mui/material'
import { AppTableRow, IconCell, InputCell } from 'src/components/tables'
import { CODE_PLACEHOLDER, NAME_PLACEHOLDER } from 'src/domain/tenant'
import {
  TenantCodeRegex,
  TenantCodeRegexErrorMessage,
} from 'src/domain/tenantCode'

export type TenantCreateTableRowDefaultProps = {
  rowState: { keys: number[] }
  orgCode: string
  scrollBottomRef: React.RefObject<HTMLTableRowElement>
  removeRow: (key: number) => void
}

const TenantCreateTableRowDefault = ({
  rowState,
  orgCode,
  scrollBottomRef,
  removeRow,
}: TenantCreateTableRowDefaultProps) => {
  return (
    <TableBody>
      {rowState.keys.map((key) => {
        return (
          <AppTableRow key={key}>
            <IconCell icon="minus" onClick={() => removeRow(key)} />
            <InputCell
              name={`tenants.${key}.code`}
              placeholder={CODE_PLACEHOLDER}
              width={180}
              required
              validations={[
                {
                  regex: TenantCodeRegex(orgCode),
                  message: TenantCodeRegexErrorMessage(orgCode),
                },
              ]}
            />
            <InputCell
              name={`tenants.${key}.name`}
              placeholder={NAME_PLACEHOLDER}
              width={260}
              required
            />
          </AppTableRow>
        )
      })}
      <tr ref={scrollBottomRef} />
    </TableBody>
  )
}

export default TenantCreateTableRowDefault
