import React, { ChangeEvent } from 'react'
import clsx from 'clsx'
import Checkbox from '@mui/material/Checkbox'
import makeStyles from '@mui/styles/makeStyles'
import color from 'src/styles/color'
import { Icon } from '../Icon'

export type CheckBoxProps = {
  name?: string
  isChecked?: boolean
  shape?: 'circle' | 'rectangle'
  disableRipple?: boolean
  disabled?: boolean
  defaultChecked?: boolean
  onChange?: (isChecked: boolean) => void
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    '&:active $icon': {
      background: color.lightGrayHover,
    },
    '&:active $checkedIcon': {
      background: color.primaryHover,
    },
  },
  icon: {
    border: `1px solid ${color.lightGray}`,
    backgroundColor: color.white,
  },
  checkedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.primary,
  },
  rectangle: {
    width: 16,
    height: 16,
    boxSizing: 'border-box',
    borderRadius: 3,
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
})

export const CheckBox: React.StyledFC<CheckBoxProps> = ({
  className,
  name,
  shape = 'circle',
  isChecked,
  disabled = false,
  defaultChecked = false,
  disableRipple = false,
  onChange,
}: CheckBoxProps) => {
  const classes = useStyles()

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (isChecked === undefined) {
        onChange(e.target.checked)
      } else {
        onChange(!isChecked)
      }
    }
  }

  return (
    <Checkbox
      name={name}
      className={clsx(classes.root, className)}
      color="default"
      icon={<span className={clsx(classes.icon, classes[shape])} />}
      checkedIcon={
        <span
          className={clsx(classes.icon, classes.checkedIcon, classes[shape])}
        >
          <Icon icon="checked" />
        </span>
      }
      checked={isChecked}
      defaultChecked={defaultChecked}
      onChange={handleOnChange}
      disabled={disabled}
      disableRipple={disableRipple}
    />
  )
}
