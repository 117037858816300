import React, { ReactElement, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TenantCreateTemplate from 'src/components/templates/TenantCreate'
import { STORE_TENANTS_PER_PAGE } from 'src/constants'
import { ToastTriggerContext } from 'src/context/toast.context'
import { TenantFormType } from 'src/domain/tenant'
import useAppTitle from 'src/hooks/useAppTitle'
import useBeforeUnload from 'src/hooks/useBeforeUnload'
import Path, { StorePathParams } from 'src/routes/path'
import {
  clearEditTenants,
  postTenants,
} from 'src/slices/editTenants/editTenantsSlice'
import {
  selectStoreByCode,
  selectStoresRequestStateByParams,
  getStore,
} from 'src/slices/stores/storesSlice'
import { clearStoreTenants } from 'src/slices/tenants/tenantsSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import { Presenter } from './presenter'

const CreateTenants: React.FC = (): ReactElement => {
  useAppTitle('新規テナント作成')
  useBeforeUnload()
  const { orgCode, storeCode } = useParams() as StorePathParams
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toastContext = useContext(ToastTriggerContext)

  const storedParams = useAppSelector((state) => state.query.storeTenantsParams)
  const storeTenantsParams = {
    orgCode,
    storeCode,
    page: storedParams.page,
    q: storedParams.q,
    status: storedParams.status,
    trained: storedParams.trained,
    perPage: STORE_TENANTS_PER_PAGE,
  }
  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])

  const handleSubmit = async (values: TenantFormType) => {
    try {
      const createdTenants = Presenter.requestBody(values, orgCode)
      await dispatch(
        postTenants({ orgCode, storeCode, tenants: createdTenants })
      ).unwrap()
      dispatch(clearEditTenants())
      dispatch(clearStoreTenants(storeTenantsParams))
      navigate(Path.store(orgCode, storeCode))
      toastContext.sendToast({
        variant: 'success',
        title: '作成に成功しました',
      })
    } catch (error) {
      // TODO: 重複している具体的なtenantCodeを表示する
      if (typeof error === 'object' && error !== null && 'message' in error) {
        toastContext.sendToast({
          variant: 'error',
          title: String(error.message),
        })
      } else {
        toastContext.sendToast({
          variant: 'error',
          title: '作成が失敗しました',
        })
      }
    }
  }

  return (
    <TenantCreateTemplate
      orgCode={orgCode}
      title="新規テナント追加"
      descriptions={[
        '「利用停止」に変更した場合、変更完了した時点からテナントのアプリが利用できなくなります',
        '同一店舗の対して、同一のテナントコードのテナントを追加することができません',
      ]}
      storeName={store?.name || ''}
      storeCode={store?.code || ''}
      submitButtonText="追加する"
      onClickBack={() => navigate(Path.store(orgCode, storeCode))}
      onSubmit={handleSubmit}
    />
  )
}

export default CreateTenants
