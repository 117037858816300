import React from 'react'
import { Box, Divider, List, ListSubheader, Typography } from '@mui/material'
import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import TenantListItem, { TenantListItemProps } from '../TenantListItem'
import styles from './styles.module.scss'

export type TenantListProps = {
  tenants: Tenant[]
  useStoreName: boolean
  orgCode?: string
} & React.StyledProps

export type Tenant = {
  name: string
  code: string
  status: TenantListItemProps['status']
  to: string
  storeName?: string
  contractorCode?: string
  section?: string
  representativeRegisterNumber?: string
  infoUpdatedAt?: string
  trainedAt?: string
}

const TenantList: React.StyledFC<TenantListProps> = ({
  className,
  useStoreName,
  tenants,
  orgCode,
}: TenantListProps) => {
  const isOpa =
    orgCode && VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode)
  return (
    <List
      className={className}
      subheader={
        <ListSubheader
          className={isOpa ? styles.opaHeader : styles.header}
          disableSticky
        >
          <Typography variant="h6" component="span">
            テナント名称
          </Typography>
          <Box className={isOpa ? styles.opaLeftColumn : styles.leftColumn}>
            <Typography className={styles.text} variant="h6" component="span">
              {useStoreName ? '店舗' : ''}
            </Typography>
            {isOpa && (
              <>
                <Typography
                  className={styles.text}
                  variant="h6"
                  component="span"
                >
                  契約者コード
                </Typography>
                <Typography
                  className={styles.text}
                  variant="h6"
                  component="span"
                >
                  区画
                </Typography>
                <Typography
                  className={styles.text}
                  variant="h6"
                  component="span"
                >
                  代表レジNo
                </Typography>
                <Typography
                  className={styles.text}
                  variant="h6"
                  component="span"
                >
                  最終更新日
                </Typography>
              </>
            )}
            <Typography className={styles.text} variant="h6" component="span">
              練習モード最終実施日時
            </Typography>
          </Box>
        </ListSubheader>
      }
    >
      {tenants.map((item) => {
        return (
          <React.Fragment key={item.code}>
            <TenantListItem
              tenantName={item.name}
              tenantCode={item.code}
              status={item.status}
              storeName={
                useStoreName && item.storeName ? item.storeName : undefined
              }
              trainedAt={item.trainedAt}
              to={item.to}
              orgCode={orgCode}
              contractorCode={item.contractorCode}
              section={item.section}
              representativeRegisterNumber={item.representativeRegisterNumber}
              infoUpdatedAt={item.infoUpdatedAt}
            />
            <Divider
              className={styles.divider}
              variant="inset"
              component="li"
            />
          </React.Fragment>
        )
      })}
    </List>
  )
}

export default TenantList
