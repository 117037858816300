import React, { useCallback, useEffect, useReducer, useState } from 'react'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { Button } from 'src/components/atoms'
import Modal from 'src/components/molecules/Modal'
import TenantFilterBar, {
  FilterForm,
} from 'src/components/organisms/TenantFilterBar'
import TenantUpdateTable, {
  TenantUpdateTableProps,
  TenantFormType,
} from 'src/components/organisms/TenantUpdateTable'
import Loading from 'src/components/templates/Loading'
import { TenantStatusEnum } from 'src/slices/services/api'
import { findDuplicates } from 'src/utils/array'
import { InitialTenantState, reducerFunc } from 'src/utils/tenantReducer'
import {
  AGREE_BUTTON,
  BACK_BUTTON,
  CHANCEL_BUTTON,
  DESCRIPTION,
  MODAL_TITLE,
  SUBMIT_BUTTON,
  TITLE,
} from './constants'
import styles from './styles.module.scss'

export type TenantUpdateProps = {
  orgCode: string
  storeName: string
  storeCode: string
  loading?: boolean
  onClickBack: () => void
  onSubmit: (values: TenantFormType) => void
} & Pick<TenantUpdateTableProps, 'tenants'>

const TenantUpdate: React.StyledFC<TenantUpdateProps> = ({
  orgCode,
  tenants,
  storeName,
  storeCode,
  loading,
  onClickBack,
  onSubmit,
}: TenantUpdateProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [tenantState, dispatch] = useReducer(
    reducerFunc,
    InitialTenantState(tenants),
    (init) => init
  )
  useEffect(() => {
    dispatch({ type: 'UPDATE_TENANTS', payload: { allTenants: tenants } })
  }, [tenants])

  const filterMethods = useForm<FilterForm>()
  const { watch: filterWatch } = filterMethods
  const filterWatcher = filterWatch()
  const [duplicateTenantCodes, setDuplicateTenantCodes] = useState<string[]>([])

  useEffect(() => {
    const filterStatus: TenantStatusEnum[] = []
    if (filterWatcher.notReady) filterStatus.push(TenantStatusEnum.NotReady)
    if (filterWatcher.ready) filterStatus.push(TenantStatusEnum.Ready)
    if (filterWatcher.closed) filterStatus.push(TenantStatusEnum.Closed)

    dispatch({
      type: 'UPDATE_FILTERED_TENANTS',
      payload: {
        filterStatus,
        searchParamText: filterWatcher.tenantSearchText,
      },
    })
  }, [
    filterWatcher.notReady,
    filterWatcher.closed,
    filterWatcher.ready,
    filterWatcher.tenantSearchText,
  ])

  const tenantFormMethods = useForm<TenantFormType>()

  const handleClickAgree = () => {
    onClickBack()
    setModalOpen(false)
  }

  const handleSubmit = async (values: TenantFormType) => {
    const mappedValues = {
      tenants: values.tenants.map((t) => ({
        ...t,
        status: t.status || TenantStatusEnum.NotReady,
      })),
    }

    onSubmit(mappedValues)
  }

  const { watch: tenantWatch } = tenantFormMethods
  const tenantWatcher = tenantWatch()

  const checkDuplicateCode = useCallback((): string[] => {
    const updatedTenants = tenantFormMethods.getValues().tenants
    if (!updatedTenants) return []

    const tenantCodes = updatedTenants.map((tenant) => tenant.code)
    const duplicates = findDuplicates(tenantCodes)
    return duplicates
  }, [tenantFormMethods])

  useEffect(() => {
    const duplicateCode = checkDuplicateCode()
    setDuplicateTenantCodes(duplicateCode)
  }, [checkDuplicateCode, tenantWatcher])

  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.header}>
          <Box className={styles.title}>
            <Typography variant="h1">{TITLE}</Typography>
            <Box className={styles.subTitle}>
              <Typography
                variant="h2"
                className={clsx(styles.item, styles.code)}
              >
                {storeCode}
              </Typography>
              <Typography variant="h2" className={styles.item}>
                {storeName}
              </Typography>
            </Box>
            <FormProvider {...filterMethods}>
              <form>
                <TenantFilterBar
                  className={styles.filterBar}
                  tenantCount={tenantState.filteredTenants.length}
                />
              </form>
            </FormProvider>
          </Box>
          <Box className={styles.description}>
            <Typography variant="body2">{DESCRIPTION}</Typography>
          </Box>
        </Box>
        {loading ? (
          <Loading />
        ) : (
          <FormProvider {...tenantFormMethods}>
            <form onSubmit={tenantFormMethods.handleSubmit(handleSubmit)}>
              <Box className={styles.main}>
                <TenantUpdateTable
                  tenants={tenantState.filteredTenants}
                  orgCode={orgCode}
                  storeCode={storeCode}
                />
              </Box>
              <Box className={styles.footer}>
                <Box className={styles.buttonWrapper}>
                  {duplicateTenantCodes.length > 0 && (
                    <span className={styles.footerErrorMessage}>
                      {`テナントコード${duplicateTenantCodes.join(
                        ', '
                      )}は重複しています`}
                    </span>
                  )}
                  <Button
                    type="button"
                    className={styles.button}
                    color="secondary"
                    size="medium"
                    title={BACK_BUTTON}
                    onClick={() => setModalOpen(true)}
                  />
                  <Button
                    type="submit"
                    className={styles.button}
                    color="primary"
                    size="medium"
                    title={SUBMIT_BUTTON}
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>
        )}
      </Box>
      <Modal
        open={modalOpen}
        title={MODAL_TITLE}
        agreeButtonTitle={AGREE_BUTTON}
        disagreeButtonTitle={CHANCEL_BUTTON}
        onClickAgree={handleClickAgree}
        onClickDisagree={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default TenantUpdate
