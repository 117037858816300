import React from 'react'
import { CircularProgress as Progress } from '@mui/material'

export type CircularProgressProps = React.StyledProps

export const CircularProgress: React.StyledFC<CircularProgressProps> = ({
  className,
}: CircularProgressProps) => {
  return <Progress color="primary" className={className} />
}
