import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import SelectWithOption, {
  SelectWithOptionProps,
} from 'src/components/molecules/SelectWithOption'
import { FormNameEnum } from '../../type'
import styles from './styles.module.scss'

export type TenantSalesReportDateOptionsProps = {
  selectOptions: SelectWithOptionProps['options']
  selectPlaceholder: string
  defaultValue?: string
} & React.StyledProps

const TenantSalesReportDateOptions: React.StyledFC<
  TenantSalesReportDateOptionsProps
> = ({
  className,
  selectOptions,
  selectPlaceholder,
  defaultValue,
}: TenantSalesReportDateOptionsProps) => {
  return (
    <Box className={clsx(className, styles.registerHeader)}>
      {selectOptions.length > 1 ? (
        <Box className={styles.textAndSelectBox}>
          <Box className={styles.number}>
            <Typography
              component="span"
              className={styles.registerHeaderNumber}
            >
              <span className={styles.reportTitle}>報告回数</span>
              <span className={styles.reportNumber}>
                {selectOptions.length}
              </span>
              <span className={styles.reportUnit}>件</span>
            </Typography>
          </Box>
          <Box className={styles.selectContainer}>
            <SelectWithOption
              className={styles.selectBox}
              name={FormNameEnum.selectSalesDate}
              options={selectOptions}
              placeholder={selectPlaceholder}
              defaultValue={defaultValue}
              variant="outlined"
            />
          </Box>
        </Box>
      ) : (
        <Typography align="center" className={clsx(styles.onlyTextArea)}>
          報告日時
          <Typography component="span" className={clsx(styles.reportDate)}>
            {selectPlaceholder}
          </Typography>
        </Typography>
      )}
    </Box>
  )
}

export default TenantSalesReportDateOptions
