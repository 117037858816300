import React, { ReactElement, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import Loading from 'src/components/templates/Loading'
import { AuthContext } from 'src/context/auth.context'
import Path from 'src/routes/path'
import FirebaseError, { localizedMessage } from 'src/slices/auth/firebaseError'
import { error, success } from 'src/slices/snackbar/snackbarSlice'

const LoginAction: React.FC = (): ReactElement => {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useMount(() => {
    if (authContext.isSignInWithEmailLink(window.location.href)) {
      authContext
        .signIn()
        .then(() => {
          navigate('/')
          dispatch(success('ログインしました'))
        })
        .catch((e: FirebaseError) => {
          navigate(Path.login)
          dispatch(error(localizedMessage(e.code)))
        })
    } else {
      navigate(Path.login)
      dispatch(error('メールで受信したリンクからログインしてください'))
    }
  })

  return <Loading />
}

export default LoginAction
