import React, { ReactElement, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import {
  StringParam,
  NumberParam,
  withDefault,
  useQueryParams,
} from 'use-query-params'
import StoresTemplate from 'src/components/templates/Stores'
import { DEFAULT_PAGE } from 'src/constants'
import useAppTitle from 'src/hooks/useAppTitle'
import { OrgPathParams } from 'src/routes/path'
import { setStoresParams } from 'src/slices/query/querySlice'
import {
  getStores,
  selectStoresByParams,
  selectStoresRequestStateByParams,
} from 'src/slices/stores/storesSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import Presenter from './presenter'

const Stores = (): ReactElement => {
  useAppTitle('店舗一覧')
  const location = useLocation()
  // Query
  const storedParams = useAppSelector((state) => state.query.storesParams)
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, DEFAULT_PAGE),
    q: withDefault(StringParam, ''),
  })
  const { page, q } = query
  const perPage = 25
  const params = useMemo(() => {
    return {
      orgCode,
      page,
      perPage,
      q,
    }
  }, [orgCode, page, perPage, q])

  useMount(() => {
    if (location.pathname === storedParams.pathname) {
      setQuery({
        page: storedParams.page,
        q: storedParams.q,
      })
    }
  })

  // Redux
  const dispatch = useAppDispatch()
  const { status, totalCount } = useAppSelector((state) =>
    selectStoresRequestStateByParams(params)(state)
  )

  const paginatedStores = useAppSelector((state) =>
    selectStoresByParams(params)(state)
  )
  const templateStores = useMemo(() => {
    return (
      paginatedStores?.map((store) => {
        return Presenter.mapTemplateStore(orgCode, store)
      }) ?? []
    )
  }, [orgCode, paginatedStores])

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getStores(params))
    }
  }, [dispatch, status, params])
  useEffect(() => {
    dispatch(
      setStoresParams({
        page: params.page,
        q: params.q,
        pathname: location.pathname,
      })
    )
  }, [dispatch, params.page, params.q, location.pathname])

  const handlePaginationClick = (move: number) => {
    setQuery({ page: move })
  }

  const handleSearchFieldChange = (text: string) => {
    setQuery({ page: DEFAULT_PAGE, q: text })
  }

  return (
    <StoresTemplate
      queryState={location.search}
      stores={templateStores}
      currentPage={page}
      total={totalCount ?? 0} // TODO: undefinedの対応
      perPage={perPage}
      paginationOnClick={handlePaginationClick}
      onChangeSearchText={handleSearchFieldChange}
      defaultSearchValue={storedParams.q}
      isLoading={status === 'loading'}
    />
  )
}

export default Stores
