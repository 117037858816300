import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from 'react'
import { useMount } from 'react-use'
import { Box, Divider } from '@mui/material'
import { Button } from 'src/components/atoms'
import Modal from 'src/components/molecules/Modal'
import { VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES } from 'src/domain/org'
import {
  TenantDetail,
  TenantRegister,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'
import commonStyles from '../../common.module.scss'
import TenantDetailForm from '../../components/TenantDetailForm'
import TenantRegistersForm from '../../components/TenantRegistersForm'
import {
  INITIAL_STATE,
  reducerFunc,
  TenantRegisterRow,
} from '../../registerReducer'
import styles from './styles.module.scss'

export type EditTenantDetailProps = {
  tenantDetail?: TenantDetail
  tenantRegisters?: TenantRegister[]
  orgCode: string
  onCancelEdit: () => void
  onSubmit: (
    tenantDetailFormData: TenantDetail,
    registers?: {
      updatedRegisters?: TenantRegister[]
      deletedIds?: string[]
    }
  ) => void
}

const footerErrorMessage = '不正な項目があります'

const EditTenantDetail: React.StyledFC<EditTenantDetailProps> = ({
  tenantDetail,
  tenantRegisters,
  orgCode,
  onSubmit,
  onCancelEdit,
}: EditTenantDetailProps) => {
  const [state, dispatch] = useReducer(reducerFunc, INITIAL_STATE)
  const tenantDetailFormData = useRef<TenantDetail>()
  const [isTenantDetailFormValid, setIsTenantDetailFormValid] =
    useState<boolean>(true)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [showFooterError, setShowFooterError] = useState<boolean>(false)
  const [showAllError, setShowAllError] = useState<boolean>(false)

  const isFormValid =
    isTenantDetailFormValid &&
    !state.registers.some((register) => register.isDuplicate) &&
    !state.registers.some((register) => register.hasFormError)

  const handleClickAgree = useCallback(() => {
    onCancelEdit()
    setIsModalOpen(false)
  }, [onCancelEdit])

  const handleClickDisagree = () => {
    setIsModalOpen(false)
  }

  const handleAddRegister = () => {
    setShowFooterError(false)
    dispatch({ type: 'ADD_ROW' })
  }

  const handleRemoveRegister = (register: TenantRegisterRow) => {
    dispatch({
      type: 'REMOVE_ROW',
      payload: {
        formData: {
          rowNum: register.rowNum,
          formUpdatedData: {
            id: register.formUpdatedData.id,
            registerNumber: register.formUpdatedData.registerNumber,
            registerCategory: register.formUpdatedData.registerCategory,
          },
        },
      },
    })
  }

  const handleRegisterNumberChange = (
    currentRegister: TenantRegisterRow,
    newRegisterNumber: string
  ) => {
    dispatch({
      type: 'UPDATE_REGISTER_FORM_DATA', // FIXME: レジ番号とレジカテゴリーの変更を別のtypeに分離する
      payload: {
        formData: {
          formUpdatedData: {
            id: currentRegister.formUpdatedData.id,
            registerNumber: newRegisterNumber,
            registerCategory: currentRegister.formUpdatedData.registerCategory,
          },
          rowNum: currentRegister.rowNum,
        },
      },
    })
  }

  const handleRegisterCategoryChange = (currentRegister: TenantRegisterRow) => {
    dispatch({
      type: 'UPDATE_REGISTER_FORM_DATA', // FIXME: レジ番号とレジカテゴリーの変更を別のtypeに分離する
      payload: {
        formData: {
          formUpdatedData: {
            id: currentRegister.formUpdatedData.id,
            registerNumber: currentRegister.formUpdatedData.registerNumber,
            registerCategory: TenantRegisterCategoryEnum.RepresentativeRegister,
          },
          rowNum: currentRegister.rowNum,
        },
      },
    })
  }

  const handleValidate = useCallback(
    (validStatus: { isValid: boolean; id?: string; rowNum: number }) => {
      dispatch({
        type: 'ERROR',
        payload: {
          eachValidStatus: {
            isValid: validStatus.isValid,
            id: validStatus.id,
            rowNum: validStatus.rowNum,
          },
        },
      })
    },
    []
  )

  const handleFormSubmit = useCallback(() => {
    setShowAllError(true)
    setShowFooterError(true)
    if (!tenantDetailFormData.current || !isFormValid) {
      return
    }
    onSubmit(tenantDetailFormData.current, {
      updatedRegisters: state.updatedRegisters,
      deletedIds: state.deletedIds,
    })
  }, [isFormValid, onSubmit, state.deletedIds, state.updatedRegisters])

  useMount(() => {
    if (tenantRegisters && tenantRegisters.length > 0) {
      dispatch({
        type: 'SET_INITIAL_REGISTERS',
        payload: {
          originalRegisters: tenantRegisters,
        },
      })
    }
  })

  useEffect(() => {
    if (showAllError) {
      setShowAllError(false)
    }
  }, [showAllError])

  return (
    <Box className={commonStyles.root}>
      <TenantDetailForm
        tenantDetail={tenantDetail}
        orgCode={orgCode}
        showAllError={showAllError}
        onTenantDetailValidate={(isValid: boolean) => {
          setIsTenantDetailFormValid(isValid)
        }}
        onTenantDetailFormDataChange={(formData: TenantDetail) => {
          tenantDetailFormData.current = formData
        }}
      />
      {VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode) &&
        state.registers.length > 0 && (
          <TenantRegistersForm
            registers={state.registers}
            showAllError={showAllError}
            onRemoveRegister={handleRemoveRegister}
            onAddRegister={handleAddRegister}
            onValidate={handleValidate}
            onRegisterNumberChange={handleRegisterNumberChange}
            onRegisterCategoryChange={handleRegisterCategoryChange}
          />
        )}
      <Box className={commonStyles.footer}>
        <Divider className={commonStyles.divider} />
        <Box className={commonStyles.footerButtonWrapper}>
          <Box className={commonStyles.flexContainer}>
            {!isFormValid && showFooterError && (
              <span className={styles.footerErrorMessage}>
                {footerErrorMessage}
              </span>
            )}
            <Button
              className={commonStyles.footerButton}
              color="secondary"
              size="medium"
              title="もどる"
              variant="light"
              onClick={() => setIsModalOpen(true)}
            />
            <Button
              className={commonStyles.footerButton}
              type="button"
              color="primary"
              size="medium"
              title="更新する"
              onClick={handleFormSubmit}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        open={isModalOpen}
        title={`今中断すると編集内容は\n失われてしまいますがよろしいですか？`}
        agreeButtonTitle="このまま中断する"
        disagreeButtonTitle="編集に戻る"
        onClickAgree={handleClickAgree}
        onClickDisagree={handleClickDisagree}
      />
    </Box>
  )
}

export default EditTenantDetail
