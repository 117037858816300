import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import { Button, Icon } from 'src/components/atoms'
import { ENABLE_SEND_NOTIFICATION_ORG_CODES } from 'src/domain/org'
import { Notification } from 'src/slices/services/api/api'
import { formatDateTime } from 'src/utils/date'
import styles from './styles.module.scss'

export type TenantSalesReportNotificationProps = {
  notifications?: Notification[]
  orgCode: string
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles: boolean
  onClickNotificationButton: () => void
} & React.StyledProps

export const TenantSalesReportNotification: React.StyledFC<
  TenantSalesReportNotificationProps
> = ({
  className,
  notifications,
  orgCode,
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles,
  onClickNotificationButton,
}: TenantSalesReportNotificationProps) => {
  if (
    !ENABLE_SEND_NOTIFICATION_ORG_CODES.includes(orgCode) ||
    !isEnableToFinalizeAndFilterByMisMatchAndSendMessageAndSendNotificationByRoles
  ) {
    return null
  }

  return (
    <Box className={clsx(className, styles.root)}>
      {notifications && notifications.length > 0 ? (
        <Box className={styles.eachCategory}>
          <Box className={styles.title}>
            <Icon
              className={clsx(styles.icon, styles.colorBlack)}
              icon="exclamation"
            />
            <Typography className={styles.text} variant="h4">
              メッセージ
              <span className={styles.notificationCount}>
                {notifications.length}件
              </span>
            </Typography>
            <button
              type="button"
              className={styles.addNotificationButton}
              onClick={onClickNotificationButton}
            >
              追加する
            </button>
          </Box>
          {notifications.map((notification) => (
            <Box key={notification.createdAt}>
              <Box className={styles.sentDate}>
                <Typography
                  className={clsx(
                    styles.fontInfo,
                    styles.status,
                    !notification.isReadByTenant && styles.unread
                  )}
                >
                  {notification.isReadByTenant ? '既読' : '未読'}
                </Typography>
                <Typography className={clsx(styles.date, styles.fontInfo)}>
                  送信日時
                </Typography>
                <Typography className={clsx(styles.date, styles.fontInfo)}>
                  {formatDateTime(notification.createdAt)}
                </Typography>
              </Box>
              <Typography className={clsx(styles.messageBody, styles.fontInfo)}>
                {notification.content}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className={styles.buttonWrapper}>
          <Button
            className={styles.notificationButton}
            color="secondary"
            size="large"
            title="メッセージを登録する"
            onClick={onClickNotificationButton}
          />
        </Box>
      )}
    </Box>
  )
}
