import React from 'react'
import clsx from 'clsx'
import { TableCell, Typography } from '@mui/material'
import { Tooltip } from 'src/components/atoms'
import cellStyles from 'src/components/tables/Cells/styles.module.scss'
import styles from './styles.module.scss'

export type TextCellProps = {
  text: string
  width?: number
  unit?: string
} & React.StyledProps

export const TextCell = React.memo(
  ({ className, style, text, width, unit }: TextCellProps) => {
    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <Tooltip title={text} showOnlyEllipsis>
          <Typography
            component="span"
            variant="h5"
            className={clsx(styles.text, width && styles.ellipsis)}
            style={{
              maxWidth: width,
              minWidth: width,
            }}
          >
            {text}
            {unit && (
              <Typography color="textSecondary" className={styles.unit}>
                {unit}
              </Typography>
            )}
          </Typography>
        </Tooltip>
      </TableCell>
    )
  }
)

TextCell.displayName = 'TextCell'
