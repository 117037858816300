import React from 'react'
import { LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import color from 'src/styles/color'

export type ProgressBarProps = {
  progress: number
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: color.white,
  },
  determinate: {
    borderRadius: 10,
  },
})

export const ProgressBar: React.StyledFC<ProgressBarProps> = ({
  className,
  progress,
}: ProgressBarProps) => {
  const classes = useStyles()
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      className={className}
      classes={{ root: classes.root, bar: classes.determinate }}
    />
  )
}
