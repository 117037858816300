import React, { memo, useCallback, useMemo, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useMount } from 'react-use'
import { Box } from '@mui/material'
import { Store as EntityStore } from 'src/slices/services/api'
import CheckBoxListWithFilter, {
  CheckFormType,
  CheckParm,
} from '../CheckBoxListWithFilter'
import PopupChild from '../PopupChild'
import style from './styles.module.scss'

export type MemberAssignedStoreCheckProps = {
  stores: EntityStore[]
  defaultCheckList: CheckFormType
  formName: string
  defaultTitle: string
  becomeErrorIfEmpty?: boolean
  disabledAllCheck?: boolean
  width?: number
  onChange?: (data: CheckFormType) => void
} & React.StyledProps

export const validateCheckBoxListValue = (value: CheckFormType) => {
  if (value.checkListValues.length > 0 || value.allChecked) {
    return true
  }
  return false
}

const MemberAssignedStoreCheck: React.StyledFC<MemberAssignedStoreCheckProps> =
  memo(
    ({
      className,
      stores,
      defaultCheckList,
      formName,
      defaultTitle,
      becomeErrorIfEmpty = false,
      disabledAllCheck = false,
      width,
      onChange,
    }: MemberAssignedStoreCheckProps) => {
      const [assignedStoreTitle, setAssignedStoreTitle] =
        React.useState<string>('')
      const formMethods = useFormContext()
      const [isEmptyError, setIsEmptyError] = useState<boolean>(false) // NOTE: Popupを初めて開くまではエラーをtriggerできないので、表示用のエラーはformとは別に管理する

      const checkList: CheckParm[] = useMemo(() => {
        return stores.map((store) => {
          return { label: `${store.code} ${store.name}`, value: store.id }
        })
      }, [stores])

      const handleChangeAssignedStoreTitle = useCallback(
        async (data: CheckFormType) => {
          let newState = ''
          const checklistLength = data.checkListValues?.length
          if (data.allChecked) {
            newState = '全ての店舗'
          } else if (checklistLength === 0) {
            newState = '未選択'
          } else if (checklistLength > 1) {
            newState = `${checklistLength}つの店舗`
          } else if (checklistLength === 1) {
            const foundStore = stores.find(
              (store) => store.id === data.checkListValues[0]
            )
            newState = foundStore?.name || '不正'
          }
          if (newState !== assignedStoreTitle) {
            setAssignedStoreTitle(newState)
          }
          if (becomeErrorIfEmpty) {
            setIsEmptyError(
              data.checkListValues?.length === 0 && !data.allChecked
            )
            await formMethods.trigger(formName)
          }
        },
        [assignedStoreTitle, becomeErrorIfEmpty, formMethods, formName, stores]
      )

      const splitedFormName = useCallback(() => {
        return formName.split('.')
      }, [formName])

      useEffect(() => {
        const subscription = formMethods.watch((value, { name }) => {
          if (name === formName) {
            const index = Number(splitedFormName()[1])
            if (!Number.isNaN(index)) {
              const formValue = value.members[index]
              const checkFormValue = formValue?.assignedStores
              if (checkFormValue) {
                handleChangeAssignedStoreTitle(checkFormValue)
              }
            }
          }
        })
        return () => subscription.unsubscribe()
      }, [
        formMethods,
        formName,
        handleChangeAssignedStoreTitle,
        splitedFormName,
      ])

      const initializeStoreCheckBox = useCallback(() => {
        formMethods.setValue(formName, defaultCheckList)
        handleChangeAssignedStoreTitle(defaultCheckList)
      }, [
        defaultCheckList,
        formMethods,
        formName,
        handleChangeAssignedStoreTitle,
      ])

      useMount(() => {
        initializeStoreCheckBox()
      })
      const handleChangeAssignedStore = useCallback(
        (data: CheckFormType) => {
          if (onChange) {
            onChange(data)
          }
          handleChangeAssignedStoreTitle(data)
          if (becomeErrorIfEmpty) {
            formMethods.trigger(formName)
          }
        },
        [
          handleChangeAssignedStoreTitle,
          onChange,
          formName,
          formMethods,
          becomeErrorIfEmpty,
        ]
      )

      return (
        <Box className={className}>
          <PopupChild
            className={style.storeFilterButton}
            width={width}
            title={assignedStoreTitle || defaultTitle}
            placement="bottom-end"
          >
            <CheckBoxListWithFilter
              defaultValues={defaultCheckList}
              checkList={checkList}
              formName={formName}
              onChange={handleChangeAssignedStore}
              disabledAllCheck={disabledAllCheck}
              validate={
                becomeErrorIfEmpty ? validateCheckBoxListValue : undefined
              }
            />
          </PopupChild>
          {isEmptyError && <span className={style.error}>必須項目です</span>}
        </Box>
      )
    }
  )

MemberAssignedStoreCheck.displayName = 'MemberAssignedStoreCheck'
export default MemberAssignedStoreCheck
