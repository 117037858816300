import React, { memo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import { CheckFormType } from 'src/components/molecules/CheckBoxListWithFilter'
import MemberAssignedStoreCheck from 'src/components/molecules/MemberAssignedStoreCheck'
import SearchTextField from 'src/components/molecules/SearchTextField'
import SelectWithOption, {
  Option,
} from 'src/components/molecules/SelectWithOption'
import { STATUS_OPTIONS_WITH_NOT_READY } from 'src/domain/tenant'
import { Store, TenantStatusEnum } from 'src/slices/services/api'
import {
  FilterFormType,
  FilterParam,
  FilterFormTypeEnum,
  TenantStatusValue,
} from '../../type'
import styles from './styles.module.scss'

const InitialFilterParm: FilterFormType = {
  [FilterFormTypeEnum.tenantStatus]: 'all',
  [FilterFormTypeEnum.searchText]: '',
}

const options: Option[] = [
  { title: 'すべてのステータス', value: 'all' },
  ...STATUS_OPTIONS_WITH_NOT_READY,
]

const defaultCheckList: CheckFormType = {
  checkListValues: [],
  allChecked: true,
}

const adjustFormParam = (formData: FilterFormType): FilterParam => {
  const tenantStatus =
    formData.tenantStatus !== 'all'
      ? (formData.tenantStatus as TenantStatusEnum)
      : undefined
  const storeCodes = formData.storeCodes?.checkListValues
  return {
    tenantStatus,
    storeCodes,
    searchText: formData.searchText,
  }
}

export type TenantsOfTenantOwnerCompanyFilterBarProps = {
  count?: number
  stores: Store[]
  isUpdatePage?: boolean
  onChangeFilterParams: (filterData: FilterParam) => void
} & React.StyledProps

const TenantsOfTenantOwnerCompanyFilterBar: React.StyledFC<TenantsOfTenantOwnerCompanyFilterBarProps> =
  memo(
    ({
      count,
      stores,
      isUpdatePage = false,
      onChangeFilterParams,
    }: TenantsOfTenantOwnerCompanyFilterBarProps) => {
      const formMethods = useForm<FilterFormType>({
        defaultValues: InitialFilterParm,
      })

      const applyFormData = (formData: FilterFormType) => {
        const adjustedParm = adjustFormParam(formData)
        onChangeFilterParams(adjustedParm)
      }

      const handleClickClear = () => {
        formMethods.reset({ searchText: '' })
        const formData = formMethods.getValues()
        formData.searchText = ''
        applyFormData(formData)
      }

      const handleChangeFormValue = (text: string) => {
        const formData = formMethods.getValues()
        formData.searchText = text
        applyFormData(formData)
      }

      const handleChangeOption = (option: Option) => {
        const formData = formMethods.getValues()
        formData.tenantStatus = option.value as TenantStatusValue
        applyFormData(formData)
      }

      const handleChangeStores = (checkForm: CheckFormType) => {
        const formData = formMethods.getValues()
        formData.storeCodes = checkForm
        applyFormData(formData)
      }

      return (
        <FormProvider {...formMethods}>
          <form>
            <Box className={styles.container}>
              <Box className={styles.item}>
                {isUpdatePage ? '選択済み' : ''}
                <span
                  className={isUpdatePage ? styles.associated : styles.total}
                >
                  {count}
                </span>
                {isUpdatePage ? '件' : 'テナント'}
              </Box>
              <Box className={styles.item}>
                <SelectWithOption
                  name={FilterFormTypeEnum.tenantStatus}
                  options={options}
                  placeholder="テナントステータス"
                  variant="standard"
                  onChange={handleChangeOption}
                />
              </Box>
              <Box className={styles.item}>
                <MemberAssignedStoreCheck
                  stores={stores}
                  defaultCheckList={defaultCheckList}
                  formName={FilterFormTypeEnum.storeCodes}
                  defaultTitle="全て"
                  onChange={handleChangeStores}
                />
              </Box>
              <Box className={styles.item}>
                <SearchTextField
                  name={FilterFormTypeEnum.searchText}
                  type="text"
                  placeholder="テナントコード or テナント名称"
                  className={styles.search}
                  onClickClear={handleClickClear}
                  onChange={handleChangeFormValue}
                />
              </Box>
            </Box>
          </form>
        </FormProvider>
      )
    }
  )
TenantsOfTenantOwnerCompanyFilterBar.displayName =
  'TenantsOfTenantOwnerCompanyFilterBar'
export default TenantsOfTenantOwnerCompanyFilterBar
