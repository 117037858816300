import React, { ChildrenProps } from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import styles from './styles.module.scss'

export type CardProps = {
  className?: string
  title: string
  subtitle?: number
  unit?: string
  width?: number
} & React.StyledProps &
  ChildrenProps

export const Card: React.StyledFC<CardProps> = ({
  className,
  title,
  subtitle,
  unit,
  children,
  width,
}: CardProps) => {
  return (
    <Box className={clsx(className, styles.root)} style={{ width }}>
      <Box className={styles.card}>
        <Typography variant="h2">{title}</Typography>
        {subtitle && (
          <Typography variant="h2" className={styles.subtitle}>
            {subtitle}
            {unit && <span className={styles.unit}>{unit}</span>}
          </Typography>
        )}
      </Box>
      <Box className={styles.body}>{children}</Box>
    </Box>
  )
}
