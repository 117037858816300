/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { RequestState } from 'src/domain/request'
import { TenantStatusEnum, ErrorCodeEnum } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from 'src/store'
import { purge } from 'src/store/action'

const createTenantDuplicateErrorMessage = '既存のテナントコードと重複しています'
const updateTenantDuplicateErrorMessage = 'テナントコードに重複が存在します'

type EditTenantsState = Omit<RequestState, 'status'> & {
  patchStatus: RequestState['status']
  postStatus: RequestState['status']
}

export const initialState: EditTenantsState = {
  patchStatus: 'idle',
  postStatus: 'idle',
}

export type CreateTenant = {
  name: string
  code: string
  status: TenantStatusEnum
  contractorCode?: string
  section?: string
  tenantRegisterNumber?: string
}

export type PostTenantsParam = {
  orgCode: string
  storeCode: string
  tenants: CreateTenant[]
}

export const postTenants = createAsyncThunk<
  void,
  PostTenantsParam,
  AppThunkConfig
>('editTenants/postTenants', async (params, { getState }) => {
  const { auth } = getState()
  try {
    await new AuthenticatedApi(auth.token).postTenants(
      params.orgCode,
      params.storeCode,
      params.tenants
    )
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorCodes = error.response?.data.errors.find(
        (err: { code: string }) => err.code.length > 0
      )
      if (errorCodes.code === ErrorCodeEnum.AlreadyExists) {
        throw new Error(createTenantDuplicateErrorMessage)
      }
    }

    throw error
  }
})

export type UpdateTenant = {
  id: string
  name?: string
  code?: string
  status?: TenantStatusEnum
  contractorCode?: string
  section?: string
  is_ocr_required?: boolean
  is_qr_printed?: boolean
}

export type PatchTenantsParam = {
  orgCode: string
  tenants: UpdateTenant[]
}

export const patchTenants = createAsyncThunk<
  void,
  PatchTenantsParam,
  AppThunkConfig
>('editTenants/patchTenants', async (params, { getState }) => {
  const { auth } = getState()
  try {
    await new AuthenticatedApi(
      auth.token
    ).patchOrganizationsOrganizationCodeTenants(params.orgCode, params.tenants)
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorCode = error.response?.data.code
      if (errorCode === ErrorCodeEnum.AlreadyExists) {
        throw new Error(updateTenantDuplicateErrorMessage)
      }
    }
    throw error
  }
})

const slice = createSlice({
  name: 'editTenants',
  initialState,
  reducers: {
    clearEditTenants: (): EditTenantsState => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(postTenants.pending, (state) => {
      return {
        ...state,
        postStatus: 'loading',
      }
    })
    builder.addCase(postTenants.fulfilled, (state) => {
      return {
        ...state,
        postStatus: 'succeeded',
      }
    })
    builder.addCase(postTenants.rejected, (state) => {
      return {
        ...state,
        postStatus: 'failed',
      }
    })
    builder.addCase(patchTenants.pending, (state) => {
      return {
        ...state,
        patchStatus: 'loading',
      }
    })
    builder.addCase(patchTenants.fulfilled, (state) => {
      return {
        ...state,
        patchStatus: 'succeeded',
      }
    })
    builder.addCase(patchTenants.rejected, (state) => {
      return {
        ...state,
        patchStatus: 'failed',
      }
    })
  },
})

export const { clearEditTenants } = slice.actions
export default slice.reducer
