import React, { ChildrenProps } from 'react'
import clsx from 'clsx'
import { Dialog, DialogTitle, DialogActions } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from 'src/components/atoms'
import color from 'src/styles/color'

export type ModalProps = {
  open: boolean
  title: string
  disableBackdropClick?: boolean
  agreeButtonTitle: string
  disagreeButtonTitle?: string
  onClose?: () => void
  onClickAgree: () => void
  onClickDisagree?: () => void
} & React.StyledProps &
  ChildrenProps

const useStyles = makeStyles({
  backDrop: {
    backgroundColor: color.black430,
  },
  dialog: {
    borderRadius: '6px',
  },
  title: {
    width: 250,
    padding: '41px 35px 20px 35px',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  action: {
    display: 'grid',
    justifyContent: 'center',
    paddingBottom: 26,
  },
  agreeButton: {
    width: 224,
    marginBottom: 8,
  },
  disagreeButton: {
    width: 224,
  },
})

const Modal: React.StyledFC<ModalProps> = ({
  className,
  open,
  disableBackdropClick,
  title,
  agreeButtonTitle,
  disagreeButtonTitle,
  children,
  onClose,
  onClickAgree,
  onClickDisagree,
}: ModalProps) => {
  const classes = useStyles()

  const handleClose = (_: Event, reason: string) => {
    if (reason === 'backdropClick' && disableBackdropClick) return
    onClose?.()
  }

  return (
    <Dialog
      classes={{
        paper: clsx(classes.dialog, className),
      }}
      BackdropProps={{
        className: classes.backDrop,
      }}
      open={open}
      data-testid="modal"
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      {children}
      <DialogActions disableSpacing className={classes.action}>
        <Button
          color="primary"
          size="large"
          className={classes.agreeButton}
          title={agreeButtonTitle}
          onClick={onClickAgree}
        />
        {disagreeButtonTitle && onClickDisagree && (
          <Button
            color="secondary"
            size="large"
            className={classes.disagreeButton}
            title={disagreeButtonTitle || ''}
            variant="light"
            onClick={onClickDisagree}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
