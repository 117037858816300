import { Column } from 'src/components/tables'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  isOneOfRoles,
} from 'src/domain/role'
import {
  CalculationItemCategoryNameTypeEnum,
  RoleEnum,
  StoreSalesReportSummary,
} from 'src/slices/services/api'
import { formatDateTime } from 'src/utils/date'
import { Row } from '../components/StoreSalesReportSummaryTable'

export interface StoreSalesReportSummaryPresenter {
  convertToRows(summaries: StoreSalesReportSummary[], role?: RoleEnum): Row[]
}

const Presenter: StoreSalesReportSummaryPresenter = {
  convertToRows: (
    summaries: StoreSalesReportSummary[],
    role?: RoleEnum
  ): Row[] => {
    return summaries.map((summary) => {
      const netSales = summary.totals?.find(
        (total) =>
          total.calculationItemCategoryName ===
          CalculationItemCategoryNameTypeEnum.NetSales
      )
      const visitor = summary.totals?.find(
        (total) =>
          total.calculationItemCategoryName ===
          CalculationItemCategoryNameTypeEnum.Visitor
      )
      const lastUpdatedAt = summary.totals?.find((total) =>
        [
          CalculationItemCategoryNameTypeEnum.NetSales,
          CalculationItemCategoryNameTypeEnum.Visitor,
        ]
          .map((value) => value.toString())
          .includes(total.calculationItemCategoryName)
      )?.updatedAt

      const additionalCells = isOneOfRoles(
        AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
        role
      )
        ? [
            {
              key: 'netSalesManualValue',
              title:
                netSales?.manualValue || netSales?.manualValue === 0
                  ? String(netSales.manualValue.toLocaleString())
                  : '-',
              unit: netSales?.manualValue ? '円' : undefined,
              isTotalCell: true,
            },
            {
              key: 'netSalesFinalizedValue',
              title:
                netSales?.finalizedValue || netSales?.finalizedValue === 0
                  ? String(netSales?.finalizedValue.toLocaleString())
                  : '-',
              unit: netSales?.finalizedValue ? '円' : undefined,
              isTotalCell: true,
            },
            {
              key: 'visitorManualValue',
              title:
                visitor?.manualValue || visitor?.manualValue === 0
                  ? String(visitor.manualValue.toLocaleString())
                  : '-',
              unit: visitor?.manualValue ? '人' : undefined,
              isTotalCell: true,
            },
            {
              key: 'lastUpdatedAt',
              title: lastUpdatedAt ? formatDateTime(lastUpdatedAt) : '-',
              unit: undefined,
              isTotalCell: false,
            },
          ]
        : []

      const cells = [
        {
          key: 'storeCode',
          title: summary.store.code,
        },
        {
          key: 'storeName',
          title: summary.store.name,
        },
        {
          key: 'total',
          title: String(summary.total),
          unit: '件',
        },
        {
          key: 'submitted',
          title: String(summary.submitted),
          unit: '件',
        },
        {
          key: 'missing',
          title: String(summary.missing),
          unit: '件',
        },
        ...additionalCells,
      ]

      return {
        key: `${summary.store.id}-${summary.date}`,
        cells,
      }
    })
  },
}

export default Presenter

export const getTableHeader = (role?: RoleEnum): Column[] => {
  const baseColumns: Column[] = [
    {
      title: '店舗コード',
    },
    {
      title: '店舗名',
      width: 184,
    },
    {
      title: '報告対象',
    },
    {
      title: '報告済み',
    },
    {
      title: '報告漏れ',
    },
  ]

  if (
    isOneOfRoles(
      AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
      role
    )
  ) {
    const additionalColumns: Column[] = [
      {
        title: '純売上合計(報告値)',
      },
      {
        title: '純売上合計(確定値)',
      },
      {
        title: '客数合計(報告値)',
      },
      {
        title: '最終更新日時',
      },
    ]
    return [...baseColumns, ...additionalColumns]
  }

  return baseColumns
}
