import { useContext, useCallback } from 'react'
import { AxiosError } from 'axios'
import { AuthContext } from 'src/context/auth.context'
import { ApiResponse } from 'src/domain/api'
import {
  TenantOwnerCompany,
  TenantOwnerCompanyPostRequest,
  TenantOwnerCompanyPatchRequest,
  ErrorCodeEnum,
} from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi' // TODO: api呼び出しをhooksから利用するとすると、feturesを呼ぶのが遠いので構成変更検討
import { axiosErrorMessage } from 'src/slices/services/error'

export interface TenantsOwnerCompanyWithCount {
  tenantOwnerCompanies: TenantOwnerCompany[]
  count: number
}

export const useApiTenantOwnerCompany = () => {
  const authContext = useContext(AuthContext)

  const getTenantOwnerCompanies = useCallback(
    async (
      orgCode: string,
      offset: number,
      limit: number,
      searchText?: string,
      searchActive?: boolean
    ): Promise<ApiResponse<TenantsOwnerCompanyWithCount>> => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        const res = await new AuthenticatedApi(
          token
        ).getOrganizationsOrganizationCodeTenantOwnerCompanies(
          orgCode,
          offset,
          limit,
          searchText,
          searchActive
        )
        // NOTE: countが無い場合は十分大きな数として5000を設定
        const count = Number(res.headers['x-total-count']) ?? 5000

        return {
          success: true,
          data: { count, tenantOwnerCompanies: res.data },
        }
      } catch (e: unknown) {
        const message = axiosErrorMessage(e)
        return { success: false, message }
      }
    },
    [authContext]
  )

  const getTenantOwnerCompany = useCallback(
    async (
      orgCode: string,
      tenantOwnerCompanyCode: string
    ): Promise<ApiResponse<TenantOwnerCompany>> => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        const res = await new AuthenticatedApi(
          token
        ).getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          orgCode,
          tenantOwnerCompanyCode
        )

        return {
          success: true,
          data: res.data,
        }
      } catch (e: unknown) {
        const message = axiosErrorMessage(e)
        return { success: false, message }
      }
    },
    [authContext]
  )

  const createTenantOwnerCompanies = useCallback(
    async (orgCode: string, data: TenantOwnerCompanyPostRequest[]) => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        const res = await new AuthenticatedApi(
          token
        ).postOrganizationsOrganizationCodeTenantOwnerCompanies(orgCode, data)

        return { success: true, data: res.data }
      } catch (err: unknown) {
        const { response } = err as AxiosError
        if (
          response?.status === 422 &&
          response?.data &&
          Array.isArray(response.data)
        ) {
          const duplicatedCodes = response.data.join(',')
          return {
            success: false,
            message: `コード ${duplicatedCodes} は既存の取引企業と重複しています`,
          }
        }
        const message = axiosErrorMessage(err)
        return { success: false, message }
      }
    },
    [authContext]
  )

  const updateTenantOwnerCompanies = useCallback(
    async (orgCode: string, data: TenantOwnerCompanyPatchRequest[]) => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        await new AuthenticatedApi(
          token
        ).patchOrganizationsOrganizationCodeTenantOwnerCompanies(orgCode, data)
        return { success: true }
      } catch (err: unknown) {
        const { response } = err as AxiosError
        if (
          (response?.data as { code: ErrorCodeEnum })?.code ===
          ErrorCodeEnum.AlreadyExists
        ) {
          return {
            success: false,
            message: '取引企業コードに重複が存在します',
          }
        }
        const message = axiosErrorMessage(err)
        return { success: false, message }
      }
    },
    [authContext]
  )

  return {
    getTenantOwnerCompanies,
    getTenantOwnerCompany,
    updateTenantOwnerCompanies,
    createTenantOwnerCompanies,
  }
}
