import { Item } from 'src/components/molecules/TableOfContents'
import { Sections } from 'src/components/organisms/Sidebar'
import { ENABLE_TENANT_OWNER_COMPANY } from 'src/domain/org'
import { AdminAdminStoreManagerRoles, isOneOfRoles } from 'src/domain/role'
import Path from 'src/routes/path'
import { RoleEnum } from 'src/slices/services/api'

interface SidebarPresenter {
  sections(orgCode: string, role: RoleEnum): Sections
}

const Presenter: SidebarPresenter = {
  sections(orgCode, role) {
    const sectionObj: Sections = {
      business: [
        {
          items: [
            {
              title: '日次管理',
              to: Path.storeSalesReportSummaries(orgCode),
              icon: 'calendar',
            },
            {
              title: '店舗一覧',
              to: Path.stores(orgCode),
              icon: 'company',
            },
            {
              title: 'テナント一覧',
              to: Path.tenants(orgCode),
              icon: 'shop',
            },
          ],
        },
      ],
      account: [
        {
          items: [
            {
              title: 'プロフィール',
              to: Path.profile(orgCode),
              icon: 'profile',
            },
            {
              title: 'リリースノート',
              to: 'https://gv-zero-public.notion.site/Zero-db2366b8414044cd9e987596a9116a0d',
              icon: 'note',
            },
          ],
        },
      ],
    }

    if (ENABLE_TENANT_OWNER_COMPANY.includes(orgCode)) {
      const tenantOwnerCompany: Item = {
        title: '取引企業',
        to: Path.tenantOwnerCompanies(orgCode),
        icon: 'bag',
      }
      sectionObj.business[0].items.splice(2, 0, tenantOwnerCompany)
    }
    if (isOneOfRoles(AdminAdminStoreManagerRoles, role)) {
      const accountItem: Item = {
        title: 'ユーザー管理',
        to: Path.members(orgCode),
        icon: 'member',
      }
      sectionObj.account[0].items.unshift(accountItem)
    }
    return sectionObj
  },
}

export default Presenter
