import React from 'react'
import clsx from 'clsx'
import { Box, Button, Typography } from '@mui/material'
import { Icon } from 'src/components/atoms'
import AccountInformation, {
  AccountInformationProps,
} from 'src/components/molecules/AccountInformation'
import Modal from 'src/components/molecules/Modal'
import { Option } from 'src/components/molecules/SelectWithOption'
import TableOfContents, {
  Content,
} from 'src/components/molecules/TableOfContents'
import styles from './styles.module.scss'

export type Sections = {
  business: Content[]
  account: Content[]
}

export type SidebarProps = {
  selectedNavigationPath?: string
  sections: Sections
  isModalOpen: boolean
  onClickLogo: () => void
  onClickLogout: () => void
  onChangeOrganization: (option: Option) => void
  onModalOpen: () => void
  onModalClose: () => void
} & React.StyledProps &
  Pick<
    AccountInformationProps,
    'organizations' | 'currentOrganizationName' | 'memberName'
  >

const Sidebar: React.FC<SidebarProps> = ({
  className,
  organizations,
  currentOrganizationName,
  memberName,
  selectedNavigationPath,
  sections,
  isModalOpen,
  onClickLogo,
  onClickLogout,
  onChangeOrganization,
  onModalOpen,
  onModalClose,
}: SidebarProps) => {
  return (
    <Box className={clsx(styles.container, className)}>
      {process.env.REACT_APP_ENV !== 'production' && (
        <Typography className={styles.env} component="span">
          {process.env.REACT_APP_ENV}
        </Typography>
      )}
      <Box className={styles.topContainer}>
        <Box className={styles.topTableOfContents}>
          <Button
            disableRipple
            className={styles.logoButton}
            onClick={onClickLogo}
          >
            <Icon icon="logo" className={styles.logo} />
          </Button>
          <TableOfContents
            contents={sections.business}
            currentPath={selectedNavigationPath}
          />
        </Box>
        <TableOfContents
          contents={sections.account}
          currentPath={selectedNavigationPath}
        />
      </Box>
      <Box className={styles.bottomContainer}>
        <AccountInformation
          organizations={organizations}
          currentOrganizationName={currentOrganizationName}
          memberName={memberName}
          selectName="organization"
          onChange={onChangeOrganization}
          onClickLogout={onModalOpen}
        />
        <Modal
          open={isModalOpen}
          disableBackdropClick={false}
          title="ログアウトしてもよろしいですか？"
          agreeButtonTitle="ログアウト"
          disagreeButtonTitle="とじる"
          onClose={onModalClose}
          onClickAgree={onClickLogout}
          onClickDisagree={onModalClose}
        />
      </Box>
    </Box>
  )
}

export default Sidebar
