import React, { useCallback } from 'react'
import { Box, Divider } from '@mui/material'
import { Button } from 'src/components/atoms'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import SmallGuide from 'src/components/molecules/SmallGuide'
import TenantFilter, {
  TenantFilterProps,
} from 'src/components/molecules/TenantFilter'
import TenantListWithPagination, {
  TenantListWithPaginationProps,
} from 'src/components/organisms/TenantListWithPagination'
import {
  AdminAllEditorStoreManagerInOperationCenterEditorStoreManagerRoles,
  isOneOfRoles,
} from 'src/domain/role'
import { RoleEnum } from 'src/slices/services/api'
import styles from './styles.module.scss'

export type StoreTenantsTabProps = {
  role?: RoleEnum
  isLoading: boolean
  showsUnderline?: boolean
  stickyTop?: number
  onClickAddTenant?: () => void
  onClickEditTenant?: () => void
} & TenantFilterProps &
  Omit<TenantListWithPaginationProps, 'useStoreName'>

const StoreTenantsTabTemplate: React.StyledFC<StoreTenantsTabProps> = ({
  role,
  isLoading,
  currentPage,
  filters,
  rowsPerPage,
  showsUnderline = false,
  stickyTop = 0,
  tenants,
  tenantCount,
  orgCode,
  paginationOnClick,
  searchFieldOnChange,
  defaultSearchValue,
  isChecked,
  onChangeCheck,
  onClickAddTenant,
  onClickEditTenant,
}: StoreTenantsTabProps) => {
  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(
      AdminAllEditorStoreManagerInOperationCenterEditorStoreManagerRoles,
      role
    )
  }, [role])

  const renderTenantList = () => {
    return tenantCount && tenantCount > 0 ? (
      <TenantListWithPagination
        {...{
          currentPage,
          rowsPerPage,
          tenantCount,
          tenants,
          useStoreName: false,
          orgCode,
          paginationOnClick,
        }}
      />
    ) : (
      <SmallGuide
        icon="pray"
        className={styles.guide}
        message="該当の結果はありません"
        description="検索キーワードを確認して再度試してください"
      />
    )
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.header} style={{ top: stickyTop }}>
        {showSwitchByRole() && (
          <Box className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="secondary"
              size="medium"
              onClick={onClickAddTenant}
              title="テナント新規追加"
            />
            <Button
              className={styles.button}
              color="primary"
              size="medium"
              onClick={onClickEditTenant}
              title="テナント編集"
            />
          </Box>
        )}
        <TenantFilter
          {...{
            filters,
            tenantCount,
            searchFieldOnChange,
            defaultSearchValue,
            isChecked,
            onChangeCheck,
          }}
        />
        <Divider
          className={styles.divider}
          style={{ opacity: showsUnderline ? 1 : 0 }}
        />
      </Box>
      {isLoading ? <CenteringLoading /> : renderTenantList()}
    </Box>
  )
}

export default StoreTenantsTabTemplate
