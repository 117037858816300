import React from 'react'
import { useFormContext } from 'react-hook-form'
import { TableBody } from '@mui/material'
import { AppTableRow, InputCell, SelectCell } from 'src/components/tables'
import {
  CODE_PLACEHOLDER,
  NAME_PLACEHOLDER,
  STATUS_OPTIONS,
  STATUS_PLACEHOLDER,
  TenantFormType,
  TenantRow,
} from 'src/domain/tenant'
import {
  TenantCodeRegex,
  TenantCodeRegexErrorMessage,
} from 'src/domain/tenantCode'

export type TenantUpdateTableDefaultProps = {
  tenants: TenantRow[]
  orgCode: string
}

const TenantUpdateTableDefault: React.FC<TenantUpdateTableDefaultProps> = ({
  tenants,
  orgCode,
}) => {
  const formMethods = useFormContext<TenantFormType>()

  return (
    <TableBody>
      {tenants.map((tenant) => (
        <AppTableRow key={tenant.index}>
          <SelectCell
            name={`tenants.${tenant.index}.status`}
            options={STATUS_OPTIONS}
            placeholder={STATUS_PLACEHOLDER}
            defaultValue={
              formMethods.getValues(`tenants.${tenant.index}.status`) ||
              tenant.status
            }
            width={120}
          />
          <InputCell
            name={`tenants.${tenant.index}.code`}
            defaultValue={
              formMethods.getValues(`tenants.${tenant.index}.code`) ||
              tenant.code
            }
            placeholder={CODE_PLACEHOLDER}
            width={180}
            required
            validations={[
              {
                regex: TenantCodeRegex(orgCode),
                message: TenantCodeRegexErrorMessage(orgCode),
              },
            ]}
          />
          <InputCell
            name={`tenants.${tenant.index}.name`}
            defaultValue={
              formMethods.getValues(`tenants.${tenant.index}.name`) ||
              tenant.name
            }
            placeholder={NAME_PLACEHOLDER}
            width={260}
            required
          />
        </AppTableRow>
      ))}
    </TableBody>
  )
}

export default TenantUpdateTableDefault
