import React from 'react'
import clsx from 'clsx'
import { Table, TableHead, TableBody, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  TableHeaderRow,
  TableHeaderItem,
  TableBodyRow,
  TableBodyItem,
} from 'src/components/atoms'
import color from 'src/styles/color'
import { TABLE_DIVIDER_THICKNESS } from 'src/styles/constants'

export type TableRow = {
  index: number
  items: TableBodyItem[]
}

export type OCRFormatTableProps = {
  columns: TableHeaderItem[]
  records: TableRow[]
} & React.StyledProps

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
  },
  leftTableWrapper: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
  },
  leftTable: {
    borderRight: `${TABLE_DIVIDER_THICKNESS}px solid ${color.lightGray02}`,
  },
  rightTableWrapper: {
    zIndex: 0,
  },
  ocrFormatTable: {
    width: 'unset',
  },
})

const OCRFormatTable: React.StyledFC<OCRFormatTableProps> = ({
  className,
  columns,
  records,
}: OCRFormatTableProps) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.leftTableWrapper}>
        <Table
          stickyHeader
          className={clsx(classes.ocrFormatTable, classes.leftTable)}
        >
          <TableHead>
            <TableHeaderRow items={columns.slice(0, 2)} />
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <TableBodyRow
                key={record.index}
                items={record.items.slice(0, 2)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.rightTableWrapper}>
        <Table stickyHeader className={classes.ocrFormatTable}>
          <TableHead>
            <TableHeaderRow items={columns.slice(2)} />
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <TableBodyRow key={record.index} items={record.items.slice(2)} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default OCRFormatTable
