/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RequestState } from 'src/domain/request'
import { CalculationDSLDefiniteValue } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import { putDefiniteValueSerializeError } from 'src/slices/services/error'
// eslint-disable-next-line import/no-cycle
import { AppThunkConfig } from 'src/store'
import { purge } from 'src/store/action'

interface DefiniteState extends RequestState {
  salesDateId?: string
  forms: PutDefiniteValue[]
}

export type PutDefiniteValue = {
  id: string
  calculationItems: CalculationDSLDefiniteValue[]
}

export const initialState: DefiniteState = {
  status: 'idle',
  forms: [],
}

type PutDefiniteValuesParam = {
  tenantId: string
  salesDateId: string
  forms: PutDefiniteValue[]
}

export const putDefiniteValues = createAsyncThunk<
  void,
  PutDefiniteValuesParam,
  AppThunkConfig
>(
  'definiteValues/putDefiniteValues',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(auth.token).putTenantsIdDefiniteValue(
      params.tenantId,
      params.salesDateId,
      params.forms
    )
  },
  { serializeError: putDefiniteValueSerializeError }
)

const slice = createSlice({
  name: 'definiteValues',
  initialState,
  reducers: {
    clearPutDefiniteValues: (): DefiniteState => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(putDefiniteValues.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(putDefiniteValues.fulfilled, () => {
      return {
        ...initialState,
        status: 'succeeded',
      }
    })
    builder.addCase(putDefiniteValues.rejected, (state, { error }) => {
      return {
        ...state,
        status: 'failed',
        error,
      }
    })
  },
})

export default slice.reducer

export const { clearPutDefiniteValues } = slice.actions
