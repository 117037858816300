import React from 'react'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import DatePagination from 'src/components/molecules/DatePagination'
import { formatSimpleDate } from 'src/utils/date'
import style from './styles.module.scss'

export type TenantSalesReportSummarySearchDateProps = {
  date: string
  onChangeDate: (date: string) => void
} & React.StyledProps

export const TenantSalesReportSummarySearchDate: React.StyledFC<
  TenantSalesReportSummarySearchDateProps
> = ({
  className,
  date,
  onChangeDate,
}: TenantSalesReportSummarySearchDateProps) => {
  const updateDate = (offsetDay: number) => {
    const newDate = formatSimpleDate(dayjs(date).add(offsetDay, 'day'))
    onChangeDate(newDate)
  }

  const handleClickPrev = () => {
    updateDate(-1)
  }

  const handleClickNext = () => {
    updateDate(1)
  }

  const handleChangeDate = (newDate: string) => {
    onChangeDate(newDate)
  }

  const maxDate = dayjs().format('YYYY-MM-DD')

  return (
    <Box className={className}>
      <DatePagination
        className={style.date}
        date={date}
        maxDate={maxDate}
        disabledNext={
          dayjs(date).isSame(maxDate) || dayjs(date).isAfter(maxDate)
        }
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
        onChange={handleChangeDate}
      />
    </Box>
  )
}
