import React, {
  useReducer,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react'
import { Box, Typography } from '@mui/material'

import CenteringLoading from 'src/components/molecules/CenteringLoading'
import LoadMore from 'src/components/molecules/LoadMore'
import Modal from 'src/components/molecules/Modal'
import SmallGuide from 'src/components/molecules/SmallGuide'
import {
  TenantWithStoreAndTenantOwnerCompany,
  Store,
  TenantOwnerCompany,
  TenantsOfTenantOwnerCompanyPatchRequest,
} from 'src/slices/services/api'
import listStyles from 'src/styles/list-styles.module.scss'

import TenantsOfTenantOwnerCompanyFilterBar from '../../components/FilterBar'
import Footer from '../../components/Footer'
import UpdateTable from '../../components/UpdateTable'
import { FilterParam } from '../../type'
import { reducerFunc, INITIAL_STATE } from '../../updateReducer'
import styles from './styles.module.scss'

export type TemplatesTenantsOfTenantOwnerCompanyUpdateProps = {
  tenantsWithStoreAndTenantOwnerCompany?: TenantWithStoreAndTenantOwnerCompany[]
  totalCount?: number
  associatedTenantsCount?: number
  stores: Store[]
  tenantOwnerCompany?: TenantOwnerCompany
  onChangeFilterParams: (filterData: FilterParam) => void
  onSubmitUpdate: (data: TenantsOfTenantOwnerCompanyPatchRequest) => void
  loadMore: (currentCount: number) => void
  onGoBack: () => void
}

const TemplatesTenantsOfTenantOwnerCompanyUpdate: React.FC<
  TemplatesTenantsOfTenantOwnerCompanyUpdateProps
> = ({
  tenantsWithStoreAndTenantOwnerCompany,
  totalCount,
  associatedTenantsCount,
  stores,
  tenantOwnerCompany,
  onChangeFilterParams,
  onSubmitUpdate,
  loadMore,
  onGoBack,
}: TemplatesTenantsOfTenantOwnerCompanyUpdateProps) => {
  const [state, dispatch] = useReducer(reducerFunc, INITIAL_STATE)
  const bottomBoundaryRef = useRef(null)
  const [showCancelModal, setShowCancelModal] = useState(false)

  useEffect(() => {
    dispatch({
      type: 'SET_TENANT_OWNER_COMPANY_ID',
      payload: { tenantOwnerCompanyId: tenantOwnerCompany?.id },
    })
  }, [tenantOwnerCompany])

  useEffect(() => {
    if (Array.isArray(tenantsWithStoreAndTenantOwnerCompany)) {
      dispatch({
        type: 'ADD_NETWORK_DATA',
        payload: {
          networkData: tenantsWithStoreAndTenantOwnerCompany,
          totalCount,
          networkAssociatedTenantsCount: associatedTenantsCount,
        },
      })
    }
  }, [
    tenantsWithStoreAndTenantOwnerCompany,
    totalCount,
    associatedTenantsCount,
  ])

  const handleChangeFilterParams = (filterData: FilterParam) => {
    dispatch({ type: 'UPDATE_SEARCH_CONDITION' })
    onChangeFilterParams(filterData)
  }

  const handleAddAssociation = (tenantId: string) => {
    dispatch({ type: 'ADD_ASSOCIATION', payload: { tenantId } })
  }

  const handleRemoveAssociation = (tenantId: string) => {
    dispatch({ type: 'REMOVE_ASSOCIATION', payload: { tenantId } })
  }

  const submitUpdate = () => {
    const add = state.shownTenants
      .filter((tenant) => tenant.associationAdded)
      .map((tenant) => tenant.tenant.id)
    const remove = state.shownTenants
      .filter((tenant) => tenant.associationRemoved)
      .map((tenant) => tenant.tenant.id)
    onSubmitUpdate({ add, remove })
  }

  const handleOnReachToBottom = useCallback(() => {
    if (state.hasMoreContent && !state.isLoadingMore) {
      if (typeof state.currentCount === 'number') {
        loadMore(state.currentCount)
        dispatch({ type: 'LOAD_MORE' })
      }
    }
  }, [loadMore, state.currentCount, state.hasMoreContent, state.isLoadingMore])

  useEffect(() => {
    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        handleOnReachToBottom()
      }
    })
    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current)
    }
    return () => observer.disconnect()
  }, [handleOnReachToBottom])

  const handleShowUpdated = (showUpdated: boolean) => {
    if (showUpdated) {
      dispatch({ type: 'SHOW_UPDATED' })
    } else {
      dispatch({ type: 'SHOW_ALL' })
    }
  }

  const renderContent = () => {
    if (state.isLoading || !state.tenantOwnerCompanyId) {
      return (
        <Box className={styles.loadingContainer}>
          <CenteringLoading />
        </Box>
      )
    }
    if (totalCount === 0) {
      return (
        <SmallGuide
          icon="pray"
          className={styles.guide}
          message="該当の結果はありません"
          description="検索キーワードを確認して再度試してください"
        />
      )
    }
    return (
      <>
        <UpdateTable
          onAddAssociation={handleAddAssociation}
          onRemoveAssociation={handleRemoveAssociation}
          tenantsWithStoreAndTenantOwnerCompany={state.shownTenants}
        />
        <div ref={bottomBoundaryRef} />
      </>
    )
  }

  return (
    <>
      <Box>
        <Box className={listStyles.container}>
          <Typography className={styles.title} variant="h1">
            {tenantOwnerCompany?.name}
          </Typography>
          <Box className={styles.filterWrapper}>
            {state.showUpdated && (
              <Box className={styles.warningUpdate}>
                <Typography variant="body1">
                  更新された箇所のみ表示されています
                </Typography>
              </Box>
            )}
            <Box
              className={
                state.showUpdated ? styles.hidden : '' // Note: html要素は残した上で隠している。
              }
            >
              <TenantsOfTenantOwnerCompanyFilterBar
                onChangeFilterParams={handleChangeFilterParams}
                count={state.associatedTenantsCount}
                stores={stores}
                isUpdatePage
              />
            </Box>
          </Box>
          <Box className={styles.mainContent}>
            {renderContent()}
            {state.isLoadingMore && <LoadMore />}
            <Box sx={{ m: '2rem' }} />
          </Box>
        </Box>
        <Box className={styles.bottom}>
          <Footer
            onUpdate={() => {
              submitUpdate()
            }}
            hasUpdate={state.hasUpdate}
            onGoBack={() => {
              setShowCancelModal(true)
            }}
            onShowUpdated={handleShowUpdated}
          />
        </Box>
      </Box>
      <Modal
        open={showCancelModal}
        title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
        agreeButtonTitle="このまま中断する"
        disagreeButtonTitle="設定に戻る"
        onClickAgree={onGoBack}
        onClickDisagree={() => setShowCancelModal(false)}
      />
    </>
  )
}
export default TemplatesTenantsOfTenantOwnerCompanyUpdate
