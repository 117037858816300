import React, {
  cloneElement,
  ReactElement,
  useState,
  UIEvent,
  useRef,
  useEffect,
} from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Breadcrumbs, {
  BreadcrumbsProps,
} from 'src/components/molecules/Breadcrumbs'
import TabsWithLine, {
  TabsWithLineProps,
} from 'src/components/molecules/TabsWithLine'
import Loading from 'src/components/templates/Loading'
import color from 'src/styles/color'
import {
  BREADCRUMB_HEIGHT,
  TABS_WITH_LINE_HEIGHT,
  TAB_CONTENT_HEIGHT,
} from 'src/styles/constants'
import listStyles from 'src/styles/list-styles.module.scss'

export type StoreProps = {
  queryState?: string
  breadCrumbPaths: BreadcrumbsProps['paths']
  tabOptions: TabsWithLineProps['options']
  tabContents: ReactElement[]
  tabIndex: number
  store: string
  storeCode: string
  isLoading: boolean
  onSelectTab: (index: number) => void
}

const useStyles = makeStyles({
  breadcrumb: {
    position: 'sticky',
    zIndex: 10,
    top: 0,
    height: BREADCRUMB_HEIGHT,
    backgroundColor: color.white,
  },
  title: {
    paddingTop: 4,
    marginBottom: 27,
    fontSize: '24px',
  },
  storeCode: {
    marginLeft: 24,
  },
  tab: {
    position: 'sticky',
    zIndex: 10,
    top: BREADCRUMB_HEIGHT - 2, // TabWithLineのmarginBottom:2px分ずらす
    backgroundColor: color.white,
  },
  contentArea: {
    flexGrow: 1,
  },
  tabContents: {
    height: TAB_CONTENT_HEIGHT,
  },
})

const Store: React.StyledFC<StoreProps> = ({
  breadCrumbPaths,
  queryState,
  tabOptions,
  tabContents,
  tabIndex,
  store,
  storeCode,
  onSelectTab,
  isLoading,
}: StoreProps) => {
  const classes = useStyles()
  const [showsUnderline, setShowsUnderline] = useState(false)
  const scrollAreaElement = useRef<HTMLDivElement>(null)
  const handleTabClick = (index: number) => {
    onSelectTab(index)
  }
  const handleScroll = (e: UIEvent) => {
    // タイトルのスペース(74px)
    setShowsUnderline(e.currentTarget.scrollTop > 74 + BREADCRUMB_HEIGHT)
  }

  useEffect(() => {
    if (scrollAreaElement.current?.scroll) {
      scrollAreaElement.current.scroll(0, 0)
    }
  }, [queryState, scrollAreaElement])

  return (
    <Box
      className={listStyles.container}
      onScroll={handleScroll}
      {...{ ref: scrollAreaElement }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Breadcrumbs className={classes.breadcrumb} paths={breadCrumbPaths} />
          <Box className={classes.contentArea}>
            <Typography className={classes.title} variant="h1">
              <Typography display="inline" variant="inherit">
                {store}
              </Typography>
              <Typography
                className={classes.storeCode}
                color="textSecondary"
                display="inline"
                variant="inherit"
              >
                {storeCode}
              </Typography>
            </Typography>
            {/* FIXME: tab じゃなくなってるので、これが表示されることがないので削除 */}
            {tabOptions.length > 1 && (
              <TabsWithLine
                className={classes.tab}
                options={tabOptions}
                selectedIndex={tabIndex}
                onClick={handleTabClick}
              />
            )}
            <Box className={classes.tabContents} data-testid="tabContents">
              {cloneElement(tabContents[tabIndex], {
                showsUnderline,
                stickyTop: TABS_WITH_LINE_HEIGHT + BREADCRUMB_HEIGHT,
              })}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Store
