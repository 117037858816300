import React from 'react'
import clsx from 'clsx'
import { TableCell } from '@mui/material'
import {
  Badge,
  BadgeColor,
  FillType as BadgeFillType,
} from 'src/components/atoms'
import cellStyles from 'src/components/tables/Cells/styles.module.scss'

export type BadgeCellProps = {
  label: string
  color: BadgeColor
  fillType?: BadgeFillType
  ellipse?: boolean
} & React.StyledProps

export const BadgeCell = React.memo<BadgeCellProps>(
  ({
    className,
    style,
    label,
    color,
    fillType,
    ellipse = false,
  }: BadgeCellProps) => {
    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <Badge
          label={label}
          color={color}
          fillType={fillType}
          ellipse={ellipse}
        />
      </TableCell>
    )
  }
)

BadgeCell.displayName = 'BadgeCell'
