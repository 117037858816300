import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { CheckFormType } from 'src/components/molecules/CheckBoxListWithFilter'
import MemberAssignedStoreCheck from 'src/components/molecules/MemberAssignedStoreCheck'
import SearchTextField from 'src/components/molecules/SearchTextField'
import SelectWithOption, {
  Option,
} from 'src/components/molecules/SelectWithOption'
import { RoleEnum, Store as EntityStore } from 'src/slices/services/api'
import { roleOptionsWithAll } from '../../type'
import style from './styles.module.scss'

const SEARCH_FIELD_NAME = 'SEARCH_FIELD_NAME'

const defaultCheckList: CheckFormType = {
  checkListValues: [],
  allChecked: true,
}

export type MemberFilterBarProps = {
  onSearchTextUpdated: (searchText: string) => void
  onRoleUpdated: (role: RoleEnum) => void
  onAssignedStoresUpdated: (data: CheckFormType) => void
  stores: EntityStore[]
} & React.StyledProps

const MemberFilterBar: React.StyledFC<MemberFilterBarProps> = memo(
  ({
    onSearchTextUpdated,
    onRoleUpdated,
    onAssignedStoresUpdated,
    stores,
  }: MemberFilterBarProps) => {
    const formMethods = useFormContext()

    const handleClickClear = () => {
      formMethods.setValue(SEARCH_FIELD_NAME, '')
      onSearchTextUpdated('')
    }

    const handleChangeSearchText = (searchText: string) => {
      onSearchTextUpdated(searchText)
    }

    const handleRoleChange = (role: Option) => {
      onRoleUpdated(role.value as RoleEnum)
    }

    return (
      <Box className={style.container}>
        <Box className={style.item}>
          <SearchTextField
            name={SEARCH_FIELD_NAME}
            type="text"
            placeholder="名前 or メールアドレス"
            onClickClear={handleClickClear}
            onChange={handleChangeSearchText}
          />
        </Box>
        <Box className={style.item}>
          <SelectWithOption
            className={style.filter}
            name="role-filter"
            options={roleOptionsWithAll}
            placeholder="権限選択"
            variant="standard"
            onChange={handleRoleChange}
          />
        </Box>
        <Box className={style.item}>
          <MemberAssignedStoreCheck
            stores={stores}
            defaultCheckList={defaultCheckList}
            formName="assignedStore"
            defaultTitle="全て"
            onChange={onAssignedStoresUpdated}
          />
        </Box>
      </Box>
    )
  }
)

MemberFilterBar.displayName = 'MemberFilterBar'
export default MemberFilterBar
