import React from 'react'
import { TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CalculationTableHeaderCell,
  CalculationTableHeaderCellSize,
} from 'src/components/atoms'

export type CalculationTableHeaderRowProps = {
  cells: Cell[]
} & React.StyledProps

export type Cell = {
  index: number
  text: string
  size: CalculationTableHeaderCellSize
  tooltipText?: string
  colSpan?: number
}

const useStyles = makeStyles({
  cell: {
    '&:last-child': {
      width: '100%',
    },
  },
})

const CalculationTableHeaderRow: React.StyledFC<
  CalculationTableHeaderRowProps
> = ({ cells, className }: CalculationTableHeaderRowProps) => {
  const classes = useStyles()

  return (
    <TableRow className={className}>
      {cells.map((cell) => (
        <CalculationTableHeaderCell
          className={classes.cell}
          key={cell.index}
          text={cell.text}
          size={cell.size}
          tooltipText={cell.tooltipText}
          colSpan={cell.colSpan}
        />
      ))}
    </TableRow>
  )
}

export default CalculationTableHeaderRow
