import React, { useCallback, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import clsx from 'clsx'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { ArrowForwardIosSharp } from '@mui/icons-material'
import { Box, Collapse, TableCell, TableRow, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Icon, Tooltip } from 'src/components/atoms'
import TextFieldWithComma from 'src/components/molecules/TextField/textFieldWithComma'
import color from 'src/styles/color'
import { isNumber } from 'src/utils/number'
import { limitValue } from '../../constants'

const timeout = 500
const rowHeight = 44
const errorMessageNineDigitsLimit = `9桁以下で入力してください`

export type TenantSalesReportTableRowProps = {
  cells: Cell[]
  childRows: ChildRow[]
  inputCell: InputCell
  sumCell?: number
  tooltipText?: string
  rowIndex: number
  registerIndex: number
  error?: boolean
  onClickValue: (value: string, childIndex?: number) => void
} & React.StyledProps

export type Cell = {
  key: string
  title: string
}

export type ChildRow = {
  key: string
  cells: Cell[]
  inputCell: InputCell
}

export type InputCell = {
  name: string
  defaultValue?: number
  placeholder?: string
}

const useStyles = makeStyles({
  expandIcon: {
    height: 8,
    margin: 'auto 0',
    color: color.black,
    transition: `${timeout}ms`,
  },
  expanded: {
    transform: 'rotate(90deg)',
  },
  row: {
    height: rowHeight,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  parentRow: {
    '&:hover': {
      backgroundColor: color.black50,
    },
  },
  childRow: {
    height: 0,
    transition: `${timeout}ms`,
  },
  expandedChildRow: {
    height: rowHeight,
  },
  cell: {
    width: 184,
    maxWidth: 184,
    boxSizing: 'border-box',
    padding: '0 8px',
    borderBottom: `solid 1px ${color.lightGray}`,
    transition: `${timeout}ms`,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  invalidDigit: {
    verticalAlign: 'text-top',
  },
  parentValueInvalid: {
    marginTop: 8,
  },
  invalidTextField: {
    borderColor: color.red,
  },
  invalidSumNumber: {
    paddingBottom: '0px !important',
  },
  firstCell: {
    paddingLeft: 24,
  },
  hideCell: {
    borderBottom: 'none',
  },
  inputField: {
    width: 108,
    height: 32,
    padding: '0 8px',
    '& input': {
      marginRight: 0,
      fontSize: 12,
    },
  },
  haveChildCell: {
    paddingLeft: 0,
  },
  typography: {
    fontSize: 15,
    lineHeight: '17px',
    wordBreak: 'break-word',
  },
  parentTitle: {
    fontWeight: '900',
  },
  valueTypography: {
    padding: '8px',
    borderRadius: 8,
    wordBreak: 'keep-all',
    '&:hover': {
      backgroundColor: color.black50,
    },
  },
  disableClickTypography: {
    padding: 8,
    wordBreak: 'keep-all',
  },
  errorText: {
    color: color.red,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconAndTypography: {
    display: 'flex',
    width: 160,
    maxWidth: 160,
  },
  error: {
    margin: '4px 0px',
    color: color.red,
    lineHeight: '16px',
  },
  wrapper: {
    display: 'flex',
  },
  childInvalidBox: {
    borderColor: color.red,
    marginTop: 8,
  },
})

const TenantSalesReportTableRow: React.StyledFC<
  TenantSalesReportTableRowProps
> = ({
  className,
  cells,
  inputCell,
  sumCell,
  childRows,
  tooltipText,
  rowIndex,
  registerIndex,
  error,
  onClickValue,
}: TenantSalesReportTableRowProps) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(error)
  const {
    formState: { errors },
    trigger,
  } = useFormContext()

  const handleClick = () => {
    // NOTE: input や Clear ボタンを触った時に閉じたり開いたりしないように
    if (
      document.activeElement?.tagName === 'INPUT' ||
      document.activeElement?.getAttribute('data-testid') === 'cancel-button'
    )
      return
    setExpanded(!expanded)
  }

  const handlerClickValue = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: string,
    index?: number
  ) => {
    e.stopPropagation()
    onClickValue(value, index)
  }

  const cellText = (title: string, isBold: boolean, childIndex?: number) => {
    const isValue = isNumber(title)
    return (
      <Typography
        className={clsx(
          classes.typography,
          isValue && classes.valueTypography,
          isBold && classes.parentTitle,
          error && classes.errorText
        )}
        data-testid={isValue ? 'value' : 'label'}
        onClick={(e) => isValue && handlerClickValue(e, title, childIndex)}
      >
        {title}
      </Typography>
    )
  }

  const align = (value: string, isFirst: boolean) => {
    if (isFirst) return 'left'
    if (isNumber(value) || value === '-') return 'right'
    return 'center'
  }

  const options: RegisterOptions = {
    max: {
      value: limitValue,
      message: errorMessageNineDigitsLimit,
    },
  }

  // NOTE: 10桁以上が入力されたのときの判定処理
  const isParentNotSumValueInvalid = Boolean(
    errors.registers &&
      Array.isArray(errors.registers) &&
      errors?.registers?.[registerIndex]?.rows?.[rowIndex]?.value
  )
  const isParentSumValueInvalid =
    typeof sumCell === 'number' && sumCell > limitValue
  const isEitherParentValueInvalid =
    isParentNotSumValueInvalid || isParentSumValueInvalid
  const isChildValueInvalid = useCallback(
    (childIndex: number) =>
      Boolean(
        errors.registers &&
          Array.isArray(errors.registers) &&
          errors.registers[registerIndex]?.rows?.[rowIndex]?.children?.[
            childIndex
          ]?.value
      ),
    [errors.registers, registerIndex, rowIndex]
  )

  return (
    <>
      <TableRow
        className={clsx(className, classes.row, classes.parentRow)}
        onClick={handleClick}
      >
        {cells.map((cell, index) => {
          const isFirst = index === 0
          const noChild = childRows.length === 0

          return (
            <TableCell
              key={cell.key}
              className={clsx(
                classes.cell,
                isFirst && classes.firstCell,
                !noChild && isFirst && classes.haveChildCell,
                isEitherParentValueInvalid && classes.invalidDigit
              )}
              align={align(cell.title, isFirst)}
            >
              {isFirst ? (
                <Box className={classes.wrapper}>
                  <Box className={classes.iconAndTypography}>
                    {!noChild && (
                      <ArrowForwardIosSharp
                        className={clsx(
                          classes.expandIcon,
                          expanded && classes.expanded
                        )}
                      />
                    )}
                    {cellText(cell.title, true)}
                  </Box>
                  {tooltipText && (
                    <Box className={classes.tooltipWrapper}>
                      <Tooltip title={tooltipText} placement="top">
                        <div className={classes.iconWrapper}>
                          <Icon icon="tips" />
                        </div>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              ) : (
                cellText(cell.title, isFirst)
              )}
            </TableCell>
          )
        })}
        <TableCell
          className={clsx(
            classes.cell,
            isEitherParentValueInvalid && classes.invalidDigit
          )}
        >
          <Box
            className={clsx(
              isEitherParentValueInvalid && classes.parentValueInvalid
            )}
          >
            <TextFieldWithComma
              className={clsx(
                classes.inputField,
                isParentNotSumValueInvalid && classes.invalidTextField
              )}
              name={inputCell.name}
              defaultValue={inputCell.defaultValue}
              placeholder={inputCell.placeholder}
              options={options}
              onKeyPress={() => trigger(inputCell.name)}
            />
          </Box>
          <ErrorMessage
            errors={errors}
            name={inputCell.name}
            render={({ message }) => (
              <Typography
                variant="body2"
                component="p"
                className={classes.error}
              >
                {message}
              </Typography>
            )}
          />
        </TableCell>
        {typeof sumCell === 'number' && (
          <TableCell
            className={clsx(
              classes.cell,
              isEitherParentValueInvalid && classes.invalidDigit
            )}
            align={align(sumCell.toLocaleString(), false)}
          >
            <Box
              className={clsx(
                isEitherParentValueInvalid && classes.parentValueInvalid
              )}
            >
              <Typography
                className={clsx(
                  classes.typography,
                  classes.disableClickTypography,
                  isParentSumValueInvalid && classes.invalidSumNumber
                )}
              >
                {sumCell.toLocaleString()}
              </Typography>
            </Box>
            {isParentSumValueInvalid && (
              <Typography
                variant="body2"
                component="p"
                className={classes.error}
              >
                {errorMessageNineDigitsLimit}
              </Typography>
            )}
          </TableCell>
        )}
      </TableRow>
      {childRows.map((child, childIndex) => (
        <TableRow
          key={child.key}
          className={clsx(
            className,
            classes.row,
            classes.childRow,
            expanded && classes.expandedChildRow,
            isChildValueInvalid(childIndex) && classes.invalidDigit
          )}
        >
          {child.cells.map((cell, index) => {
            const isFirst = index === 0
            return (
              <TableCell
                key={cell.key}
                className={clsx(
                  classes.cell,
                  isFirst && classes.firstCell,
                  !expanded && classes.hideCell
                )}
                align={align(cell.title, isFirst)}
              >
                <Collapse key={cell.key} in={expanded}>
                  {cellText(cell.title, false, childIndex)}
                </Collapse>
              </TableCell>
            )
          })}
          <TableCell
            className={clsx(classes.cell, !expanded && classes.hideCell)}
            align="left"
          >
            <Box
              className={clsx(
                expanded &&
                  isChildValueInvalid(childIndex) &&
                  classes.childInvalidBox
              )}
            >
              <Collapse in={expanded}>
                <TextFieldWithComma
                  className={clsx(
                    classes.inputField,
                    isChildValueInvalid(childIndex) && classes.invalidTextField
                  )}
                  name={child.inputCell.name}
                  defaultValue={child.inputCell.defaultValue}
                  placeholder={child.inputCell.placeholder}
                  options={options}
                  onKeyPress={() => trigger(child.inputCell.name)}
                />
                <ErrorMessage
                  errors={errors}
                  name={child.inputCell.name}
                  render={({ message }) => (
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.error}
                    >
                      {message}
                    </Typography>
                  )}
                />
              </Collapse>
            </Box>
          </TableCell>
          {typeof sumCell === 'number' && (
            <TableCell
              className={clsx(classes.cell, !expanded && classes.hideCell)}
              align="right"
            >
              <Collapse in={expanded}>
                <Typography
                  className={clsx(
                    classes.typography,
                    classes.disableClickTypography
                  )}
                >
                  -
                </Typography>
              </Collapse>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  )
}

export default TenantSalesReportTableRow
