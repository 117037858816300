import { AxiosError } from 'axios'
import AppError, {
  isAppError,
  isPostCalculationDSLError,
  isPutDefiniteValueError,
  PostCalculationDSLError,
  PutDefiniteValueError,
} from 'src/domain/error'
import errorMessage from './error_message'

interface ApiError {
  code: string
  message: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAxiosError(err: any): err is AxiosError {
  if (!err) {
    return false
  }
  return err.isAxiosError !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isApiError(data: any): data is ApiError {
  if (!data) {
    return false
  }
  return (
    data.code !== undefined &&
    typeof data.code === 'string' &&
    data.message !== undefined &&
    typeof data.message === 'string'
  )
}

// FIXME: serialize error の共通化
export default function serializeError(err: unknown): AppError {
  if (isAppError(err)) {
    return err
  }

  if (!isAxiosError(err)) {
    return {
      code: 'unknown',
      message: errorMessage('unknown'),
      showSnackbar: true,
    }
  }

  const { response } = err
  if (!response) {
    return {
      code: 'network',
      message: errorMessage('network'),
      showSnackbar: true,
    }
  }

  const { status, data } = response
  if (!isApiError(data)) {
    // FIXME: DSLのエラーなど、複数のエラーを含む場合に対応できてない、（409 なども）
    return {
      code: 'unhandled',
      message: '',
      showSnackbar: false,
    }
  }

  switch (status) {
    case 400:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
    case 401:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
    default:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postCalculationDSLSerializeError(
  err: unknown
): PostCalculationDSLError {
  if (isAppError(err)) {
    return err
  }

  if (!isAxiosError(err)) {
    return {
      code: 'unknown',
      message: errorMessage('unknown'),
      showSnackbar: true,
    }
  }

  const { response } = err
  if (!response) {
    return {
      code: 'network',
      message: errorMessage('network'),
      showSnackbar: true,
    }
  }

  const { status, data } = response

  if (isApiError(data)) {
    return serializeError(err)
  }

  if (!isPostCalculationDSLError(data)) {
    return {
      code: 'unhandled',
      message: '',
      showSnackbar: false,
    }
  }

  switch (status) {
    case 401:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
    case 422:
      return {
        code: data.code,
        message: '',
        showSnackbar: false,
        errors: data.errors,
      }
    default:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putDefiniteValueSerializeError(
  err: unknown
): PutDefiniteValueError {
  if (isAppError(err)) {
    return err
  }

  if (!isAxiosError(err)) {
    return {
      code: 'unknown',
      message: errorMessage('unknown'),
      showSnackbar: true,
    }
  }

  const { response } = err
  if (!response) {
    return {
      code: 'network',
      message: errorMessage('network'),
      showSnackbar: true,
    }
  }

  const { status, data } = response

  if (isApiError(data)) {
    return serializeError(err)
  }

  if (!isPutDefiniteValueError(data)) {
    return {
      code: 'unhandled',
      message: '',
      showSnackbar: false,
    }
  }

  switch (status) {
    case 401:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
    case 422:
      return {
        code: data.code,
        message:
          '縦計算に差額が発生しています。確定値を確認の上、修正をしてください。',
        showSnackbar: true,
        errors: data.errors,
      }
    default:
      return {
        code: data.code,
        message: errorMessage(data.code),
        showSnackbar: true,
      }
  }
}

export function axiosErrorMessage(err: unknown): string {
  if (!isAxiosError(err)) {
    return errorMessage('unknown')
  }
  const { response } = err
  if (!response) {
    return errorMessage('network')
  }

  if (!isApiError(response.data)) {
    return errorMessage('unknown')
  }
  const { code } = response.data
  return errorMessage(code)
}
