import React, { useReducer, useEffect, useRef, useCallback } from 'react'
import { Box } from '@mui/material'

import Breadcrumbs, {
  BreadcrumbsProps,
} from 'src/components/molecules/Breadcrumbs'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import LoadMore from 'src/components/molecules/LoadMore'
import SmallGuide from 'src/components/molecules/SmallGuide'
import {
  TenantWithStoreAndTenantOwnerCompany,
  Store,
  TenantOwnerCompany,
} from 'src/slices/services/api'
import listStyles from 'src/styles/list-styles.module.scss'

import TenantsOfTenantOwnerCompanyFilterBar from '../../components/FilterBar'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import ListTable from '../../components/ListTable'
import { reducerFunc, INITIAL_STATE } from '../../listReducer'
import { FilterParam } from '../../type'
import styles from './styles.module.scss'

export type TemplatesTenantsOfTenantOwnerCompanyUpdateProps = {
  tenantsWithStoreAndTenantOwnerCompany?: TenantWithStoreAndTenantOwnerCompany[]
  totalCount?: number
  breadCrumbPaths: BreadcrumbsProps['paths']
  stores: Store[]
  tenantOwnerCompany?: TenantOwnerCompany
  onChangeFilterParams: (filterData: FilterParam) => void
  loadMore: (currentCount: number) => void
  onGoToUpdate: () => void
}

const TemplatesTenantsOfTenantOwnerCompanyList: React.FC<
  TemplatesTenantsOfTenantOwnerCompanyUpdateProps
> = ({
  tenantsWithStoreAndTenantOwnerCompany,
  totalCount,
  breadCrumbPaths,
  stores,
  tenantOwnerCompany,
  onChangeFilterParams,
  loadMore,
  onGoToUpdate,
}: TemplatesTenantsOfTenantOwnerCompanyUpdateProps) => {
  const bottomBoundaryRef = useRef(null)
  const [state, dispatch] = useReducer(reducerFunc, INITIAL_STATE)

  useEffect(() => {
    if (Array.isArray(tenantsWithStoreAndTenantOwnerCompany)) {
      dispatch({
        type: 'ADD_NETWORK_DATA',
        payload: {
          networkData: tenantsWithStoreAndTenantOwnerCompany,
          totalCount,
        },
      })
    }
  }, [tenantsWithStoreAndTenantOwnerCompany, totalCount])

  const handleChangeFilterParams = (filterData: FilterParam) => {
    dispatch({ type: 'UPDATE_SEARCH_CONDITION', payload: { filterData } })
    onChangeFilterParams(filterData)
  }

  const handleOnReachToBottom = useCallback(() => {
    if (state.hasMoreContent && !state.isLoadingMore) {
      if (typeof state.currentCount === 'number') {
        loadMore(state.currentCount)
        dispatch({ type: 'LOAD_MORE' })
      }
    }
  }, [state.hasMoreContent, state.isLoadingMore, state.currentCount, loadMore])

  useEffect(() => {
    const observer = new IntersectionObserver(async ([entry]) => {
      if (entry.isIntersecting) {
        handleOnReachToBottom()
      }
    })
    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current)
    }
    return () => observer.disconnect()
  }, [handleOnReachToBottom])

  const renderContent = () => {
    if (state.isLoading) {
      return (
        <Box className={styles.loadingContainer}>
          <CenteringLoading />
        </Box>
      )
    }
    if (totalCount === 0) {
      if (state.hasFilterParam) {
        return (
          <SmallGuide
            icon="pray"
            className={styles.guide}
            message="該当の結果はありません"
            description="検索キーワードを確認して再度試してください"
          />
        )
      }
      return (
        <SmallGuide
          icon="bag"
          className={styles.guide}
          message="この企業にまだテナントが登録されていません"
          description={
            '「テナントリスト編集」ボタンをクリックして、\nテナントを追加してください'
          }
        />
      )
    }
    return (
      <>
        <ListTable tenantsWithStoreAndTenantOwnerCompany={state.allShownData} />
        <div ref={bottomBoundaryRef} />
      </>
    )
  }

  return (
    <Box>
      <Box className={listStyles.container}>
        <Breadcrumbs className={styles.breadcrumb} paths={breadCrumbPaths} />
        <Header tenantOwnerCompany={tenantOwnerCompany} />
        <Box className={styles.filterWrapper}>
          <Box className={styles.header}>
            <TenantsOfTenantOwnerCompanyFilterBar
              count={state.totalCount}
              onChangeFilterParams={handleChangeFilterParams}
              stores={stores}
            />
          </Box>
        </Box>
        <Box className={styles.mainContent}>
          {renderContent()}
          {state.isLoadingMore && <LoadMore />}
        </Box>
      </Box>
      <Box className={styles.bottom}>
        <Footer onGoToUpdate={onGoToUpdate} />
      </Box>
    </Box>
  )
}
export default TemplatesTenantsOfTenantOwnerCompanyList
