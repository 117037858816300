import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { TableBody } from '@mui/material'
import tenantUpdateStyles from 'src/components/organisms/TenantUpdateTable/styles.module.scss'
import {
  AppTableRow,
  IconCell,
  InputCell,
  TextCell,
  SelectCell,
} from 'src/components/tables'
import {
  NAME_PLACEHOLDER,
  CONTRACTOR_CODE_PLACEHOLDER,
  SECTION_PLACEHOLDER,
  REGISTER_NUMBER_PLACEHOLDER,
  PREFIX_OPTIONS,
  TenantFormType,
  TenantFormTypeEnum,
  createTenantCodeAndContractorCode,
} from 'src/domain/tenant'
import {
  InvalidInputErrorMessage,
  NumericRegexErrorMessage,
  RegexNoSpaceOnly,
  RegexNumeric,
} from 'src/utils/regularExpression'

export type TenantCreateTableRowForOpaProps = {
  rowState: { keys: number[] }
  storeCode: string
  scrollBottomRef: React.RefObject<HTMLTableRowElement>
  removeRow: (key: number) => void
}

const TenantCreateTableRowForOpa = ({
  rowState,
  storeCode,
  scrollBottomRef,
  removeRow,
}: TenantCreateTableRowForOpaProps) => {
  const formMethods = useFormContext()
  const { watch, setValue, getValues } = formMethods
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name) return
      const parts = name?.split('.')
      const key = Number(parts[1])
      const formName = parts[2]
      if (
        formName === TenantFormTypeEnum.prefix ||
        formName === TenantFormTypeEnum.contractorCodeNumber ||
        formName === TenantFormTypeEnum.section
      ) {
        const { tenantCode, contractorCode } =
          createTenantCodeAndContractorCode(
            key,
            value as TenantFormType,
            storeCode
          )
        // NOTE: 以下の2つの値は送信する必要があるが、入力するフィールドは無いのでsetValueで設定している
        setValue(`tenants.${key}.contractorCode`, contractorCode)
        setValue(`tenants.${key}.code`, tenantCode)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, rowState.keys, setValue, storeCode])

  return (
    <TableBody>
      {rowState.keys.map((key) => {
        return (
          <AppTableRow key={key}>
            <IconCell icon="minus" onClick={() => removeRow(key)} />
            <TextCell
              className={tenantUpdateStyles.textCell}
              text={getValues(`tenants.${key}.code`)}
            />
            <InputCell
              name={`tenants.${key}.name`}
              placeholder={NAME_PLACEHOLDER}
              width={260}
              required
            />
            <>
              <SelectCell
                className={tenantUpdateStyles.selectCell}
                name={`tenants.${key}.prefix`}
                options={PREFIX_OPTIONS}
                placeholder={PREFIX_OPTIONS[0]?.title}
                defaultValue={PREFIX_OPTIONS[0].value}
                width={80}
              />
              <InputCell
                className={tenantUpdateStyles.contractorCodeCell}
                name={`tenants.${key}.contractorCodeNumber`}
                placeholder={CONTRACTOR_CODE_PLACEHOLDER}
                width={150}
                minLength={10}
                maxLength={10}
                required
                validations={[
                  {
                    regex: RegexNoSpaceOnly,
                    message: InvalidInputErrorMessage,
                  },
                  {
                    regex: RegexNumeric,
                    message: NumericRegexErrorMessage,
                  },
                ]}
              />
              <InputCell
                name={`tenants.${key}.section`}
                placeholder={SECTION_PLACEHOLDER}
                width={90}
                required
                minLength={2}
                maxLength={2}
                validations={[
                  {
                    regex: RegexNoSpaceOnly,
                    message: InvalidInputErrorMessage,
                  },
                  {
                    regex: RegexNumeric,
                    message: NumericRegexErrorMessage,
                  },
                ]}
              />
              <InputCell
                name={`tenants.${key}.tenantRegisterNumber`}
                placeholder={REGISTER_NUMBER_PLACEHOLDER}
                width={100}
                required
                minLength={3}
                maxLength={3}
                validations={[
                  {
                    regex: RegexNoSpaceOnly,
                    message: InvalidInputErrorMessage,
                  },
                  {
                    regex: RegexNumeric,
                    message: NumericRegexErrorMessage,
                  },
                ]}
              />
            </>
          </AppTableRow>
        )
      })}
      <tr ref={scrollBottomRef} />
    </TableBody>
  )
}

export default TenantCreateTableRowForOpa
