import { useContext } from 'react'
import { AuthContext } from 'src/context/auth.context'
import { ApiResponse } from 'src/domain/api'
import { SalesReportWithTenant } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import { axiosErrorMessage } from 'src/slices/services/error'

export const useApiSalesReports = () => {
  const authContext = useContext(AuthContext)

  const getByStore = async (
    orgCode: string,
    storeCode: string,
    page: number,
    perPage: number,
    startDate: string,
    endDate: string
  ): Promise<ApiResponse<SalesReportWithTenant[]>> => {
    try {
      const token = authContext.getAuthToken()
      if (!token) {
        return {
          success: false,
          message: 'ログインしなおしてください: token is not found',
        }
      }

      const res = await new AuthenticatedApi(
        token
      ).getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        orgCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate
      )
      return { success: true, data: res.data }
    } catch (e: unknown) {
      const message = axiosErrorMessage(e)
      return { success: false, message }
    }
  }

  return { getByStore }
}
