import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Selector } from 'react-redux'
import { matchPath } from 'react-router'
import { PathTemplate } from 'src/routes/path'
// eslint-disable-next-line import/no-cycle
import { RootState } from 'src/store'

export type PathState = {
  locationHistories: string[]
  currentRoot: string | null
}

export const initialState: PathState = {
  locationHistories: [PathTemplate.stores],
  currentRoot: null,
}

const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    pushPathStack: (state, { payload }: PayloadAction<string>): PathState => {
      const updatedLocationHistories = [...state.locationHistories, payload]

      // サイドバーに表示するルートパスを設定
      const {
        stores,
        tenants,
        storeSalesReportSummaries,
        profile,
        tenantOwnerCompanies,
        members,
      } = PathTemplate
      const rootPathTemplates = [
        stores,
        tenants,
        storeSalesReportSummaries,
        profile,
        tenantOwnerCompanies,
        members,
      ]

      const previous = updatedLocationHistories
        .slice()
        .reverse()
        .find((history: string) => {
          return Object.values(rootPathTemplates).find((v: string) => {
            const result = matchPath(
              {
                path: v,
                caseSensitive: true,
                end: true,
              },
              history
            )
            return result
          })
        })

      return {
        locationHistories: updatedLocationHistories,
        currentRoot: previous ?? null,
      }
    },
  },
})

export const { pushPathStack } = slice.actions

export default slice.reducer

export const selectLastVisitedPath = (
  currentPath: string | undefined
): Selector<RootState, string | undefined> => {
  return createSelector(
    [(state) => state.navigation.locationHistories],
    (histories) => {
      return histories
        .slice()
        .reverse()
        .filter((history: any) => history !== currentPath)[0] // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  )
}
