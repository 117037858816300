/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestState } from 'src/domain/request'
import { OCRFormat } from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi'
import { postCalculationDSLSerializeError } from 'src/slices/services/error'
// eslint-disable-next-line import/no-cycle
import { PostCalculationDSLThunkConfig } from 'src/store'
import { purge } from 'src/store/action'

interface EditCalculationState extends RequestState {
  editReadItems: EditReadItem[]
  editOCRFormats: EditOCRFormat[]
}

export type EditOCRFormat = OCRFormat & {
  calculationId: string
}

export type EditReadItem = {
  id: string
  readItem: string
  calculationId: string
  calculationIndex: number
}

export const initialState: EditCalculationState = {
  status: 'idle',
  editReadItems: [],
  editOCRFormats: [],
}

export type EditCalculation = {
  calculationId: string
  dsl: string
  ocrFormats: OCRFormat[]
}

type EditCalculationParam = {
  tenantId: string
  editCalculations: EditCalculation[]
}

export const postTenantsIdCalculationDsl = createAsyncThunk<
  void,
  EditCalculationParam,
  PostCalculationDSLThunkConfig
>(
  'editCalculation/postTenantsIdCalculationDsl',
  async (params, { getState }) => {
    const { auth } = getState()
    await new AuthenticatedApi(auth.token).postTenantsIdCalculationDsl(
      params.tenantId,
      params.editCalculations
    )
  },
  { serializeError: postCalculationDSLSerializeError }
)

const sortByCalculationIndex = (
  editReadItems: EditReadItem[]
): EditReadItem[] => {
  return editReadItems.sort((a, b) => {
    return a.calculationIndex - b.calculationIndex
  })
}

const slice = createSlice({
  name: 'editCalculations',
  initialState,
  reducers: {
    clearEditCalculation: (): EditCalculationState => {
      return initialState
    },
    putEditReadItems: (
      state,
      { payload }: PayloadAction<EditReadItem[]>
    ): EditCalculationState => {
      return {
        ...state,
        editReadItems: sortByCalculationIndex(payload),
      }
    },
    putEditOCRFormats: (
      state,
      { payload }: PayloadAction<EditOCRFormat[]>
    ): EditCalculationState => {
      return {
        ...state,
        editOCRFormats: payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(postTenantsIdCalculationDsl.pending, (state) => {
      return {
        ...state,
        status: 'loading',
      }
    })
    builder.addCase(postTenantsIdCalculationDsl.fulfilled, () => {
      return {
        ...initialState,
        status: 'succeeded',
      }
    })
    builder.addCase(postTenantsIdCalculationDsl.rejected, (state) => {
      return {
        ...state,
        status: 'failed',
      }
    })
  },
})

export const { clearEditCalculation, putEditReadItems, putEditOCRFormats } =
  slice.actions

export default slice.reducer
