import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import {
  ReportMessage,
  reportTypeEnum,
} from 'src/components/molecules/ReportMessages'
import { Option } from 'src/components/molecules/SelectWithGroupOption'
import { ZoomableReceiptImageProps } from 'src/components/molecules/ZoomableReceiptImage'
import { HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES } from 'src/domain/org'
import CalculationDSL from 'src/slices/calculation/calculation_dsl'
import { parseCalculationDSLsToText } from 'src/slices/calculation/parser'
import { PutDefiniteValue } from 'src/slices/definiteValues/definiteValuesSlice'
import {
  CalculationDSLDefiniteValue,
  OCRFormatDefiniteValue,
  ReceiptImage,
  SalesReportCalculationResult,
  SalesReportOCRFormatResult,
  TenantRegisterSalesReport,
  TenantResentRegister,
  TenantSalesReport,
} from 'src/slices/services/api'
import { DATE_FORMAT, formatDateTime } from 'src/utils/date'
import { isNumber } from 'src/utils/number'
import { Cell, ChildRow } from '../../components/TenantSalesReportTableRow'
import {
  FormTypesTenantSalesReport,
  FormNameEnum,
  Register,
  RowWithSum,
} from '../../type'

const convertValue = (value?: number | null): string => {
  return typeof value === 'number' ? value.toLocaleString() : '-'
}

const getReadItemDefaultValue = (
  calcInputVal: number | null,
  calcOcrVal: number | null,
  readOcrVal: number | null,
  definiteValue?: number
): number => {
  // NOTE: 確定値があればそれを返す
  if (definiteValue !== undefined) return definiteValue
  // NOTE: 算出項目の手入力値と OCR 値が一致すれば、 読取項目の OCR 値を返す。
  // 両方とも null なら 0 を返す
  if (calcInputVal === calcOcrVal && readOcrVal !== null) return readOcrVal

  return 0
}

const getCalculationDefaultValue = (
  inputVal: number | null,
  ocrVal: number | null,
  definiteValue?: number
): number => {
  // NOTE: 確定値があればそれを返す
  if (definiteValue !== undefined) return definiteValue
  // NOTE: ocr値より手入力値が優先される
  if (inputVal !== null) return inputVal
  if (ocrVal !== null) return ocrVal

  // NOTE: 両方とも null なら初期値に 0 を返す
  return 0
}

interface TenantSalesReportPresenter {
  convertToRegisters(
    reports: TenantRegisterSalesReport[],
    orgCode: string
  ): Register[]
  convertToDefiniteValues(
    tenantSalesReports: TenantRegisterSalesReport[],
    inputValues: FormTypesTenantSalesReport
  ): PutDefiniteValue[]
  validateInputValues(inputValues: FormTypesTenantSalesReport): boolean
  genReportMessages(report?: TenantSalesReport): ReportMessage[]
  genSelectDateOptions(report?: TenantSalesReport): Option[]
  findSelectedResentImage(
    id: string,
    report?: TenantSalesReport
  ): ReceiptImage | undefined
  convertResentImages(
    reports: TenantResentRegister[]
  ): ZoomableReceiptImageProps[]
}

const Presenter: TenantSalesReportPresenter = {
  convertToRegisters(reports, orgCode) {
    const placeholder = 'ご入力ください'

    const convertToCells = (
      result: SalesReportCalculationResult,
      organizationCode: string
    ): Cell[] => {
      return [
        {
          key: 'name',
          title: result.calculationItem.name,
        },
        {
          key: 'input',
          title: convertValue(result.results.manualInputValue),
        },
        {
          key: 'ocr',
          title: HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES.includes(organizationCode)
            ? '-'
            : convertValue(result.results.ocrValue),
        },
      ]
    }

    const convertChildCells = (
      result: SalesReportOCRFormatResult,
      organizationCode: string
    ): Cell[] => {
      return [
        {
          key: 'name',
          title: result.ocrFormat.readItem,
        },
        {
          key: 'input',
          title: convertValue(result.result.manualInputValue),
        },
        {
          key: 'ocr',
          title: HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES.includes(organizationCode)
            ? '-'
            : convertValue(result.result.ocrValue),
        },
      ]
    }

    const convertChildRows = (
      registerIndex: number,
      rowIndex: number,
      result: SalesReportCalculationResult,
      organizationCode: string
    ): ChildRow[] => {
      return result.ocrFormatResults.map((ocrResult, index) => {
        const inputName = `${FormNameEnum.registers}.${registerIndex}.rows.${rowIndex}.children.${index}.value`
        return {
          key: ocrResult.ocrFormat.id,
          cells: convertChildCells(ocrResult, organizationCode),
          inputCell: {
            name: inputName,
            placeholder,
            defaultValue: getReadItemDefaultValue(
              result.results.manualInputValue,
              result.results.ocrValue,
              ocrResult.result.ocrValue,
              ocrResult.result.definiteValue
            ),
          },
        }
      })
    }

    const mapResultsToDSLs = (
      results: SalesReportCalculationResult[]
    ): CalculationDSL[] => {
      return results.map((result) => {
        const calculationItem = {
          id: result.calculationItem.id,
          name: result.calculationItem.name,
        }
        const readItems = result.ocrFormatResults.map((formatResult) => {
          return {
            id: formatResult.ocrFormat.id,
            name: formatResult.ocrFormat.readItem,
          }
        })

        return {
          dsl: result.calculationDsl?.dsl || '',
          calculationItem,
          readItems,
        }
      })
    }

    const checkError = (
      result: SalesReportCalculationResult
    ): boolean | undefined => {
      if (result.inputConsistencyResult === undefined) {
        return undefined
      }
      if (
        result.results.manualInputValue === null &&
        result.results.ocrValue === 0
      ) {
        return false
      }
      return !result.inputConsistencyResult
    }

    const convertToRows = (
      registerIndex: number,
      results: SalesReportCalculationResult[],
      organizationCode: string
    ): RowWithSum[] => {
      const dsls = mapResultsToDSLs(results)
      const textResults = parseCalculationDSLsToText(dsls)
      return results.map((result, rowIndex) => {
        const inputName = `${FormNameEnum.registers}.${registerIndex}.rows.${rowIndex}.value`
        return {
          key: result.calculationItem.id,
          cells: convertToCells(result, organizationCode),
          childRows: convertChildRows(
            registerIndex,
            rowIndex,
            result,
            organizationCode
          ),
          // NOTE: ['=', 'name', '+', 'name', ...] to '= name + name...'
          tooltipText:
            textResults[rowIndex].text.length === 0
              ? undefined
              : textResults[rowIndex].text.join(' '),
          error: checkError(result),
          inputCell: {
            name: inputName,
            placeholder,
            defaultValue: getCalculationDefaultValue(
              result.results.manualInputValue,
              result.results.ocrValue,
              result.results.definiteValue
            ),
          },
        }
      })
    }

    return reports.map((report, registerIndex) => {
      return {
        key: report.id,
        calculationResult: report.consistencyResult,
        rows: convertToRows(registerIndex, report.calculationResults, orgCode),
        concatOriginal: report.receiptImage?.concatOriginal,
        separatedOriginal: report.receiptImage?.separatedOriginal,
        resized: report.receiptImage?.resized,
        isPdf: report.receiptImage?.isPdf,
        tenantRegister: report.tenantRegister,
      }
    })
  },
  convertToDefiniteValues(tenantSalesReports, inputValues) {
    // NOTE: inputValuesが前のテナントの入力値を持っているため、tenantSalesReportsを軸に作成する
    return tenantSalesReports.map((report, reportIndex) => {
      const formValues = inputValues.registers[reportIndex]

      const calculationItems: CalculationDSLDefiniteValue[] =
        formValues?.rows.map((calcVal, calcIndex) => {
          const calcItem = report.calculationResults[calcIndex]

          const ocrFormats: OCRFormatDefiniteValue[] = calcVal.children
            ? calcVal.children
                .map((ocrVal, ocrIndex) => {
                  const ocrFormat = calcItem?.ocrFormatResults[ocrIndex]
                  return {
                    id: ocrFormat?.ocrFormat?.id ?? '',
                    // TODO: Validation で不要になる
                    definiteValue: Number(ocrVal.value || 0),
                  }
                })
                .filter((child) => Boolean(child.id))
            : []

          return {
            id: calcItem?.results.definiteId,
            calculationItemId: calcItem?.calculationItem.id,
            // TODO: Validation で不要になる
            definiteValue: Number(calcVal.value || 0),
            ocrFormats,
          }
        })

      return {
        id: report.id,
        calculationItems,
      }
    })
  },
  validateInputValues(inputValues) {
    if (!inputValues.registers) return false
    // NOTE: 全ての入力値が埋まっていれば true

    return inputValues.registers.every((register) => {
      return register.rows.every((row) => {
        const parent = isNumber(row.value)
        if (!row.children) {
          return parent
        }
        return (
          parent &&
          row.children.every((child) => {
            return isNumber(child.value)
          })
        )
      })
    })
  },
  genReportMessages(report) {
    if (!report?.registers) return []

    const reportMessages: ReportMessage[] = []
    dayjs.locale('ja')

    const initialReport = report.registers[0]

    if (report.reportMessage) {
      reportMessages.push({
        id: initialReport?.id,
        date: formatDateTime(initialReport.updatedAt),
        reportType: reportTypeEnum.Normal,
        messageBody: report.reportMessage,
      })
    }

    if (!report.resentRegisters) return reportMessages
    report.resentRegisters.forEach((resentRegister) => {
      if (resentRegister.resentMessage) {
        reportMessages.push({
          id: resentRegister.id,
          date: formatDateTime(resentRegister.updatedAt),
          reportType: reportTypeEnum.Resent,
          reportIndex: resentRegister.index,
          messageBody: resentRegister.resentMessage,
        })
      }
    })
    return reportMessages.sort((a, b) => {
      return dayjs(a.date, DATE_FORMAT).isBefore(dayjs(b.date, DATE_FORMAT))
        ? 1
        : -1
    })
  },
  genSelectDateOptions(report) {
    if (!report?.registers) return []

    const options: Option[] = []
    dayjs.locale('ja')

    const initialReport = report.registers[0]

    options.push({
      value: initialReport?.id,
      title: formatDateTime(initialReport?.updatedAt),
    })

    if (!report.resentRegisters) return options
    report.resentRegisters.forEach((resentRegister) => {
      options.push({
        value: resentRegister.id,
        title: formatDateTime(resentRegister.updatedAt),
      })
    })
    return options.sort((a, b) => {
      return dayjs(a.title, DATE_FORMAT).isBefore(dayjs(b.title, DATE_FORMAT))
        ? 1
        : -1
    })
  },
  findSelectedResentImage(id, report) {
    if (!report?.registers) return undefined
    if (report.registers[0].id === id) return undefined
    if (!report.resentRegisters) return undefined
    return (
      report.resentRegisters.find((resentRegister) => resentRegister.id === id)
        ?.receiptImage || undefined
    )
  },
  convertResentImages(resentRegisters) {
    if (resentRegisters.length === 0) return []

    return resentRegisters
      .map((resentRegister) => {
        if (!resentRegister.receiptImage) return undefined
        return {
          concatOriginal: resentRegister.receiptImage.concatOriginal,
          separatedOriginal: resentRegister.receiptImage.separatedOriginal,
          resized: resentRegister.receiptImage.resized,
        }
      })
      .filter((resentImage) => resentImage) as ZoomableReceiptImageProps[]
  },
}

export default Presenter
