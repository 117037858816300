import React, { UIEvent, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import { Box, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Breadcrumbs from 'src/components/molecules/Breadcrumbs'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import Pagination from 'src/components/molecules/Pagination'
import SmallGuide from 'src/components/molecules/SmallGuide'
import StoreList from 'src/components/molecules/StoreList'
import StoreListHeader from 'src/components/organisms/StoreListHeader'
import color from 'src/styles/color'
import { BREADCRUMB_HEIGHT } from 'src/styles/constants'
import listStyles from 'src/styles/list-styles.module.scss'

export type StoresProps = {
  queryState?: string
  stores: Store[]
  currentPage: number
  total: number
  isLoading?: boolean
  perPage: number
  defaultSearchValue?: string
  paginationOnClick: (move: number) => void
  onChangeSearchText: (text: string) => void
}

export type Store = {
  name: string
  code: string
  to: string
  tenantCount: number
}

const useStyles = makeStyles<unknown, StoresProps>(() => {
  return {
    breadcrumb: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      height: BREADCRUMB_HEIGHT,
      backgroundColor: color.white,
    },
    pagination: {
      marginTop: 24,
      marginBottom: 34,
    },
    title: {
      marginBottom: 20,
      paddingTop: 4,
      fontSize: 24,
    },
    filterWrapper: {
      position: 'sticky',
      top: BREADCRUMB_HEIGHT,
      backgroundColor: color.white,
      zIndex: 1,
    },
    header: {
      height: 52,
      boxSizing: 'border-box',
      paddingTop: 18,
    },
    stickyPadding: {
      paddingBottom: 6,
    },
    divider: {
      marginTop: 5,
    },
    guide: {
      marginTop: 16, // 22 - 6
    },
  }
})

const breadcrumbs = [{ index: 0, title: '店舗一覧', to: '' }]

const Stores: React.StyledFC<StoresProps> = ({
  queryState,
  stores,
  total,
  currentPage,
  isLoading,
  perPage,
  defaultSearchValue,
  paginationOnClick,
  onChangeSearchText,
}: StoresProps) => {
  const classes = useStyles({
    stores,
    total,
    currentPage,
    isLoading,
    perPage,
    defaultSearchValue,
    paginationOnClick,
    onChangeSearchText,
  })

  const [page, setPage] = useState(currentPage)
  const [showDivider, setShowDivider] = useState(false)

  const scrollAreaElement = useRef<HTMLDivElement>(null)
  const handleScroll = (e: UIEvent) => {
    setShowDivider(e.currentTarget.scrollTop > BREADCRUMB_HEIGHT)
  }

  useEffect(() => {
    if (scrollAreaElement.current?.scroll) {
      scrollAreaElement.current.scroll(0, 0)
    }
  }, [queryState, scrollAreaElement])

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  const renderStoreList = () => {
    return total > 0 ? (
      <Box>
        <StoreList stores={stores} />
        <Pagination
          className={classes.pagination}
          currentPage={page}
          disabled={isLoading}
          pageCount={Math.ceil(total / perPage)}
          onClick={(move) => paginationOnClick(move)}
        />
      </Box>
    ) : (
      <SmallGuide
        icon="pray"
        className={classes.guide}
        message="該当の結果はありません"
        description="検索キーワードを確認して再度試してください"
      />
    )
  }

  return (
    <Box
      className={listStyles.container}
      onScroll={(e) => handleScroll(e)}
      {...{ ref: scrollAreaElement }}
    >
      <Breadcrumbs className={classes.breadcrumb} paths={breadcrumbs} />
      <Typography className={classes.title} variant="h1">
        店舗一覧
      </Typography>
      <Box
        className={clsx(
          classes.filterWrapper,
          !showDivider && classes.stickyPadding
        )}
      >
        <StoreListHeader
          className={classes.header}
          totalStores={total}
          defaultSearchValue={defaultSearchValue}
          onChangeSearchText={onChangeSearchText}
        />
        {showDivider && <Divider className={classes.divider} />}
      </Box>
      {isLoading ? <CenteringLoading /> : renderStoreList()}
    </Box>
  )
}

export default Stores
