import React from 'react'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from '../Icon'

export type MoreButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
} & React.StyledProps

const useStyles = makeStyles({
  svgIcon: {
    width: 16,
    height: 16,
  },
})

export const MoreButton: React.StyledFC<MoreButtonProps> = ({
  className,
  onClick,
}: MoreButtonProps) => {
  const classes = useStyles()

  return (
    <IconButton className={className} onClick={onClick} size="large">
      <Icon icon="more" className={classes.svgIcon} />
    </IconButton>
  )
}
