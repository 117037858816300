import { useContext, useCallback } from 'react'
import { AuthContext } from 'src/context/auth.context'
import { ApiResponse } from 'src/domain/api'
import {
  TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount,
  TenantsOfTenantOwnerCompanyPatchRequest,
} from 'src/slices/services/api'
import { TenantStatusEnum } from 'src/slices/services/api/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi' // TODO: api呼び出しをhooksから利用するとすると、feturesを呼ぶのが遠いので構成変更検討
import { axiosErrorMessage } from 'src/slices/services/error'

export interface TenantsOwnerCompanyAndAssociatedCountWithTotalCount {
  tenantsAndAssociatedCount: TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount
  count: number
}

export const useApiTenantsOfTenantOwnerCompany = () => {
  const authContext = useContext(AuthContext)

  const getTenantsOfTenantOwnerCompany = useCallback(
    async (
      orgCode: string,
      loadOffsetNum: number,
      limit: number,
      tenantOwnerCompanyCode: string,
      isAllTenantsReturned: boolean,
      tenantCodeOrName?: string,
      tenantStatus?: TenantStatusEnum,
      storeCodes?: string[]
    ): Promise<
      ApiResponse<TenantsOwnerCompanyAndAssociatedCountWithTotalCount>
    > => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        const res = await new AuthenticatedApi(
          token
        ).getOrganizationsOrganizationCodeTenants(
          orgCode,
          undefined,
          undefined,
          tenantCodeOrName,
          tenantStatus,
          undefined,
          undefined,
          isAllTenantsReturned,
          loadOffsetNum,
          limit,
          storeCodes,
          tenantOwnerCompanyCode
        )
        // NOTE: countが無い場合は十分大きな数として5000を設定
        const count = Number(res.headers['x-total-count']) ?? 5000

        const tenantsAndAssociatedCount =
          res.data as TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount

        return {
          success: true,
          data: {
            count,
            tenantsAndAssociatedCount,
          },
        }
      } catch (e: unknown) {
        const message = axiosErrorMessage(e)
        return { success: false, message }
      }
    },
    [authContext]
  )

  const updateTenantsOfTenantOwnerCompany = useCallback(
    async (
      orgCode: string,
      tenantOwnerCompanyCode: string,
      data: TenantsOfTenantOwnerCompanyPatchRequest
    ) => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        await new AuthenticatedApi(
          token
        ).patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          orgCode,
          tenantOwnerCompanyCode,
          data
        )
        return { success: true }
      } catch (err: unknown) {
        const message = axiosErrorMessage(err)
        return { success: false, message }
      }
    },
    [authContext]
  )

  return {
    getTenantsOfTenantOwnerCompany,
    updateTenantsOfTenantOwnerCompany,
  }
}
