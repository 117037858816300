import {
  TenantRegister,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'

export interface TenantRegisterRow {
  formUpdatedData: {
    id?: string
    registerNumber: string
    registerCategory: TenantRegisterCategoryEnum
  }
  rowNum: number
  isDuplicate?: boolean
  hasFormError?: boolean
}

type Payload = {
  formData?: TenantRegisterRow
  eachValidStatus?: { id?: string; isValid: boolean; rowNum?: number }
  originalRegisters?: TenantRegister[]
}

export type Action = {
  type:
    | 'SET_INITIAL_REGISTERS'
    | 'ADD_ROW'
    | 'REMOVE_ROW'
    | 'UPDATE_REGISTER_FORM_DATA'
    | 'ERROR'
  payload?: Payload
}

export type RegistersState = {
  registers: TenantRegisterRow[]
  duplicateRegisterNumbers: string[]
  originalRegisters: TenantRegister[]
  updatedRegisters: TenantRegister[]
  deletedIds: string[]
}

export const INITIAL_STATE: RegistersState = {
  registers: [],
  duplicateRegisterNumbers: [],
  originalRegisters: [],
  updatedRegisters: [],
  deletedIds: [],
}

const getDuplicateRegisterNumber = (
  registers: TenantRegisterRow[]
): string[] => {
  const registerNumbers = registers.map(
    (register) => register.formUpdatedData.registerNumber
  )

  const duplicatedNumbers = registerNumbers.filter(
    (number, index) =>
      number.length > 0 && registerNumbers.indexOf(number) !== index
  )
  return duplicatedNumbers
}

const addDuplicateFlag = (
  registers: TenantRegisterRow[],
  duplicateNumbers: string[]
): TenantRegisterRow[] => {
  return registers.map((register) => ({
    ...register,
    isDuplicate:
      register.formUpdatedData.registerNumber.length > 0 &&
      duplicateNumbers.includes(register.formUpdatedData.registerNumber),
  }))
}

// 代表レジが1つだけになるように調整する関数
const updateRegisterCategory = (
  registers: TenantRegisterRow[],
  updatedRegisterRowNum: number
): TenantRegisterRow[] => {
  const currentRepresentativeIndex = registers.findIndex(
    (register) =>
      register.formUpdatedData.registerCategory ===
        TenantRegisterCategoryEnum.RepresentativeRegister &&
      register.rowNum !== updatedRegisterRowNum
  )
  const updatedRegisterIndex = registers.findIndex(
    (register) => register.rowNum === updatedRegisterRowNum
  )

  return registers.map((register, index) => {
    // 現在の代表レジ→その他レジに変更
    if (index === currentRepresentativeIndex) {
      return {
        ...register,
        formUpdatedData: {
          ...register.formUpdatedData,
          registerCategory: TenantRegisterCategoryEnum.OtherRegisters,
        },
      }
    }

    // 新しい代表レジを設定
    if (index === updatedRegisterIndex) {
      return {
        ...register,
        formUpdatedData: {
          ...register.formUpdatedData,
          registerCategory: TenantRegisterCategoryEnum.RepresentativeRegister,
        },
      }
    }
    return register
  })
}

// 初期値と比較して更新されているregisterを探す関数
const findUpdatedRegisters = (
  editedRegisters: TenantRegisterRow[],
  originalRegisters: TenantRegister[]
): TenantRegister[] => {
  return editedRegisters.map((editedRegister) => {
    const originalRegister = originalRegisters.find(
      (original) =>
        original.registerNumber ===
        editedRegister.formUpdatedData.registerNumber
    )

    return originalRegister
      ? {
          ...editedRegister.formUpdatedData,
          id: originalRegister.id,
        }
      : { ...editedRegister.formUpdatedData, id: undefined }
  })
}

// 削除されたidを特定する関数
const findDeletedIds = (
  editedRegisters: TenantRegisterRow[],
  originalRegisters: TenantRegister[]
): string[] => {
  if (originalRegisters.length === 0) {
    return []
  }
  const currentNumbers = editedRegisters.map(
    (register) => register.formUpdatedData.registerNumber
  )
  const deletedRegisters = originalRegisters.filter(
    (register) => !currentNumbers.includes(register.registerNumber)
  )
  const deletedIds = deletedRegisters
    .map((register) => register.id)
    .filter((id): id is string => id !== undefined)

  return deletedIds
}

export const reducerFunc = (
  state: RegistersState,
  action: Action
): RegistersState => {
  let newState: RegistersState

  switch (action.type) {
    case 'SET_INITIAL_REGISTERS': {
      if (!action.payload) {
        newState = { ...state }
      } else {
        const initialRegisters = action.payload.originalRegisters || []
        const tenantRegisterRow: TenantRegisterRow[] = initialRegisters.map(
          (register, index) => ({
            formUpdatedData: {
              id: register.id,
              registerNumber: register.registerNumber,
              registerCategory: register.registerCategory,
            },
            rowNum: index,
            isDuplicate: false,
            hasFormError: false,
          })
        )

        newState = {
          ...INITIAL_STATE,
          registers: tenantRegisterRow,
          originalRegisters: initialRegisters,
        }
      }
      break
    }
    case 'ADD_ROW': {
      // NOTE: 削除されたrowNumを再利用せず、最後の要素のrowNumの値+1として作成する
      const newRowNum =
        state.registers.length > 0
          ? state.registers[state.registers.length - 1].rowNum + 1
          : 0
      const newTenantRegisterRow: TenantRegisterRow = {
        formUpdatedData: {
          registerNumber: '',
          registerCategory: TenantRegisterCategoryEnum.OtherRegisters,
        },
        rowNum: newRowNum,
        isDuplicate: false,
        hasFormError: false,
      }
      const newRegisters = [...state.registers, newTenantRegisterRow]
      const updatedRegisters = findUpdatedRegisters(
        newRegisters,
        state.originalRegisters
      )

      newState = {
        ...state,
        registers: newRegisters,
        updatedRegisters,
      }
      break
    }
    case 'REMOVE_ROW': {
      const rowNum = action.payload?.formData?.rowNum
      if (rowNum === undefined) {
        newState = { ...state }
      } else {
        const remainingRegisters = state.registers.filter(
          (register) => register.rowNum !== rowNum
        )

        const duplicateNumbers = getDuplicateRegisterNumber(remainingRegisters)
        const remainingRegistersWithDuplicateCheck = addDuplicateFlag(
          remainingRegisters,
          duplicateNumbers
        )
        const updatedRegisters = findUpdatedRegisters(
          remainingRegistersWithDuplicateCheck,
          state.originalRegisters
        )
        const deletedIds = findDeletedIds(
          remainingRegistersWithDuplicateCheck,
          state.originalRegisters
        )

        return {
          ...state,
          registers: remainingRegistersWithDuplicateCheck,
          updatedRegisters,
          deletedIds,
        }
      }
      break
    }
    // FIXME: よりわかりやすい変数名に変更
    // FIXME: UPDATE_REGISTER_NUMBERとUPDATE_REGISTER_CATEGORYに分ける
    case 'UPDATE_REGISTER_FORM_DATA': {
      const { formData } = action.payload || {}
      if (!formData) {
        newState = { ...state }
      } else {
        const updatedRegisters = state.registers.map((register) =>
          register.rowNum === formData.rowNum
            ? { ...register, formUpdatedData: formData.formUpdatedData }
            : register
        )

        const originalRegisterCategory = state.registers.find(
          (r) => r.rowNum === formData.rowNum
        )?.formUpdatedData.registerCategory
        const isCategoryChanged =
          formData.formUpdatedData.registerCategory !== originalRegisterCategory

        const updatedRegistersWithCategory = isCategoryChanged
          ? updateRegisterCategory(updatedRegisters, formData.rowNum)
          : updatedRegisters

        const duplicateNumbers = getDuplicateRegisterNumber(
          updatedRegistersWithCategory
        )
        const updatedRegistersWithDuplicateFlag = addDuplicateFlag(
          updatedRegistersWithCategory,
          duplicateNumbers
        )
        const newUpdatedRegisters = findUpdatedRegisters(
          updatedRegistersWithDuplicateFlag,
          state.originalRegisters
        )
        const newDeletedIds = findDeletedIds(
          updatedRegistersWithDuplicateFlag,
          state.originalRegisters
        )
        newState = {
          ...state,
          registers: updatedRegistersWithDuplicateFlag,
          updatedRegisters: newUpdatedRegisters,
          deletedIds: newDeletedIds,
        }
      }
      break
    }
    case 'ERROR':
      {
        const { eachValidStatus } = action.payload || {}
        if (!eachValidStatus) {
          newState = { ...state }
        } else {
          const updatedRegisters = state.registers.map((register) => {
            if (register.rowNum === eachValidStatus.rowNum) {
              return {
                ...register,
                hasFormError: !eachValidStatus.isValid,
              }
            }
            return register
          })

          newState = {
            ...state,
            registers: updatedRegisters,
          }
        }
      }
      break
    default:
      newState = {
        ...state,
      }
  }
  return newState
}
