import { Store as TemplateStore } from 'src/components/templates/Stores'
import Path from 'src/routes/path'
import { Store as EntityStore } from 'src/slices/services/api'

export interface StoresPresenter {
  mapTemplateStore(orgCode: string, store: EntityStore): TemplateStore
}

const Presenter: StoresPresenter = {
  mapTemplateStore: (orgCode: string, store: EntityStore): TemplateStore => {
    const summary = store.tenantSummary
    return {
      name: store.name,
      code: store.code,
      to: Path.store(orgCode, store.code),
      tenantCount: summary.totalCount,
    }
  },
}
export default Presenter
