export const headerColumns = [
  {
    title: 'テナントステータス',
    width: 100,
  },
  {
    title: '店舗コード',
    width: 100,
  },
  {
    title: '店舗名称',
    width: 150,
  },
  {
    title: 'テナントコード',
    width: 100,
  },
  {
    title: 'テナント名称',
    width: 150,
  },
  {
    title: '取引企業更新日',
    width: 180,
  },
]
