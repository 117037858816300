import React, {
  ReactElement,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { ToastTriggerContext } from 'src/context/toast.context'
import { INFINITE_SCROLL_CONTENT_LOADING_NUM } from 'src/domain/config'
import { useApiTenantOwnerCompany } from 'src/hooks/useApiTenantOwnerCompany'
import { useApiTenantsOfTenantOwnerCompany } from 'src/hooks/useApiTenantsOfTenantOwnerCompany'
import useAppTitle from 'src/hooks/useAppTitle'
import Path, {
  OrgPathParams,
  TenantOwnerCompanyPathParams,
} from 'src/routes/path'
import {
  TenantWithStoreAndTenantOwnerCompany,
  Store,
  TenantOwnerCompany,
  TenantsOfTenantOwnerCompanyPatchRequest,
} from 'src/slices/services/api'
import {
  selectStoresByParams,
  selectStoresRequestStateByParams,
  getStores,
} from 'src/slices/stores/storesSlice'
import { useAppSelector, useAppDispatch } from 'src/store'

import TemplatesTenantsOfTenantOwnerCompanyUpdate from '../../templates/Update'
import { SearchParam, FilterParam } from '../../type'

const isAllTenantsReturned = true

const PagesTenantsOfTenantOwnerCompanyUpdate: React.FC = (): ReactElement => {
  useAppTitle('取引企業詳細更新')
  const { orgCode } = useParams<OrgPathParams>() as OrgPathParams
  const { tenantOwnerCompanyCode } =
    useParams<TenantOwnerCompanyPathParams>() as TenantOwnerCompanyPathParams
  const [totalCount, setTotalCount] = useState<number>()
  const [associatedTenantsCount, setAssociatedTenantsCount] = useState<number>()
  const [tenantOwnerCompany, setTenantOwnerCompany] =
    useState<TenantOwnerCompany>()
  const toastContext = useContext(ToastTriggerContext)
  const dispatch = useAppDispatch()
  const param = useMemo(() => {
    return { orgCode, page: 1, perPage: 1000 }
  }, [orgCode])
  const navigate = useNavigate()
  const stores: Store[] =
    useAppSelector((state) => selectStoresByParams(param)(state)) ?? []
  const { status } = useAppSelector((state) =>
    selectStoresRequestStateByParams(param)(state)
  )

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getStores(param))
    }
  }, [dispatch, status, param])

  const [searchParam, setSearchParam] = useState<SearchParam>({
    tenantStatus: undefined,
    storeCodes: [],
    searchText: '',
    loadOffsetNum: 0,
  })

  const { getTenantsOfTenantOwnerCompany, updateTenantsOfTenantOwnerCompany } =
    useApiTenantsOfTenantOwnerCompany()
  const { getTenantOwnerCompany } = useApiTenantOwnerCompany()

  const [
    tenantsWithStoreAndTenantOwnerCompany,
    setTenantsWithStoreAndTenantOwnerCompany,
  ] = useState<TenantWithStoreAndTenantOwnerCompany[]>()

  useEffect(() => {
    const executeApi = async () => {
      const res = await getTenantsOfTenantOwnerCompany(
        orgCode,
        searchParam.loadOffsetNum,
        INFINITE_SCROLL_CONTENT_LOADING_NUM,
        tenantOwnerCompanyCode,
        isAllTenantsReturned,
        searchParam.searchText,
        searchParam.tenantStatus,
        searchParam.storeCodes
      )
      if (!res.success) {
        toastContext.sendToast({
          variant: 'error',
          title: res.message,
        })
        return
      }
      setTenantsWithStoreAndTenantOwnerCompany(
        res.data?.tenantsAndAssociatedCount
          .tenantsWithStoreAndTenantOwnerCompany || []
      )
      setAssociatedTenantsCount(
        res.data?.tenantsAndAssociatedCount.associatedTenantsCount
      )
      if (typeof res.data?.count === 'number') {
        setTotalCount(res.data?.count)
      }
    }
    executeApi()
  }, [
    getTenantsOfTenantOwnerCompany,
    orgCode,
    searchParam,
    tenantOwnerCompanyCode,
    toastContext,
  ])

  useEffect(() => {
    const executeApi = async () => {
      const res = await getTenantOwnerCompany(orgCode, tenantOwnerCompanyCode)
      if (!res.success) {
        toastContext.sendToast({
          variant: 'error',
          title: res.message,
        })
        return
      }
      setTenantOwnerCompany(res.data)
    }
    executeApi()
  }, [getTenantOwnerCompany, orgCode, tenantOwnerCompanyCode, toastContext])

  const handleChangeFilterParams = (data: FilterParam) => {
    setSearchParam({ ...data, loadOffsetNum: 0 })
  }

  const handleSubmitUpdate = async (
    data: TenantsOfTenantOwnerCompanyPatchRequest
  ) => {
    const resp = await updateTenantsOfTenantOwnerCompany(
      orgCode,
      tenantOwnerCompanyCode,
      data
    )
    if (resp.success) {
      toastContext.sendToast({
        variant: 'success',
        title: 'テナントの紐付けを更新しました',
      })
      navigate(
        Path.tenantsOfTenantOwnerCompanyList(orgCode, tenantOwnerCompanyCode)
      )
    } else {
      toastContext.sendToast({
        variant: 'error',
        title: resp.message,
      })
    }
  }

  const handleLoadMore = (offset: number) => {
    setSearchParam({ ...searchParam, loadOffsetNum: offset })
  }

  const handleGoBack = () => {
    navigate(
      Path.tenantsOfTenantOwnerCompanyList(orgCode, tenantOwnerCompanyCode)
    )
  }

  return (
    <TemplatesTenantsOfTenantOwnerCompanyUpdate
      tenantsWithStoreAndTenantOwnerCompany={
        tenantsWithStoreAndTenantOwnerCompany
      }
      tenantOwnerCompany={tenantOwnerCompany}
      onChangeFilterParams={handleChangeFilterParams}
      associatedTenantsCount={associatedTenantsCount}
      totalCount={totalCount}
      stores={stores}
      onSubmitUpdate={handleSubmitUpdate}
      loadMore={handleLoadMore}
      onGoBack={handleGoBack}
    />
  )
}
export default PagesTenantsOfTenantOwnerCompanyUpdate
