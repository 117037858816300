import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'
import { Path as BreadcrumbPath } from 'src/components/molecules/BreadcrumbsPipe'
import { Option } from 'src/components/molecules/SelectWithOption'
import { ToastTriggerContext } from 'src/context/toast.context'
import useAppTitle from 'src/hooks/useAppTitle'
import Path, { TenantPathParams } from 'src/routes/path'
import {
  getCalculationDSLs,
  selectDenormalizedCalculationDSLsByParams,
  selectCalculationDSLsRequestStateByParams,
} from 'src/slices/calculationDSLs/calculationDSLsSlice'
import {
  getCalculationGroup,
  selectCalculationGroupStateByParams,
} from 'src/slices/calculationGroups/calculationGroupsSlice'
import { selectCalculationItemsByParams } from 'src/slices/calculationItems/calculationItemsSlice'
import {
  clearEditCalculation,
  EditOCRFormat as EditOCRFormatPayload,
  putEditOCRFormats,
} from 'src/slices/editCalculations/editCalculationsSlice'
import {
  getStore,
  selectStoresRequestStateByParams,
  selectStoreByCode,
} from 'src/slices/stores/storesSlice'
import {
  getTenant,
  selectTenantStateByCode,
  selectTenantByCode,
} from 'src/slices/tenants/tenantsSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import { isEqual } from 'src/utils/array'
import { BodyRow } from '../components/EditOCRFormatTable'
import EditOCRFormatTemplate from '../templates'
import Presenter, { RowFieldValue } from './presenter'

const EditOCRFormat: React.FC = (): ReactElement => {
  useAppTitle('利用設定 ステップ2')
  const isLoadingRef = useRef(true)
  const toastContext = useContext(ToastTriggerContext)
  const { orgCode, storeCode, tenantCode } =
    useParams<TenantPathParams>() as TenantPathParams
  const [rows, setRows] = useState<BodyRow[]>([])
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState<
    (string | number | boolean | undefined)[]
  >([])
  const editReadItems = useAppSelector(
    (state) => state.forms.editCalculations.editReadItems
  )
  const editOCRFormats = useAppSelector(
    (state) => state.forms.editCalculations.editOCRFormats
  )
  const dsls = useAppSelector(
    selectDenormalizedCalculationDSLsByParams({
      orgCode,
      storeCode,
      tenantCode,
    })
  )
  const dispatch = useAppDispatch()
  const formMethods = useForm<{ rows: RowFieldValue[] }>()

  useBeforeUnload(() => {
    const currentValues = formMethods
      .getValues()
      .rows?.flatMap((row) => Object.values(row))
    return !isEqual(initialValues, currentValues)
    // FIXME: 入力した文字列は表示されないけど、文字列は入れないと表示されないので、入れてる
  }, 'Alert')

  const store = useAppSelector(selectStoreByCode({ orgCode, storeCode }))
  const tenant = useAppSelector(
    selectTenantByCode({ orgCode, storeCode, tenantCode })
  )
  const calculationItems = useAppSelector(
    selectCalculationItemsByParams({ orgCode, storeCode })
  )
  const [showModal, setShowModal] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbPath[]>([])
  useEffect(() => {
    setBreadcrumbs(Presenter.breadcrumbs(store?.name, tenant?.name))
  }, [store?.name, tenant?.name])

  const tenantRequestState = useAppSelector(
    selectTenantStateByCode({ orgCode, storeCode, tenantCode })
  )
  const storeRequestState = useAppSelector(
    selectStoresRequestStateByParams({ orgCode, storeCode })
  )
  const calculationGroupRequestState = useAppSelector(
    selectCalculationGroupStateByParams({ orgCode, storeCode })
  )

  const { status } = useAppSelector(
    selectCalculationDSLsRequestStateByParams({
      orgCode,
      storeCode,
      tenantCode,
    })
  )

  // API Request
  useEffect(() => {
    if (status === 'idle') {
      dispatch(getCalculationDSLs({ orgCode, storeCode, tenantCode }))
    }
  }, [status, dispatch, orgCode, storeCode, tenantCode])

  useEffect(() => {
    if (tenantRequestState.status === 'idle') {
      dispatch(getTenant({ orgCode, storeCode, tenantCode }))
    }
  }, [dispatch, orgCode, storeCode, tenantCode, tenantRequestState.status])
  useEffect(() => {
    if (store === undefined && storeRequestState.status === 'idle') {
      dispatch(getStore({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, store, storeCode, storeRequestState.status])
  useEffect(() => {
    if (calculationGroupRequestState.status === 'idle') {
      dispatch(getCalculationGroup({ orgCode, storeCode }))
    }
  }, [dispatch, orgCode, storeCode, calculationGroupRequestState.status])

  // Set and get rows
  useEffect(() => {
    if (!dsls || !isLoadingRef.current || !calculationItems) return

    // Presenterがどちらを使うか判断する
    // - restoredEditOCRFormats: APIのレスポンスを加工したEditOCRFormats
    // - editOCRFormats: ReduxのStateからselectしたEditOCRFormats
    //
    // 1. 新規作成時
    //  -  restoredEditOCRFormatsが空
    //  -  editOCRFormatsが空
    // 2. 新規作成時でStep3まで進んで戻ったとき
    //  -  restoredEditOCRFormatsが空
    //  -  editOCRFormatsが値あり
    // 3. 編集時
    //  -  restoredEditOCRFormatsが値あり
    //  -  editOCRFormatsが空
    // 4. 編集時でStep3まで進んで戻ったとき
    //  -  restoredEditOCRFormatsが値あり
    //  -  editOCRFormatsが値あり
    const restoredEditOCRFormats = Presenter.convertDSLToEditOCRFormat(
      dsls,
      editReadItems
    )

    const updatedRows = Presenter.rows(
      calculationItems,
      editReadItems,
      editOCRFormats,
      restoredEditOCRFormats
    )
    if (!editOCRFormats || editOCRFormats.length === 0) {
      dispatch(putEditOCRFormats(restoredEditOCRFormats))
    }
    setRows(updatedRows)
    isLoadingRef.current = false
  }, [
    calculationItems,
    dispatch,
    dsls,
    editOCRFormats,
    editReadItems,
    rows.length,
  ])

  const setDefaultValues = useCallback(() => {
    editOCRFormats.forEach((editOCRFormat, index) => {
      Presenter.getFormNameWithValues(index, editOCRFormat).forEach(
        (formNameWithValue) => {
          const { name, value } = formNameWithValue
          formMethods.setValue(name, value)
        }
      )
    })
  }, [editOCRFormats, formMethods])

  useEffect(() => {
    if (!rows.length) return

    setDefaultValues()
    const values = formMethods
      .getValues()
      .rows?.flatMap((row) => Object.values(row))
    setInitialValues(values)
  }, [formMethods, rows.length, setDefaultValues])

  const getOCRFormats = (): EditOCRFormatPayload[] => {
    const rowsValue = Presenter.convertOCRFormatsToApiVal(
      formMethods.getValues().rows
    )
    return editReadItems.map((editReadItem, index) => {
      const rowValue = rowsValue[index]
      return {
        ...editReadItem,
        ...rowValue,
      }
    })
  }

  // event handler
  const handleClickClose = () => {
    setShowModal(true)
  }

  const handleClickGoBack = () => {
    dispatch(putEditOCRFormats(getOCRFormats()))
    navigate(Path.editReadItem(orgCode, storeCode, tenantCode))
  }

  // NOTE: 同名称項目「あり」だが、名称が選択されてない場合、false
  const validateOCRFormats = (values: EditOCRFormatPayload[]): boolean => {
    if (values.length === 0) return true
    return !values.some((value) => {
      return value.isDuplicated && !value.sectionName
    })
  }

  const handleClickGoForward = () => {
    const ocrFormats = getOCRFormats()
    if (!validateOCRFormats(ocrFormats)) {
      toastContext.sendToast({
        variant: 'error',
        title:
          '同名称項目が有りの場合は、セクション名を記入する必要があります。',
      })
      return
    }
    dispatch(putEditOCRFormats(ocrFormats))
    navigate(Path.editCalculationDSL(orgCode, storeCode, tenantCode))
  }

  const handleClickCancel = () => {
    setShowModal(false)
  }

  const handleClickConfirmClose = () => {
    dispatch(clearEditCalculation())
    navigate(Path.tenantCalculationTab(orgCode, storeCode, tenantCode))
  }

  const handleChangeSelect = (
    option: Option,
    rowIndex: number,
    name: string
  ) => {
    if (name.endsWith('isDuplicated')) {
      const updatedRow: BodyRow = {
        ...rows[rowIndex],
        showInput: Boolean(option.value),
      }

      const result = rows.slice()
      result.splice(rowIndex, 1, updatedRow)

      setRows(result)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <EditOCRFormatTemplate
          columns={Presenter.columns()}
          rows={rows}
          breadcrumbs={breadcrumbs}
          showModal={showModal}
          isLoading={isLoadingRef.current}
          onClickGoForward={handleClickGoForward}
          onClickGoBack={handleClickGoBack}
          onClickClose={handleClickClose}
          onClickConfirmClose={handleClickConfirmClose}
          onClickCancelClose={handleClickCancel}
          onChangeSelect={handleChangeSelect}
        />
      </form>
    </FormProvider>
  )
}

export default EditOCRFormat
