import { useContext, useCallback } from 'react'
import { AuthContext } from 'src/context/auth.context'
import { ApiResponse } from 'src/domain/api'
import {
  TenantDetailsPostRequest,
  TenantDetailWithTenant,
} from 'src/slices/services/api'
import AuthenticatedApi from 'src/slices/services/authenticatedApi' // TODO: api呼び出しをhooksから利用するとすると、feturesを呼ぶのが遠いので構成変更検討
import { axiosErrorMessage } from 'src/slices/services/error'

export const useApiTenantDetail = () => {
  const authContext = useContext(AuthContext)

  const getTenantDetailAndRegisters = useCallback(
    async (
      orgCode: string,
      storeCode: string,
      tenantCode: string
    ): Promise<ApiResponse<TenantDetailWithTenant>> => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        const res = await new AuthenticatedApi(
          token
        ).getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          orgCode,
          storeCode,
          tenantCode
        )
        return {
          success: true,
          data: res.data,
        }
      } catch (e: unknown) {
        const message = axiosErrorMessage(e)
        return { success: false, message }
      }
    },
    [authContext]
  )

  const postTenantDetailAndRegisters = useCallback(
    async (
      orgCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailAndRegisters: TenantDetailsPostRequest
    ): Promise<ApiResponse<TenantDetailsPostRequest>> => {
      try {
        const token = authContext.getAuthToken()
        if (!token) {
          return {
            success: false,
            message: 'ログインしなおしてください',
          }
        }
        await new AuthenticatedApi(
          token
        ).postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          orgCode,
          storeCode,
          tenantCode,
          tenantDetailAndRegisters
        )
        return { success: true }
      } catch (e: unknown) {
        const message = axiosErrorMessage(e)
        return { success: false, message }
      }
    },
    [authContext]
  )

  return { getTenantDetailAndRegisters, postTenantDetailAndRegisters }
}
