import React, { memo } from 'react'
import clsx from 'clsx'
import { Box, Slide, SlideProps, Snackbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import colors from 'src/styles/color'
import { Icon, IconName } from '../Icon'

export type ToastProps = {
  open: boolean
  title: string
  variant: Variant
  duration?: number
  autoHideDuration?: number
  direction?: Direction
  position?: Position
  onClose: () => void
} & React.StyledProps

export type Variant = 'success' | 'error'

export type Direction = 'left' | 'right' | 'up' | 'down'

export type Position = {
  vertical: 'top' | 'bottom'
  horizontal: 'left' | 'center' | 'right'
}

const iconNames = (name: Variant): IconName => {
  switch (name) {
    case 'success':
      return 'check'
    case 'error':
      return 'closeWithCircle'
    default:
      return 'check'
  }
}

const variantStyles = {
  success: {
    backgroundColor: colors.green02,
  },
  error: {
    backgroundColor: colors.vermilion,
  },
}

const useStyles = makeStyles<unknown, ToastProps>(() => {
  return {
    snackbar: {
      right: 24,
    },
    hideSnackbar: {
      // FIXME: これがないと、Toastが表示された後、下の要素がClickできなくなる。#1867
      display: 'none',
    },
    typography: {
      fontSize: 16,
      fontWeight: 700,
      color: colors.white,
      alignSelf: 'center',
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    leftIcon: {
      marginRight: 16,
      width: 24,
      height: 24,
      '& path': {
        fill: colors.white,
      },
      '& rect': {
        fill: colors.white,
      },
    },
    closeIcon: {
      '& rect': {
        fill: colors.white,
      },
    },
  }
})

const TransitionComponent = (
  { children, in: boolean, timeout, ref, onEnter, onExited }: SlideProps,
  direction: Direction
) => {
  return (
    <Slide
      {...{
        children,
        in: boolean,
        timeout,
        ref,
        onEnter,
        onExited,
      }}
      direction={direction}
    />
  )
}
interface SnackbarBoxProps {
  variant: keyof typeof variantStyles
}

const ToastBox = styled(Box)<SnackbarBoxProps>((props) => ({
  display: 'flex',
  width: 400,
  height: 20,
  borderRadius: 10,
  padding: '28px 32px',
  justifyContent: 'space-between',
  backgroundColor: variantStyles[props.variant].backgroundColor,
}))

export const Toast: React.StyledFC<ToastProps> = memo(
  ({
    className,
    open,
    title,
    variant,
    duration,
    autoHideDuration,
    direction = 'left',
    position,
    onClose,
  }: ToastProps) => {
    const classes = useStyles({
      open,
      title,
      variant,
      duration,
      autoHideDuration,
      onClose,
    })
    return (
      <Snackbar
        className={clsx(
          className,
          classes.snackbar,
          !open && classes.hideSnackbar
        )}
        data-testid="toast"
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        onClick={onClose}
        TransitionComponent={(props) => TransitionComponent(props, direction)}
        TransitionProps={{ timeout: duration }}
        anchorOrigin={position}
      >
        <ToastBox variant={variant}>
          <Box className={classes.contentWrapper}>
            <Icon className={classes.leftIcon} icon={iconNames(variant)} />
            <Typography className={classes.typography}>{title}</Typography>
          </Box>
          <Icon className={classes.closeIcon} icon="close" />
        </ToastBox>
      </Snackbar>
    )
  }
)

Toast.displayName = 'Toast'
