import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs as MaterialBreadcrumbs, Typography } from '@mui/material'
import { Icon } from 'src/components/atoms'
import style from './styles.module.scss'

export type Path = {
  index: number
  title: string
  to: string
}

export type BreadcrumbsProps = {
  paths: Path[]
} & React.StyledProps

const Breadcrumbs: React.StyledFC<BreadcrumbsProps> = ({
  className,
  paths,
}: BreadcrumbsProps) => {
  const navigate = useNavigate()

  return (
    <MaterialBreadcrumbs
      className={className}
      aria-label="breadcrumb"
      classes={{ root: style.root, ol: style.ol }}
      separator={<Icon icon="navigateNext" className={style.svgIcon} />}
    >
      {paths.map((path, index) => {
        const last = index === paths.length - 1

        return last ? (
          <Typography
            color="textPrimary"
            variant="h6"
            key={path.index}
            className={style.typography}
          >
            {path.title}
          </Typography>
        ) : (
          <Typography
            color="black"
            variant="h6"
            onClick={() => navigate(path.to)}
            key={path.index}
            className={style.link}
          >
            {path.title}
          </Typography>
        )
      })}
    </MaterialBreadcrumbs>
  )
}

export default Breadcrumbs
