import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link as MuiLink,
} from '@mui/material'
import { Badge, Icon, IconName } from 'src/components/atoms'
import styles from './styles.module.scss'

export type TableOfContentsProps = {
  contents: Content[]
  currentPath?: string
} & React.StyledProps

export type Content = {
  items: Item[]
}

export type Item = {
  title: string
  to: string
  icon: IconName
  label?: string
}

const TableOfContents: React.StyledFC<TableOfContentsProps> = ({
  className,
  currentPath,
  contents,
}: TableOfContentsProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      {contents.map((content, index) => (
        <List key={content.items[index].title}>
          {content.items.map((item) => {
            const isInternal = item.to.startsWith('/')
            const selected = item.to === currentPath
            return (
              <ListItem
                className={clsx(
                  styles.listItem,
                  selected && styles.selectedItem
                )}
                button
                component={isInternal ? Link : MuiLink}
                key={item.title}
                to={item.to}
                href={item.to}
                target={isInternal ? undefined : '_blank'}
              >
                <Icon
                  icon={item.icon}
                  className={styles.svgIcon}
                  fontSize="inherit"
                />
                <ListItemText className={styles.listItemText}>
                  <Typography
                    className={clsx(
                      styles.title,
                      selected && styles.selectedTitle
                    )}
                    variant="button"
                  >
                    {item.title}
                  </Typography>
                  {item.label && (
                    <Badge
                      className={styles.badge}
                      label={item.label}
                      color="vermilion"
                      fillType="color"
                      ellipse
                      size="small"
                    />
                  )}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      ))}
    </Box>
  )
}

export default TableOfContents
