import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@mui/material'
import { Badge } from 'src/components/atoms'
import PdfViewer from 'src/components/molecules/PdfViewer'
import ZoomableReceiptImage, {
  ZoomableReceiptImageProps,
} from 'src/components/molecules/ZoomableReceiptImage'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  isOneOfRoles,
} from 'src/domain/role'
import {
  RoleEnum,
  TenantRegister,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'
import TenantSalesReportTable, {
  TenantSalesReportTableProps,
} from '../TenantSalesReportTable'
import styles from './styles.module.scss'

export type TenantSalesReportRegisterProps = React.StyledProps &
  TenantSalesReportTableProps &
  ZoomableReceiptImageProps & {
    isPdf?: boolean
    isFirst?: boolean
    onLoaded?: () => void
    role?: RoleEnum
    tenantRegister?: TenantRegister
  }

const TenantSalesReportRegister: React.StyledFC<
  TenantSalesReportRegisterProps
> = ({
  className,
  concatOriginal,
  separatedOriginal,
  resized,
  registerIndex,
  columns,
  rows,
  role,
  isPdf = false,
  isFirst,
  tenantRegister,
  onLoaded,
  onClickValue,
}: TenantSalesReportRegisterProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      <Box className={styles.wrapper}>
        <Box className={isPdf ? styles.pdfWrapper : styles.receiptWrapper}>
          {isPdf ? (
            <PdfViewer
              className={styles.pdf}
              separatedOriginal={separatedOriginal}
              concatOriginal={concatOriginal}
            />
          ) : (
            <ZoomableReceiptImage
              className={styles.receipt}
              {...{
                concatOriginal,
                separatedOriginal,
                resized,
                onLoaded,
              }}
            />
          )}
        </Box>
        {isOneOfRoles(
          AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
          role
        ) && (
          <Box
            className={clsx(
              styles.tableWrapper,
              isFirst && !tenantRegister && styles.topMargin
            )}
          >
            {tenantRegister && (
              <Box className={styles.tenantRegister}>
                <Typography className={styles.title}>レジNo</Typography>
                <Typography className={styles.number}>
                  {tenantRegister.registerNumber}
                </Typography>
                {tenantRegister.registerCategory ===
                  TenantRegisterCategoryEnum.RepresentativeRegister && (
                  <Badge
                    className={styles.badge}
                    label="代表レジ"
                    color="lightYellow"
                    fillType="color"
                    ellipse
                  />
                )}
              </Box>
            )}
            <TenantSalesReportTable
              className={styles.table}
              {...{ columns, rows, registerIndex, onClickValue }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TenantSalesReportRegister
