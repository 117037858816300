import React, { useEffect } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import clsx from 'clsx'
import { FormProvider, useForm, useFormState } from 'react-hook-form'
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  IconButton,
} from '@mui/material'
import { Icon } from 'src/components/atoms'
import TextField from 'src/components/molecules/TextField'
import { OPA_REGISTER_NUMBER_DIGIT } from 'src/domain/org'
import { TenantRegisterCategoryEnum } from 'src/slices/services/api'
import {
  RegexNoSpaceOnly,
  InvalidInputErrorMessage,
  RegexNumeric,
  NumericRegexErrorMessage,
} from 'src/utils/regularExpression'
import commonStyles from '../../common.module.scss'
import { TenantRegisterRow } from '../../registerReducer'
import { RegisterFormTypes, RegisterFormNameEnum } from '../../type'
import styles from './styles.module.scss'

export type RegistersRowProps = {
  register: TenantRegisterRow
  rowNum: number
  showAllError: boolean
  onRemoveRegister: (register: TenantRegisterRow) => void
  onValidate: (validStatus: {
    isValid: boolean
    rowNum: number
    id?: string
  }) => void
  onRegisterNumberChange: (
    currentRegister: TenantRegisterRow,
    newRegisterNumber: string
  ) => void
  onRegisterCategoryChange: (currentRegister: TenantRegisterRow) => void
} & React.StyledProps

const duplicateErrorMessage = 'レジNoが重複しています'

const RegisterRow: React.StyledFC<RegistersRowProps> = ({
  className,
  register,
  rowNum,
  showAllError,
  onRemoveRegister,
  onValidate,
  onRegisterNumberChange,
  onRegisterCategoryChange,
}: RegistersRowProps) => {
  const registerMethod = useForm<RegisterFormTypes>({
    mode: 'onChange',
    defaultValues: {
      [RegisterFormNameEnum.registerNumber]:
        register.formUpdatedData.registerNumber,
      [RegisterFormNameEnum.registerCategory]:
        register.formUpdatedData.registerCategory,
    },
  })
  const { isValid } = useFormState({ control: registerMethod.control })
  const { trigger, formState, watch } = registerMethod

  useEffect(() => {
    onValidate({ isValid, id: register.formUpdatedData.id, rowNum })
  }, [isValid, onValidate, register.formUpdatedData.id, rowNum])

  // NOTE: 未入力エラーは一度も触れていないTextFieldには表示されていないので、送信時にtriggerして未入力エラーを設定する
  useEffect(() => {
    if (showAllError) {
      trigger()
    }
  }, [showAllError, trigger])

  useEffect(() => {
    const watchedRegisterNumber = watch(RegisterFormNameEnum.registerNumber)
    if (watchedRegisterNumber === undefined) {
      return
    }
    if (register.formUpdatedData.registerNumber !== watchedRegisterNumber) {
      onRegisterNumberChange(register, watchedRegisterNumber)
    }
  }, [onRegisterNumberChange, register, formState, watch])

  return (
    <FormProvider {...registerMethod}>
      <Box className={clsx(styles.root, className)}>
        <Box>
          <Box className={styles.labelContainer}>
            <Typography component="h3" className={styles.label}>
              レジNo
            </Typography>
            <Typography className={commonStyles.digit}>
              <span className={commonStyles.digitNumber}>
                {OPA_REGISTER_NUMBER_DIGIT}
              </span>
              桁
            </Typography>
          </Box>
          <Box className={styles.textFieldWrapper}>
            <TextField
              className={clsx(
                styles.textField,
                (register.isDuplicate ||
                  formState.errors[RegisterFormNameEnum.registerNumber]) &&
                  styles.error
              )}
              name={RegisterFormNameEnum.registerNumber}
              defaultValue={register.formUpdatedData.registerNumber || ''}
              type="text"
              placeholder="123"
              required
              validations={[
                {
                  regex: RegexNoSpaceOnly,
                  message: InvalidInputErrorMessage,
                },
                {
                  regex: RegexNumeric,
                  message: NumericRegexErrorMessage,
                },
              ]}
              maxLength={OPA_REGISTER_NUMBER_DIGIT}
              minLength={OPA_REGISTER_NUMBER_DIGIT}
              onBlur={() => trigger(RegisterFormNameEnum.registerNumber)}
            />
          </Box>
          <Box className={styles.errorContainer}>
            {register.hasFormError ? (
              <ErrorMessage
                errors={formState.errors}
                name={RegisterFormNameEnum.registerNumber}
                render={({ message }) => (
                  <Typography component="p" className={styles.errorMessage}>
                    {/* フォームで管理しているエラー */}
                    {message}
                  </Typography>
                )}
              />
            ) : (
              <Typography component="p" className={styles.errorMessage}>
                {/* registerReducerで管理しているエラー */}
                {register.isDuplicate ? duplicateErrorMessage : ''}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={styles.radioButtonWrapper}>
          <FormControlLabel
            control={
              <Radio
                name={RegisterFormNameEnum.registerCategory}
                onChange={() => {
                  onRegisterCategoryChange(register)
                }}
                value={TenantRegisterCategoryEnum.RepresentativeRegister}
                checked={
                  register.formUpdatedData.registerCategory ===
                  TenantRegisterCategoryEnum.RepresentativeRegister
                }
              />
            }
            label="代表レジとして登録する"
          />
          {register.formUpdatedData.registerCategory !==
            TenantRegisterCategoryEnum.RepresentativeRegister && (
            <IconButton
              className={styles.deleteButton}
              onClick={() => onRemoveRegister(register)}
            >
              <Icon className={styles.icon} icon="trash" />
              <span className={styles.deleteButtonText}>レジを削除する</span>
            </IconButton>
          )}
        </Box>
      </Box>
    </FormProvider>
  )
}

export default RegisterRow
