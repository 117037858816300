import React from 'react'
import clsx from 'clsx'
import { ArrowForwardIosSharp } from '@mui/icons-material'
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import color from 'src/styles/color'

export type AccordionProps = {
  title: string
  body: string
  defaultExpanded?: boolean
} & React.StyledProps

const timeout = 500

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
  summary: {
    padding: 0,
    backgroundColor: color.lightGray,
  },
  content: {
    margin: '8px 20px 4px',
  },
  expandIcon: {
    margin: 12,
    color: color.black,
  },
  expandIconWrapper: {
    transform: 'rotate(90deg)',
    transitionDuration: `${timeout}ms`,
    '&.Mui-expanded': {
      transform: 'rotate(-90deg)',
    },
  },
  title: {
    fontSize: 17,
  },
  details: {
    padding: '12px 20px',
    backgroundColor: color.lightGray00,
  },
  body: {
    lineHeight: '20px',
  },
})

export const Accordion: React.StyledFC<AccordionProps> = ({
  className,
  title,
  body,
  defaultExpanded,
}: AccordionProps) => {
  const classes = useStyles()

  return (
    <MuiAccordion
      className={clsx(className, classes.root)}
      defaultExpanded={defaultExpanded}
      disableGutters
      TransitionProps={{
        timeout,
      }}
    >
      <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp className={classes.expandIcon} />}
        classes={{
          root: classes.summary,
          content: classes.content,
          expandIconWrapper: classes.expandIconWrapper,
        }}
      >
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={{ root: classes.details }}>
        <Typography variant="h4" className={classes.body}>
          {body}
        </Typography>
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}
