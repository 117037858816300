import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { Badge, Button, Card } from 'src/components/atoms'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import {
  VIEWABLE_CONTRACT_DATE_CODES,
  VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES,
} from 'src/domain/org'
import {
  TenantDetail,
  TenantRegister,
  TenantRegisterCategoryEnum,
} from 'src/slices/services/api'
import commonStyles from '../../common.module.scss'
import { reportTypeOption } from '../../constants'
import styles from './styles.module.scss'

export type TenantDetailTabProps = {
  tenantDetail?: TenantDetail
  tenantRegisters?: TenantRegister[]
  isLoaded: boolean
  orgCode: string
  onStartEdit?: () => void
}

const TenantDetailTab: React.StyledFC<TenantDetailTabProps> = ({
  tenantDetail,
  tenantRegisters,
  isLoaded,
  orgCode,
  onStartEdit,
}: TenantDetailTabProps) => {
  const getReportType = (value: string) => {
    const selectedOption = reportTypeOption.find(
      (option) => option.value === value
    )
    return selectedOption?.label || '-'
  }

  return (
    <Box className={commonStyles.root}>
      {!isLoaded ? (
        <CenteringLoading />
      ) : (
        <Box className={commonStyles.container}>
          <Box className={commonStyles.leftColumn}>
            <Box className={commonStyles.leftCard}>
              <Card className={commonStyles.cardTitle} title="テナント情報">
                <Box className={styles.content}>
                  <Typography component="h3" className={styles.label}>
                    代表者氏名
                  </Typography>
                  <p className={commonStyles.value}>
                    {tenantDetail?.staffName || '-'}
                  </p>
                </Box>
                <Box className={styles.content}>
                  <Typography component="h3" className={styles.label}>
                    電話番号
                  </Typography>
                  <p className={commonStyles.value}>
                    {tenantDetail?.staffTel || '-'}
                  </p>
                </Box>
                {VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(
                  orgCode
                ) && (
                  <>
                    <Box className={styles.content}>
                      <Typography component="h3" className={styles.label}>
                        報告形態
                      </Typography>
                      <p className={commonStyles.value}>
                        {getReportType(tenantDetail?.reportType || '')}
                      </p>
                    </Box>
                    <Box className={styles.content}>
                      <Typography component="h3" className={styles.label}>
                        部門コード
                      </Typography>
                      <p className={commonStyles.value}>
                        {tenantDetail?.departmentCode || '-'}
                      </p>
                    </Box>
                    <Box className={styles.content}>
                      <Typography component="h3" className={styles.label}>
                        契約者コード
                      </Typography>
                      <p className={commonStyles.value}>
                        {tenantDetail?.contractorCode || '-'}
                      </p>
                    </Box>
                    <Box className={styles.content}>
                      <Typography component="h3" className={styles.label}>
                        区画
                      </Typography>
                      <p className={commonStyles.value}>
                        {tenantDetail?.section || '-'}
                      </p>
                    </Box>
                  </>
                )}
                {VIEWABLE_CONTRACT_DATE_CODES.includes(orgCode) && (
                  <>
                    <Box className={styles.cardDivider} />
                    <Box className={styles.datesContainer}>
                      <Box>
                        <Typography className={styles.dateLabel} component="h3">
                          契約開始日
                        </Typography>
                        <p className={commonStyles.value}>
                          {tenantDetail?.contractStartDate || '-'}
                        </p>
                      </Box>
                      <Box>
                        <Typography className={styles.dateLabel} component="h3">
                          契約終了日
                        </Typography>
                        <p className={commonStyles.value}>
                          {tenantDetail?.contractEndDate || '-'}
                        </p>
                      </Box>
                    </Box>
                  </>
                )}
              </Card>
            </Box>
            {VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES.includes(orgCode) &&
              tenantRegisters &&
              tenantRegisters.length > 0 && (
                <Box className={commonStyles.leftCard}>
                  <Card
                    className={commonStyles.cardTitle}
                    title="レジ情報"
                    subtitle={tenantRegisters.length}
                    unit="台"
                  >
                    {tenantRegisters.map((register, index) => (
                      <Box
                        className={styles.content}
                        key={register.registerNumber}
                      >
                        <Typography component="h3" className={styles.label}>
                          {index + 1}. レジNo
                        </Typography>
                        <p className={commonStyles.value}>
                          {register.registerNumber}
                        </p>
                        {register.registerCategory ===
                          TenantRegisterCategoryEnum.RepresentativeRegister && (
                          <Badge
                            className={styles.registerBadge}
                            label="代表レジ"
                            color="lightYellow"
                            fillType="color"
                            ellipse
                          />
                        )}
                      </Box>
                    ))}
                  </Card>
                </Box>
              )}
          </Box>
          <Box className={commonStyles.rightColumn}>
            <Box className={commonStyles.rightCard}>
              <Card
                className={commonStyles.cardTitle}
                title="管理者メモ（テナントごと）"
              >
                <p className={styles.note}>{tenantDetail?.note || '-'}</p>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={commonStyles.footer}>
        <Divider className={commonStyles.divider} />
        <Box className={commonStyles.footerButtonWrapper}>
          <Button
            className={commonStyles.footerButton}
            color="primary"
            size="medium"
            title="設定を変更する"
            onClick={onStartEdit}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TenantDetailTab
