import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { TableCell, Typography } from '@mui/material'
import TextField from 'src/components/molecules/TextField'
import cellStyles from 'src/components/tables/Cells/styles.module.scss'
import { FormValidation } from 'src/utils/form'
import styles from './styles.module.scss'

export type InputCellProps = {
  name: string
  placeholder?: string
  defaultValue?: string | number
  inputType?: React.HTMLInputTypeAttribute
  required?: boolean
  minLength?: number
  maxLength?: number
  validations?: FormValidation[]
  width?: number
  disabled?: boolean
  hideError?: boolean
} & React.StyledProps

export const InputCell = React.memo<InputCellProps>(
  ({
    className,
    style,
    name,
    placeholder,
    defaultValue,
    inputType,
    required,
    minLength,
    maxLength,
    validations,
    width,
    disabled,
    hideError,
  }: InputCellProps) => {
    const {
      formState: { errors },
      trigger,
    } = useFormContext()

    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <TextField
          className={styles.input}
          disabled={disabled}
          name={name}
          type={inputType || 'text'}
          defaultValue={defaultValue}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          validations={validations}
          placeholder={placeholder}
          style={{ width }}
          onKeyPress={() => trigger(name)}
        />
        {!hideError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Typography
                variant="body2"
                component="p"
                className={styles.error}
              >
                {message}
              </Typography>
            )}
          />
        )}
      </TableCell>
    )
  }
)

InputCell.displayName = 'InputCell'
