import React from 'react'
import clsx from 'clsx'
import { TableCell, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip } from '../Tooltip'

export type CalculationTableBodyCellProps = {
  text: string
  size: CalculationTableBodyCellSize
} & React.StyledProps

export type CalculationTableBodyCellSize = 'small' | 'medium' | 'large'

const useStyles = makeStyles({
  cell: {
    height: 48,
    boxSizing: 'border-box',
    borderBottom: 'none',
  },
  cellText: {
    display: 'block',
    overflow: 'hidden',
    maxWidth: 140,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  // NOTE: 画面サイズを縮小した際に、各 cell サイズが縮小してしまうのを防ぐため
  small: {
    width: 24,
    minWidth: 24,
    textAlign: 'center',
  },
  medium: {
    width: 40,
    minWidth: 40,
    padding: '0 10px',
  },
  large: {
    width: 120,
    minWidth: 120,
    padding: '0 8px',
  },
})

export const CalculationTableBodyCell: React.StyledFC<
  CalculationTableBodyCellProps
> = ({ text, size, className }: CalculationTableBodyCellProps) => {
  const classes = useStyles()

  return (
    <TableCell
      className={clsx(className, classes.cell, classes[size])}
      padding="none"
    >
      {size === 'large' ? (
        <Tooltip title={text} showOnlyEllipsis>
          <Typography
            component="span"
            variant="h5"
            className={classes.cellText}
          >
            {text}
          </Typography>
        </Tooltip>
      ) : (
        <Typography component="span" variant="h4" className={classes.cellText}>
          {text}
        </Typography>
      )}
    </TableCell>
  )
}
