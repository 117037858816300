import React from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import Breadcrumbs from 'src/components/molecules/Breadcrumbs'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import DatePagination from 'src/components/molecules/DatePagination'
import LoadingModal from 'src/components/molecules/LoadingModal'
import SearchTextField from 'src/components/molecules/SearchTextField'
import SmallGuide from 'src/components/molecules/SmallGuide'
import UpdateButton from 'src/components/molecules/UpdateButton'
import { Column } from 'src/components/tables'
import {
  AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
  isOneOfRoles,
} from 'src/domain/role'
import { RoleEnum } from 'src/slices/services/api'
import listStyles from 'src/styles/list-styles.module.scss'
import StoreSalesReportSummaryTable, {
  Row,
} from '../components/StoreSalesReportSummaryTable'
import style from './styles.module.scss'

export type StoreSalesReportSummaryProps = {
  columns: Column[]
  role?: RoleEnum
  rows: Row[]
  isLoading?: boolean
  isUpdateTotals?: boolean
  date: string
  defaultDate: string
  maxDate: string
  onClickTableRow: (rowIndex: number) => void
  onClickPrev: () => void
  onClickNext: () => void
  onChangeDate: (date: string) => void
  onChangeSearchText: (text: string) => void
  onUpdateTotals: () => void
}

const breadcrumbs = [{ index: 0, title: '日次管理', to: '' }]

const StoreSalesReportSummary: React.StyledFC<StoreSalesReportSummaryProps> = ({
  columns,
  role,
  rows,
  date,
  defaultDate,
  maxDate,
  isLoading,
  isUpdateTotals,
  onClickTableRow,
  onClickPrev,
  onClickNext,
  onChangeDate,
  onChangeSearchText,
  onUpdateTotals,
}: StoreSalesReportSummaryProps) => {
  const formMethods = useForm()
  const searchFieldName = 'searchStore'

  const handleClickClear = () => {
    formMethods.setValue(searchFieldName, '')
    onChangeSearchText('')
  }

  const renderStoreList = () => {
    return rows.length > 0 ? (
      <Box className={style.tableWrapper}>
        <StoreSalesReportSummaryTable
          className={style.table}
          columns={columns}
          rows={rows}
          onClick={onClickTableRow}
        />
      </Box>
    ) : (
      <SmallGuide
        icon="pray"
        className={style.guide}
        message="該当の結果はありません"
        description="検索キーワードを確認して再度試してください"
      />
    )
  }

  return (
    <div>
      <Box className={clsx(style.container, listStyles.container)}>
        <Breadcrumbs className={style.breadcrumb} paths={breadcrumbs} />
        <Typography className={style.title} variant="h1">
          日次管理
        </Typography>
        <Box className={style.filterBar}>
          {isOneOfRoles(
            AdminAdminSalesReportManagerEditorSalesReportManagerRoles,
            role
          ) && (
            <Box className={style.actions}>
              <UpdateButton
                className={style.updateButton}
                onUpdate={onUpdateTotals}
              />
            </Box>
          )}
          <FormProvider {...formMethods}>
            <form>
              <Box className={style.filterWrapper}>
                <DatePagination
                  className={style.date}
                  data-chromatic="ignore"
                  date={date}
                  defaultDate={defaultDate}
                  maxDate={maxDate}
                  disabledNext={
                    dayjs(date).isSame(maxDate) || dayjs(date).isAfter(maxDate)
                  }
                  onClickPrev={onClickPrev}
                  onClickNext={onClickNext}
                  onChange={onChangeDate}
                />
                <SearchTextField
                  name={searchFieldName}
                  type="text"
                  placeholder="コード or 店舗名"
                  onClickClear={handleClickClear}
                  onChange={(text: string) => onChangeSearchText(text)}
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
        {isLoading ? (
          <CenteringLoading
            className={style.centeringLoading}
            text="データを取得しています。10〜30秒ほどお待ちください。"
          />
        ) : (
          renderStoreList()
        )}
      </Box>
      {isUpdateTotals && (
        <LoadingModal
          open={isUpdateTotals}
          title="報告値/確定値を更新中"
          content={
            <>
              タブ削除・リロードを行うと更新が中止されます。
              <br />
              通信環境によって30~60秒ほど時間がかかる場合がございます。
            </>
          }
        />
      )}
    </div>
  )
}

export default StoreSalesReportSummary
