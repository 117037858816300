import { ENABLE_SEND_NOTIFICATION_ORG_CODES } from 'src/domain/org'
import { MemoAndNotificationType, UpdateStatusType } from './type'

const memoMap = {
  reportMemo: '補足事項',
  manageMemo: '管理者メモ',
  isNotificationSent: 'メッセージ',
}

export const getMemoTitle = (memo?: MemoAndNotificationType): string => {
  return memo ? memoMap[memo] ?? '' : ''
}

export const getMemoOptions = (
  orgCode: string
): {
  label: string
  value: MemoAndNotificationType
}[] => {
  const options: {
    label: string
    value: MemoAndNotificationType
  }[] = [
    { label: getMemoTitle('reportMemo'), value: 'reportMemo' },
    { label: getMemoTitle('manageMemo'), value: 'manageMemo' },
  ]

  if (!ENABLE_SEND_NOTIFICATION_ORG_CODES.includes(orgCode)) {
    return options
  }

  options.push({
    label: getMemoTitle('isNotificationSent'),
    value: 'isNotificationSent',
  })
  return options
}

export const popupOptions: UpdateStatusType[] = [
  'revertReported',
  'bulkFinalize',
]
