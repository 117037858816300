import React from 'react'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'src/components/atoms'
import BreadcrumbsPipe, { Path } from 'src/components/molecules/BreadcrumbsPipe'
import Guide from 'src/components/molecules/Guide'

export type StartEditCalculationProps = {
  breadcrumbs: Path[]
  onClickClose: () => void
  onClickForward: () => void
}

const HEADER_HEIGHT = 94

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    height: HEADER_HEIGHT,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  breadcrumb: {
    margin: '24px 0 0 20px',
  },
  closeButtonWrapper: {
    margin: '20px 20px 0 0',
  },
  closeButton: {
    width: 20,
    height: 20,
  },
  contentWrapper: {
    display: 'flex',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const StartEditCalculation: React.StyledFC<StartEditCalculationProps> = ({
  breadcrumbs,
  onClickClose,
  onClickForward,
}: StartEditCalculationProps) => {
  const classes = useStyles()

  const buttons = [
    {
      index: 0,
      title: 'すすむ',
      onClick: onClickForward,
    },
  ]

  return (
    <Box className={classes.root}>
      <Box className={classes.header} component="header">
        <BreadcrumbsPipe className={classes.breadcrumb} paths={breadcrumbs} />
        <Box className={classes.closeButtonWrapper}>
          <IconButton
            className={classes.closeButton}
            onClick={onClickClose}
            size="large"
          >
            <Icon icon="close" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.contentWrapper}>
        <Guide
          message={`利用開始に必要な準備を\n行っていきましょう。`}
          description="各テナントごとの独自レシート設定を行うことで売上報告ツールが利用開始できます。"
          buttons={buttons}
          icon="pointRight"
        />
      </Box>
    </Box>
  )
}

export default StartEditCalculation
