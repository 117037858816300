import React, {
  memo,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { Button } from 'src/components/atoms'
import { CheckFormType } from 'src/components/molecules/CheckBoxListWithFilter'
import { validateCheckBoxListValue } from 'src/components/molecules/MemberAssignedStoreCheck'
import Modal from 'src/components/molecules/Modal'
import { Member, RoleEnum, Store as EntityStore } from 'src/slices/services/api'
import listStyles from 'src/styles/list-styles.module.scss'
import { isEqualArrayElementl } from 'src/utils/array'
import MemberFilterBar from '../../components/FilterBar'
import MembersTable from '../../components/Table'
import MembersUpdateTable, {
  MemberFormType,
  MembersFormType,
} from '../../components/UpdateTable'
import { IndexedMember, INITIAL_STATE, reducerFunc } from '../../reducer'
import style from './styles.module.scss'

export type TemplatesMembersUpdateProps = {
  members: Member[]
  stores: EntityStore[]
  selfRole: RoleEnum
  orgCode: string
  onUpdateMember: (members: Member[]) => void
  onClickConfirmClose: () => void
}

const TemplatesMembersUpdate: React.StyledFC<TemplatesMembersUpdateProps> =
  memo(
    ({
      members,
      stores,
      selfRole,
      orgCode,
      onUpdateMember,
      onClickConfirmClose,
    }: TemplatesMembersUpdateProps) => {
      const tableFormMethods = useForm<MembersFormType>()
      const [showCancelModal, setShowCancelModal] = useState(false)
      const [memberState, dispatch] = useReducer(reducerFunc, INITIAL_STATE)
      const [submittedMembers, setSubmittedMembers] = React.useState<Member[]>(
        []
      )
      const [duplicationEmails, setDuplicationEmails] = useState<string[]>([])
      const [showNoDifferenceModal, setShowNoDifferenceModal] = useState(false)

      useEffect(() => {
        dispatch({ type: 'UPDATE_MEMBERS', payload: { allMembers: members } })
      }, [members])

      const onSearchTextUpdated = (searchText: string) => {
        dispatch({
          type: 'UPDATE_SEARCH_TEXT',
          payload: { searchParamText: searchText },
        })
      }

      const onRoleUpdated = (role: RoleEnum) => {
        dispatch({
          type: 'UPDATE_SEARCH_ROLE',
          payload: { role },
        })
      }

      const onAssignedStoresUpdated = (assignedStores: CheckFormType) => {
        dispatch({
          type: 'UPDATE_ASSIGNED_STORE',
          payload: { assignedStores },
        })
      }
      const getSelfDuplication = useCallback(
        (formMembers: MemberFormType[]) => {
          const emails = formMembers.map((member) => member.email)
          const duplicate = emails.filter(
            (email, index) => emails.indexOf(email) !== index
          )
          return duplicate
        },
        []
      )

      const extractUpdatedMembers = (
        formMembers: MemberFormType[],
        serverMembers: IndexedMember[]
      ) => {
        const difference: Member[] = []
        formMembers.forEach((formMember, index) => {
          let exactSameAssignedStore = true
          if (
            (serverMembers[index].assignedStores &&
              serverMembers[index].assignedStores.length > 0) ||
            formMember.assignedStores?.checkListValues.length > 0
          ) {
            const serverAssignStoresIds =
              serverMembers[index].assignedStores?.map((store) => store.id) ||
              []
            if (
              !isEqualArrayElementl(
                serverAssignStoresIds,
                formMember.assignedStores?.checkListValues || []
              )
            ) {
              exactSameAssignedStore = false
            }
          }

          // NOTE: 完全にserverMembersとformMemberが同じかどうかのチェック
          if (
            serverMembers[index].name !== formMember.name ||
            serverMembers[index].email !== formMember.email ||
            serverMembers[index].role !== formMember.role ||
            serverMembers[index].hasAllStoresPermission !==
              formMember.assignedStores?.allChecked ||
            serverMembers[index].active !==
              JSON.parse(formMember.active.toLowerCase()) ||
            !exactSameAssignedStore
          ) {
            difference.push({
              id: serverMembers[index].id,
              name: formMember.name,
              role: formMember.role,
              email: formMember.email,
              hasAllStoresPermission: formMember.assignedStores?.allChecked,
              active: JSON.parse(formMember.active.toLowerCase()),
              assignedStores: formMember.assignedStores?.checkListValues.map(
                (storeId) => {
                  const foundStore = stores.find(
                    (store) => store.id === storeId
                  )
                  return {
                    id: storeId,
                    name: foundStore?.name || '',
                    code: foundStore?.code || '',
                  }
                }
              ),
            })
          }
        })
        return difference
      }

      const handleSubmit = () => {
        const formData = tableFormMethods.getValues()
        const isValid = formData.members.every((member) =>
          validateCheckBoxListValue(member.assignedStores)
        )
        if (!isValid) {
          return
        }
        const dup = getSelfDuplication(formData.members)
        if (dup.length > 0) {
          setDuplicationEmails(dup)
          return
        }
        const differences = extractUpdatedMembers(
          formData.members,
          memberState.allIndexedMembers
        )
        if (differences.length === 0) {
          setShowNoDifferenceModal(true)
        }
        setSubmittedMembers(differences)
      }
      const openUpdateConfirmationModal = useCallback((): boolean => {
        return submittedMembers.length > 0
      }, [submittedMembers])

      return (
        <>
          <FormProvider {...tableFormMethods}>
            <form>
              <Box className={listStyles.container}>
                <Typography className={style.title} variant="h1">
                  ユーザー情報編集
                </Typography>
                <Box className={style.filterWrapper}>
                  <MemberFilterBar
                    onSearchTextUpdated={onSearchTextUpdated}
                    onRoleUpdated={onRoleUpdated}
                    stores={stores}
                    onAssignedStoresUpdated={onAssignedStoresUpdated}
                  />
                </Box>

                <Box className={style.mainContent}>
                  <MembersUpdateTable
                    members={memberState.filteredMembers}
                    stores={stores}
                    selfRole={selfRole}
                    orgCode={orgCode}
                  />
                </Box>

                <Box className={style.bottom}>
                  <Button
                    title="キャンセル"
                    size="medium"
                    color="secondary"
                    onClick={() => setShowCancelModal(true)}
                  />
                  <Button
                    title="更新する"
                    size="medium"
                    color="primary"
                    type="button"
                    onClick={tableFormMethods.handleSubmit(handleSubmit)}
                  />
                </Box>
              </Box>
            </form>
          </FormProvider>
          <Modal
            open={openUpdateConfirmationModal()}
            disableBackdropClick={false}
            title="下記のユーザを更新しますか？"
            agreeButtonTitle="更新する"
            disagreeButtonTitle="キャンセル"
            onClose={() => setSubmittedMembers([])}
            onClickAgree={() => onUpdateMember(submittedMembers)}
            onClickDisagree={() => setSubmittedMembers([])}
            className={style.modal}
          >
            <MembersTable members={submittedMembers} isSmallTable />
          </Modal>
          <Modal
            open={showCancelModal}
            title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
            agreeButtonTitle="このまま中断する"
            disagreeButtonTitle="設定に戻る"
            onClickAgree={onClickConfirmClose}
            onClickDisagree={() => setShowCancelModal(false)}
          />
          <Modal
            open={duplicationEmails?.length > 0}
            disableBackdropClick={false}
            title="登録メールが重複しています。修正してください"
            agreeButtonTitle="ok"
            onClose={() => setDuplicationEmails([])}
            onClickAgree={() => setDuplicationEmails([])}
          >
            <Box>
              {duplicationEmails.map((email) => (
                <Typography
                  variant="body1"
                  className={style.duplicateList}
                  key={email}
                >
                  {email}
                </Typography>
              ))}
            </Box>
          </Modal>
          <Modal
            open={showNoDifferenceModal}
            title={`変更する対象がございませんでした\n`}
            agreeButtonTitle="ユーザー管理に戻る"
            disagreeButtonTitle="キャンセルする"
            onClickAgree={onClickConfirmClose}
            onClickDisagree={() => setShowNoDifferenceModal(false)}
          />
        </>
      )
    }
  )

TemplatesMembersUpdate.displayName = 'TemplatesMembersUpdate'
export default TemplatesMembersUpdate
