import React, { memo } from 'react'
import { Box, Table, TableBody } from '@mui/material'
import { AppTableHead } from 'src/components/tables'

import { ShownTenant } from '../../updateReducer'
import UpdateTableRow from '../UpdateTableRow'
import { headerColumns } from './constant'

export type UpdateTableProps = {
  tenantsWithStoreAndTenantOwnerCompany: ShownTenant[]
  onAddAssociation: (tenantId: string) => void
  onRemoveAssociation: (tenantId: string) => void
} & React.StyledProps

const UpdateTable: React.StyledFC<UpdateTableProps> = memo(
  ({
    tenantsWithStoreAndTenantOwnerCompany,
    onAddAssociation,
    onRemoveAssociation,
  }: UpdateTableProps) => {
    return (
      <Box>
        <Table stickyHeader>
          <AppTableHead columns={headerColumns} />
          <TableBody>
            {tenantsWithStoreAndTenantOwnerCompany.map((data) => {
              return (
                <UpdateTableRow
                  key={data.tenant.id}
                  shownTenant={data}
                  onAddAssociation={onAddAssociation}
                  onRemoveAssociation={onRemoveAssociation}
                />
              )
            })}
          </TableBody>
        </Table>
      </Box>
    )
  }
)

UpdateTable.displayName = 'UpdateTable'
export default UpdateTable
