export const headerColumns = [
  {
    title: '',
    width: 30,
  },
  {
    title: 'ステータス',
    width: 100,
  },
  {
    title: 'テナントコード',
    width: 100,
  },
  {
    title: 'テナント名称',
    width: 150,
  },
  {
    title: '店舗コード',
    width: 100,
  },
  {
    title: '店舗名称',
    width: 150,
  },
  {
    title: '取引企業コード',
    width: 100,
  },
  {
    title: '取引企業名称',
    width: 180,
  },
]
