import React from 'react'
import clsx from 'clsx'
import { TableHead, TableCell, TableRow, Typography, Box } from '@mui/material'
import { CheckBox } from 'src/components/atoms'
import styles from './styles.module.scss'

export type AppTableHeadProps = {
  columns: Column[]
  checkable?: boolean
  isChecked?: boolean
  hideCellWidth?: number
  onChange?: (isChecked: boolean) => void
} & React.StyledProps

export type Column = {
  title: string
  width?: number
  labeledMessage?: string
  message?: string
  isCenter?: boolean
  isCombined?: boolean
}

export const AppTableHead: React.StyledFC<AppTableHeadProps> = ({
  className,
  columns,
  checkable,
  isChecked,
  hideCellWidth = 0,
  onChange,
}: AppTableHeadProps) => {
  return (
    <TableHead className={clsx(styles.header, className)}>
      <TableRow>
        {hideCellWidth > 0 && (
          <TableCell
            className={clsx(styles.cell, styles.hide)}
            style={{ width: hideCellWidth }}
          />
        )}
        {checkable && (
          <TableCell className={styles.cell}>
            <CheckBox
              isChecked={isChecked}
              onChange={onChange}
              shape="rectangle"
              disableRipple
            />
          </TableCell>
        )}
        {columns.map((cell) => (
          <TableCell
            key={cell.title}
            align="left"
            className={styles.cell}
            style={{
              width: cell.width ?? 100,
            }}
            colSpan={cell.isCombined ? 2 : 1}
          >
            <Box
              className={clsx(
                styles.cellBox,
                cell.isCenter && styles.centerCell
              )}
            >
              <Typography variant="subtitle2">{cell.title}</Typography>
              {cell.labeledMessage && (
                <span className={styles.labeledMessage}>
                  {cell.labeledMessage}
                </span>
              )}
              {cell.message && (
                <span className={styles.message}>{cell.message}</span>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
