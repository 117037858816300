// 同期対象の組織コード
export const ENABLE_SYNC_ORG_CODES = ['999']

// CSVダウンロード可能な組織コード
export const ENABLE_DOWNLOAD_CSV_ORG_CODES = ['007', '988', '999']

export const ENABLE_UPDATING_REGISTERED_STATUS = ['001', '004']

export const VIEWABLE_CONTRACT_DATE_CODES = ['006', '007', '988', '999']

// 契約形態やレジNo（OPA向けの項目）を利用する組織コード
export const VIEWABLE_CONTRACT_TYPE_AND_REGISTER_ORG_CODES = ['006', '999']

export const HYPHEN_OCR_AND_CONSISTENCY_ORG_CODES = ['006']

export const ENABLE_ATTACH_EDITOR_STORE_MANAGER_IN_OPERATION_CENTER_ROLE = [
  '007',
  '999',
]

// Appへメッセージを送信できる組織コード
export const ENABLE_SEND_NOTIFICATION_ORG_CODES = ['007', '988', '999']

// 取引企業を利用する組織コード
export const ENABLE_TENANT_OWNER_COMPANY = ['999']

export const OPA_REGISTER_NUMBER_DIGIT = 3
export const OPA_DEPARTMENT_CODE_DIGIT = 3
