import { IconName } from 'src/components/atoms'

export enum ConsistencyEnum {
  allMatchExcludingAllZero = 'allMatchExcludingAllZero',
  allMatchIncludingAllZero = 'allMatchIncludingAllZero',
  misMatch = 'misMatch',
}

export type ConsistencyMap = {
  [key in ConsistencyEnum]: {
    title: string
    icon: IconName
  }
}

const consistencyMap: ConsistencyMap = {
  [ConsistencyEnum.allMatchExcludingAllZero]: {
    title: '一致(すべて0含まない)',
    icon: 'match',
  },
  [ConsistencyEnum.allMatchIncludingAllZero]: {
    title: '一致(すべて0含む)',
    icon: 'matchPartial',
  },
  [ConsistencyEnum.misMatch]: {
    title: '不一致',
    icon: 'misMatch',
  },
}

export const getConsistencyTitle = (consistency: ConsistencyEnum): string => {
  return consistencyMap[consistency].title
}

export const getConsistencyIcon = (consistency: ConsistencyEnum): IconName => {
  return consistencyMap[consistency].icon
}

export const consistencyCheckOptions: {
  label: string
  value: ConsistencyEnum
  icon?: IconName
}[] = [
  {
    label: getConsistencyTitle(ConsistencyEnum.allMatchExcludingAllZero),
    value: ConsistencyEnum.allMatchExcludingAllZero,
    icon: getConsistencyIcon(ConsistencyEnum.allMatchExcludingAllZero),
  },
  {
    label: getConsistencyTitle(ConsistencyEnum.allMatchIncludingAllZero),
    value: ConsistencyEnum.allMatchIncludingAllZero,
    icon: getConsistencyIcon(ConsistencyEnum.allMatchIncludingAllZero),
  },
  {
    label: getConsistencyTitle(ConsistencyEnum.misMatch),
    value: ConsistencyEnum.misMatch,
    icon: getConsistencyIcon(ConsistencyEnum.misMatch),
  },
]

export type ConvertedAggregatedResult = {
  is_all_manual_input_values_zero: boolean
  extracted_consistency_result: boolean
}

export const convertToAggregatedResult = (
  consistency: ConsistencyEnum
): ConvertedAggregatedResult[] => {
  switch (consistency) {
    case ConsistencyEnum.allMatchExcludingAllZero:
      return [
        {
          is_all_manual_input_values_zero: false,
          extracted_consistency_result: true,
        },
      ]
    case ConsistencyEnum.allMatchIncludingAllZero:
      return [
        {
          is_all_manual_input_values_zero: true,
          extracted_consistency_result: true,
        },
      ]
    case ConsistencyEnum.misMatch:
      return [
        {
          is_all_manual_input_values_zero: true,
          extracted_consistency_result: false,
        },
        {
          is_all_manual_input_values_zero: false,
          extracted_consistency_result: false,
        },
      ]
    default:
      return []
  }
}

export const convertToConsistencyEnum = (
  aggregatedResults: ConvertedAggregatedResult[]
): ConsistencyEnum[] => {
  const consistencyEnums: ConsistencyEnum[] = aggregatedResults.map(
    (aggregatedResult) => {
      if (!aggregatedResult.extracted_consistency_result) {
        return ConsistencyEnum.misMatch
      }
      if (
        aggregatedResult.is_all_manual_input_values_zero &&
        aggregatedResult.extracted_consistency_result
      ) {
        return ConsistencyEnum.allMatchIncludingAllZero
      }
      return ConsistencyEnum.allMatchExcludingAllZero
    }
  )

  // NOTE: 配列の重複排除
  return Array.from(new Set(consistencyEnums))
}

export const getConvertedAggregatedResults = (
  consistency: ConsistencyEnum[]
): ConvertedAggregatedResult[] => {
  return consistency.flatMap(convertToAggregatedResult)
}
