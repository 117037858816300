import React, { memo } from 'react'
import { Table, TableBody, Typography } from '@mui/material'
import { EllipseIcon } from 'src/components/atoms'
import {
  AppTableHead,
  TextCell,
  AppTableRow,
  ChildCell,
} from 'src/components/tables'
import { getTenantStatusTitle, getTenantStatusColor } from 'src/domain/tenant'
import { TenantWithStoreAndTenantOwnerCompany } from 'src/slices/services/api'
import { formatDateTime } from 'src/utils/date'
import { headerColumns } from './constant'
import styles from './styles.module.scss'

export type ListTableProps = {
  tenantsWithStoreAndTenantOwnerCompany: TenantWithStoreAndTenantOwnerCompany[]
} & React.StyledProps

const ListTable: React.StyledFC<ListTableProps> = memo(
  ({ tenantsWithStoreAndTenantOwnerCompany }: ListTableProps) => {
    return (
      <Table stickyHeader>
        <AppTableHead columns={headerColumns} />
        <TableBody>
          {tenantsWithStoreAndTenantOwnerCompany.map((data) => {
            return (
              <AppTableRow key={data.tenant.id} className={styles.row}>
                <ChildCell>
                  <Typography className={styles.statusWord}>
                    <EllipseIcon
                      color={getTenantStatusColor(data.tenant.status)}
                    />
                    <span className={styles.status}>
                      {getTenantStatusTitle(data.tenant.status)}
                    </span>
                  </Typography>
                </ChildCell>
                <TextCell
                  text={data.store.code}
                  width={100}
                  className={styles.text}
                />
                <TextCell
                  text={data.store.name}
                  width={150}
                  className={styles.text}
                />
                <TextCell
                  text={data.tenant.code}
                  width={100}
                  className={styles.text}
                />
                <TextCell
                  text={data.tenant.name}
                  width={150}
                  className={styles.text}
                />
                <TextCell
                  text={
                    data.tenantAssociatedAt
                      ? formatDateTime(data.tenantAssociatedAt)
                      : '-'
                  }
                  width={180}
                  className={styles.text}
                />
              </AppTableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
)

ListTable.displayName = 'ListTable'
export default ListTable
