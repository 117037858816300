import React, { useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { ReceiptImage, RoleEnum } from 'src/slices/services/api'
import {
  findSelectedResentImage,
  genReportMessages,
  genSelectDateOptions,
} from '../../functions'
import { SalesReportType } from '../../type'
import SalesReport from '../SalesReport'

type SalesReportsProps = {
  role?: RoleEnum
  salesReports: SalesReportType[]
  stickyTop: number
  filterForMismatch: boolean
  onClickTenantName: (code: string) => void
}

const SalesReports: React.FC<SalesReportsProps> = ({
  role,
  salesReports,
  stickyTop,
  filterForMismatch,
  onClickTenantName,
}) => {
  const FILTER_HEIGHT = 70

  const selectOptions = useMemo(() => {
    return genSelectDateOptions(salesReports)
  }, [salesReports])

  const reportMessages = useMemo(() => {
    return genReportMessages(salesReports)
  }, [salesReports])

  const [selectedResentImage, setSelectedResentImage] = useState<
    ReceiptImage | undefined
  >()

  const handleSelectDateChanged = (selectedDate: string) => {
    const newImage = findSelectedResentImage(selectedDate, salesReports)

    setSelectedResentImage(newImage)
  }

  return (
    <>
      {salesReports.map((salesReport) => (
        <Box key={salesReport.id}>
          <SalesReport
            stickyTop={stickyTop + FILTER_HEIGHT}
            salesReport={salesReport}
            tenantRegister={salesReport.tenantRegister}
            reportMessages={reportMessages}
            role={role}
            selectOptions={selectOptions}
            selectedResentImage={selectedResentImage}
            filterForMismatch={filterForMismatch}
            onClickName={() => onClickTenantName(salesReport.code)}
            onSelectDateChanged={handleSelectDateChanged}
          />
        </Box>
      ))}
    </>
  )
}

export default SalesReports
