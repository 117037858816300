import { Path } from 'src/components/molecules/BreadcrumbsPipe'
import { injectIndex } from 'src/utils/array'

interface StarEditCalculationPresenter {
  breadcrumbs(storeName?: string, tenantName?: string): Path[]
}

const Presenter: StarEditCalculationPresenter = {
  breadcrumbs(storeName?: string, tenantName?: string): Path[] {
    const paths = []

    if (storeName && tenantName) {
      paths.push({ title: storeName }, { title: tenantName })
    }

    paths.push({ title: '利用開始設定' })

    return injectIndex(paths)
  },
}

export default Presenter
