import { Path as BreadcrumbsPath } from 'src/components/molecules/Breadcrumbs'
import Path from 'src/routes/path'

interface TenantSalesReportSummaryPresenter {
  breadcrumbs(orgCode: string, storeName?: string): BreadcrumbsPath[]
}

const Presenter: TenantSalesReportSummaryPresenter = {
  breadcrumbs: (orgCode: string, storeName?: string): BreadcrumbsPath[] => {
    const paths = [
      {
        index: 0,
        title: '日次管理',
        to: Path.storeSalesReportSummaries(orgCode),
      },
    ]
    if (storeName) {
      paths.push({
        index: 1,
        title: storeName,
        to: '', // NOTE: リンクを作成しないために空文字
      })
    }
    return paths
  },
}

export default Presenter
