import React from 'react'
import { Box, MenuItem, MenuList, Typography } from '@mui/material'
import { Badge } from 'src/components/atoms'
import {
  getReportStatusTitle,
  getReportStatusBadgeColor,
  getReportStatusFillTypeColor,
} from 'src/domain/reportStatus'
import { ReportStatusEnum } from 'src/slices/services/api'

import { popupOptions } from '../../constantsAndFunction'
import { UpdateStatusType } from '../../type'
import style from './styles.module.scss'

export type UpdateStatusMenuProps = {
  onClickMenuItem: (updateStatusType: UpdateStatusType) => void
  checkCount: number
} & React.StyledProps

const UpdateStatusMenu: React.StyledFC<UpdateStatusMenuProps> = ({
  className,
  onClickMenuItem,
  checkCount,
}: UpdateStatusMenuProps) => {
  return (
    <Box className={className}>
      {checkCount > 0 && (
        <MenuList className={style.menuList}>
          {popupOptions.map((option) => (
            <MenuItem
              className={style.item}
              key={option}
              onClick={() => {
                onClickMenuItem(option)
              }}
            >
              {option === 'revertReported' ? (
                <Box className={style.menuItemBox}>
                  <Badge
                    className={style.badge}
                    label={getReportStatusTitle(ReportStatusEnum.Reported)}
                    color={getReportStatusBadgeColor(ReportStatusEnum.Reported)}
                    fillType={getReportStatusFillTypeColor(
                      ReportStatusEnum.Reported
                    )}
                    ellipse
                  />
                  <Typography className={style.obliqueLine}>/</Typography>
                  <Badge
                    className={style.badge}
                    label={getReportStatusTitle(ReportStatusEnum.ImageResent)}
                    color={getReportStatusBadgeColor(
                      ReportStatusEnum.ImageResent
                    )}
                    fillType={getReportStatusFillTypeColor(
                      ReportStatusEnum.ImageResent
                    )}
                    ellipse
                  />
                </Box>
              ) : (
                <Box className={style.menuItemBox}>
                  <Badge
                    className={style.badge}
                    label={getReportStatusTitle(
                      ReportStatusEnum.FinalizedReportSubmitted
                    )}
                    color={getReportStatusBadgeColor(
                      ReportStatusEnum.FinalizedReportSubmitted
                    )}
                    fillType={getReportStatusFillTypeColor(
                      ReportStatusEnum.FinalizedReportSubmitted
                    )}
                    ellipse
                  />
                </Box>
              )}
              <Typography>に変更する</Typography>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Box>
  )
}

export default UpdateStatusMenu
