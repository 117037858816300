import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { Box, FormControlLabel, Typography } from '@mui/material'
import { CheckBox } from 'src/components/atoms'
import SearchTextField from '../SearchTextField'
import SelectWithOption, { SelectWithOptionProps } from '../SelectWithOption'
import styles from './styles.module.scss'

export type Filter = Omit<SelectWithOptionProps, 'variant'>

export type TenantFilterProps = {
  filters: Filter[]
  tenantCount?: number
  defaultSearchValue?: string
  isChecked?: boolean
  searchFieldOnChange: (value: string) => void
  onChangeCheck?: () => void
} & React.StyledProps

const TenantFilter: React.StyledFC<TenantFilterProps> = ({
  className,
  filters,
  tenantCount,
  defaultSearchValue,
  isChecked,
  searchFieldOnChange,
  onChangeCheck,
}: TenantFilterProps) => {
  const { setValue } = useFormContext()
  const searchFieldName = 'searchTenant'

  const handleClickClear = () => {
    setValue(searchFieldName, '')
    searchFieldOnChange('')
  }

  useEffect(() => {
    if (!defaultSearchValue) return

    setValue(searchFieldName, defaultSearchValue)
  }, [defaultSearchValue, setValue])

  return (
    <Box className={clsx(className, styles.header)}>
      {tenantCount !== undefined ? (
        <Box>
          <Typography variant="h2" component="p" display="inline">
            {tenantCount}
          </Typography>
          <Typography variant="subtitle1" component="p" display="inline">
            テナント
          </Typography>
        </Box>
      ) : (
        <Box />
      )}
      <Box display="flex">
        <Box className={styles.filterWrapper}>
          {filters.map((filter) => (
            <SelectWithOption
              className={styles.filter}
              key={filter.name}
              name={filter.name}
              options={filter.options}
              placeholder={filter.placeholder}
              variant="standard"
            />
          ))}
        </Box>
        <SearchTextField
          name={searchFieldName}
          className={styles.searchFiled}
          type="text"
          placeholder="コード or テナント名"
          onClickClear={handleClickClear}
          onChange={(text: string) => searchFieldOnChange(text)}
        />
        {onChangeCheck && (
          <FormControlLabel
            className={styles.checkAria}
            control={
              <CheckBox
                isChecked={isChecked}
                shape="rectangle"
                onChange={onChangeCheck}
              />
            }
            label="練習モード実施なしで絞る"
          />
        )}
      </Box>
    </Box>
  )
}

export default TenantFilter
