/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import clsx from 'clsx'
import { IconButton, TableCell } from '@mui/material'
import { Icon, IconName } from 'src/components/atoms'
import cellStyles from 'src/components/tables/Cells/styles.module.scss'

export type IconCellProps = {
  icon: IconName
  onClick?: () => void
} & React.StyledProps

export const IconCell = React.memo(
  ({ className, style, icon, onClick }: IconCellProps) => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (onClick) {
        onClick()
      }
    }
    return (
      <TableCell className={clsx(className, cellStyles.cell)} style={style}>
        <IconButton onClick={handleClick}>
          <Icon icon={icon} />
        </IconButton>
      </TableCell>
    )
  }
)

IconCell.displayName = 'IconCell'
