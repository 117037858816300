import React, { useState, useRef, useEffect, memo } from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react'
import { bugsnagApiKey } from 'src/constants'

type PropsBugsasng = {
  plugin: BugsnagPluginReactResult
  children: React.ReactNode
  FallbackComponent?: React.ComponentType<{
    error: Error
    info: React.ErrorInfo
    clearError: () => void
  }>
}

const BugsangErrorBoundary: React.FC<PropsBugsasng> = memo(
  (props: PropsBugsasng) => {
    const { plugin, FallbackComponent, children } = props
    const ErrorBoundary = plugin!.createErrorBoundary(React) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        {children}
      </ErrorBoundary>
    )
  }
)
BugsangErrorBoundary.displayName = 'BugsangErrorBoundary'

type Props = {
  children: React.ReactNode
  FallbackComponent?: React.ComponentType<{
    error: Error
    info: React.ErrorInfo
    clearError: () => void
  }>
}

const ErrorBoundary: React.FC<Props> = memo((props: Props) => {
  const { children, FallbackComponent } = props
  const [isShowErrorBoundary, setIsShowErrorBoundary] = useState<boolean>(false)
  const refObject = useRef<BugsnagPluginReactResult>()

  useEffect(() => {
    try {
      Bugsnag.start({
        releaseStage: process.env.REACT_APP_ENV,
        apiKey: bugsnagApiKey,
        plugins: [new BugsnagPluginReact()],
      })
      const plugin = Bugsnag.getPlugin('react')
      if (plugin) {
        refObject.current = plugin
        setIsShowErrorBoundary(true)
      }
    } catch (error) {
      setIsShowErrorBoundary(false)
    }
  }, [])

  if (isShowErrorBoundary) {
    return (
      <BugsangErrorBoundary
        plugin={refObject.current!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
        FallbackComponent={FallbackComponent}
      >
        {children}
      </BugsangErrorBoundary>
    )
  }
  return <div>{children}</div>
})
ErrorBoundary.displayName = 'ErrorBoundary'

export default ErrorBoundary
