/* tslint:disable */
/* eslint-disable */
/**
 * Admin API
 * API for Admin
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@wed.company
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base'

/**
 * 完全一致フィルターで絞り込みに使用する値
 * @export
 * @interface AggregatedResult
 */
export interface AggregatedResult {
  /**
   *
   * @type {boolean}
   * @memberof AggregatedResult
   */
  isAllManualInputValuesZero: boolean
  /**
   *
   * @type {boolean}
   * @memberof AggregatedResult
   */
  extractedConsistencyResult: boolean
}
/**
 * Member\'s Authorization
 * @export
 * @interface Authorization
 */
export interface Authorization {
  /**
   *
   * @type {Organization}
   * @memberof Authorization
   */
  organization: Organization
  /**
   *
   * @type {RoleEnum}
   * @memberof Authorization
   */
  role: RoleEnum
  /**
   *
   * @type {string}
   * @memberof Authorization
   */
  memberName: string
  /**
   *
   * @type {boolean}
   * @memberof Authorization
   */
  active: boolean
}

/**
 * 400番エラー
 * @export
 * @interface BadRequestError
 */
export interface BadRequestError {
  /**
   *
   * @type {BadRequestErrorCodeEnum}
   * @memberof BadRequestError
   */
  code: BadRequestErrorCodeEnum
  /**
   *
   * @type {string}
   * @memberof BadRequestError
   */
  message: string
}

/**
 * 400番エラーのコード
 * @export
 * @enum {string}
 */

export const BadRequestErrorCodeEnum = {
  Json: 'invalid_json',
  Parameter: 'invalid_parameter',
  RequestBody: 'invalid_request_body',
  Token: 'invalid_token',
  SalesDate: 'invalid_sales_date',
} as const

export type BadRequestErrorCodeEnum =
  (typeof BadRequestErrorCodeEnum)[keyof typeof BadRequestErrorCodeEnum]

/**
 * 算出ロジックのベース
 * @export
 * @interface BaseCalculationDSL
 */
export interface BaseCalculationDSL {
  /**
   * DSL
   * @type {string}
   * @memberof BaseCalculationDSL
   */
  dsl: string
  /**
   *
   * @type {CalculationItem}
   * @memberof BaseCalculationDSL
   */
  calculationItem: CalculationItem
}
/**
 * ストアのベース
 * @export
 * @interface BaseStore
 */
export interface BaseStore {
  /**
   *
   * @type {string}
   * @memberof BaseStore
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof BaseStore
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof BaseStore
   */
  code: string
}
/**
 *
 * @export
 * @interface BaseTenant
 */
export interface BaseTenant {
  /**
   *
   * @type {string}
   * @memberof BaseTenant
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof BaseTenant
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof BaseTenant
   */
  code: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof BaseTenant
   */
  status: TenantStatusEnum
}

/**
 * テナント詳細のベース(OPAさんのみ)
 * @export
 * @interface BaseTenantDetail
 */
export interface BaseTenantDetail {
  /**
   * 契約者コード
   * @type {string}
   * @memberof BaseTenantDetail
   */
  contractorCode?: string
  /**
   * 区画
   * @type {string}
   * @memberof BaseTenantDetail
   */
  section?: string
  /**
   * 契約開始日
   * @type {string}
   * @memberof BaseTenantDetail
   */
  contractStartDate?: string
  /**
   * 契約終了日
   * @type {string}
   * @memberof BaseTenantDetail
   */
  contractEndDate?: string
}
/**
 * 縦計算チェックの結果
 * @export
 * @interface CalculationConsistencyResult
 */
export interface CalculationConsistencyResult {
  /**
   * 縦計算名
   * @type {string}
   * @memberof CalculationConsistencyResult
   */
  displayName: string
  /**
   * 整合性チェックの結果
   * @type {boolean}
   * @memberof CalculationConsistencyResult
   */
  result: boolean
}
/**
 * 算出ロジック
 * @export
 * @interface CalculationDSL
 */
export interface CalculationDSL {
  /**
   * DSL
   * @type {string}
   * @memberof CalculationDSL
   */
  dsl: string
  /**
   *
   * @type {CalculationItem}
   * @memberof CalculationDSL
   */
  calculationItem: CalculationItem
  /**
   *
   * @type {string}
   * @memberof CalculationDSL
   */
  id: string
  /**
   * 紐づく読み取り項目
   * @type {Array<OCRFormat>}
   * @memberof CalculationDSL
   */
  ocrFormats?: Array<OCRFormat>
}
/**
 *
 * @export
 * @interface CalculationDSLDefiniteValue
 */
export interface CalculationDSLDefiniteValue {
  /**
   * calculation_definite_value_id
   * @type {string}
   * @memberof CalculationDSLDefiniteValue
   */
  id?: string
  /**
   * calculation_item_id
   * @type {string}
   * @memberof CalculationDSLDefiniteValue
   */
  calculationItemId: string
  /**
   *
   * @type {number}
   * @memberof CalculationDSLDefiniteValue
   */
  definiteValue: number
  /**
   *
   * @type {Array<OCRFormatDefiniteValue>}
   * @memberof CalculationDSLDefiniteValue
   */
  ocrFormats?: Array<OCRFormatDefiniteValue>
}
/**
 * 算出項目作成時のエラー
 * @export
 * @interface CalculationDSLPostError
 */
export interface CalculationDSLPostError {
  /**
   *
   * @type {string}
   * @memberof CalculationDSLPostError
   */
  message: string
  /**
   *
   * @type {Array<CalculationDSLPostErrorItem>}
   * @memberof CalculationDSLPostError
   */
  errors: Array<CalculationDSLPostErrorItem>
}
/**
 *
 * @export
 * @interface CalculationDSLPostErrorItem
 */
export interface CalculationDSLPostErrorItem {
  /**
   *
   * @type {string}
   * @memberof CalculationDSLPostErrorItem
   */
  calculationId: string
  /**
   *
   * @type {DSLErrorCodeEnum}
   * @memberof CalculationDSLPostErrorItem
   */
  code: DSLErrorCodeEnum
  /**
   * フィード名(id, section_name, dsl, etc)
   * @type {string}
   * @memberof CalculationDSLPostErrorItem
   */
  field: string
  /**
   *
   * @type {DSLOrFormatResourceEnum}
   * @memberof CalculationDSLPostErrorItem
   */
  resource: DSLOrFormatResourceEnum
}

/**
 *
 * @export
 * @interface CalculationDSLPostRequestInner
 */
export interface CalculationDSLPostRequestInner {
  /**
   *
   * @type {string}
   * @memberof CalculationDSLPostRequestInner
   */
  calculationId: string
  /**
   *
   * @type {string}
   * @memberof CalculationDSLPostRequestInner
   */
  dsl: string
  /**
   *
   * @type {Array<OCRFormat>}
   * @memberof CalculationDSLPostRequestInner
   */
  ocrFormats?: Array<OCRFormat>
}
/**
 *
 * @export
 * @interface CalculationDSLsWithCalcMethod
 */
export interface CalculationDSLsWithCalcMethod {
  /**
   *
   * @type {Array<CalculationDSL>}
   * @memberof CalculationDSLsWithCalcMethod
   */
  dsls: Array<CalculationDSL>
  /**
   *
   * @type {CalculationMethod}
   * @memberof CalculationDSLsWithCalcMethod
   */
  calculationMethod: CalculationMethod | null
  /**
   * DSLまたは端数の最終更新日
   * @type {string}
   * @memberof CalculationDSLsWithCalcMethod
   */
  lastUpdatedAt: string | null
}
/**
 * 算出項目のグループ
 * @export
 * @interface CalculationGroup
 */
export interface CalculationGroup {
  /**
   *
   * @type {string}
   * @memberof CalculationGroup
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CalculationGroup
   */
  name: string
  /**
   *
   * @type {Array<CalculationItem>}
   * @memberof CalculationGroup
   */
  calculationItems: Array<CalculationItem>
}
/**
 * 算出項目
 * @export
 * @interface CalculationItem
 */
export interface CalculationItem {
  /**
   *
   * @type {string}
   * @memberof CalculationItem
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof CalculationItem
   */
  index: number
  /**
   *
   * @type {string}
   * @memberof CalculationItem
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CalculationItem
   */
  sendParameterName: string
  /**
   * 固定のDSL。持っている場合は編集不可。
   * @type {string}
   * @memberof CalculationItem
   */
  fixedDsl?: string | null
}
/**
 * 算出項目のカテゴリ名
 * @export
 * @enum {string}
 */

export const CalculationItemCategoryNameTypeEnum = {
  NetSales: 'net_sales',
  Visitor: 'visitor',
} as const

export type CalculationItemCategoryNameTypeEnum =
  (typeof CalculationItemCategoryNameTypeEnum)[keyof typeof CalculationItemCategoryNameTypeEnum]

/**
 * 端数処理と精度
 * @export
 * @interface CalculationMethod
 */
export interface CalculationMethod {
  /**
   *
   * @type {string}
   * @memberof CalculationMethod
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CalculationMethod
   */
  tenantId: string
  /**
   *
   * @type {CalculationRoundingTypeEnum}
   * @memberof CalculationMethod
   */
  roundingType: CalculationRoundingTypeEnum
  /**
   *
   * @type {number}
   * @memberof CalculationMethod
   */
  precision: number
}

/**
 * 端数処理更新時のリクエスト
 * @export
 * @interface CalculationMethodPatchRequest
 */
export interface CalculationMethodPatchRequest {
  /**
   *
   * @type {string}
   * @memberof CalculationMethodPatchRequest
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CalculationMethodPatchRequest
   */
  tenantId: string
  /**
   *
   * @type {CalculationRoundingTypeEnum}
   * @memberof CalculationMethodPatchRequest
   */
  roundingType: CalculationRoundingTypeEnum
  /**
   *
   * @type {number}
   * @memberof CalculationMethodPatchRequest
   */
  precision: number
}

/**
 * 端数処理
 * @export
 * @enum {string}
 */

export const CalculationRoundingTypeEnum = {
  Round: 'round',
  Truncate: 'truncate',
  RoundingUp: 'rounding_up',
} as const

export type CalculationRoundingTypeEnum =
  (typeof CalculationRoundingTypeEnum)[keyof typeof CalculationRoundingTypeEnum]

/**
 * DSL作成エラー時のコード
 * @export
 * @enum {string}
 */

export const DSLErrorCodeEnum = {
  CircuitReference: 'circuit_reference',
  ZeroDivide: 'zero_divide',
  OcrFormatNotFound: 'ocr_format_not_found',
  Invalid: 'invalid',
  Required: 'required',
  DivideByNotNumber: 'divide_by_not_number',
} as const

export type DSLErrorCodeEnum =
  (typeof DSLErrorCodeEnum)[keyof typeof DSLErrorCodeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const DSLOrFormatResourceEnum = {
  Dsl: 'dsl',
  Format: 'format',
} as const

export type DSLOrFormatResourceEnum =
  (typeof DSLOrFormatResourceEnum)[keyof typeof DSLOrFormatResourceEnum]

/**
 *
 * @export
 * @interface DefiniteValuePutRequestInner
 */
export interface DefiniteValuePutRequestInner {
  /**
   * register_id
   * @type {string}
   * @memberof DefiniteValuePutRequestInner
   */
  id: string
  /**
   *
   * @type {Array<CalculationDSLDefiniteValue>}
   * @memberof DefiniteValuePutRequestInner
   */
  calculationItems: Array<CalculationDSLDefiniteValue>
}
/**
 * emailが存在するか否か
 * @export
 * @interface EmailExistence
 */
export interface EmailExistence {
  /**
   *
   * @type {boolean}
   * @memberof EmailExistence
   */
  isExist: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const ErrorCodeEnum = {
  InternalServerError: 'internal_server_error',
  AlreadyExists: 'already_exists',
  NotFound: 'not_found',
  UnprocessableEntity: 'unprocessable_entity',
} as const

export type ErrorCodeEnum = (typeof ErrorCodeEnum)[keyof typeof ErrorCodeEnum]

/**
 * 完了したファネル - selecting_sales_date: 売上報告日選択 - uploading_images: 画像アップロード - sending_manual_input_values: 手入力値送信 - adding_register: レジ追加 - submitting_report: 報告完了
 * @export
 * @enum {string}
 */

export const FinishedFunnelEnum = {
  SelectingSalesDate: 'selecting_sales_date',
  UploadingImages: 'uploading_images',
  SendingManualInputValues: 'sending_manual_input_values',
  AddingRegister: 'adding_register',
  SubmittingReport: 'submitting_report',
} as const

export type FinishedFunnelEnum =
  (typeof FinishedFunnelEnum)[keyof typeof FinishedFunnelEnum]

/**
 * 403番エラー
 * @export
 * @interface ForbiddenError
 */
export interface ForbiddenError {
  /**
   *
   * @type {ForbiddenErrorCodeEnum}
   * @memberof ForbiddenError
   */
  code: ForbiddenErrorCodeEnum
  /**
   *
   * @type {string}
   * @memberof ForbiddenError
   */
  message: string
}

/**
 * 403番のエラーコード
 * @export
 * @enum {string}
 */

export const ForbiddenErrorCodeEnum = {
  NotAllowed: 'not_allowed',
} as const

export type ForbiddenErrorCodeEnum =
  (typeof ForbiddenErrorCodeEnum)[keyof typeof ForbiddenErrorCodeEnum]

/**
 * @type GetOrganizationsOrganizationCodeTenants200Response
 * @export
 */
export type GetOrganizationsOrganizationCodeTenants200Response =
  | Array<OrgTenant>
  | TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount

/**
 *
 * @export
 * @interface Member
 */
export interface Member {
  /**
   *
   * @type {string}
   * @memberof Member
   */
  id: string
  /**
   *
   * @type {RoleEnum}
   * @memberof Member
   */
  role: RoleEnum
  /**
   * メールアドレス
   * @type {string}
   * @memberof Member
   */
  email: string
  /**
   * メンバー名
   * @type {string}
   * @memberof Member
   */
  name: string
  /**
   * 担当店舗のリスト
   * @type {Array<BaseStore>}
   * @memberof Member
   */
  assignedStores: Array<BaseStore>
  /**
   * 全店舗OKの権限を持つか
   * @type {boolean}
   * @memberof Member
   */
  hasAllStoresPermission: boolean
  /**
   * メンバーの活性/非活性
   * @type {boolean}
   * @memberof Member
   */
  active: boolean
}

/**
 * メンバー編集時のリクエスト
 * @export
 * @interface MemberPatchRequest
 */
export interface MemberPatchRequest {
  /**
   *
   * @type {string}
   * @memberof MemberPatchRequest
   */
  id: string
  /**
   * メールアドレス
   * @type {string}
   * @memberof MemberPatchRequest
   */
  email?: string
  /**
   * メンバー名
   * @type {string}
   * @memberof MemberPatchRequest
   */
  name?: string
  /**
   *
   * @type {RoleEnum}
   * @memberof MemberPatchRequest
   */
  role?: RoleEnum
  /**
   * 担当店舗idのリスト
   * @type {Array<string>}
   * @memberof MemberPatchRequest
   */
  assignedStoreIds?: Array<string>
  /**
   * 全店舗OKの権限を持つか
   * @type {boolean}
   * @memberof MemberPatchRequest
   */
  hasAllStoresPermission?: boolean
  /**
   * メンバーの活性化/非活性化
   * @type {boolean}
   * @memberof MemberPatchRequest
   */
  active?: boolean
}

/**
 * メンバー作成時のリクエスト
 * @export
 * @interface MemberPostRequest
 */
export interface MemberPostRequest {
  /**
   * メールアドレス
   * @type {string}
   * @memberof MemberPostRequest
   */
  email: string
  /**
   * メンバー名
   * @type {string}
   * @memberof MemberPostRequest
   */
  name: string
  /**
   *
   * @type {RoleEnum}
   * @memberof MemberPostRequest
   */
  role: RoleEnum
  /**
   * 担当店舗idのリスト
   * @type {Array<string>}
   * @memberof MemberPostRequest
   */
  assignedStoreIds?: Array<string>
  /**
   * 全店舗OKの権限を持つか
   * @type {boolean}
   * @memberof MemberPostRequest
   */
  hasAllStoresPermission: boolean
}

/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {ErrorCodeEnum}
   * @memberof ModelError
   */
  code: ErrorCodeEnum
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string
}

/**
 * モジュール名
 * @export
 * @enum {string}
 */

export const ModuleEnum = {
  Oioi: 'OIOI',
  MallPro: 'MallPro',
} as const

export type ModuleEnum = (typeof ModuleEnum)[keyof typeof ModuleEnum]

/**
 * テナントに通知するメッセージ(レスポンス用)
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  id: string
  /**
   * 内容
   * @type {string}
   * @memberof Notification
   */
  content: string
  /**
   * 既読か
   * @type {boolean}
   * @memberof Notification
   */
  isReadByTenant: boolean
  /**
   * 作成した時間
   * @type {string}
   * @memberof Notification
   */
  createdAt: string
}
/**
 * テナントに通知するメッセージ(リクエスト用)
 * @export
 * @interface NotificationToTenant
 */
export interface NotificationToTenant {
  /**
   *
   * @type {string}
   * @memberof NotificationToTenant
   */
  salesDateId: string
  /**
   * 内容
   * @type {string}
   * @memberof NotificationToTenant
   */
  content: string
}
/**
 * OCRフォーマット(対応表を表現する) ReadItemにリネームする予定
 * @export
 * @interface OCRFormat
 */
export interface OCRFormat {
  /**
   *
   * @type {string}
   * @memberof OCRFormat
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof OCRFormat
   */
  readItem: string
  /**
   *
   * @type {boolean}
   * @memberof OCRFormat
   */
  isDuplicated: boolean
  /**
   *
   * @type {string}
   * @memberof OCRFormat
   */
  sectionName?: string
  /**
   *
   * @type {string}
   * @memberof OCRFormat
   */
  suffix?: string
  /**
   *
   * @type {string}
   * @memberof OCRFormat
   */
  prefix?: string
  /**
   *
   * @type {number}
   * @memberof OCRFormat
   */
  readTargetNumberColumn?: number
  /**
   *
   * @type {number}
   * @memberof OCRFormat
   */
  readTargetNumberLine?: number
}
/**
 *
 * @export
 * @interface OCRFormatDefiniteValue
 */
export interface OCRFormatDefiniteValue {
  /**
   * ocr_format_id
   * @type {string}
   * @memberof OCRFormatDefiniteValue
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof OCRFormatDefiniteValue
   */
  definiteValue: number
}
/**
 * 組織配下のテナント
 * @export
 * @interface OrgTenant
 */
export interface OrgTenant {
  /**
   *
   * @type {string}
   * @memberof OrgTenant
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof OrgTenant
   */
  name: string
  /**
   * 電話番号
   * @type {string}
   * @memberof OrgTenant
   */
  tel?: string
  /**
   * テナントコード
   * @type {string}
   * @memberof OrgTenant
   */
  code: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof OrgTenant
   */
  status: TenantStatusEnum
  /**
   * OCR有無
   * @type {boolean}
   * @memberof OrgTenant
   */
  isOcrRequired: boolean
  /**
   * status, dsl, ocr_formatの最終更新日時
   * @type {string}
   * @memberof OrgTenant
   */
  infoUpdatedAt: string
  /**
   * 最終テスト実施日時
   * @type {string}
   * @memberof OrgTenant
   */
  lastTestedAt: string | null
  /**
   * 研修実施日
   * @type {string}
   * @memberof OrgTenant
   */
  trainedAt?: string
  /**
   *
   * @type {TrialStatus}
   * @memberof OrgTenant
   */
  trialStatus: TrialStatus
  /**
   * 取引企業との紐づけ日時
   * @type {string}
   * @memberof OrgTenant
   */
  tenantOwnerCompanyAssociatedAt?: string
  /**
   *
   * @type {BaseStore}
   * @memberof OrgTenant
   */
  store: BaseStore
}

/**
 * 組織
 * @export
 * @interface Organization
 */
export interface Organization {
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  name: string
  /**
   * 組織コード
   * @type {string}
   * @memberof Organization
   */
  code: string
}
/**
 * 自身のプロフィール更新時リクエスト
 * @export
 * @interface ProfilePatchRequest
 */
export interface ProfilePatchRequest {
  /**
   * メンバー名
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  name: string
}
/**
 * salesDateのステータス(詳細はDocumentを参照) - created: 報告待ち - submitted: 報告済み - registered: 報告済み - completed: 報告済み - finalized_report_submitted: 確定値入力済み - finalized_report_synced: csv(確定値含む)連携済み
 * @export
 * @enum {string}
 */

export const ProgressStatusEnum = {
  Created: 'created',
  Submitted: 'submitted',
  Registered: 'registered',
  Completed: 'completed',
  FinalizedReportSubmitted: 'finalized_report_submitted',
  FinalizedReportSynced: 'finalized_report_synced',
} as const

export type ProgressStatusEnum =
  (typeof ProgressStatusEnum)[keyof typeof ProgressStatusEnum]

/**
 * 再送信画像
 * @export
 * @interface ReceiptImage
 */
export interface ReceiptImage {
  /**
   * オリジナルサイズのconcatした画像URI
   * @type {string}
   * @memberof ReceiptImage
   */
  concatOriginal?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ReceiptImage
   */
  separatedOriginal: Array<string>
  /**
   * リサイズ済みのconcatした画像URI
   * @type {string}
   * @memberof ReceiptImage
   */
  resized?: string
  /**
   * pdfかどうか
   * @type {boolean}
   * @memberof ReceiptImage
   */
  isPdf: boolean
}
/**
 * 売上の報告ステータス - waiting: 報告待ち - leftHalfway: 途中離脱 - reported: 報告済み - imageResent: 画像再送信済み - finalizedReportSubmitted: 確定値入力済み - finalizedReportSynced: csv(確定値含む)連携済み
 * @export
 * @enum {string}
 */

export const ReportStatusEnum = {
  Waiting: 'waiting',
  LeftHalfway: 'leftHalfway',
  Reported: 'reported',
  ImageResent: 'imageResent',
  FinalizedReportSubmitted: 'finalizedReportSubmitted',
  FinalizedReportSynced: 'finalizedReportSynced',
} as const

export type ReportStatusEnum =
  (typeof ReportStatusEnum)[keyof typeof ReportStatusEnum]

/**
 * 報告形態
 * @export
 * @enum {string}
 */

export const ReportTypeEnum = {
  Cct: 'cct',
  Offline: 'offline',
} as const

export type ReportTypeEnum =
  (typeof ReportTypeEnum)[keyof typeof ReportTypeEnum]

/**
 * メンバーの権限 - admin: 全体管理者 - admin_store_manager: 施設管理者 - admin_sales_report_manager: 売上担当者・責任者 - editor_sales_report_manager: 売上担当者・作業者 - editor_store_manager_in_operation_center: 施設担当者･OPC(三井不用) - editor_store_manager: 施設担当者 - viewer: 閲覧者
 * @export
 * @enum {string}
 */

export const RoleEnum = {
  Admin: 'admin',
  AdminStoreManager: 'admin_store_manager',
  AdminSalesReportManager: 'admin_sales_report_manager',
  EditorSalesReportManager: 'editor_sales_report_manager',
  EditorStoreManagerInOperationCenter:
    'editor_store_manager_in_operation_center',
  EditorStoreManager: 'editor_store_manager',
  Viewer: 'viewer',
} as const

export type RoleEnum = (typeof RoleEnum)[keyof typeof RoleEnum]

/**
 * 売上報告日の範囲
 * @export
 * @interface SalesDateRange
 */
export interface SalesDateRange {
  /**
   * 開始日
   * @type {string}
   * @memberof SalesDateRange
   */
  startDate: string
  /**
   * 終了日
   * @type {string}
   * @memberof SalesDateRange
   */
  endDate: string
}
/**
 * 1テナントの1日分の売上報告結果
 * @export
 * @interface SalesReport
 */
export interface SalesReport {
  /**
   *
   * @type {string}
   * @memberof SalesReport
   */
  id: string
  /**
   * 売上報告の日付
   * @type {string}
   * @memberof SalesReport
   */
  date: string
  /**
   * 手入力値とOCR値の比較結果
   * @type {boolean}
   * @memberof SalesReport
   */
  extractedConsistencyResult?: boolean
  /**
   * 全ての手入力値が0
   * @type {boolean}
   * @memberof SalesReport
   */
  isAllManualInputValuesZero?: boolean
  /**
   *
   * @type {ReportStatusEnum}
   * @memberof SalesReport
   */
  reportStatus: ReportStatusEnum
  /**
   * 報告者のメモ
   * @type {string}
   * @memberof SalesReport
   */
  reportMessage?: string
  /**
   * 管理者のメモ
   * @type {string}
   * @memberof SalesReport
   */
  manageMessage?: string
  /**
   * レジごとの売上報告結果
   * @type {Array<SalesReportRegister>}
   * @memberof SalesReport
   */
  registers: Array<SalesReportRegister>
  /**
   *
   * @type {Array<TenantResentRegister>}
   * @memberof SalesReport
   */
  resentRegisters?: Array<TenantResentRegister>
}

/**
 * 算出ロジック
 * @export
 * @interface SalesReportCalculationDSL
 */
export interface SalesReportCalculationDSL {
  /**
   * CalculationDSL ID
   * @type {string}
   * @memberof SalesReportCalculationDSL
   */
  id: string
  /**
   * DSL。関連する算出項目と読取項目はそれぞれ `calculation_item` と `ocr_format_results.ocr_format` に含まれている。
   * @type {string}
   * @memberof SalesReportCalculationDSL
   */
  dsl: string
}
/**
 * レジ1台ごとかつ算出項目1つごとの対応表、dsl、結果、手入力チェック。
 * @export
 * @interface SalesReportCalculationResult
 */
export interface SalesReportCalculationResult {
  /**
   *
   * @type {CalculationItem}
   * @memberof SalesReportCalculationResult
   */
  calculationItem: CalculationItem
  /**
   *
   * @type {SalesReportCalculationDSL}
   * @memberof SalesReportCalculationResult
   */
  calculationDsl: SalesReportCalculationDSL
  /**
   * 読取項目ごとの結果(手入力値、OCR値、確定値)の配列
   * @type {Array<SalesReportOCRFormatResult>}
   * @memberof SalesReportCalculationResult
   */
  ocrFormatResults: Array<SalesReportOCRFormatResult>
  /**
   *
   * @type {SalesReportResult}
   * @memberof SalesReportCalculationResult
   */
  results: SalesReportResult
  /**
   * 手入力チェックの結果
   * @type {boolean}
   * @memberof SalesReportCalculationResult
   */
  inputConsistencyResult?: boolean
}
/**
 * 売上報告メモ
 * @export
 * @interface SalesReportMessage
 */
export interface SalesReportMessage {
  /**
   * 内容
   * @type {string}
   * @memberof SalesReportMessage
   */
  message: string
}
/**
 * 対応表と読取項目ごとのOCR値、手入力値、確定値
 * @export
 * @interface SalesReportOCRFormatResult
 */
export interface SalesReportOCRFormatResult {
  /**
   *
   * @type {OCRFormat}
   * @memberof SalesReportOCRFormatResult
   */
  ocrFormat: OCRFormat
  /**
   *
   * @type {SalesReportResult}
   * @memberof SalesReportOCRFormatResult
   */
  result: SalesReportResult
}
/**
 * レジ1台の売上報告結果
 * @export
 * @interface SalesReportRegister
 */
export interface SalesReportRegister {
  /**
   *
   * @type {string}
   * @memberof SalesReportRegister
   */
  id: string
  /**
   * レジのレコードの作成が早い順のindex
   * @type {number}
   * @memberof SalesReportRegister
   */
  index: number
  /**
   *
   * @type {ReceiptImage}
   * @memberof SalesReportRegister
   */
  receiptImage: ReceiptImage | null
  /**
   * 算出項目の結果の配列
   * @type {Array<SalesReportCalculationResult>}
   * @memberof SalesReportRegister
   */
  calculationResults: Array<SalesReportCalculationResult>
  /**
   * 縦計算チェックの結果の配列
   * @type {Array<CalculationConsistencyResult>}
   * @memberof SalesReportRegister
   */
  calculationConsistencyResults: Array<CalculationConsistencyResult>
  /**
   * レジの更新時間
   * @type {string}
   * @memberof SalesReportRegister
   */
  updatedAt: string
  /**
   *
   * @type {TenantRegister}
   * @memberof SalesReportRegister
   */
  tenantRegister?: TenantRegister
}
/**
 * 算出項目と読取項目それぞれのOCR値、手入力値、確定値
 * @export
 * @interface SalesReportResult
 */
export interface SalesReportResult {
  /**
   * OCR値
   * @type {number}
   * @memberof SalesReportResult
   */
  ocrValue: number | null
  /**
   * 手入力値
   * @type {number}
   * @memberof SalesReportResult
   */
  manualInputValue: number | null
  /**
   * 確定値
   * @type {number}
   * @memberof SalesReportResult
   */
  definiteValue?: number
  /**
   * calculation_definite_values
   * @type {string}
   * @memberof SalesReportResult
   */
  definiteId?: string
}
/**
 * SalesReportとTenant
 * @export
 * @interface SalesReportWithTenant
 */
export interface SalesReportWithTenant {
  /**
   *
   * @type {string}
   * @memberof SalesReportWithTenant
   */
  id: string
  /**
   * 売上報告の日付
   * @type {string}
   * @memberof SalesReportWithTenant
   */
  date: string
  /**
   * 手入力値とOCR値の比較結果
   * @type {boolean}
   * @memberof SalesReportWithTenant
   */
  extractedConsistencyResult?: boolean
  /**
   * 全ての手入力値が0
   * @type {boolean}
   * @memberof SalesReportWithTenant
   */
  isAllManualInputValuesZero?: boolean
  /**
   *
   * @type {ReportStatusEnum}
   * @memberof SalesReportWithTenant
   */
  reportStatus: ReportStatusEnum
  /**
   * 報告者のメモ
   * @type {string}
   * @memberof SalesReportWithTenant
   */
  reportMessage?: string
  /**
   * 管理者のメモ
   * @type {string}
   * @memberof SalesReportWithTenant
   */
  manageMessage?: string
  /**
   * レジごとの売上報告結果
   * @type {Array<SalesReportRegister>}
   * @memberof SalesReportWithTenant
   */
  registers: Array<SalesReportRegister>
  /**
   *
   * @type {Array<TenantResentRegister>}
   * @memberof SalesReportWithTenant
   */
  resentRegisters?: Array<TenantResentRegister>
  /**
   *
   * @type {TenantDetail}
   * @memberof SalesReportWithTenant
   */
  tenantDetail?: TenantDetail
  /**
   *
   * @type {TenantInfoForDetail}
   * @memberof SalesReportWithTenant
   */
  tenant: TenantInfoForDetail
  /**
   *
   * @type {Array<TenantRegister>}
   * @memberof SalesReportWithTenant
   */
  tenantRegisters?: Array<TenantRegister>
}

/**
 * 店舗
 * @export
 * @interface Store
 */
export interface Store {
  /**
   *
   * @type {string}
   * @memberof Store
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Store
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Store
   */
  code: string
  /**
   *
   * @type {TenantSummary}
   * @memberof Store
   */
  tenantSummary: TenantSummary
}
/**
 * ストアの売上報告状況
 * @export
 * @interface StoreSalesReportSummary
 */
export interface StoreSalesReportSummary {
  /**
   * 報告されるべきテナントの数(submitted + missing の値)
   * @type {number}
   * @memberof StoreSalesReportSummary
   */
  total: number
  /**
   * 報告されたテナントの数
   * @type {number}
   * @memberof StoreSalesReportSummary
   */
  submitted: number
  /**
   * 報告漏れのテナントの数
   * @type {number}
   * @memberof StoreSalesReportSummary
   */
  missing: number
  /**
   *
   * @type {BaseStore}
   * @memberof StoreSalesReportSummary
   */
  store: BaseStore
  /**
   * 売上報告の日付
   * @type {string}
   * @memberof StoreSalesReportSummary
   */
  date: string
  /**
   *
   * @type {Array<Total>}
   * @memberof StoreSalesReportSummary
   */
  totals: Array<Total>
}
/**
 * ストア配下のテナント
 * @export
 * @interface StoreTenant
 */
export interface StoreTenant {
  /**
   *
   * @type {string}
   * @memberof StoreTenant
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof StoreTenant
   */
  name: string
  /**
   * 電話番号
   * @type {string}
   * @memberof StoreTenant
   */
  tel?: string
  /**
   * テナントコード
   * @type {string}
   * @memberof StoreTenant
   */
  code: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof StoreTenant
   */
  status: TenantStatusEnum
  /**
   * OCR有無
   * @type {boolean}
   * @memberof StoreTenant
   */
  isOcrRequired: boolean
  /**
   * status, dsl, ocr_formatの最終更新日時
   * @type {string}
   * @memberof StoreTenant
   */
  infoUpdatedAt: string
  /**
   * 最終テスト実施日時
   * @type {string}
   * @memberof StoreTenant
   */
  lastTestedAt: string | null
  /**
   * 研修実施日
   * @type {string}
   * @memberof StoreTenant
   */
  trainedAt?: string
  /**
   *
   * @type {TrialStatus}
   * @memberof StoreTenant
   */
  trialStatus: TrialStatus
  /**
   * 取引企業との紐づけ日時
   * @type {string}
   * @memberof StoreTenant
   */
  tenantOwnerCompanyAssociatedAt?: string
  /**
   * 契約者コード
   * @type {string}
   * @memberof StoreTenant
   */
  contractorCode?: string
  /**
   * 区画
   * @type {string}
   * @memberof StoreTenant
   */
  section?: string
  /**
   * 契約開始日
   * @type {string}
   * @memberof StoreTenant
   */
  contractStartDate?: string
  /**
   * 契約終了日
   * @type {string}
   * @memberof StoreTenant
   */
  contractEndDate?: string
  /**
   * 代表レジNO
   * @type {string}
   * @memberof StoreTenant
   */
  tenantRegisterNumber?: string
}

/**
 * テナント
 * @export
 * @interface Tenant
 */
export interface Tenant {
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  name: string
  /**
   * 電話番号
   * @type {string}
   * @memberof Tenant
   */
  tel?: string
  /**
   * テナントコード
   * @type {string}
   * @memberof Tenant
   */
  code: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof Tenant
   */
  status: TenantStatusEnum
  /**
   * OCR有無
   * @type {boolean}
   * @memberof Tenant
   */
  isOcrRequired: boolean
  /**
   * status, dsl, ocr_formatの最終更新日時
   * @type {string}
   * @memberof Tenant
   */
  infoUpdatedAt: string
  /**
   * 最終テスト実施日時
   * @type {string}
   * @memberof Tenant
   */
  lastTestedAt: string | null
  /**
   * 研修実施日
   * @type {string}
   * @memberof Tenant
   */
  trainedAt?: string
  /**
   *
   * @type {TrialStatus}
   * @memberof Tenant
   */
  trialStatus: TrialStatus
  /**
   * 取引企業との紐づけ日時
   * @type {string}
   * @memberof Tenant
   */
  tenantOwnerCompanyAssociatedAt?: string
}

/**
 * テナント詳細
 * @export
 * @interface TenantDetail
 */
export interface TenantDetail {
  /**
   * 担当者の名前
   * @type {string}
   * @memberof TenantDetail
   */
  staffName?: string
  /**
   * 担当者の電話番号
   * @type {string}
   * @memberof TenantDetail
   */
  staffTel?: string
  /**
   * 営業開始時間
   * @type {string}
   * @memberof TenantDetail
   */
  openingTime?: string
  /**
   * 営業終了時間
   * @type {string}
   * @memberof TenantDetail
   */
  closingTime?: string
  /**
   * 契約開始日
   * @type {string}
   * @memberof TenantDetail
   */
  contractStartDate?: string
  /**
   * 契約終了日
   * @type {string}
   * @memberof TenantDetail
   */
  contractEndDate?: string
  /**
   *
   * @type {ReportTypeEnum}
   * @memberof TenantDetail
   */
  reportType?: ReportTypeEnum
  /**
   * 契約者コード
   * @type {string}
   * @memberof TenantDetail
   */
  contractorCode?: string
  /**
   * 部門コード
   * @type {string}
   * @memberof TenantDetail
   */
  departmentCode?: string
  /**
   * 区画
   * @type {string}
   * @memberof TenantDetail
   */
  section?: string
  /**
   * WebページのURL
   * @type {string}
   * @memberof TenantDetail
   */
  url?: string
  /**
   * テナントに関する個別メモ
   * @type {string}
   * @memberof TenantDetail
   */
  note?: string
  /**
   * テナント詳細の更新時間
   * @type {string}
   * @memberof TenantDetail
   */
  updatedAt?: string
}

/**
 * テナント詳細とテナント(OPAさんのみテナントレジ情報も含む)
 * @export
 * @interface TenantDetailWithTenant
 */
export interface TenantDetailWithTenant {
  /**
   *
   * @type {TenantDetail}
   * @memberof TenantDetailWithTenant
   */
  tenantDetail?: TenantDetail
  /**
   *
   * @type {TenantInfoForDetail}
   * @memberof TenantDetailWithTenant
   */
  tenant: TenantInfoForDetail
  /**
   *
   * @type {Array<TenantRegister>}
   * @memberof TenantDetailWithTenant
   */
  tenantRegisters?: Array<TenantRegister>
}
/**
 * テナント詳細情報新規追加リクエスト(OPAさんはテナントレジも登録)
 * @export
 * @interface TenantDetailsPostRequest
 */
export interface TenantDetailsPostRequest {
  /**
   *
   * @type {TenantDetail}
   * @memberof TenantDetailsPostRequest
   */
  tenantDetail: TenantDetail
  /**
   *
   * @type {UpdatedTenantRegisters}
   * @memberof TenantDetailsPostRequest
   */
  tenantRegisters?: UpdatedTenantRegisters
}
/**
 * テナント詳細で使うテナントの一部の情報
 * @export
 * @interface TenantInfoForDetail
 */
export interface TenantInfoForDetail {
  /**
   * tenant_id
   * @type {string}
   * @memberof TenantInfoForDetail
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof TenantInfoForDetail
   */
  name: string
  /**
   * テナントコード
   * @type {string}
   * @memberof TenantInfoForDetail
   */
  code: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof TenantInfoForDetail
   */
  status: TenantStatusEnum
  /**
   * status, dsl, ocr_formatの最終更新日時
   * @type {string}
   * @memberof TenantInfoForDetail
   */
  infoUpdatedAt: string
  /**
   * 研修実施日
   * @type {string}
   * @memberof TenantInfoForDetail
   */
  trainedAt?: string
}

/**
 * 取引企業
 * @export
 * @interface TenantOwnerCompany
 */
export interface TenantOwnerCompany {
  /**
   *
   * @type {string}
   * @memberof TenantOwnerCompany
   */
  id: string
  /**
   * 取引企業会社名
   * @type {string}
   * @memberof TenantOwnerCompany
   */
  name: string
  /**
   * 取引企業コード
   * @type {string}
   * @memberof TenantOwnerCompany
   */
  code: string
  /**
   * 取引企業の利用中/停止中
   * @type {boolean}
   * @memberof TenantOwnerCompany
   */
  active?: boolean
  /**
   * 取引企業に紐づくテナントの数
   * @type {number}
   * @memberof TenantOwnerCompany
   */
  numberOfTenants?: number
  /**
   * 取引企業の最終更新日
   * @type {string}
   * @memberof TenantOwnerCompany
   */
  updatedAt?: string
}
/**
 * 取引企業の更新リクエスト単体
 * @export
 * @interface TenantOwnerCompanyPatchRequest
 */
export interface TenantOwnerCompanyPatchRequest {
  /**
   *
   * @type {string}
   * @memberof TenantOwnerCompanyPatchRequest
   */
  id: string
  /**
   * 取引企業名
   * @type {string}
   * @memberof TenantOwnerCompanyPatchRequest
   */
  name?: string
  /**
   * 取引企業コード
   * @type {string}
   * @memberof TenantOwnerCompanyPatchRequest
   */
  code?: string
  /**
   * 取引企業の利用中/停止中
   * @type {boolean}
   * @memberof TenantOwnerCompanyPatchRequest
   */
  active?: boolean
}
/**
 * 取引企業の作成リクエスト単体
 * @export
 * @interface TenantOwnerCompanyPostRequest
 */
export interface TenantOwnerCompanyPostRequest {
  /**
   * 取引企業名
   * @type {string}
   * @memberof TenantOwnerCompanyPostRequest
   */
  name: string
  /**
   * 取引企業コード
   * @type {string}
   * @memberof TenantOwnerCompanyPostRequest
   */
  code: string
}
/**
 * レジNoとレジ区分の組み合わせ。代表レジが先頭でその後はNOで昇順。
 * @export
 * @interface TenantRegister
 */
export interface TenantRegister {
  /**
   *
   * @type {string}
   * @memberof TenantRegister
   */
  id?: string
  /**
   * レジNO
   * @type {string}
   * @memberof TenantRegister
   */
  registerNumber: string
  /**
   *
   * @type {TenantRegisterCategoryEnum}
   * @memberof TenantRegister
   */
  registerCategory: TenantRegisterCategoryEnum
}

/**
 * レジ区分(代表レジorその他レジ)
 * @export
 * @enum {string}
 */

export const TenantRegisterCategoryEnum = {
  RepresentativeRegister: 'representative_register',
  OtherRegisters: 'other_registers',
} as const

export type TenantRegisterCategoryEnum =
  (typeof TenantRegisterCategoryEnum)[keyof typeof TenantRegisterCategoryEnum]

/**
 * レジごとの売上報告詳細(OPAさんのみテナントレジ情報も含む)
 * @export
 * @interface TenantRegisterSalesReport
 */
export interface TenantRegisterSalesReport {
  /**
   * sales_date_register_id
   * @type {string}
   * @memberof TenantRegisterSalesReport
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof TenantRegisterSalesReport
   */
  index: number
  /**
   *
   * @type {ReceiptImage}
   * @memberof TenantRegisterSalesReport
   */
  receiptImage: ReceiptImage | null
  /**
   *
   * @type {Array<SalesReportCalculationResult>}
   * @memberof TenantRegisterSalesReport
   */
  calculationResults: Array<SalesReportCalculationResult>
  /**
   * 縦計算チェックの結果(ないこともある)
   * @type {boolean}
   * @memberof TenantRegisterSalesReport
   */
  consistencyResult?: boolean
  /**
   * レジの更新時間
   * @type {string}
   * @memberof TenantRegisterSalesReport
   */
  updatedAt: string
  /**
   *
   * @type {TenantRegister}
   * @memberof TenantRegisterSalesReport
   */
  tenantRegister?: TenantRegister
}
/**
 * 再送信用レジ
 * @export
 * @interface TenantResentRegister
 */
export interface TenantResentRegister {
  /**
   * sales_date_register_id
   * @type {string}
   * @memberof TenantResentRegister
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof TenantResentRegister
   */
  index: number
  /**
   *
   * @type {ReceiptImage}
   * @memberof TenantResentRegister
   */
  receiptImage: ReceiptImage | null
  /**
   * 報告者の再送信メモ
   * @type {string}
   * @memberof TenantResentRegister
   */
  resentMessage?: string
  /**
   * 再送信用レジの更新時間
   * @type {string}
   * @memberof TenantResentRegister
   */
  updatedAt: string
}
/**
 * テナントの売上報告
 * @export
 * @interface TenantSalesReport
 */
export interface TenantSalesReport {
  /**
   * tenants_sales_date_id
   * @type {string}
   * @memberof TenantSalesReport
   */
  id: string
  /**
   * 報告者のメモ
   * @type {string}
   * @memberof TenantSalesReport
   */
  reportMessage?: string
  /**
   * 管理者のメモ
   * @type {string}
   * @memberof TenantSalesReport
   */
  manageMessage?: string
  /**
   *
   * @type {Array<Notification>}
   * @memberof TenantSalesReport
   */
  notifications?: Array<Notification>
  /**
   *
   * @type {Tenant}
   * @memberof TenantSalesReport
   */
  tenant: Tenant
  /**
   *
   * @type {TenantDetail}
   * @memberof TenantSalesReport
   */
  tenantDetail?: TenantDetail
  /**
   *
   * @type {Array<TenantRegisterSalesReport>}
   * @memberof TenantSalesReport
   */
  registers: Array<TenantRegisterSalesReport>
  /**
   *
   * @type {Array<TenantResentRegister>}
   * @memberof TenantSalesReport
   */
  resentRegisters?: Array<TenantResentRegister>
}
/**
 * テナントの売上報告状況
 * @export
 * @interface TenantSalesReportSummary
 */
export interface TenantSalesReportSummary {
  /**
   * tenants_sales_date_id
   * @type {string}
   * @memberof TenantSalesReportSummary
   */
  id: string
  /**
   *
   * @type {Tenant}
   * @memberof TenantSalesReportSummary
   */
  tenant: Tenant
  /**
   *
   * @type {string}
   * @memberof TenantSalesReportSummary
   */
  date: string
  /**
   * 手入力値とOCR値の比較結果
   * @type {boolean}
   * @memberof TenantSalesReportSummary
   */
  extractedConsistencyResult?: boolean
  /**
   * 全ての手入力値が0
   * @type {boolean}
   * @memberof TenantSalesReportSummary
   */
  isAllManualInputValuesZero?: boolean
  /**
   *
   * @type {ReportStatusEnum}
   * @memberof TenantSalesReportSummary
   */
  reportStatus: ReportStatusEnum
  /**
   * 報告者のメモ
   * @type {string}
   * @memberof TenantSalesReportSummary
   */
  reportMessage?: string
  /**
   * 管理者のメモ
   * @type {string}
   * @memberof TenantSalesReportSummary
   */
  manageMessage?: string
  /**
   * メッセージを通知しているかどうか
   * @type {boolean}
   * @memberof TenantSalesReportSummary
   */
  isNotificationSent?: boolean
  /**
   *
   * @type {Array<Total>}
   * @memberof TenantSalesReportSummary
   */
  totals: Array<Total>
  /**
   * レジ台数
   * @type {number}
   * @memberof TenantSalesReportSummary
   */
  countRegisters?: number
  /**
   *
   * @type {FinishedFunnelEnum}
   * @memberof TenantSalesReportSummary
   */
  lastFinishedFunnel?: FinishedFunnelEnum
  /**
   * 最終作業時間
   * @type {string}
   * @memberof TenantSalesReportSummary
   */
  lastActionTime?: string
}

/**
 *
 * @export
 * @enum {string}
 */

export const TenantStatusEnum = {
  Ready: 'ready',
  NotReady: 'not_ready',
  Closed: 'closed',
} as const

export type TenantStatusEnum =
  (typeof TenantStatusEnum)[keyof typeof TenantStatusEnum]

/**
 * テナント情報のサマリー
 * @export
 * @interface TenantSummary
 */
export interface TenantSummary {
  /**
   * 店舗に属する全てのテナント数
   * @type {number}
   * @memberof TenantSummary
   */
  totalCount: number
  /**
   * 利用中のテナント数
   * @type {number}
   * @memberof TenantSummary
   */
  readyCount: number
  /**
   * OCR有効なテナント数
   * @type {number}
   * @memberof TenantSummary
   */
  ocrEnabledCount: number
  /**
   *
   * @type {number}
   * @memberof TenantSummary
   */
  ocrDisabledCount: number
}
/**
 *
 * @export
 * @interface TenantWithStoreAndTenantOwnerCompany
 */
export interface TenantWithStoreAndTenantOwnerCompany {
  /**
   *
   * @type {BaseTenant}
   * @memberof TenantWithStoreAndTenantOwnerCompany
   */
  tenant: BaseTenant
  /**
   *
   * @type {BaseStore}
   * @memberof TenantWithStoreAndTenantOwnerCompany
   */
  store: BaseStore
  /**
   *
   * @type {TenantOwnerCompany}
   * @memberof TenantWithStoreAndTenantOwnerCompany
   */
  tenantOwnerCompany?: TenantOwnerCompany
  /**
   * テナントと店舗の紐づけ日
   * @type {string}
   * @memberof TenantWithStoreAndTenantOwnerCompany
   */
  tenantAssociatedAt?: string
}
/**
 * テナントへ取引企業を紐づける、取引企業の紐付けを外す更新リクエスト
 * @export
 * @interface TenantsOfTenantOwnerCompanyPatchRequest
 */
export interface TenantsOfTenantOwnerCompanyPatchRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof TenantsOfTenantOwnerCompanyPatchRequest
   */
  add?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof TenantsOfTenantOwnerCompanyPatchRequest
   */
  remove?: Array<string>
}
/**
 *
 * @export
 * @interface TenantsPatchRequestInner
 */
export interface TenantsPatchRequestInner {
  /**
   * 契約者コード
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  contractorCode?: string
  /**
   * 区画
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  section?: string
  /**
   * 契約開始日
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  contractStartDate?: string
  /**
   * 契約終了日
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  contractEndDate?: string
  /**
   *
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  id: string
  /**
   * テナントコード
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  code?: string
  /**
   * テナント名
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  name?: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof TenantsPatchRequestInner
   */
  status?: TenantStatusEnum
  /**
   *
   * @type {boolean}
   * @memberof TenantsPatchRequestInner
   */
  isOcrRequired?: boolean
  /**
   * QRコードをダウンロードしたかどうか
   * @type {boolean}
   * @memberof TenantsPatchRequestInner
   */
  isQrPrinted?: boolean
  /**
   * 電話番号
   * @type {string}
   * @memberof TenantsPatchRequestInner
   */
  tel?: string
}

/**
 *
 * @export
 * @interface TenantsPostRequestInner
 */
export interface TenantsPostRequestInner {
  /**
   * 契約者コード
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  contractorCode?: string
  /**
   * 区画
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  section?: string
  /**
   * 契約開始日
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  contractStartDate?: string
  /**
   * 契約終了日
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  contractEndDate?: string
  /**
   * テナントコード
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  code: string
  /**
   * テナント名
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  name: string
  /**
   *
   * @type {TenantStatusEnum}
   * @memberof TenantsPostRequestInner
   */
  status: TenantStatusEnum
  /**
   * 代表レジNO(OPAさんのみ)
   * @type {string}
   * @memberof TenantsPostRequestInner
   */
  tenantRegisterNumber?: string
}

/**
 *
 * @export
 * @interface TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount
 */
export interface TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount {
  /**
   *
   * @type {Array<TenantWithStoreAndTenantOwnerCompany>}
   * @memberof TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount
   */
  tenantsWithStoreAndTenantOwnerCompany: Array<TenantWithStoreAndTenantOwnerCompany>
  /**
   * 取引企業に紐付け済みのテナントの数
   * @type {number}
   * @memberof TenantsWithStoreAndTenantOwnerCompanyAndAssociatedCount
   */
  associatedTenantsCount: number
}
/**
 * 各算出項目の合計値
 * @export
 * @interface Total
 */
export interface Total {
  /**
   *
   * @type {CalculationItemCategoryNameTypeEnum}
   * @memberof Total
   */
  calculationItemCategoryName: CalculationItemCategoryNameTypeEnum
  /**
   * 手入力値の合計値
   * @type {number}
   * @memberof Total
   */
  manualValue?: number
  /**
   * 確定値の合計値
   * @type {number}
   * @memberof Total
   */
  finalizedValue?: number
  /**
   * 更新日時
   * @type {string}
   * @memberof Total
   */
  updatedAt?: string
}

/**
 * 読取りテストのステータス
 * @export
 * @enum {string}
 */

export const TrialStatus = {
  NoResult: 'no_result',
  Ok: 'ok',
  Ng: 'ng',
} as const

export type TrialStatus = (typeof TrialStatus)[keyof typeof TrialStatus]

/**
 * 401(認証)番エラー
 * @export
 * @interface UnauthorizedError
 */
export interface UnauthorizedError {
  /**
   *
   * @type {UnauthorizedErrorCodeEnum}
   * @memberof UnauthorizedError
   */
  code: UnauthorizedErrorCodeEnum
  /**
   *
   * @type {string}
   * @memberof UnauthorizedError
   */
  message: string
}

/**
 * 401(認証)番のエラーコード
 * @export
 * @enum {string}
 */

export const UnauthorizedErrorCodeEnum = {
  InvalidToken: 'invalid_token',
  ExpiredToken: 'expired_token',
  ResourceNotExist: 'resource_not_exist',
} as const

export type UnauthorizedErrorCodeEnum =
  (typeof UnauthorizedErrorCodeEnum)[keyof typeof UnauthorizedErrorCodeEnum]

/**
 * 422番エラー
 * @export
 * @interface UnprocesableError
 */
export interface UnprocesableError {
  /**
   *
   * @type {string}
   * @memberof UnprocesableError
   */
  message: string
  /**
   *
   * @type {Array<UnprocesableErrorItem>}
   * @memberof UnprocesableError
   */
  errors: Array<UnprocesableErrorItem>
}
/**
 *
 * @export
 * @interface UnprocesableErrorItem
 */
export interface UnprocesableErrorItem {
  /**
   *
   * @type {string}
   * @memberof UnprocesableErrorItem
   */
  field: string
  /**
   *
   * @type {ValueErrorCodeEnum}
   * @memberof UnprocesableErrorItem
   */
  code: ValueErrorCodeEnum
}

/**
 * 売上報告日IDのリストと、どの進捗ステータスに変更したいか
 * @export
 * @interface UpdateStatuses
 */
export interface UpdateStatuses {
  /**
   * 売上報告日IDのリスト
   * @type {Array<string>}
   * @memberof UpdateStatuses
   */
  salesDateIds: Array<string>
  /**
   *
   * @type {ProgressStatusEnum}
   * @memberof UpdateStatuses
   */
  progressStatus: ProgressStatusEnum
}

/**
 * テナントレジ情報更新
 * @export
 * @interface UpdatedTenantRegisters
 */
export interface UpdatedTenantRegisters {
  /**
   *
   * @type {Array<TenantRegister>}
   * @memberof UpdatedTenantRegisters
   */
  updatedRegisters?: Array<TenantRegister>
  /**
   * 非アクティブ化するテナントレジIDのリスト
   * @type {Array<string>}
   * @memberof UpdatedTenantRegisters
   */
  deletedIds?: Array<string>
}
/**
 *
 * @export
 * @enum {string}
 */

export const ValueErrorCodeEnum = {
  Required: 'required',
  Invalid: 'invalid',
  AlreadyExists: 'already_exists',
} as const

export type ValueErrorCodeEnum =
  (typeof ValueErrorCodeEnum)[keyof typeof ValueErrorCodeEnum]

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCode',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織に紐づくメンバーの一括取得
     * @summary 組織のメンバー一括取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeMembers: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeMembers',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/members`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/store_sales_report_summaries`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStores',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/stores`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales_reports`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (reportStatus) {
        localVarQueryParameter['report_status'] = reportStatus
      }

      if (aggregatedResult) {
        localVarQueryParameter['aggregated_result'] = aggregatedResult
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
      async (
        organizationCode: string,
        storeCode: string,
        startDate?: string,
        endDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (salesDate !== undefined) {
        localVarQueryParameter['sales_date'] =
          (salesDate as any) instanceof Date
            ? (salesDate as any).toISOString().substring(0, 10)
            : salesDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/calculation_dsl`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (tenantOwnerCompanyCodeOrName !== undefined) {
        localVarQueryParameter['tenant_owner_company_code_or_name'] =
          tenantOwnerCompanyCodeOrName
      }

      if (tenantOwnerCompanyIsActive !== undefined) {
        localVarQueryParameter['tenant_owner_company_is_active'] =
          tenantOwnerCompanyIsActive
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode:
      async (
        organizationCode: string,
        tenantOwnerCompanyCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'tenantOwnerCompanyCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode',
          'tenantOwnerCompanyCode',
          tenantOwnerCompanyCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_company/{tenant_owner_company_code}`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(
              `{${'tenant_owner_company_code'}}`,
              encodeURIComponent(String(tenantOwnerCompanyCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      if (isAllTenantsReturned !== undefined) {
        localVarQueryParameter['is_all_tenants_returned'] = isAllTenantsReturned
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (storeCodes) {
        localVarQueryParameter['store_codes'] = storeCodes
      }

      if (tenantOwnerCompanyCode !== undefined) {
        localVarQueryParameter['tenant_owner_company_code'] =
          tenantOwnerCompanyCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織に紐づくメンバーを一括で編集
     * @summary 組織のメンバー一括編集
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPatchRequest>} [memberPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeMembers: async (
      organizationCode: string,
      memberPatchRequest?: Array<MemberPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeMembers',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/members`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        memberPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses:
      async (
        organizationCode: string,
        storeCode: string,
        updateStatuses?: UpdateStatuses,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/bulk_update_sales_date_statuses`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          updateStatuses,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods: async (
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_methods`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculationMethodPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantOwnerCompanyPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants:
      async (
        organizationCode: string,
        tenantOwnerCompanyCode: string,
        tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'tenantOwnerCompanyCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants',
          'tenantOwnerCompanyCode',
          tenantOwnerCompanyCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_company/{tenant_owner_company_code}/tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(
              `{${'tenant_owner_company_code'}}`,
              encodeURIComponent(String(tenantOwnerCompanyCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantsOfTenantOwnerCompanyPatchRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPatchRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTotalsByStore: async (
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTotalsByStore',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/totals_by_store`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (storeCode !== undefined) {
        localVarQueryParameter['store_code'] = storeCode
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesDateRange,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile: async (
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('patchProfile', 'organizationCode', organizationCode)
      const localVarPath = `/organizations/{organization_code}/profile`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織に紐づくメンバーを一括で新規追加
     * @summary 組織のメンバー一括新規追加
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPostRequest>} [memberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeMembers: async (
      organizationCode: string,
      memberPostRequest?: Array<MemberPostRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeMembers',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/members`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        memberPostRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates:
      async (
        organizationCode: string,
        storeCode: string,
        requestBody?: Array<string>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/bulk_finalization_of_sales_dates`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          requestBody,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
     * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles: async (
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales-report-csv-files`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        tenantDetailsPostRequest?: TenantDetailsPostRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantDetailsPostRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        notificationToTenant?: NotificationToTenant,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/notifications_to_tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          notificationToTenant,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantOwnerCompanyPostRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants: async (
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('postTenants', 'organizationCode', organizationCode)
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists('postTenants', 'storeCode', storeCode)
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl: async (
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('postTenantsIdCalculationDsl', 'id', id)
      const localVarPath = `/tenants/{id}/calculation_dsl`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculationDSLPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdDefiniteValue: async (
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantId' is not null or undefined
      assertParamExists('putTenantsIdDefiniteValue', 'tenantId', tenantId)
      // verify required parameter 'salesDateId' is not null or undefined
      assertParamExists('putTenantsIdDefiniteValue', 'salesDateId', salesDateId)
      const localVarPath =
        `/tenants/{tenant_id}/sales_dates/{sales_date_id}/definite_values`
          .replace(`{${'tenant_id'}}`, encodeURIComponent(String(tenantId)))
          .replace(
            `{${'sales_date_id'}}`,
            encodeURIComponent(String(salesDateId))
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        definiteValuePutRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdSalesDateIdMessages: async (
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantId' is not null or undefined
      assertParamExists('putTenantsIdSalesDateIdMessages', 'tenantId', tenantId)
      // verify required parameter 'salesDateId' is not null or undefined
      assertParamExists(
        'putTenantsIdSalesDateIdMessages',
        'salesDateId',
        salesDateId
      )
      // verify required parameter 'salesDateRegisterId' is not null or undefined
      assertParamExists(
        'putTenantsIdSalesDateIdMessages',
        'salesDateRegisterId',
        salesDateRegisterId
      )
      const localVarPath =
        `/tenants/{tenant_id}/sales_dates/{sales_date_id}/sales_date_registers/{sales_date_register_id}/messages`
          .replace(`{${'tenant_id'}}`, encodeURIComponent(String(tenantId)))
          .replace(
            `{${'sales_date_id'}}`,
            encodeURIComponent(String(salesDateId))
          )
          .replace(
            `{${'sales_date_register_id'}}`,
            encodeURIComponent(String(salesDateRegisterId))
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesReportMessage,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.getOrganizationsOrganizationCode']?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織に紐づくメンバーの一括取得
     * @summary 組織のメンバー一括取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Member>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeMembers(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeMembers'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.getOrganizationsOrganizationCodeStores']?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationDSLsWithCalcMethod>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantDetailWithTenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantOwnerCompany>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantOwnerCompany>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          organizationCode,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織に紐づくメンバーを一括で編集
     * @summary 組織のメンバー一括編集
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPatchRequest>} [memberPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPatchRequest?: Array<MemberPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeMembers'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
      organizationCode: string,
      storeCode: string,
      updateStatuses?: UpdateStatuses,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
          organizationCode,
          storeCode,
          updateStatuses,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CalculationMethod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          organizationCode,
          tenantOwnerCompanyCode,
          tenantsOfTenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTotalsByStore(
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTotalsByStore(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          salesDateRange,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.patchOrganizationsOrganizationCodeTotalsByStore'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.patchProfile']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.postAuth']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織に紐づくメンバーを一括で新規追加
     * @summary 組織のメンバー一括新規追加
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPostRequest>} [memberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPostRequest?: Array<MemberPostRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.postOrganizationsOrganizationCodeMembers'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
     * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      notificationToTenant?: NotificationToTenant,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
          organizationCode,
          storeCode,
          tenantCode,
          notificationToTenant,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminApi.postOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.postTenants']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.postTenantsIdCalculationDsl']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTenantsIdDefiniteValue(
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTenantsIdDefiniteValue(
          tenantId,
          salesDateId,
          definiteValuePutRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.putTenantsIdDefiniteValue']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTenantsIdSalesDateIdMessages(
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTenantsIdSalesDateIdMessages(
          tenantId,
          salesDateId,
          salesDateRegisterId,
          salesReportMessage,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminApi.putTenantsIdSalesDateIdMessages']?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織に紐づくメンバーの一括取得
     * @summary 組織のメンバー一括取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Array<Member>> {
      return localVarFp
        .getOrganizationsOrganizationCodeMembers(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<CalculationDSLsWithCalcMethod> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<TenantDetailWithTenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: any
    ): AxiosPromise<Array<TenantOwnerCompany>> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      options?: any
    ): AxiosPromise<TenantOwnerCompany> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          organizationCode,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織に紐づくメンバーを一括で編集
     * @summary 組織のメンバー一括編集
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPatchRequest>} [memberPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPatchRequest?: Array<MemberPatchRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
      organizationCode: string,
      storeCode: string,
      updateStatuses?: UpdateStatuses,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
          organizationCode,
          storeCode,
          updateStatuses,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: any
    ): AxiosPromise<Array<CalculationMethod>> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          organizationCode,
          tenantOwnerCompanyCode,
          tenantsOfTenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTotalsByStore(
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTotalsByStore(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          salesDateRange,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織に紐づくメンバーを一括で新規追加
     * @summary 組織のメンバー一括新規追加
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPostRequest>} [memberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPostRequest?: Array<MemberPostRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
     * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      notificationToTenant?: NotificationToTenant,
      options?: any
    ): AxiosPromise<Notification> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
          organizationCode,
          storeCode,
          tenantCode,
          notificationToTenant,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenants(
          organizationCode,
          storeCode,
          tenantsPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdDefiniteValue(
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putTenantsIdDefiniteValue(
          tenantId,
          salesDateId,
          definiteValuePutRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdSalesDateIdMessages(
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putTenantsIdSalesDateIdMessages(
          tenantId,
          salesDateId,
          salesDateRegisterId,
          salesReportMessage,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織に紐づくメンバーの一括取得
   * @summary 組織のメンバー一括取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeMembers(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeMembers(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
   * @summary 算出ロジック&読取項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の取得
   * @summary テナント詳細情報の取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一覧取得
   * @summary 取引企業の一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
   * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    offset?: number,
    limit?: number,
    tenantOwnerCompanyCodeOrName?: string,
    tenantOwnerCompanyIsActive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        offset,
        limit,
        tenantOwnerCompanyCodeOrName,
        tenantOwnerCompanyIsActive,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の取得
   * @summary 取引企業の取得
   * @param {string} organizationCode 組織コード
   * @param {string} tenantOwnerCompanyCode 取引企業コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
    organizationCode: string,
    tenantOwnerCompanyCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
        organizationCode,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織に紐づくメンバーを一括で編集
   * @summary 組織のメンバー一括編集
   * @param {string} organizationCode 組織コード
   * @param {Array<MemberPatchRequest>} [memberPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeMembers(
    organizationCode: string,
    memberPatchRequest?: Array<MemberPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeMembers(
        organizationCode,
        memberPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告日(sales_date)のstatusを一括更新する
   * @summary 売上報告日のstatusを一括更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {UpdateStatuses} [updateStatuses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
    organizationCode: string,
    storeCode: string,
    updateStatuses?: UpdateStatuses,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
        organizationCode,
        storeCode,
        updateStatuses,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 端数処理のtypeと桁数を更新し、それらを返す
   * @summary 端数処理更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
    organizationCode: string,
    storeCode: string,
    calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
        organizationCode,
        storeCode,
        calculationMethodPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一括更新
   * @summary 取引企業の一括更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業に紐づくテナント一括更新
   * @summary 取引企業に紐づくテナント一括更新
   * @param {string} organizationCode 組織コード
   * @param {string} tenantOwnerCompanyCode 取引企業コード
   * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
    organizationCode: string,
    tenantOwnerCompanyCode: string,
    tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
        organizationCode,
        tenantOwnerCompanyCode,
        tenantsOfTenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント情報を更新する
   * @summary テナント更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenants(
        organizationCode,
        tenantsPatchRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗単位の合計値(報告値と確定値の両方)を更新
   * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
   * @param {string} organizationCode 組織コード
   * @param {string} [storeCode] フィルターする店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {SalesDateRange} [salesDateRange]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchOrganizationsOrganizationCodeTotalsByStore(
    organizationCode: string,
    storeCode?: string,
    startDate?: string,
    endDate?: string,
    salesDateRange?: SalesDateRange,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTotalsByStore(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        salesDateRange,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織に紐づくメンバーを一括で新規追加
   * @summary 組織のメンバー一括新規追加
   * @param {string} organizationCode 組織コード
   * @param {Array<MemberPostRequest>} [memberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postOrganizationsOrganizationCodeMembers(
    organizationCode: string,
    memberPostRequest?: Array<MemberPostRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postOrganizationsOrganizationCodeMembers(
        organizationCode,
        memberPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
   * @summary 売上報告日一括確定
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
    organizationCode: string,
    storeCode: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
        organizationCode,
        storeCode,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
   * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
    organizationCode: string,
    storeCode: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
        organizationCode,
        storeCode,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の登録(OPAさんはテナントレジも登録)
   * @summary テナント詳細情報の登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    tenantDetailsPostRequest?: TenantDetailsPostRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        tenantDetailsPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * デベロッパーからテナントに通知するメッセージを登録
   * @summary デベロッパーからテナントに通知するメッセージを登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {NotificationToTenant} [notificationToTenant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    notificationToTenant?: NotificationToTenant,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
        organizationCode,
        storeCode,
        tenantCode,
        notificationToTenant,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の登録
   * @summary 取引企業の登録
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナントを新規追加する
   * @summary テナント新規追加
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postTenants(
    organizationCode: string,
    storeCode: string,
    tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
   * @summary 算出ロジック&読取項目作成
   * @param {string} id テナントID
   * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postTenantsIdCalculationDsl(
    id: string,
    calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .postTenantsIdCalculationDsl(id, calculationDSLPostRequestInner, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 売上報告の確定値の保存
   * @param {string} tenantId テナントID
   * @param {string} salesDateId 売上報告日ID
   * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public putTenantsIdDefiniteValue(
    tenantId: string,
    salesDateId: string,
    definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .putTenantsIdDefiniteValue(
        tenantId,
        salesDateId,
        definiteValuePutRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 売上報告の管理者メモ(報告ごと)の保存
   * @param {string} tenantId テナントID
   * @param {string} salesDateId 売上報告日ID
   * @param {string} salesDateRegisterId 売上報告レジID
   * @param {SalesReportMessage} [salesReportMessage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public putTenantsIdSalesDateIdMessages(
    tenantId: string,
    salesDateId: string,
    salesDateRegisterId: string,
    salesReportMessage?: SalesReportMessage,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .putTenantsIdSalesDateIdMessages(
        tenantId,
        salesDateId,
        salesDateRegisterId,
        salesReportMessage,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AdminSalesReportManagerApi - axios parameter creator
 * @export
 */
export const AdminSalesReportManagerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCode',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/store_sales_report_summaries`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStores',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/stores`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales_reports`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (reportStatus) {
        localVarQueryParameter['report_status'] = reportStatus
      }

      if (aggregatedResult) {
        localVarQueryParameter['aggregated_result'] = aggregatedResult
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
      async (
        organizationCode: string,
        storeCode: string,
        startDate?: string,
        endDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (salesDate !== undefined) {
        localVarQueryParameter['sales_date'] =
          (salesDate as any) instanceof Date
            ? (salesDate as any).toISOString().substring(0, 10)
            : salesDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/calculation_dsl`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (tenantOwnerCompanyCodeOrName !== undefined) {
        localVarQueryParameter['tenant_owner_company_code_or_name'] =
          tenantOwnerCompanyCodeOrName
      }

      if (tenantOwnerCompanyIsActive !== undefined) {
        localVarQueryParameter['tenant_owner_company_is_active'] =
          tenantOwnerCompanyIsActive
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode:
      async (
        organizationCode: string,
        tenantOwnerCompanyCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'tenantOwnerCompanyCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode',
          'tenantOwnerCompanyCode',
          tenantOwnerCompanyCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_company/{tenant_owner_company_code}`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(
              `{${'tenant_owner_company_code'}}`,
              encodeURIComponent(String(tenantOwnerCompanyCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      if (isAllTenantsReturned !== undefined) {
        localVarQueryParameter['is_all_tenants_returned'] = isAllTenantsReturned
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (storeCodes) {
        localVarQueryParameter['store_codes'] = storeCodes
      }

      if (tenantOwnerCompanyCode !== undefined) {
        localVarQueryParameter['tenant_owner_company_code'] =
          tenantOwnerCompanyCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses:
      async (
        organizationCode: string,
        storeCode: string,
        updateStatuses?: UpdateStatuses,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/bulk_update_sales_date_statuses`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          updateStatuses,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods: async (
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_methods`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculationMethodPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantOwnerCompanyPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants:
      async (
        organizationCode: string,
        tenantOwnerCompanyCode: string,
        tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'tenantOwnerCompanyCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants',
          'tenantOwnerCompanyCode',
          tenantOwnerCompanyCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_company/{tenant_owner_company_code}/tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(
              `{${'tenant_owner_company_code'}}`,
              encodeURIComponent(String(tenantOwnerCompanyCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantsOfTenantOwnerCompanyPatchRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPatchRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTotalsByStore: async (
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTotalsByStore',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/totals_by_store`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (storeCode !== undefined) {
        localVarQueryParameter['store_code'] = storeCode
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesDateRange,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile: async (
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('patchProfile', 'organizationCode', organizationCode)
      const localVarPath = `/organizations/{organization_code}/profile`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates:
      async (
        organizationCode: string,
        storeCode: string,
        requestBody?: Array<string>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/bulk_finalization_of_sales_dates`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          requestBody,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
     * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles: async (
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales-report-csv-files`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        tenantDetailsPostRequest?: TenantDetailsPostRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantDetailsPostRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        notificationToTenant?: NotificationToTenant,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/notifications_to_tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          notificationToTenant,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantOwnerCompanyPostRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants: async (
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('postTenants', 'organizationCode', organizationCode)
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists('postTenants', 'storeCode', storeCode)
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl: async (
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('postTenantsIdCalculationDsl', 'id', id)
      const localVarPath = `/tenants/{id}/calculation_dsl`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculationDSLPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdDefiniteValue: async (
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantId' is not null or undefined
      assertParamExists('putTenantsIdDefiniteValue', 'tenantId', tenantId)
      // verify required parameter 'salesDateId' is not null or undefined
      assertParamExists('putTenantsIdDefiniteValue', 'salesDateId', salesDateId)
      const localVarPath =
        `/tenants/{tenant_id}/sales_dates/{sales_date_id}/definite_values`
          .replace(`{${'tenant_id'}}`, encodeURIComponent(String(tenantId)))
          .replace(
            `{${'sales_date_id'}}`,
            encodeURIComponent(String(salesDateId))
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        definiteValuePutRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdSalesDateIdMessages: async (
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantId' is not null or undefined
      assertParamExists('putTenantsIdSalesDateIdMessages', 'tenantId', tenantId)
      // verify required parameter 'salesDateId' is not null or undefined
      assertParamExists(
        'putTenantsIdSalesDateIdMessages',
        'salesDateId',
        salesDateId
      )
      // verify required parameter 'salesDateRegisterId' is not null or undefined
      assertParamExists(
        'putTenantsIdSalesDateIdMessages',
        'salesDateRegisterId',
        salesDateRegisterId
      )
      const localVarPath =
        `/tenants/{tenant_id}/sales_dates/{sales_date_id}/sales_date_registers/{sales_date_register_id}/messages`
          .replace(`{${'tenant_id'}}`, encodeURIComponent(String(tenantId)))
          .replace(
            `{${'sales_date_id'}}`,
            encodeURIComponent(String(salesDateId))
          )
          .replace(
            `{${'sales_date_register_id'}}`,
            encodeURIComponent(String(salesDateRegisterId))
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesReportMessage,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AdminSalesReportManagerApi - functional programming interface
 * @export
 */
export const AdminSalesReportManagerApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    AdminSalesReportManagerApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStores'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationDSLsWithCalcMethod>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantDetailWithTenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantOwnerCompany>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantOwnerCompany>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          organizationCode,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
      organizationCode: string,
      storeCode: string,
      updateStatuses?: UpdateStatuses,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
          organizationCode,
          storeCode,
          updateStatuses,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CalculationMethod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.patchOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          organizationCode,
          tenantOwnerCompanyCode,
          tenantsOfTenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.patchOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTotalsByStore(
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTotalsByStore(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          salesDateRange,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.patchOrganizationsOrganizationCodeTotalsByStore'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminSalesReportManagerApi.patchProfile']?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminSalesReportManagerApi.postAuth']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
     * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      notificationToTenant?: NotificationToTenant,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
          organizationCode,
          storeCode,
          tenantCode,
          notificationToTenant,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.postOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminSalesReportManagerApi.postTenants']?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.postTenantsIdCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTenantsIdDefiniteValue(
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTenantsIdDefiniteValue(
          tenantId,
          salesDateId,
          definiteValuePutRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.putTenantsIdDefiniteValue'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTenantsIdSalesDateIdMessages(
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTenantsIdSalesDateIdMessages(
          tenantId,
          salesDateId,
          salesDateRegisterId,
          salesReportMessage,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminSalesReportManagerApi.putTenantsIdSalesDateIdMessages'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * AdminSalesReportManagerApi - factory interface
 * @export
 */
export const AdminSalesReportManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminSalesReportManagerApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<CalculationDSLsWithCalcMethod> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<TenantDetailWithTenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: any
    ): AxiosPromise<Array<TenantOwnerCompany>> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      options?: any
    ): AxiosPromise<TenantOwnerCompany> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          organizationCode,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
      organizationCode: string,
      storeCode: string,
      updateStatuses?: UpdateStatuses,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
          organizationCode,
          storeCode,
          updateStatuses,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: any
    ): AxiosPromise<Array<CalculationMethod>> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          organizationCode,
          tenantOwnerCompanyCode,
          tenantsOfTenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTotalsByStore(
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTotalsByStore(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          salesDateRange,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
     * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      notificationToTenant?: NotificationToTenant,
      options?: any
    ): AxiosPromise<Notification> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
          organizationCode,
          storeCode,
          tenantCode,
          notificationToTenant,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenants(
          organizationCode,
          storeCode,
          tenantsPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdDefiniteValue(
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putTenantsIdDefiniteValue(
          tenantId,
          salesDateId,
          definiteValuePutRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdSalesDateIdMessages(
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putTenantsIdSalesDateIdMessages(
          tenantId,
          salesDateId,
          salesDateRegisterId,
          salesReportMessage,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AdminSalesReportManagerApi - object-oriented interface
 * @export
 * @class AdminSalesReportManagerApi
 * @extends {BaseAPI}
 */
export class AdminSalesReportManagerApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
   * @summary 算出ロジック&読取項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の取得
   * @summary テナント詳細情報の取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一覧取得
   * @summary 取引企業の一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
   * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    offset?: number,
    limit?: number,
    tenantOwnerCompanyCodeOrName?: string,
    tenantOwnerCompanyIsActive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        offset,
        limit,
        tenantOwnerCompanyCodeOrName,
        tenantOwnerCompanyIsActive,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の取得
   * @summary 取引企業の取得
   * @param {string} organizationCode 組織コード
   * @param {string} tenantOwnerCompanyCode 取引企業コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
    organizationCode: string,
    tenantOwnerCompanyCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
        organizationCode,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告日(sales_date)のstatusを一括更新する
   * @summary 売上報告日のstatusを一括更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {UpdateStatuses} [updateStatuses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
    organizationCode: string,
    storeCode: string,
    updateStatuses?: UpdateStatuses,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
        organizationCode,
        storeCode,
        updateStatuses,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 端数処理のtypeと桁数を更新し、それらを返す
   * @summary 端数処理更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
    organizationCode: string,
    storeCode: string,
    calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
        organizationCode,
        storeCode,
        calculationMethodPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一括更新
   * @summary 取引企業の一括更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業に紐づくテナント一括更新
   * @summary 取引企業に紐づくテナント一括更新
   * @param {string} organizationCode 組織コード
   * @param {string} tenantOwnerCompanyCode 取引企業コード
   * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
    organizationCode: string,
    tenantOwnerCompanyCode: string,
    tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
        organizationCode,
        tenantOwnerCompanyCode,
        tenantsOfTenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント情報を更新する
   * @summary テナント更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenants(
        organizationCode,
        tenantsPatchRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗単位の合計値(報告値と確定値の両方)を更新
   * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
   * @param {string} organizationCode 組織コード
   * @param {string} [storeCode] フィルターする店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {SalesDateRange} [salesDateRange]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTotalsByStore(
    organizationCode: string,
    storeCode?: string,
    startDate?: string,
    endDate?: string,
    salesDateRange?: SalesDateRange,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTotalsByStore(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        salesDateRange,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
   * @summary 売上報告日一括確定
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
    organizationCode: string,
    storeCode: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
        organizationCode,
        storeCode,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)に送信する
   * @summary 売上報告情報のcsvファイル(確定値含む)をクライアント(Org)のサーバーに送信するAPI
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
    organizationCode: string,
    storeCode: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeSalesReportCsvFiles(
        organizationCode,
        storeCode,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の登録(OPAさんはテナントレジも登録)
   * @summary テナント詳細情報の登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    tenantDetailsPostRequest?: TenantDetailsPostRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        tenantDetailsPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * デベロッパーからテナントに通知するメッセージを登録
   * @summary デベロッパーからテナントに通知するメッセージを登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {NotificationToTenant} [notificationToTenant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    notificationToTenant?: NotificationToTenant,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
        organizationCode,
        storeCode,
        tenantCode,
        notificationToTenant,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の登録
   * @summary 取引企業の登録
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナントを新規追加する
   * @summary テナント新規追加
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postTenants(
    organizationCode: string,
    storeCode: string,
    tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
   * @summary 算出ロジック&読取項目作成
   * @param {string} id テナントID
   * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public postTenantsIdCalculationDsl(
    id: string,
    calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .postTenantsIdCalculationDsl(id, calculationDSLPostRequestInner, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 売上報告の確定値の保存
   * @param {string} tenantId テナントID
   * @param {string} salesDateId 売上報告日ID
   * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public putTenantsIdDefiniteValue(
    tenantId: string,
    salesDateId: string,
    definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .putTenantsIdDefiniteValue(
        tenantId,
        salesDateId,
        definiteValuePutRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 売上報告の管理者メモ(報告ごと)の保存
   * @param {string} tenantId テナントID
   * @param {string} salesDateId 売上報告日ID
   * @param {string} salesDateRegisterId 売上報告レジID
   * @param {SalesReportMessage} [salesReportMessage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSalesReportManagerApi
   */
  public putTenantsIdSalesDateIdMessages(
    tenantId: string,
    salesDateId: string,
    salesDateRegisterId: string,
    salesReportMessage?: SalesReportMessage,
    options?: AxiosRequestConfig
  ) {
    return AdminSalesReportManagerApiFp(this.configuration)
      .putTenantsIdSalesDateIdMessages(
        tenantId,
        salesDateId,
        salesDateRegisterId,
        salesReportMessage,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AdminStoreManagerApi - axios parameter creator
 * @export
 */
export const AdminStoreManagerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCode',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織に紐づくメンバーの一括取得
     * @summary 組織のメンバー一括取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeMembers: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeMembers',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/members`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/store_sales_report_summaries`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStores',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/stores`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales_reports`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (reportStatus) {
        localVarQueryParameter['report_status'] = reportStatus
      }

      if (aggregatedResult) {
        localVarQueryParameter['aggregated_result'] = aggregatedResult
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
      async (
        organizationCode: string,
        storeCode: string,
        startDate?: string,
        endDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (salesDate !== undefined) {
        localVarQueryParameter['sales_date'] =
          (salesDate as any) instanceof Date
            ? (salesDate as any).toISOString().substring(0, 10)
            : salesDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      if (isAllTenantsReturned !== undefined) {
        localVarQueryParameter['is_all_tenants_returned'] = isAllTenantsReturned
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (storeCodes) {
        localVarQueryParameter['store_codes'] = storeCodes
      }

      if (tenantOwnerCompanyCode !== undefined) {
        localVarQueryParameter['tenant_owner_company_code'] =
          tenantOwnerCompanyCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織に紐づくメンバーを一括で編集
     * @summary 組織のメンバー一括編集
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPatchRequest>} [memberPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeMembers: async (
      organizationCode: string,
      memberPatchRequest?: Array<MemberPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeMembers',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/members`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        memberPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPatchRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile: async (
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('patchProfile', 'organizationCode', organizationCode)
      const localVarPath = `/organizations/{organization_code}/profile`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織に紐づくメンバーを一括で新規追加
     * @summary 組織のメンバー一括新規追加
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPostRequest>} [memberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeMembers: async (
      organizationCode: string,
      memberPostRequest?: Array<MemberPostRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeMembers',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/members`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        memberPostRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants: async (
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('postTenants', 'organizationCode', organizationCode)
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists('postTenants', 'storeCode', storeCode)
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AdminStoreManagerApi - functional programming interface
 * @export
 */
export const AdminStoreManagerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminStoreManagerApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織に紐づくメンバーの一括取得
     * @summary 組織のメンバー一括取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Member>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeMembers(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeMembers'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStores'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織に紐づくメンバーを一括で編集
     * @summary 組織のメンバー一括編集
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPatchRequest>} [memberPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPatchRequest?: Array<MemberPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.patchOrganizationsOrganizationCodeMembers'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.patchOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminStoreManagerApi.patchProfile']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminStoreManagerApi.postAuth']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織に紐づくメンバーを一括で新規追加
     * @summary 組織のメンバー一括新規追加
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPostRequest>} [memberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPostRequest?: Array<MemberPostRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'AdminStoreManagerApi.postOrganizationsOrganizationCodeMembers'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['AdminStoreManagerApi.postTenants']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * AdminStoreManagerApi - factory interface
 * @export
 */
export const AdminStoreManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminStoreManagerApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織に紐づくメンバーの一括取得
     * @summary 組織のメンバー一括取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Array<Member>> {
      return localVarFp
        .getOrganizationsOrganizationCodeMembers(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織に紐づくメンバーを一括で編集
     * @summary 組織のメンバー一括編集
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPatchRequest>} [memberPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPatchRequest?: Array<MemberPatchRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織に紐づくメンバーを一括で新規追加
     * @summary 組織のメンバー一括新規追加
     * @param {string} organizationCode 組織コード
     * @param {Array<MemberPostRequest>} [memberPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeMembers(
      organizationCode: string,
      memberPostRequest?: Array<MemberPostRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeMembers(
          organizationCode,
          memberPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenants(
          organizationCode,
          storeCode,
          tenantsPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AdminStoreManagerApi - object-oriented interface
 * @export
 * @class AdminStoreManagerApi
 * @extends {BaseAPI}
 */
export class AdminStoreManagerApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織に紐づくメンバーの一括取得
   * @summary 組織のメンバー一括取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeMembers(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeMembers(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織に紐づくメンバーを一括で編集
   * @summary 組織のメンバー一括編集
   * @param {string} organizationCode 組織コード
   * @param {Array<MemberPatchRequest>} [memberPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public patchOrganizationsOrganizationCodeMembers(
    organizationCode: string,
    memberPatchRequest?: Array<MemberPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeMembers(
        organizationCode,
        memberPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント情報を更新する
   * @summary テナント更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public patchOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenants(
        organizationCode,
        tenantsPatchRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return AdminStoreManagerApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織に紐づくメンバーを一括で新規追加
   * @summary 組織のメンバー一括新規追加
   * @param {string} organizationCode 組織コード
   * @param {Array<MemberPostRequest>} [memberPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public postOrganizationsOrganizationCodeMembers(
    organizationCode: string,
    memberPostRequest?: Array<MemberPostRequest>,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeMembers(
        organizationCode,
        memberPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナントを新規追加する
   * @summary テナント新規追加
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStoreManagerApi
   */
  public postTenants(
    organizationCode: string,
    storeCode: string,
    tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return AdminStoreManagerApiFp(this.configuration)
      .postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * メンバーのemailが存在するか確認
     * @summary メンバーのemail存在確認
     * @param {string} [email] メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMembersEmailExistence: async (
      email?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/member/email_existence`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (email !== undefined) {
        localVarQueryParameter['email'] = email
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     * メンバーのemailが存在するか確認
     * @summary メンバーのemail存在確認
     * @param {string} [email] メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMembersEmailExistence(
      email?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailExistence>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMembersEmailExistence(email, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['DefaultApi.getMembersEmailExistence']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     * メンバーのemailが存在するか確認
     * @summary メンバーのemail存在確認
     * @param {string} [email] メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMembersEmailExistence(
      email?: string,
      options?: any
    ): AxiosPromise<EmailExistence> {
      return localVarFp
        .getMembersEmailExistence(email, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * メンバーのemailが存在するか確認
   * @summary メンバーのemail存在確認
   * @param {string} [email] メールアドレス
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getMembersEmailExistence(
    email?: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .getMembersEmailExistence(email, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * EditorSalesReportManagerApi - axios parameter creator
 * @export
 */
export const EditorSalesReportManagerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCode',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/store_sales_report_summaries`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStores',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/stores`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales_reports`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (reportStatus) {
        localVarQueryParameter['report_status'] = reportStatus
      }

      if (aggregatedResult) {
        localVarQueryParameter['aggregated_result'] = aggregatedResult
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
      async (
        organizationCode: string,
        storeCode: string,
        startDate?: string,
        endDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (salesDate !== undefined) {
        localVarQueryParameter['sales_date'] =
          (salesDate as any) instanceof Date
            ? (salesDate as any).toISOString().substring(0, 10)
            : salesDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/calculation_dsl`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (tenantOwnerCompanyCodeOrName !== undefined) {
        localVarQueryParameter['tenant_owner_company_code_or_name'] =
          tenantOwnerCompanyCodeOrName
      }

      if (tenantOwnerCompanyIsActive !== undefined) {
        localVarQueryParameter['tenant_owner_company_is_active'] =
          tenantOwnerCompanyIsActive
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode:
      async (
        organizationCode: string,
        tenantOwnerCompanyCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'tenantOwnerCompanyCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode',
          'tenantOwnerCompanyCode',
          tenantOwnerCompanyCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_company/{tenant_owner_company_code}`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(
              `{${'tenant_owner_company_code'}}`,
              encodeURIComponent(String(tenantOwnerCompanyCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      if (isAllTenantsReturned !== undefined) {
        localVarQueryParameter['is_all_tenants_returned'] = isAllTenantsReturned
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (storeCodes) {
        localVarQueryParameter['store_codes'] = storeCodes
      }

      if (tenantOwnerCompanyCode !== undefined) {
        localVarQueryParameter['tenant_owner_company_code'] =
          tenantOwnerCompanyCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses:
      async (
        organizationCode: string,
        storeCode: string,
        updateStatuses?: UpdateStatuses,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/bulk_update_sales_date_statuses`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          updateStatuses,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods: async (
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_methods`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculationMethodPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantOwnerCompanyPatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants:
      async (
        organizationCode: string,
        tenantOwnerCompanyCode: string,
        tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'tenantOwnerCompanyCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants',
          'tenantOwnerCompanyCode',
          tenantOwnerCompanyCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_company/{tenant_owner_company_code}/tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(
              `{${'tenant_owner_company_code'}}`,
              encodeURIComponent(String(tenantOwnerCompanyCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantsOfTenantOwnerCompanyPatchRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTotalsByStore: async (
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTotalsByStore',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/totals_by_store`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (storeCode !== undefined) {
        localVarQueryParameter['store_code'] = storeCode
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesDateRange,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile: async (
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('patchProfile', 'organizationCode', organizationCode)
      const localVarPath = `/organizations/{organization_code}/profile`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates:
      async (
        organizationCode: string,
        storeCode: string,
        requestBody?: Array<string>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/bulk_finalization_of_sales_dates`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          requestBody,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        tenantDetailsPostRequest?: TenantDetailsPostRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantDetailsPostRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants:
      async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        notificationToTenant?: NotificationToTenant,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/notifications_to_tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          notificationToTenant,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies: async (
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'postOrganizationsOrganizationCodeTenantOwnerCompanies',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/tenant_owner_companies`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantOwnerCompanyPostRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl: async (
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('postTenantsIdCalculationDsl', 'id', id)
      const localVarPath = `/tenants/{id}/calculation_dsl`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        calculationDSLPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdDefiniteValue: async (
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantId' is not null or undefined
      assertParamExists('putTenantsIdDefiniteValue', 'tenantId', tenantId)
      // verify required parameter 'salesDateId' is not null or undefined
      assertParamExists('putTenantsIdDefiniteValue', 'salesDateId', salesDateId)
      const localVarPath =
        `/tenants/{tenant_id}/sales_dates/{sales_date_id}/definite_values`
          .replace(`{${'tenant_id'}}`, encodeURIComponent(String(tenantId)))
          .replace(
            `{${'sales_date_id'}}`,
            encodeURIComponent(String(salesDateId))
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        definiteValuePutRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdSalesDateIdMessages: async (
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tenantId' is not null or undefined
      assertParamExists('putTenantsIdSalesDateIdMessages', 'tenantId', tenantId)
      // verify required parameter 'salesDateId' is not null or undefined
      assertParamExists(
        'putTenantsIdSalesDateIdMessages',
        'salesDateId',
        salesDateId
      )
      // verify required parameter 'salesDateRegisterId' is not null or undefined
      assertParamExists(
        'putTenantsIdSalesDateIdMessages',
        'salesDateRegisterId',
        salesDateRegisterId
      )
      const localVarPath =
        `/tenants/{tenant_id}/sales_dates/{sales_date_id}/sales_date_registers/{sales_date_register_id}/messages`
          .replace(`{${'tenant_id'}}`, encodeURIComponent(String(tenantId)))
          .replace(
            `{${'sales_date_id'}}`,
            encodeURIComponent(String(salesDateId))
          )
          .replace(
            `{${'sales_date_register_id'}}`,
            encodeURIComponent(String(salesDateRegisterId))
          )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesReportMessage,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EditorSalesReportManagerApi - functional programming interface
 * @export
 */
export const EditorSalesReportManagerApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    EditorSalesReportManagerApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStores'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationDSLsWithCalcMethod>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantDetailWithTenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantOwnerCompany>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantOwnerCompany>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          organizationCode,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
      organizationCode: string,
      storeCode: string,
      updateStatuses?: UpdateStatuses,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
          organizationCode,
          storeCode,
          updateStatuses,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CalculationMethod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.patchOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          organizationCode,
          tenantOwnerCompanyCode,
          tenantsOfTenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTotalsByStore(
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTotalsByStore(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          salesDateRange,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.patchOrganizationsOrganizationCodeTotalsByStore'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['EditorSalesReportManagerApi.patchProfile']?.[index]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['EditorSalesReportManagerApi.postAuth']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      notificationToTenant?: NotificationToTenant,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
          organizationCode,
          storeCode,
          tenantCode,
          notificationToTenant,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.postOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.postTenantsIdCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTenantsIdDefiniteValue(
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTenantsIdDefiniteValue(
          tenantId,
          salesDateId,
          definiteValuePutRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.putTenantsIdDefiniteValue'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTenantsIdSalesDateIdMessages(
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putTenantsIdSalesDateIdMessages(
          tenantId,
          salesDateId,
          salesDateRegisterId,
          salesReportMessage,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorSalesReportManagerApi.putTenantsIdSalesDateIdMessages'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * EditorSalesReportManagerApi - factory interface
 * @export
 */
export const EditorSalesReportManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EditorSalesReportManagerApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<CalculationDSLsWithCalcMethod> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<TenantDetailWithTenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: any
    ): AxiosPromise<Array<TenantOwnerCompany>> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の取得
     * @summary 取引企業の取得
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      options?: any
    ): AxiosPromise<TenantOwnerCompany> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
          organizationCode,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告日(sales_date)のstatusを一括更新する
     * @summary 売上報告日のstatusを一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {UpdateStatuses} [updateStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
      organizationCode: string,
      storeCode: string,
      updateStatuses?: UpdateStatuses,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
          organizationCode,
          storeCode,
          updateStatuses,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: any
    ): AxiosPromise<Array<CalculationMethod>> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業に紐づくテナント一括更新
     * @summary 取引企業に紐づくテナント一括更新
     * @param {string} organizationCode 組織コード
     * @param {string} tenantOwnerCompanyCode 取引企業コード
     * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
      organizationCode: string,
      tenantOwnerCompanyCode: string,
      tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
          organizationCode,
          tenantOwnerCompanyCode,
          tenantsOfTenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗単位の合計値(報告値と確定値の両方)を更新
     * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
     * @param {string} organizationCode 組織コード
     * @param {string} [storeCode] フィルターする店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {SalesDateRange} [salesDateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTotalsByStore(
      organizationCode: string,
      storeCode?: string,
      startDate?: string,
      endDate?: string,
      salesDateRange?: SalesDateRange,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTotalsByStore(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          salesDateRange,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
     * @summary 売上報告日一括確定
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
      organizationCode: string,
      storeCode: string,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
          organizationCode,
          storeCode,
          requestBody,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * デベロッパーからテナントに通知するメッセージを登録
     * @summary デベロッパーからテナントに通知するメッセージを登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {NotificationToTenant} [notificationToTenant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      notificationToTenant?: NotificationToTenant,
      options?: any
    ): AxiosPromise<Notification> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
          organizationCode,
          storeCode,
          tenantCode,
          notificationToTenant,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 売上報告の確定値の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdDefiniteValue(
      tenantId: string,
      salesDateId: string,
      definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putTenantsIdDefiniteValue(
          tenantId,
          salesDateId,
          definiteValuePutRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 売上報告の管理者メモ(報告ごと)の保存
     * @param {string} tenantId テナントID
     * @param {string} salesDateId 売上報告日ID
     * @param {string} salesDateRegisterId 売上報告レジID
     * @param {SalesReportMessage} [salesReportMessage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTenantsIdSalesDateIdMessages(
      tenantId: string,
      salesDateId: string,
      salesDateRegisterId: string,
      salesReportMessage?: SalesReportMessage,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putTenantsIdSalesDateIdMessages(
          tenantId,
          salesDateId,
          salesDateRegisterId,
          salesReportMessage,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * EditorSalesReportManagerApi - object-oriented interface
 * @export
 * @class EditorSalesReportManagerApi
 * @extends {BaseAPI}
 */
export class EditorSalesReportManagerApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
   * @summary 算出ロジック&読取項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の取得
   * @summary テナント詳細情報の取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一覧取得
   * @summary 取引企業の一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
   * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    offset?: number,
    limit?: number,
    tenantOwnerCompanyCodeOrName?: string,
    tenantOwnerCompanyIsActive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        offset,
        limit,
        tenantOwnerCompanyCodeOrName,
        tenantOwnerCompanyIsActive,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の取得
   * @summary 取引企業の取得
   * @param {string} organizationCode 組織コード
   * @param {string} tenantOwnerCompanyCode 取引企業コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
    organizationCode: string,
    tenantOwnerCompanyCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanyTenantOwnerCompanyCode(
        organizationCode,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告日(sales_date)のstatusを一括更新する
   * @summary 売上報告日のstatusを一括更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {UpdateStatuses} [updateStatuses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
    organizationCode: string,
    storeCode: string,
    updateStatuses?: UpdateStatuses,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeBulkUpdateSalesDateStatuses(
        organizationCode,
        storeCode,
        updateStatuses,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 端数処理のtypeと桁数を更新し、それらを返す
   * @summary 端数処理更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
    organizationCode: string,
    storeCode: string,
    calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
        organizationCode,
        storeCode,
        calculationMethodPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一括更新
   * @summary 取引企業の一括更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業に紐づくテナント一括更新
   * @summary 取引企業に紐づくテナント一括更新
   * @param {string} organizationCode 組織コード
   * @param {string} tenantOwnerCompanyCode 取引企業コード
   * @param {TenantsOfTenantOwnerCompanyPatchRequest} [tenantsOfTenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
    organizationCode: string,
    tenantOwnerCompanyCode: string,
    tenantsOfTenantOwnerCompanyPatchRequest?: TenantsOfTenantOwnerCompanyPatchRequest,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompaniesTenantOwnerCompanyCodeTenants(
        organizationCode,
        tenantOwnerCompanyCode,
        tenantsOfTenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗単位の合計値(報告値と確定値の両方)を更新
   * @summary 店舗単位の合計値(報告値と確定値の両方)を更新
   * @param {string} organizationCode 組織コード
   * @param {string} [storeCode] フィルターする店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {SalesDateRange} [salesDateRange]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public patchOrganizationsOrganizationCodeTotalsByStore(
    organizationCode: string,
    storeCode?: string,
    startDate?: string,
    endDate?: string,
    salesDateRange?: SalesDateRange,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTotalsByStore(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        salesDateRange,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 売上報告日(sales_date)のstatusを一括で確定済みにし、手入力値を確定値として登録する
   * @summary 売上報告日一括確定
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
    organizationCode: string,
    storeCode: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeBulkFinalizationOfSalesDates(
        organizationCode,
        storeCode,
        requestBody,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の登録(OPAさんはテナントレジも登録)
   * @summary テナント詳細情報の登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    tenantDetailsPostRequest?: TenantDetailsPostRequest,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        tenantDetailsPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * デベロッパーからテナントに通知するメッセージを登録
   * @summary デベロッパーからテナントに通知するメッセージを登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {NotificationToTenant} [notificationToTenant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    notificationToTenant?: NotificationToTenant,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeNotificationsToTenants(
        organizationCode,
        storeCode,
        tenantCode,
        notificationToTenant,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の登録
   * @summary 取引企業の登録
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public postOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .postOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
   * @summary 算出ロジック&読取項目作成
   * @param {string} id テナントID
   * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public postTenantsIdCalculationDsl(
    id: string,
    calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .postTenantsIdCalculationDsl(id, calculationDSLPostRequestInner, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 売上報告の確定値の保存
   * @param {string} tenantId テナントID
   * @param {string} salesDateId 売上報告日ID
   * @param {Array<DefiniteValuePutRequestInner>} [definiteValuePutRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public putTenantsIdDefiniteValue(
    tenantId: string,
    salesDateId: string,
    definiteValuePutRequestInner?: Array<DefiniteValuePutRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .putTenantsIdDefiniteValue(
        tenantId,
        salesDateId,
        definiteValuePutRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 売上報告の管理者メモ(報告ごと)の保存
   * @param {string} tenantId テナントID
   * @param {string} salesDateId 売上報告日ID
   * @param {string} salesDateRegisterId 売上報告レジID
   * @param {SalesReportMessage} [salesReportMessage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorSalesReportManagerApi
   */
  public putTenantsIdSalesDateIdMessages(
    tenantId: string,
    salesDateId: string,
    salesDateRegisterId: string,
    salesReportMessage?: SalesReportMessage,
    options?: AxiosRequestConfig
  ) {
    return EditorSalesReportManagerApiFp(this.configuration)
      .putTenantsIdSalesDateIdMessages(
        tenantId,
        salesDateId,
        salesDateRegisterId,
        salesReportMessage,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * EditorStoreManagerApi - axios parameter creator
 * @export
 */
export const EditorStoreManagerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCode',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/store_sales_report_summaries`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStores',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/stores`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales_reports`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (reportStatus) {
        localVarQueryParameter['report_status'] = reportStatus
      }

      if (aggregatedResult) {
        localVarQueryParameter['aggregated_result'] = aggregatedResult
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
      async (
        organizationCode: string,
        storeCode: string,
        startDate?: string,
        endDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (salesDate !== undefined) {
        localVarQueryParameter['sales_date'] =
          (salesDate as any) instanceof Date
            ? (salesDate as any).toISOString().substring(0, 10)
            : salesDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      if (isAllTenantsReturned !== undefined) {
        localVarQueryParameter['is_all_tenants_returned'] = isAllTenantsReturned
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (storeCodes) {
        localVarQueryParameter['store_codes'] = storeCodes
      }

      if (tenantOwnerCompanyCode !== undefined) {
        localVarQueryParameter['tenant_owner_company_code'] =
          tenantOwnerCompanyCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'patchOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPatchRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile: async (
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('patchProfile', 'organizationCode', organizationCode)
      const localVarPath = `/organizations/{organization_code}/profile`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants: async (
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('postTenants', 'organizationCode', organizationCode)
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists('postTenants', 'storeCode', storeCode)
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantsPostRequestInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EditorStoreManagerApi - functional programming interface
 * @export
 */
export const EditorStoreManagerApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    EditorStoreManagerApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStores'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerApi.patchOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['EditorStoreManagerApi.patchProfile']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['EditorStoreManagerApi.postAuth']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['EditorStoreManagerApi.postTenants']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * EditorStoreManagerApi - factory interface
 * @export
 */
export const EditorStoreManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EditorStoreManagerApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenants(
          organizationCode,
          storeCode,
          tenantsPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * EditorStoreManagerApi - object-oriented interface
 * @export
 * @class EditorStoreManagerApi
 * @extends {BaseAPI}
 */
export class EditorStoreManagerApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント情報を更新する
   * @summary テナント更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public patchOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenants(
        organizationCode,
        tenantsPatchRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return EditorStoreManagerApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナントを新規追加する
   * @summary テナント新規追加
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerApi
   */
  public postTenants(
    organizationCode: string,
    storeCode: string,
    tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerApiFp(this.configuration)
      .postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * EditorStoreManagerInOperationCenterApi - axios parameter creator
 * @export
 */
export const EditorStoreManagerInOperationCenterApiAxiosParamCreator =
  function (configuration?: Configuration) {
    return {
      /**
       * 組織詳細取得
       * @summary 組織詳細取得
       * @param {string} organizationCode 組織コード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCode: async (
        organizationCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCode',
          'organizationCode',
          organizationCode
        )
        const localVarPath = `/organizations/{organization_code}`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * ソート順は `date desc, store_code asc` で返却する。
       * @summary 店舗売上報告一覧取得
       * @param {string} organizationCode 組織コード
       * @param {string} [startDate] 検索対象の日付の開始日
       * @param {string} [endDate] 検索対象の日付の終了日
       * @param {string} [q] フィルターする店舗名or店舗コード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
        organizationCode: string,
        startDate?: string,
        endDate?: string,
        q?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/store_sales_report_summaries`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        if (q !== undefined) {
          localVarQueryParameter['q'] = q
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 店舗一覧を返却する。 store_codeでソート済み
       * @summary 店舗一覧取得
       * @param {string} organizationCode 組織コード
       * @param {number} [page] ページ数
       * @param {number} [perPage] 1ページのオブジェクト数
       * @param {string} [q] フィルターする店舗名or店舗コード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStores: async (
        organizationCode: string,
        page?: number,
        perPage?: number,
        q?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStores',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (page !== undefined) {
          localVarQueryParameter['page'] = page
        }

        if (perPage !== undefined) {
          localVarQueryParameter['per_page'] = perPage
        }

        if (q !== undefined) {
          localVarQueryParameter['q'] = q
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       *
       * @summary 店舗取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCode: async (
        organizationCode: string,
        storeCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCode',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCode',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 算出項目のグループを取得
       * @summary 算出項目一覧取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
        organizationCode: string,
        storeCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
       * @summary 店舗ごとの売上報告一覧取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {number} [page] ページ数
       * @param {number} [perPage] 1ページのオブジェクト数
       * @param {string} [startDate] 検索対象の日付の開始日
       * @param {string} [endDate] 検索対象の日付の終了日
       * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
       * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
       * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
        organizationCode: string,
        storeCode: string,
        page?: number,
        perPage?: number,
        startDate?: string,
        endDate?: string,
        tenantCodeOrName?: string,
        reportStatus?: Array<ReportStatusEnum>,
        aggregatedResult?: Array<AggregatedResult>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/sales_reports`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (page !== undefined) {
          localVarQueryParameter['page'] = page
        }

        if (perPage !== undefined) {
          localVarQueryParameter['per_page'] = perPage
        }

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        if (tenantCodeOrName !== undefined) {
          localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
        }

        if (reportStatus) {
          localVarQueryParameter['report_status'] = reportStatus
        }

        if (aggregatedResult) {
          localVarQueryParameter['aggregated_result'] = aggregatedResult
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       *
       * @summary テナント売上報告一覧取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {string} [startDate] 検索対象の日付の開始日
       * @param {string} [endDate] 検索対象の日付の終了日
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
        async (
          organizationCode: string,
          storeCode: string,
          startDate?: string,
          endDate?: string,
          options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
          // verify required parameter 'organizationCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
            'organizationCode',
            organizationCode
          )
          // verify required parameter 'storeCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
            'storeCode',
            storeCode
          )
          const localVarPath =
            `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
              .replace(
                `{${'organization_code'}}`,
                encodeURIComponent(String(organizationCode))
              )
              .replace(
                `{${'store_code'}}`,
                encodeURIComponent(String(storeCode))
              )
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
          let baseOptions
          if (configuration) {
            baseOptions = configuration.baseOptions
          }

          const localVarRequestOptions = {
            method: 'GET',
            ...baseOptions,
            ...options,
          }
          const localVarHeaderParameter = {} as any
          const localVarQueryParameter = {} as any

          // authentication BearerAuth required
          // http bearer authentication required
          await setBearerAuthToObject(localVarHeaderParameter, configuration)

          if (startDate !== undefined) {
            localVarQueryParameter['start_date'] =
              (startDate as any) instanceof Date
                ? (startDate as any).toISOString().substring(0, 10)
                : startDate
          }

          if (endDate !== undefined) {
            localVarQueryParameter['end_date'] =
              (endDate as any) instanceof Date
                ? (endDate as any).toISOString().substring(0, 10)
                : endDate
          }

          setSearchParams(localVarUrlObj, localVarQueryParameter)
          let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {}
          localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers,
          }

          return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
          }
        },
      /**
       *
       * @summary テナントごとの売上報告
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {string} tenantCode テナントコード
       * @param {string} [salesDate] 検索対象の日付
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        salesDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (salesDate !== undefined) {
          localVarQueryParameter['sales_date'] =
            (salesDate as any) instanceof Date
              ? (salesDate as any).toISOString().substring(0, 10)
              : salesDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * ストア下のテナント一覧
       * @summary 【店舗】テナント一覧取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {number} [page] ページ数
       * @param {number} [perPage] 1ページのアイテム数
       * @param {string} [q] フィルターするテナント名 or テナントコード
       * @param {TenantStatusEnum} [status] フィルターするテナントステータス
       * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
       * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
        organizationCode: string,
        storeCode: string,
        page?: number,
        perPage?: number,
        q?: string,
        status?: TenantStatusEnum,
        isOcrRequired?: boolean,
        isTrained?: boolean,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (page !== undefined) {
          localVarQueryParameter['page'] = page
        }

        if (perPage !== undefined) {
          localVarQueryParameter['per_page'] = perPage
        }

        if (q !== undefined) {
          localVarQueryParameter['q'] = q
        }

        if (status !== undefined) {
          localVarQueryParameter['status'] = status
        }

        if (isOcrRequired !== undefined) {
          localVarQueryParameter['is_ocr_required'] = isOcrRequired
        }

        if (isTrained !== undefined) {
          localVarQueryParameter['is_trained'] = isTrained
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * テナント取得
       * @summary テナント取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {string} tenantCode テナントコード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
        organizationCode: string,
        storeCode: string,
        tenantCode: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
          'storeCode',
          storeCode
        )
        // verify required parameter 'tenantCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
          'tenantCode',
          tenantCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
            .replace(
              `{${'tenant_code'}}`,
              encodeURIComponent(String(tenantCode))
            )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
       * @summary 算出ロジック&読取項目一覧取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {string} tenantCode テナントコード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl:
        async (
          organizationCode: string,
          storeCode: string,
          tenantCode: string,
          options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
          // verify required parameter 'organizationCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
            'organizationCode',
            organizationCode
          )
          // verify required parameter 'storeCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
            'storeCode',
            storeCode
          )
          // verify required parameter 'tenantCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl',
            'tenantCode',
            tenantCode
          )
          const localVarPath =
            `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/calculation_dsl`
              .replace(
                `{${'organization_code'}}`,
                encodeURIComponent(String(organizationCode))
              )
              .replace(
                `{${'store_code'}}`,
                encodeURIComponent(String(storeCode))
              )
              .replace(
                `{${'tenant_code'}}`,
                encodeURIComponent(String(tenantCode))
              )
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
          let baseOptions
          if (configuration) {
            baseOptions = configuration.baseOptions
          }

          const localVarRequestOptions = {
            method: 'GET',
            ...baseOptions,
            ...options,
          }
          const localVarHeaderParameter = {} as any
          const localVarQueryParameter = {} as any

          // authentication BearerAuth required
          // http bearer authentication required
          await setBearerAuthToObject(localVarHeaderParameter, configuration)

          setSearchParams(localVarUrlObj, localVarQueryParameter)
          let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {}
          localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers,
          }

          return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
          }
        },
      /**
       * テナント詳細情報の取得
       * @summary テナント詳細情報の取得
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {string} tenantCode テナントコード
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
        async (
          organizationCode: string,
          storeCode: string,
          tenantCode: string,
          options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
          // verify required parameter 'organizationCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
            'organizationCode',
            organizationCode
          )
          // verify required parameter 'storeCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
            'storeCode',
            storeCode
          )
          // verify required parameter 'tenantCode' is not null or undefined
          assertParamExists(
            'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
            'tenantCode',
            tenantCode
          )
          const localVarPath =
            `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
              .replace(
                `{${'organization_code'}}`,
                encodeURIComponent(String(organizationCode))
              )
              .replace(
                `{${'store_code'}}`,
                encodeURIComponent(String(storeCode))
              )
              .replace(
                `{${'tenant_code'}}`,
                encodeURIComponent(String(tenantCode))
              )
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
          let baseOptions
          if (configuration) {
            baseOptions = configuration.baseOptions
          }

          const localVarRequestOptions = {
            method: 'GET',
            ...baseOptions,
            ...options,
          }
          const localVarHeaderParameter = {} as any
          const localVarQueryParameter = {} as any

          // authentication BearerAuth required
          // http bearer authentication required
          await setBearerAuthToObject(localVarHeaderParameter, configuration)

          setSearchParams(localVarUrlObj, localVarQueryParameter)
          let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {}
          localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers,
          }

          return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
          }
        },
      /**
       * 取引企業の一覧取得
       * @summary 取引企業の一覧取得
       * @param {string} organizationCode 組織コード
       * @param {number} [offset] データ取得の基準位置
       * @param {number} [limit] 一回あたりのデータ取得件数
       * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
       * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeTenantOwnerCompanies: async (
        organizationCode: string,
        offset?: number,
        limit?: number,
        tenantOwnerCompanyCodeOrName?: string,
        tenantOwnerCompanyIsActive?: boolean,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenantOwnerCompanies',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_companies`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (offset !== undefined) {
          localVarQueryParameter['offset'] = offset
        }

        if (limit !== undefined) {
          localVarQueryParameter['limit'] = limit
        }

        if (tenantOwnerCompanyCodeOrName !== undefined) {
          localVarQueryParameter['tenant_owner_company_code_or_name'] =
            tenantOwnerCompanyCodeOrName
        }

        if (tenantOwnerCompanyIsActive !== undefined) {
          localVarQueryParameter['tenant_owner_company_is_active'] =
            tenantOwnerCompanyIsActive
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 組織配下のテナント一覧を返す
       * @summary 【組織】テナント一覧
       * @param {string} organizationCode 組織コード
       * @param {number} [page] ページ数
       * @param {number} [perPage] 1ページのオブジェクト数
       * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
       * @param {TenantStatusEnum} [status] フィルターするテナントステータス
       * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
       * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
       * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
       * @param {number} [offset] データ取得の基準位置
       * @param {number} [limit] 一回あたりのデータ取得件数
       * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
       * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      getOrganizationsOrganizationCodeTenants: async (
        organizationCode: string,
        page?: number,
        perPage?: number,
        tenantCodeOrName?: string,
        status?: TenantStatusEnum,
        isOcrRequired?: boolean,
        isTrained?: boolean,
        isAllTenantsReturned?: boolean,
        offset?: number,
        limit?: number,
        storeCodes?: Array<string>,
        tenantOwnerCompanyCode?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeTenants',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenants`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (page !== undefined) {
          localVarQueryParameter['page'] = page
        }

        if (perPage !== undefined) {
          localVarQueryParameter['per_page'] = perPage
        }

        if (tenantCodeOrName !== undefined) {
          localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
        }

        if (status !== undefined) {
          localVarQueryParameter['status'] = status
        }

        if (isOcrRequired !== undefined) {
          localVarQueryParameter['is_ocr_required'] = isOcrRequired
        }

        if (isTrained !== undefined) {
          localVarQueryParameter['is_trained'] = isTrained
        }

        if (isAllTenantsReturned !== undefined) {
          localVarQueryParameter['is_all_tenants_returned'] =
            isAllTenantsReturned
        }

        if (offset !== undefined) {
          localVarQueryParameter['offset'] = offset
        }

        if (limit !== undefined) {
          localVarQueryParameter['limit'] = limit
        }

        if (storeCodes) {
          localVarQueryParameter['store_codes'] = storeCodes
        }

        if (tenantOwnerCompanyCode !== undefined) {
          localVarQueryParameter['tenant_owner_company_code'] =
            tenantOwnerCompanyCode
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 端数処理のtypeと桁数を更新し、それらを返す
       * @summary 端数処理更新
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods:
        async (
          organizationCode: string,
          storeCode: string,
          calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
          options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
          // verify required parameter 'organizationCode' is not null or undefined
          assertParamExists(
            'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
            'organizationCode',
            organizationCode
          )
          // verify required parameter 'storeCode' is not null or undefined
          assertParamExists(
            'patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods',
            'storeCode',
            storeCode
          )
          const localVarPath =
            `/organizations/{organization_code}/stores/{store_code}/calculation_methods`
              .replace(
                `{${'organization_code'}}`,
                encodeURIComponent(String(organizationCode))
              )
              .replace(
                `{${'store_code'}}`,
                encodeURIComponent(String(storeCode))
              )
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
          let baseOptions
          if (configuration) {
            baseOptions = configuration.baseOptions
          }

          const localVarRequestOptions = {
            method: 'PATCH',
            ...baseOptions,
            ...options,
          }
          const localVarHeaderParameter = {} as any
          const localVarQueryParameter = {} as any

          // authentication BearerAuth required
          // http bearer authentication required
          await setBearerAuthToObject(localVarHeaderParameter, configuration)

          localVarHeaderParameter['Content-Type'] = 'application/json'

          setSearchParams(localVarUrlObj, localVarQueryParameter)
          let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {}
          localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers,
          }
          localVarRequestOptions.data = serializeDataIfNeeded(
            calculationMethodPatchRequest,
            localVarRequestOptions,
            configuration
          )

          return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
          }
        },
      /**
       * 取引企業の一括更新
       * @summary 取引企業の一括更新
       * @param {string} organizationCode 組織コード
       * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      patchOrganizationsOrganizationCodeTenantOwnerCompanies: async (
        organizationCode: string,
        tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenantOwnerCompanies',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_companies`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantOwnerCompanyPatchRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * テナント情報を更新する
       * @summary テナント更新
       * @param {string} organizationCode 組織コード
       * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      patchOrganizationsOrganizationCodeTenants: async (
        organizationCode: string,
        tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'patchOrganizationsOrganizationCodeTenants',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenants`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantsPatchRequestInner,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 自身のメンバー情報を更新する
       * @summary 自身のプロフィール更新
       * @param {string} organizationCode 組織コード
       * @param {ProfilePatchRequest} [profilePatchRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      patchProfile: async (
        organizationCode: string,
        profilePatchRequest?: ProfilePatchRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists('patchProfile', 'organizationCode', organizationCode)
        const localVarPath =
          `/organizations/{organization_code}/profile`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          profilePatchRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * Firebase Authを使用したログイン
       * @summary 認証
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      postAuth: async (
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/auth`
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * テナント詳細情報の登録(OPAさんはテナントレジも登録)
       * @summary テナント詳細情報の登録
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {string} tenantCode テナントコード
       * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails:
        async (
          organizationCode: string,
          storeCode: string,
          tenantCode: string,
          tenantDetailsPostRequest?: TenantDetailsPostRequest,
          options: AxiosRequestConfig = {}
        ): Promise<RequestArgs> => {
          // verify required parameter 'organizationCode' is not null or undefined
          assertParamExists(
            'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
            'organizationCode',
            organizationCode
          )
          // verify required parameter 'storeCode' is not null or undefined
          assertParamExists(
            'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
            'storeCode',
            storeCode
          )
          // verify required parameter 'tenantCode' is not null or undefined
          assertParamExists(
            'postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails',
            'tenantCode',
            tenantCode
          )
          const localVarPath =
            `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/details`
              .replace(
                `{${'organization_code'}}`,
                encodeURIComponent(String(organizationCode))
              )
              .replace(
                `{${'store_code'}}`,
                encodeURIComponent(String(storeCode))
              )
              .replace(
                `{${'tenant_code'}}`,
                encodeURIComponent(String(tenantCode))
              )
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
          let baseOptions
          if (configuration) {
            baseOptions = configuration.baseOptions
          }

          const localVarRequestOptions = {
            method: 'POST',
            ...baseOptions,
            ...options,
          }
          const localVarHeaderParameter = {} as any
          const localVarQueryParameter = {} as any

          // authentication BearerAuth required
          // http bearer authentication required
          await setBearerAuthToObject(localVarHeaderParameter, configuration)

          localVarHeaderParameter['Content-Type'] = 'application/json'

          setSearchParams(localVarUrlObj, localVarQueryParameter)
          let headersFromBaseOptions =
            baseOptions && baseOptions.headers ? baseOptions.headers : {}
          localVarRequestOptions.headers = {
            ...localVarHeaderParameter,
            ...headersFromBaseOptions,
            ...options.headers,
          }
          localVarRequestOptions.data = serializeDataIfNeeded(
            tenantDetailsPostRequest,
            localVarRequestOptions,
            configuration
          )

          return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
          }
        },
      /**
       * 取引企業の登録
       * @summary 取引企業の登録
       * @param {string} organizationCode 組織コード
       * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      postOrganizationsOrganizationCodeTenantOwnerCompanies: async (
        organizationCode: string,
        tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'postOrganizationsOrganizationCodeTenantOwnerCompanies',
          'organizationCode',
          organizationCode
        )
        const localVarPath =
          `/organizations/{organization_code}/tenant_owner_companies`.replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantOwnerCompanyPostRequest,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * テナントを新規追加する
       * @summary テナント新規追加
       * @param {string} organizationCode 組織コード
       * @param {string} storeCode 店舗コード
       * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      postTenants: async (
        organizationCode: string,
        storeCode: string,
        tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists('postTenants', 'organizationCode', organizationCode)
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists('postTenants', 'storeCode', storeCode)
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenants`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          tenantsPostRequestInner,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
      /**
       * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
       * @summary 算出ロジック&読取項目作成
       * @param {string} id テナントID
       * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      postTenantsIdCalculationDsl: async (
        id: string,
        calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'id' is not null or undefined
        assertParamExists('postTenantsIdCalculationDsl', 'id', id)
        const localVarPath = `/tenants/{id}/calculation_dsl`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id))
        )
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        localVarHeaderParameter['Content-Type'] = 'application/json'

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }
        localVarRequestOptions.data = serializeDataIfNeeded(
          calculationDSLPostRequestInner,
          localVarRequestOptions,
          configuration
        )

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    }
  }

/**
 * EditorStoreManagerInOperationCenterApi - functional programming interface
 * @export
 */
export const EditorStoreManagerInOperationCenterApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    EditorStoreManagerInOperationCenterApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStores'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationDSLsWithCalcMethod>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantDetailWithTenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantOwnerCompany>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CalculationMethod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.patchOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.patchOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.patchProfile'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['EditorStoreManagerInOperationCenterApi.postAuth']?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.postOrganizationsOrganizationCodeTenantOwnerCompanies'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.postTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'EditorStoreManagerInOperationCenterApi.postTenantsIdCalculationDsl'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * EditorStoreManagerInOperationCenterApi - factory interface
 * @export
 */
export const EditorStoreManagerInOperationCenterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EditorStoreManagerInOperationCenterApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
     * @summary 算出ロジック&読取項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<CalculationDSLsWithCalcMethod> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の取得
     * @summary テナント詳細情報の取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<TenantDetailWithTenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一覧取得
     * @summary 取引企業の一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
     * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      offset?: number,
      limit?: number,
      tenantOwnerCompanyCodeOrName?: string,
      tenantOwnerCompanyIsActive?: boolean,
      options?: any
    ): AxiosPromise<Array<TenantOwnerCompany>> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          offset,
          limit,
          tenantOwnerCompanyCodeOrName,
          tenantOwnerCompanyIsActive,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 端数処理のtypeと桁数を更新し、それらを返す
     * @summary 端数処理更新
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
      organizationCode: string,
      storeCode: string,
      calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
      options?: any
    ): AxiosPromise<Array<CalculationMethod>> {
      return localVarFp
        .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
          organizationCode,
          storeCode,
          calculationMethodPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の一括更新
     * @summary 取引企業の一括更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPatchRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント情報を更新する
     * @summary テナント更新
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchOrganizationsOrganizationCodeTenants(
          organizationCode,
          tenantsPatchRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント詳細情報の登録(OPAさんはテナントレジも登録)
     * @summary テナント詳細情報の登録
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      tenantDetailsPostRequest?: TenantDetailsPostRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
          organizationCode,
          storeCode,
          tenantCode,
          tenantDetailsPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 取引企業の登録
     * @summary 取引企業の登録
     * @param {string} organizationCode 組織コード
     * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrganizationsOrganizationCodeTenantOwnerCompanies(
      organizationCode: string,
      tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postOrganizationsOrganizationCodeTenantOwnerCompanies(
          organizationCode,
          tenantOwnerCompanyPostRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナントを新規追加する
     * @summary テナント新規追加
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenants(
      organizationCode: string,
      storeCode: string,
      tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenants(
          organizationCode,
          storeCode,
          tenantsPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
     * @summary 算出ロジック&読取項目作成
     * @param {string} id テナントID
     * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantsIdCalculationDsl(
      id: string,
      calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .postTenantsIdCalculationDsl(
          id,
          calculationDSLPostRequestInner,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * EditorStoreManagerInOperationCenterApi - object-oriented interface
 * @export
 * @class EditorStoreManagerInOperationCenterApi
 * @extends {BaseAPI}
 */
export class EditorStoreManagerInOperationCenterApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジックを返却する。 対象となっている算出項目は全てレスポンスに含まれます。
   * @summary 算出ロジック&読取項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeCalculationDsl(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の取得
   * @summary テナント詳細情報の取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一覧取得
   * @summary 取引企業の一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {string} [tenantOwnerCompanyCodeOrName] フィルターする取引企業コード or 取引企業名 (部分一致検索用)
   * @param {boolean} [tenantOwnerCompanyIsActive] 取引企業の利用中停止中ステータス
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    offset?: number,
    limit?: number,
    tenantOwnerCompanyCodeOrName?: string,
    tenantOwnerCompanyIsActive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        offset,
        limit,
        tenantOwnerCompanyCodeOrName,
        tenantOwnerCompanyIsActive,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 端数処理のtypeと桁数を更新し、それらを返す
   * @summary 端数処理更新
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<CalculationMethodPatchRequest>} [calculationMethodPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
    organizationCode: string,
    storeCode: string,
    calculationMethodPatchRequest?: Array<CalculationMethodPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeStoresStoreCodeCalculationMethods(
        organizationCode,
        storeCode,
        calculationMethodPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の一括更新
   * @summary 取引企業の一括更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPatchRequest>} [tenantOwnerCompanyPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public patchOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPatchRequest?: Array<TenantOwnerCompanyPatchRequest>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPatchRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント情報を更新する
   * @summary テナント更新
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantsPatchRequestInner>} [tenantsPatchRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public patchOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    tenantsPatchRequestInner?: Array<TenantsPatchRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .patchOrganizationsOrganizationCodeTenants(
        organizationCode,
        tenantsPatchRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント詳細情報の登録(OPAさんはテナントレジも登録)
   * @summary テナント詳細情報の登録
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {TenantDetailsPostRequest} [tenantDetailsPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    tenantDetailsPostRequest?: TenantDetailsPostRequest,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .postOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCodeDetails(
        organizationCode,
        storeCode,
        tenantCode,
        tenantDetailsPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 取引企業の登録
   * @summary 取引企業の登録
   * @param {string} organizationCode 組織コード
   * @param {Array<TenantOwnerCompanyPostRequest>} [tenantOwnerCompanyPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public postOrganizationsOrganizationCodeTenantOwnerCompanies(
    organizationCode: string,
    tenantOwnerCompanyPostRequest?: Array<TenantOwnerCompanyPostRequest>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .postOrganizationsOrganizationCodeTenantOwnerCompanies(
        organizationCode,
        tenantOwnerCompanyPostRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナントを新規追加する
   * @summary テナント新規追加
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {Array<TenantsPostRequestInner>} [tenantsPostRequestInner]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public postTenants(
    organizationCode: string,
    storeCode: string,
    tenantsPostRequestInner?: Array<TenantsPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .postTenants(
        organizationCode,
        storeCode,
        tenantsPostRequestInner,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出ロジック&読み取り項目(OCRFormat)を登録する。 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは`0`とする
   * @summary 算出ロジック&読取項目作成
   * @param {string} id テナントID
   * @param {Array<CalculationDSLPostRequestInner>} [calculationDSLPostRequestInner] 読み取り項目のIDはクライアント側で生成する。 対象でない算出ロジックは&#x60;0&#x60;とする
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EditorStoreManagerInOperationCenterApi
   */
  public postTenantsIdCalculationDsl(
    id: string,
    calculationDSLPostRequestInner?: Array<CalculationDSLPostRequestInner>,
    options?: AxiosRequestConfig
  ) {
    return EditorStoreManagerInOperationCenterApiFp(this.configuration)
      .postTenantsIdCalculationDsl(id, calculationDSLPostRequestInner, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ViewerApi - axios parameter creator
 * @export
 */
export const ViewerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode: async (
      organizationCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCode',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries: async (
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoreSalesReportSummaries',
        'organizationCode',
        organizationCode
      )
      const localVarPath =
        `/organizations/{organization_code}/store_sales_report_summaries`.replace(
          `{${'organization_code'}}`,
          encodeURIComponent(String(organizationCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStores',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/stores`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCode',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups: async (
      organizationCode: string,
      storeCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/calculation_groups`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeSalesReports',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/sales_reports`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substring(0, 10)
            : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString().substring(0, 10)
            : endDate
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (reportStatus) {
        localVarQueryParameter['report_status'] = reportStatus
      }

      if (aggregatedResult) {
        localVarQueryParameter['aggregated_result'] = aggregatedResult
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries:
      async (
        organizationCode: string,
        storeCode: string,
        startDate?: string,
        endDate?: string,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        // verify required parameter 'organizationCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'organizationCode',
          organizationCode
        )
        // verify required parameter 'storeCode' is not null or undefined
        assertParamExists(
          'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries',
          'storeCode',
          storeCode
        )
        const localVarPath =
          `/organizations/{organization_code}/stores/{store_code}/tenant_sales_report_summaries`
            .replace(
              `{${'organization_code'}}`,
              encodeURIComponent(String(organizationCode))
            )
            .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
        let baseOptions
        if (configuration) {
          baseOptions = configuration.baseOptions
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        }
        const localVarHeaderParameter = {} as any
        const localVarQueryParameter = {} as any

        // authentication BearerAuth required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] =
            (startDate as any) instanceof Date
              ? (startDate as any).toISOString().substring(0, 10)
              : startDate
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] =
            (endDate as any) instanceof Date
              ? (endDate as any).toISOString().substring(0, 10)
              : endDate
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter)
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {}
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        }

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        }
      },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}/sales_report`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (salesDate !== undefined) {
        localVarQueryParameter['sales_date'] =
          (salesDate as any) instanceof Date
            ? (salesDate as any).toISOString().substring(0, 10)
            : salesDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants: async (
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenants',
        'storeCode',
        storeCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode: async (
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'organizationCode',
        organizationCode
      )
      // verify required parameter 'storeCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'storeCode',
        storeCode
      )
      // verify required parameter 'tenantCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode',
        'tenantCode',
        tenantCode
      )
      const localVarPath =
        `/organizations/{organization_code}/stores/{store_code}/tenants/{tenant_code}`
          .replace(
            `{${'organization_code'}}`,
            encodeURIComponent(String(organizationCode))
          )
          .replace(`{${'store_code'}}`, encodeURIComponent(String(storeCode)))
          .replace(`{${'tenant_code'}}`, encodeURIComponent(String(tenantCode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants: async (
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists(
        'getOrganizationsOrganizationCodeTenants',
        'organizationCode',
        organizationCode
      )
      const localVarPath = `/organizations/{organization_code}/tenants`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['per_page'] = perPage
      }

      if (tenantCodeOrName !== undefined) {
        localVarQueryParameter['tenant_code_or_name'] = tenantCodeOrName
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (isOcrRequired !== undefined) {
        localVarQueryParameter['is_ocr_required'] = isOcrRequired
      }

      if (isTrained !== undefined) {
        localVarQueryParameter['is_trained'] = isTrained
      }

      if (isAllTenantsReturned !== undefined) {
        localVarQueryParameter['is_all_tenants_returned'] = isAllTenantsReturned
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (storeCodes) {
        localVarQueryParameter['store_codes'] = storeCodes
      }

      if (tenantOwnerCompanyCode !== undefined) {
        localVarQueryParameter['tenant_owner_company_code'] =
          tenantOwnerCompanyCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile: async (
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationCode' is not null or undefined
      assertParamExists('patchProfile', 'organizationCode', organizationCode)
      const localVarPath = `/organizations/{organization_code}/profile`.replace(
        `{${'organization_code'}}`,
        encodeURIComponent(String(organizationCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePatchRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ViewerApi - functional programming interface
 * @export
 */
export const ViewerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ViewerApiAxiosParamCreator(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCode(
          organizationCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['ViewerApi.getOrganizationsOrganizationCode']?.[
          index
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoreSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Store>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStores'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CalculationGroup>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<SalesReportWithTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCodeSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<TenantSalesReportSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantSalesReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<StoreTenant>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap[
          'ViewerApi.getOrganizationsOrganizationCodeTenants'
        ]?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchProfile(
        organizationCode,
        profilePatchRequest,
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['ViewerApi.patchProfile']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAuth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Authorization>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAuth(
        options
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath =
        operationServerMap['ViewerApi.postAuth']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ViewerApi - factory interface
 * @export
 */
export const ViewerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ViewerApiFp(configuration)
  return {
    /**
     * 組織詳細取得
     * @summary 組織詳細取得
     * @param {string} organizationCode 組織コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCode(
      organizationCode: string,
      options?: any
    ): AxiosPromise<Organization> {
      return localVarFp
        .getOrganizationsOrganizationCode(organizationCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, store_code asc` で返却する。
     * @summary 店舗売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoreSalesReportSummaries(
      organizationCode: string,
      startDate?: string,
      endDate?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Array<StoreSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
          organizationCode,
          startDate,
          endDate,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 店舗一覧を返却する。 store_codeでソート済み
     * @summary 店舗一覧取得
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [q] フィルターする店舗名or店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStores(
      organizationCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      options?: any
    ): AxiosPromise<Array<Store>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStores(
          organizationCode,
          page,
          perPage,
          q,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 店舗取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCode(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<Store> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCode(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 算出項目のグループを取得
     * @summary 算出項目一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
      organizationCode: string,
      storeCode: string,
      options?: any
    ): AxiosPromise<CalculationGroup> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
          organizationCode,
          storeCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
     * @summary 店舗ごとの売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
     * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      startDate?: string,
      endDate?: string,
      tenantCodeOrName?: string,
      reportStatus?: Array<ReportStatusEnum>,
      aggregatedResult?: Array<AggregatedResult>,
      options?: any
    ): AxiosPromise<Array<SalesReportWithTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
          organizationCode,
          storeCode,
          page,
          perPage,
          startDate,
          endDate,
          tenantCodeOrName,
          reportStatus,
          aggregatedResult,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナント売上報告一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} [startDate] 検索対象の日付の開始日
     * @param {string} [endDate] 検索対象の日付の終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
      organizationCode: string,
      storeCode: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<Array<TenantSalesReportSummary>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
          organizationCode,
          storeCode,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary テナントごとの売上報告
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {string} [salesDate] 検索対象の日付
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      salesDate?: string,
      options?: any
    ): AxiosPromise<TenantSalesReport> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
          organizationCode,
          storeCode,
          tenantCode,
          salesDate,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * ストア下のテナント一覧
     * @summary 【店舗】テナント一覧取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのアイテム数
     * @param {string} [q] フィルターするテナント名 or テナントコード
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenants(
      organizationCode: string,
      storeCode: string,
      page?: number,
      perPage?: number,
      q?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      options?: any
    ): AxiosPromise<Array<StoreTenant>> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
          organizationCode,
          storeCode,
          page,
          perPage,
          q,
          status,
          isOcrRequired,
          isTrained,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * テナント取得
     * @summary テナント取得
     * @param {string} organizationCode 組織コード
     * @param {string} storeCode 店舗コード
     * @param {string} tenantCode テナントコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
      organizationCode: string,
      storeCode: string,
      tenantCode: string,
      options?: any
    ): AxiosPromise<Tenant> {
      return localVarFp
        .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
          organizationCode,
          storeCode,
          tenantCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 組織配下のテナント一覧を返す
     * @summary 【組織】テナント一覧
     * @param {string} organizationCode 組織コード
     * @param {number} [page] ページ数
     * @param {number} [perPage] 1ページのオブジェクト数
     * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
     * @param {TenantStatusEnum} [status] フィルターするテナントステータス
     * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
     * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
     * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
     * @param {number} [offset] データ取得の基準位置
     * @param {number} [limit] 一回あたりのデータ取得件数
     * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
     * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationsOrganizationCodeTenants(
      organizationCode: string,
      page?: number,
      perPage?: number,
      tenantCodeOrName?: string,
      status?: TenantStatusEnum,
      isOcrRequired?: boolean,
      isTrained?: boolean,
      isAllTenantsReturned?: boolean,
      offset?: number,
      limit?: number,
      storeCodes?: Array<string>,
      tenantOwnerCompanyCode?: string,
      options?: any
    ): AxiosPromise<GetOrganizationsOrganizationCodeTenants200Response> {
      return localVarFp
        .getOrganizationsOrganizationCodeTenants(
          organizationCode,
          page,
          perPage,
          tenantCodeOrName,
          status,
          isOcrRequired,
          isTrained,
          isAllTenantsReturned,
          offset,
          limit,
          storeCodes,
          tenantOwnerCompanyCode,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * 自身のメンバー情報を更新する
     * @summary 自身のプロフィール更新
     * @param {string} organizationCode 組織コード
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchProfile(
      organizationCode: string,
      profilePatchRequest?: ProfilePatchRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchProfile(organizationCode, profilePatchRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Firebase Authを使用したログイン
     * @summary 認証
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAuth(options?: any): AxiosPromise<Array<Authorization>> {
      return localVarFp
        .postAuth(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ViewerApi - object-oriented interface
 * @export
 * @class ViewerApi
 * @extends {BaseAPI}
 */
export class ViewerApi extends BaseAPI {
  /**
   * 組織詳細取得
   * @summary 組織詳細取得
   * @param {string} organizationCode 組織コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCode(
    organizationCode: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCode(organizationCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, store_code asc` で返却する。
   * @summary 店舗売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoreSalesReportSummaries(
    organizationCode: string,
    startDate?: string,
    endDate?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoreSalesReportSummaries(
        organizationCode,
        startDate,
        endDate,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 店舗一覧を返却する。 store_codeでソート済み
   * @summary 店舗一覧取得
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [q] フィルターする店舗名or店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStores(
    organizationCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStores(
        organizationCode,
        page,
        perPage,
        q,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 店舗取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCode(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCode(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 算出項目のグループを取得
   * @summary 算出項目一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
    organizationCode: string,
    storeCode: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeCalculationGroups(
        organizationCode,
        storeCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ソート順は `date desc, tenant_code asc, register.index asc` で返却する。売上報告のステータスは `submitted` 以降である。
   * @summary 店舗ごとの売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {Array<ReportStatusEnum>} [reportStatus] フィルターするreport_statusの項目
   * @param {Array<AggregatedResult>} [aggregatedResult] 完全一致フィルターで絞り込みに使用する項目
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    startDate?: string,
    endDate?: string,
    tenantCodeOrName?: string,
    reportStatus?: Array<ReportStatusEnum>,
    aggregatedResult?: Array<AggregatedResult>,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeSalesReports(
        organizationCode,
        storeCode,
        page,
        perPage,
        startDate,
        endDate,
        tenantCodeOrName,
        reportStatus,
        aggregatedResult,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナント売上報告一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} [startDate] 検索対象の日付の開始日
   * @param {string} [endDate] 検索対象の日付の終了日
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
    organizationCode: string,
    storeCode: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReportSummaries(
        organizationCode,
        storeCode,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary テナントごとの売上報告
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {string} [salesDate] 検索対象の日付
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    salesDate?: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantSalesReports(
        organizationCode,
        storeCode,
        tenantCode,
        salesDate,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * ストア下のテナント一覧
   * @summary 【店舗】テナント一覧取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのアイテム数
   * @param {string} [q] フィルターするテナント名 or テナントコード
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenants(
    organizationCode: string,
    storeCode: string,
    page?: number,
    perPage?: number,
    q?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenants(
        organizationCode,
        storeCode,
        page,
        perPage,
        q,
        status,
        isOcrRequired,
        isTrained,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * テナント取得
   * @summary テナント取得
   * @param {string} organizationCode 組織コード
   * @param {string} storeCode 店舗コード
   * @param {string} tenantCode テナントコード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
    organizationCode: string,
    storeCode: string,
    tenantCode: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeStoresStoreCodeTenantsTenantCode(
        organizationCode,
        storeCode,
        tenantCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 組織配下のテナント一覧を返す
   * @summary 【組織】テナント一覧
   * @param {string} organizationCode 組織コード
   * @param {number} [page] ページ数
   * @param {number} [perPage] 1ページのオブジェクト数
   * @param {string} [tenantCodeOrName] フィルターするテナントコード or テナント名
   * @param {TenantStatusEnum} [status] フィルターするテナントステータス
   * @param {boolean} [isOcrRequired] フィルターするテナントOCR有無
   * @param {boolean} [isTrained] テナントの研修実施状況でのフィルター
   * @param {boolean} [isAllTenantsReturned] 紐付け済み以外も含む全テナントを返すかどうか
   * @param {number} [offset] データ取得の基準位置
   * @param {number} [limit] 一回あたりのデータ取得件数
   * @param {Array<string>} [storeCodes] フィルターする店舗コードのリスト
   * @param {string} [tenantOwnerCompanyCode] フィルターする取引企業コード (完全一致検索用)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public getOrganizationsOrganizationCodeTenants(
    organizationCode: string,
    page?: number,
    perPage?: number,
    tenantCodeOrName?: string,
    status?: TenantStatusEnum,
    isOcrRequired?: boolean,
    isTrained?: boolean,
    isAllTenantsReturned?: boolean,
    offset?: number,
    limit?: number,
    storeCodes?: Array<string>,
    tenantOwnerCompanyCode?: string,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .getOrganizationsOrganizationCodeTenants(
        organizationCode,
        page,
        perPage,
        tenantCodeOrName,
        status,
        isOcrRequired,
        isTrained,
        isAllTenantsReturned,
        offset,
        limit,
        storeCodes,
        tenantOwnerCompanyCode,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 自身のメンバー情報を更新する
   * @summary 自身のプロフィール更新
   * @param {string} organizationCode 組織コード
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public patchProfile(
    organizationCode: string,
    profilePatchRequest?: ProfilePatchRequest,
    options?: AxiosRequestConfig
  ) {
    return ViewerApiFp(this.configuration)
      .patchProfile(organizationCode, profilePatchRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Firebase Authを使用したログイン
   * @summary 認証
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewerApi
   */
  public postAuth(options?: AxiosRequestConfig) {
    return ViewerApiFp(this.configuration)
      .postAuth(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
