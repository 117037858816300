import React from 'react'
import clsx from 'clsx'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { Icon } from 'src/components/atoms'
import SelectWithGroupOption, {
  OptionGroup,
  Option,
} from 'src/components/molecules/SelectWithGroupOption'
import style from './styles.module.scss'

export type Cell = {
  options: OptionGroup[]
  defaultValue?: string | number
  name: string
  size: 'medium' | 'large'
  ref?: React.Ref<HTMLSelectElement>
}

export type EditCalculationDSLBodyRowProps = {
  cells: Cell[]
  emptyCellCount?: number
  isGray?: boolean
  onClickAdd: () => void
  onOpenSelect: (cellIndex: number) => void
  onChangeSelect: (option: Option, cellIndex: number) => void
  disabledRow?: boolean
  disabledAddButton?: boolean
  valueToRenderEmpty?: string
} & React.StyledProps

const EditCalculationDSLBodyRow: React.StyledFC<
  EditCalculationDSLBodyRowProps
> = ({
  className,
  cells,
  emptyCellCount = 0,
  isGray = false,
  onClickAdd,
  onOpenSelect,
  onChangeSelect,
  disabledRow,
  disabledAddButton,
  valueToRenderEmpty,
}: EditCalculationDSLBodyRowProps) => {
  const emptyCells = (num: number): React.ReactNode => {
    const countArray = Array.from({ length: num }, (_, i) => i + 1)
    return countArray.map((count) => (
      <TableCell key={count} className={style.cell} />
    ))
  }

  return (
    <TableRow className={clsx(className, style.row, isGray && style.grayRow)}>
      {cells.map((cell, cellIndex) => (
        <TableCell key={`${cell.name}`} className={style.cell}>
          <SelectWithGroupOption
            className={clsx(style.select, style[cell.size])}
            optionGroups={cell.options}
            defaultValue={cell.defaultValue}
            name={cell.name}
            variant="outlined"
            onOpen={() => onOpenSelect(cellIndex)}
            onChange={(option) => onChangeSelect(option, cellIndex)}
            disabled={disabledRow}
            valueToRenderEmpty={valueToRenderEmpty}
          />
        </TableCell>
      ))}

      <TableCell key="icon" className={style.cell}>
        <IconButton
          onClick={onClickAdd}
          className={style.icon}
          disabled={disabledRow || disabledAddButton}
          size="large"
        >
          <Icon icon="plus" />
        </IconButton>
      </TableCell>
      {emptyCellCount > 0 && emptyCells(emptyCellCount)}
    </TableRow>
  )
}

export default EditCalculationDSLBodyRow
