import { CalculationTableHeaderCellSize, IconName } from 'src/components/atoms'
import { Path } from 'src/components/molecules/BreadcrumbsPipe'
import { Cell as Column } from 'src/components/molecules/CalculationTableHeaderRow'
import { OptionGroup } from 'src/components/molecules/SelectWithGroupOption'
import {
  PostCalculationDSLError,
  isPostCalculationDSLError,
} from 'src/domain/error'
import { parseCalculationDSLsToTokens } from 'src/slices/calculation/parser'
import Token from 'src/slices/calculation/token'
import TokenType from 'src/slices/calculation/token_type'
import {
  CalculationItemState,
  selectCalculationItemById,
} from 'src/slices/calculationItems/calculationItemsSlice'
import { EditOCRFormat } from 'src/slices/editCalculations/editCalculationsSlice'
import { CalculationDSL, CalculationItem } from 'src/slices/services/api'
import errorMessage from 'src/slices/services/error_message'
import { groupBy, injectIndex } from 'src/utils/array'
import { Cell as SelectCell } from '../components/EditCalculationDSLBodyRow'
import { BodyRow } from '../components/EditCalculationDSLTable'

// NOTE: 一度に追加させる cell のセットが [開きカッコ, 名前, 閉じカッコ, 四則演算]の4つなので
export const CELL_SET_COUNT = 4

export type RowFieldValue = {
  [key: string]: string | undefined
}

export type FieldValues = {
  rows: RowFieldValue[][]
}

type FieldName = 'open' | 'value' | 'close' | 'symbol'

export const FieldNameList: FieldName[] = ['open', 'value', 'close', 'symbol']

type FormNameWithValue = {
  name: string
  value?: string | number
}

type TableData = {
  rows: BodyRow[]
  maxCellSetCount: number
}

interface EditCalculationDSLPresenter {
  breadcrumbs(storeName?: string, tenantName?: string): Path[]
  columns(addCellSetCount?: number): Column[]
  tableData(
    calculationItems: CalculationItem[],
    editOCRFormats: EditOCRFormat[],
    restoredEditCalculationDSLs?: FormNameWithValue[][]
  ): TableData
  convertFormValuesToDSLs(values: FieldValues): string[]
  convertEditDataToFormValues(
    dsls: CalculationDSL[],
    calculationItems: CalculationItem[],
    editOCRFormats: EditOCRFormat[]
  ): FormNameWithValue[][]
  convertErrorToMessage(
    error: PostCalculationDSLError,
    calculationItemsState: CalculationItemState
  ): string
  convertErrorType(e: unknown): PostCalculationDSLError
}

const emptyColumnSet = [
  {
    text: '',
    size: 'large' as CalculationTableHeaderCellSize,
  },
  {
    text: '',
    size: 'small' as CalculationTableHeaderCellSize,
  },
  {
    text: '',
    size: 'small' as CalculationTableHeaderCellSize,
  },
  {
    text: '',
    size: 'small' as CalculationTableHeaderCellSize,
  },
]

const baseColumns = (addCellSetCount = 0): Column[] => {
  const base = [
    {
      text: 'No.',
      size: 'medium' as CalculationTableHeaderCellSize,
    },
    {
      text: '算出項目',
      size: 'large' as CalculationTableHeaderCellSize,
    },
    {
      text: '',
      size: 'small' as CalculationTableHeaderCellSize,
    },
    {
      text: '読取項目',
      size: 'large' as CalculationTableHeaderCellSize,
      colSpan: 2,
    },
    {
      text: '',
      size: 'small' as CalculationTableHeaderCellSize,
    },
    {
      text: '',
      size: 'small' as CalculationTableHeaderCellSize,
    },
    {
      text: '',
      size: 'small' as CalculationTableHeaderCellSize,
    },
  ]

  const emptyColumns = Array(addCellSetCount).fill(emptyColumnSet).flat()

  return injectIndex([...base, ...emptyColumns])
}

const openBracket: OptionGroup[] = [
  {
    options: [
      { title: '', value: '' },
      { title: '(', value: '((' },
    ],
  },
]

const closeBracket: OptionGroup[] = [
  {
    options: [
      { title: '', value: '' },
      { title: ')', value: '))' },
    ],
  },
]

const operatorSymbols: OptionGroup[] = [
  {
    options: [
      { title: '', value: '' },
      { title: '+', value: '+' },
      { title: '-', value: '-' },
      { title: '×', value: '*' },
      { title: '÷', value: '/' },
    ],
  },
]

const generateOptionGroup = (
  calculationItems: CalculationItem[],
  editOCRFormats: EditOCRFormat[],
  currentCalculationItemId: string,
  referencedCalculationItemIds: string[]
): OptionGroup[] => {
  const readItemOptions = editOCRFormats
    .filter((item) => item.calculationId === currentCalculationItemId)
    .map((item) => {
      return {
        // TODO: state の型修正後に修正
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        title: item.readItem!,
        value: `{{${item.id}}}`,
      }
    })
  const readItemOptionGroup = {
    title: '読取項目',
    isIndent: true,
    options: readItemOptions,
  }
  const calculationOptionGroup = {
    title: '算出項目',
    options: calculationItems
      .filter(
        (item) =>
          item.id !== currentCalculationItemId &&
          !referencedCalculationItemIds.includes(item.id)
      )
      .map((item) => {
        return {
          title: item.name,
          value: `<<${item.id}>>`,
        }
      }),
  }
  const deselect = {
    options: [
      {
        title: '',
        value: '',
      },
    ],
  }
  const calculator = {
    options: [
      {
        title: '数値を入力する',
        value: 'inputNumber',
        icon: 'calculator' as IconName,
      },
    ],
  }

  const resultOptionGroup = [deselect, calculationOptionGroup, calculator]

  if (readItemOptions.length > 0) {
    resultOptionGroup.splice(1, 0, readItemOptionGroup)
  }

  return resultOptionGroup
}

const generateSelectCell = (
  name: string,
  options: OptionGroup[],
  size: 'medium' | 'large',
  hasFixedDSL: boolean,
  defaultValues: FormNameWithValue[] = []
): SelectCell => {
  const defaultValue = defaultValues.find((value) => value.name === name)
  return {
    name,
    options,
    size,
    defaultValue: hasFixedDSL ? undefined : defaultValue?.value,
  }
}

const convertTokenToValue = (
  token: Token,
  editOCRFormatId?: string
): string => {
  return editOCRFormatId ? `{{${editOCRFormatId}}}` : token.raw
}

const isSimpleFormula = (tokens: Token[]): boolean => {
  const allowedSymbols = ['+', '-']
  const operationTokens = tokens.filter(
    (token) => token.tokenType === TokenType.Operation
  )

  return operationTokens.every((token) =>
    allowedSymbols.includes(token.toDisplayString())
  )
}

const convertEditOCRFormatToFormValue = (
  editOCRFormat: EditOCRFormat,
  rowIndex: number,
  cellSetIndex: number
): FormNameWithValue[] => {
  const valueName = `rows.${rowIndex}.${cellSetIndex}.value`
  const addedFormValues = [
    {
      // TODO: 括弧をつける処理は convertItemIdToDSLId 的な関数に閉じる
      value: `{{${editOCRFormat.id}}}`,
      name: valueName,
    },
  ]

  // NOTE: cellSetIndex = 0 の場合は、それよりも左に式がないため、四則演算子が不要
  if (cellSetIndex > 0) {
    // TODO: getFormName 的な関数に閉じる
    const symbolName = `rows.${rowIndex}.${cellSetIndex - 1}.symbol`
    addedFormValues.unshift({
      value: '+',
      name: symbolName,
    })
  }

  return addedFormValues
}

export const generateCellSet = (
  rowIndex: number,
  cellIndex: number,
  calculationItems: CalculationItem[],
  editOCRFormats: EditOCRFormat[],
  calculationItemId: string,
  defaultValues?: FormNameWithValue[]
): SelectCell[] => {
  const calculationItemsWithFixedDSLs = calculationItems.filter(
    (calculationItem) => calculationItem.fixedDsl
  )

  const referencedCalculationItemIds = calculationItemsWithFixedDSLs
    .filter(
      (calculationItemsWithFixedDSL) =>
        calculationItemsWithFixedDSL.fixedDsl?.includes(calculationItemId)
    )
    .map((calculationItemsWithFixedDSL) => calculationItemsWithFixedDSL.id)

  const name = (prefix: string) => {
    return `rows.${rowIndex}.${cellIndex / CELL_SET_COUNT}.${prefix}`
  }
  const hasFixedDSL = Boolean(
    calculationItems.find(
      (calculationItem) => calculationItem.id === calculationItemId
    )?.fixedDsl
  )

  return [
    generateSelectCell(
      name('open'),
      openBracket,
      'medium',
      hasFixedDSL,
      defaultValues
    ),
    generateSelectCell(
      name('value'),
      generateOptionGroup(
        calculationItems,
        editOCRFormats,
        calculationItemId,
        referencedCalculationItemIds
      ),
      'large',
      hasFixedDSL,
      defaultValues
    ),
    generateSelectCell(
      name('close'),
      closeBracket,
      'medium',
      hasFixedDSL,
      defaultValues
    ),
    generateSelectCell(
      name('symbol'),
      operatorSymbols,
      'medium',
      hasFixedDSL,
      defaultValues
    ),
  ]
}

const generatedDefaultCalculationDSLCells = (
  rowIndex: number,
  calculationItems: CalculationItem[],
  editOCRFormats: EditOCRFormat[],
  calculationItemId: string,
  defaultValues: FormNameWithValue[],
  cellIndex = 0
): SelectCell[] => {
  return generateCellSet(
    rowIndex,
    cellIndex,
    calculationItems,
    editOCRFormats,
    calculationItemId,
    defaultValues
  )
}

const generateCalculationDSLRow = (
  rowIndex: number,
  calculationItems: CalculationItem[],
  editOCRFormats: EditOCRFormat[],
  calculationItemId: string,
  defaultValues: FormNameWithValue[] = []
): BodyRow => {
  const disabledRow =
    calculationItems.find((item) => item.id === calculationItemId)?.fixedDsl !==
    null

  const calculationNameList = calculationItems.map(
    (calculationItem) => calculationItem.name
  )

  const hasFixedDSL = Boolean(
    calculationItems.find(
      (calculationItem) => calculationItem.id === calculationItemId
    )?.fixedDsl
  )

  const cellSetCount = defaultValues.filter((fieldValues) => {
    return fieldValues.name.match(/value/)
  }).length

  const cellLength = hasFixedDSL ? 1 : cellSetCount || 1

  const cells = new Array(cellLength).fill(null).flatMap((_, i) => {
    return generatedDefaultCalculationDSLCells(
      rowIndex,
      calculationItems,
      editOCRFormats,
      calculationItemId,
      hasFixedDSL ? [] : defaultValues,
      i * CELL_SET_COUNT
    )
  })

  return {
    cells,
    title: calculationNameList[rowIndex],
    number: `${rowIndex + 1}`,
    disabledRow,
    // NOTE: 画面を開いた時には、四則演算子が埋まっていないため、プラスボタンは非活性
    disabledAddButton: true,
  }
}

const tokenTypeToFieldName = (type: TokenType): FieldName => {
  switch (type) {
    case TokenType.Operation:
      return 'symbol'
    case TokenType.LeftParenthesis:
      return 'open'
    case TokenType.RightParenthesis:
      return 'close'
    case TokenType.ReadItem:
    case TokenType.CalculationItem:
    case TokenType.Number:
    default:
      return 'value'
  }
}

const convertFromOnlyEditData = (
  calculationItems: CalculationItem[],
  groupedEditOCRFormats: Map<string, EditOCRFormat[]>
): FormNameWithValue[][] => {
  return calculationItems.map((calculationItem, rowIndex) => {
    const calculationId = calculationItem.id
    const editOCRFormatsInRow = groupedEditOCRFormats.get(calculationId)

    if (!editOCRFormatsInRow) return []

    return editOCRFormatsInRow.flatMap((editOCRFormat, cellIndex) => {
      return convertEditOCRFormatToFormValue(editOCRFormat, rowIndex, cellIndex)
    })
  })
}

// FIXME: リファクタリングが必要
const convertFromReferenceRestoreDSL = (
  dsls: CalculationDSL[],
  groupedEditOCRFormats: Map<string, EditOCRFormat[]>
): FormNameWithValue[][] => {
  const dslsToParse = dsls.map((dsl) => {
    return {
      ...dsl,
      readItems: (dsl.ocrFormats ?? []).map((format) => {
        return {
          id: format.id,
          name: format.readItem,
        }
      }),
    }
  })

  const parseDSLs = parseCalculationDSLsToTokens(dslsToParse)

  const dslValues = parseDSLs
    .map((tokens, rowIndex) => {
      let cellSetIndex = 0
      let skip = false

      const calculationId = dsls[rowIndex].calculationItem.id
      const editOCRFormatsInRow = groupedEditOCRFormats.get(calculationId)

      const resultFormValues: FormNameWithValue[] = []
      tokens.forEach((token) => {
        // NOTE: editOCRFormatId の場合、 DSL で使われている readItemId と違うため、
        // raw データを使わず、 editOCRFormats から display string で find する
        const editOCRFormatId = editOCRFormatsInRow?.find(
          (format) => format.readItem === token.toDisplayString()
        )?.id

        const fieldName = tokenTypeToFieldName(token.tokenType)

        // NOTE: cellSet は [開き括弧, value, 閉じ括弧, 四則演算子] までが一つのセットなので
        // 一度 value で return をした場合は ( = step1 で入力項目を削除 or 名前を変更した時)
        // 次の四則演算子までスキップしたい、
        // そのため、 'open' or 'value' がきたらスキップフラグを落とすようにしている
        if (skip && (fieldName === 'open' || fieldName === 'value')) {
          skip = false
        }

        // NOTE: ReadItem でかつ editOCRFormatId が見つからない時は、
        // DSL にある読取項目名の名前を変更した時 or DSL にある読取項目名を削除した時、
        // なので DB に登録されている DSL と関係ないため、 return する
        // また、 DSL がない場合もスキップする
        if (
          (token.tokenType === TokenType.ReadItem && !editOCRFormatId) ||
          token.raw === '0' ||
          skip
        ) {
          skip = true
          return
        }

        // NOTE:
        // DSL がない場合 DB には "0" で登録される。
        // しかし画面に 0 と表示すると、数字の 0 なのか、何もないという意味の 0 なのかがわからないため、
        // 0 の場合は、画面に何かが表示されないようにするため、undefined とする
        const value =
          token.raw === '0'
            ? undefined
            : convertTokenToValue(token, editOCRFormatId)
        const name = `rows.${rowIndex}.${cellSetIndex}.${fieldName}`

        // NOTE:
        // DSL の読取項目名と step1 の読取項目名が一致している場合、
        // 重複してしまうため、 editOCRFormatsInRow から取り除く
        if (editOCRFormatsInRow) {
          const deleteIndex = editOCRFormatsInRow?.findIndex(
            (format) => `{{${format.id}}}` === value
          )
          if (deleteIndex >= 0) {
            editOCRFormatsInRow.splice(deleteIndex, 1)
          }
        }

        if (token.tokenType === TokenType.Operation) {
          cellSetIndex += 1
        }
        resultFormValues.push({
          value,
          name,
        })
      })

      if (isSimpleFormula(tokens) && editOCRFormatsInRow) {
        const originalCellSetCount = resultFormValues.filter((value) =>
          value.name.endsWith('value')
        ).length
        const addedFormValues = editOCRFormatsInRow.flatMap(
          (editOCRFormat, index) => {
            const cellIndex = originalCellSetCount + index
            return convertEditOCRFormatToFormValue(
              editOCRFormat,
              rowIndex,
              cellIndex
            )
          }
        )

        return resultFormValues.concat(addedFormValues)
      }

      return resultFormValues
    })
    .map((formValues) => {
      // NOTE: 最後の formValue が四則演算子だった場合
      // 最後 formValue を削除する
      if (formValues[formValues.length - 1]?.name.endsWith('symbol')) {
        formValues.pop()
      }

      return formValues
    })

  return dslValues
}

const Presenter: EditCalculationDSLPresenter = {
  breadcrumbs(storeName?: string, tenantName?: string): Path[] {
    const paths = []

    if (storeName && tenantName) {
      paths.push({ title: storeName }, { title: tenantName })
    }

    paths.push({ title: '利用開始設定' }, { title: 'ステップ 3' })

    return injectIndex(paths)
  },
  columns(addCellSetCount?: number): Column[] {
    return baseColumns(addCellSetCount)
  },
  tableData(
    calculationItems,
    editOCRFormats,
    restoredEditCalculationDSLs = []
  ) {
    const rows = new Array(calculationItems.length)
      .fill(null)
      .map((_, i) =>
        generateCalculationDSLRow(
          i,
          calculationItems,
          editOCRFormats,
          calculationItems[i].id,
          restoredEditCalculationDSLs[i]
        )
      )
    const maxCellSetCount =
      Math.max(...rows.map((row) => row.cells.length)) / CELL_SET_COUNT
    return {
      rows,
      maxCellSetCount,
    }
  },
  convertFormValuesToDSLs(values) {
    return values.rows.map((row) => {
      return row
        .flatMap((formValue) => {
          return Object.values(formValue)
        })
        .join('')
    })
  },
  convertEditDataToFormValues(dsls, calculationItems, editOCRFormats) {
    const groupedEditOCRFormats = groupBy<EditOCRFormat>(
      editOCRFormats,
      (editOCRFormat: EditOCRFormat) => editOCRFormat.calculationId
    )

    // DSL がない場合
    if (dsls.length === 0) {
      return convertFromOnlyEditData(calculationItems, groupedEditOCRFormats)
    }

    // DSL がある場合
    return convertFromReferenceRestoreDSL(dsls, groupedEditOCRFormats)
  },
  convertErrorToMessage(error, calculationItemsState) {
    if (error.code === 'network') {
      return error.message
    }
    const calculationNames = error.errors
      ?.map(
        (err) =>
          selectCalculationItemById(calculationItemsState, err.calculation_id)
            ?.name
      )
      .filter((name) => name)
    if (!calculationNames || !calculationNames.length) return ''
    return `${calculationNames.join(
      'と'
    )}でエラーが発生しています。算出ロジックを確認してください。`
  },
  convertErrorType(err: unknown): PostCalculationDSLError {
    if (!isPostCalculationDSLError(err)) {
      return {
        code: 'network',
        message: errorMessage('network'),
        showSnackbar: false,
      }
    }
    return {
      code: err.code,
      message: err.message,
      showSnackbar: err.showSnackbar,
      errors: err.errors,
    }
  },
}

export default Presenter
