import { v4 as uuidv4 } from 'uuid'
import { CalculationTableHeaderCellSize } from 'src/components/atoms'
import { Path } from 'src/components/molecules/BreadcrumbsPipe'
import { Cell as Column } from 'src/components/molecules/CalculationTableHeaderRow'
import { EditReadItem } from 'src/slices/editCalculations/editCalculationsSlice'
import { CalculationDSL, CalculationItem } from 'src/slices/services/api'
import { injectIndex } from 'src/utils/array'
import { Cell as InputCell } from '../components/EditReadItemBodyRow'
import { BodyRow } from '../components/EditReadItemTable'

interface EditReadItemPresenter {
  breadcrumbs(storeName?: string, tenantName?: string): Path[]
  columns(addCellCount?: number): Column[]
  rows(calculationItems: CalculationItem[]): BodyRow[]
  calculationItemIds(calculationItems: CalculationItem[]): string[]
  getInputCellName(rowIndex: number, cellIndex: number): string
  convertDSLToForm(dsls: CalculationDSL[]): EditReadItem[]
}

export const defaultColumnLength = 5

const addColumns = (count: number): Column[] => {
  const baseColumn = (index: number) => {
    return {
      index,
      text: '',
      size: 'large' as CalculationTableHeaderCellSize,
    }
  }

  return new Array<Column | null>(count)
    .fill(null)
    .map((_, i) => baseColumn(i + defaultColumnLength))
}

const baseColumns = (addCellCount = 0): Column[] => {
  const base = [
    {
      text: 'No.',
      size: 'medium' as CalculationTableHeaderCellSize,
    },
    {
      text: '算出項目名',
      size: 'large' as CalculationTableHeaderCellSize,
    },
    {
      text: '',
      size: 'small' as CalculationTableHeaderCellSize,
    },
    {
      text: '対応項目',
      size: 'large' as CalculationTableHeaderCellSize,
    },
    {
      text: '',
      size: 'large' as CalculationTableHeaderCellSize,
    },
  ]
  return injectIndex([...base, ...addColumns(addCellCount)])
}

export const editReadItemCell = (name: string): InputCell => {
  return {
    name,
    placeholder: '対応するレシートの項目を入力',
  }
}

const baseRows = (calculationItems: CalculationItem[]): BodyRow[] => {
  return calculationItems.map((item, rowIndex) => {
    const name = `rows.${rowIndex}.0`
    return {
      title: item.name,
      number: String(item.index + 1),
      cells: [editReadItemCell(name)],
      disabledRow: item.fixedDsl !== null,
    }
  })
}

const Presenter: EditReadItemPresenter = {
  breadcrumbs(storeName?: string, tenantName?: string): Path[] {
    const paths = []

    if (storeName && tenantName) {
      paths.push({ title: storeName }, { title: tenantName })
    }

    paths.push({ title: '利用開始設定' }, { title: 'ステップ 1' })

    return injectIndex(paths)
  },
  columns(addCellCount?: number): Column[] {
    return baseColumns(addCellCount)
  },
  rows(calculationItems: CalculationItem[]): BodyRow[] {
    return baseRows(calculationItems)
  },
  calculationItemIds(calculationItems: CalculationItem[]): string[] {
    return calculationItems.map((item) => item.id)
  },
  getInputCellName(rowIndex: number, cellIndex: number): string {
    return `rows.${rowIndex}.${cellIndex}`
  },
  convertDSLToForm(dsls) {
    if (dsls.length === 0) return []

    const records = dsls.reduce((accumulator: EditReadItem[], current) => {
      if (current.ocrFormats) {
        current.ocrFormats.forEach((format) => {
          // fixedDsl がある calculationItem は input が disabled なので、default value を入れる必要がない
          if (current.calculationItem.fixedDsl) return

          accumulator.push({
            id: uuidv4(),
            readItem: format.readItem,
            calculationId: current.calculationItem.id,
            calculationIndex: current.calculationItem.index,
          })
        })
      }

      return accumulator
    }, [])

    return records
  },
}

export default Presenter
