import React, { useEffect } from 'react'
import { useForm, FormProvider, useFormState } from 'react-hook-form'
import { AppTableRow, InputCell, SelectCell } from 'src/components/tables'
import { Option } from 'src/components/tables/Cells/SelectCell'
import {
  InvalidInputErrorMessage,
  RegexAlphanumeric,
} from 'src/utils/regularExpression'
import { FormNameEnum } from '../../type'

export interface UpdateFormType {
  [FormNameEnum.Name]?: string
  [FormNameEnum.Code]?: string
  [FormNameEnum.Active]?: string
}

export interface RowDataType {
  id: string
  [FormNameEnum.Name]?: string
  [FormNameEnum.Code]?: string
  [FormNameEnum.Active]?: boolean
}

export const ACTIVE_OPTIONS: Option[] = [
  {
    title: '利用中',
    value: 'true',
    color: 'green',
  },
  {
    title: '利用停止',
    value: 'false',
    color: 'red',
  },
]

export type ValidateFormType = { isValid: boolean; id: string }

export type UpdateTableProps = {
  shownTenantOwnerCompanyFormData: RowDataType
  onChangeFormValue: (formData: RowDataType) => void
  onValidate: (validStatus: ValidateFormType) => void
} & React.StyledProps

const UpdateTableRow: React.StyledFC<UpdateTableProps> = ({
  shownTenantOwnerCompanyFormData,
  onChangeFormValue,
  onValidate,
}: UpdateTableProps) => {
  const formMethods = useForm<UpdateFormType>({ mode: 'onChange' })
  const { isValid } = useFormState({ control: formMethods.control })

  useEffect(() => {
    onValidate({ isValid, id: shownTenantOwnerCompanyFormData.id })
  }, [isValid, onValidate, shownTenantOwnerCompanyFormData.id])

  useEffect(() => {
    formMethods.trigger()
  }, [formMethods])

  useEffect(() => {
    const subscription = formMethods.watch((value: UpdateFormType) => {
      const updatedValue: RowDataType = {
        ...value,
        active: value.active === 'true',
        id: shownTenantOwnerCompanyFormData.id,
      }
      onChangeFormValue(updatedValue)
    })
    return () => subscription.unsubscribe()
  }, [formMethods, onChangeFormValue, shownTenantOwnerCompanyFormData.id])

  return (
    <AppTableRow>
      <FormProvider {...formMethods}>
        <SelectCell
          name={FormNameEnum.Active}
          options={ACTIVE_OPTIONS}
          defaultValue={
            shownTenantOwnerCompanyFormData.active ? 'true' : 'false'
          }
          width={120}
        />
        <InputCell
          defaultValue={shownTenantOwnerCompanyFormData.code || ''}
          name={FormNameEnum.Code}
          required
          validations={[
            {
              regex: RegexAlphanumeric,
              message: InvalidInputErrorMessage,
            },
          ]}
          minLength={1}
          width={180}
        />
        <InputCell
          defaultValue={shownTenantOwnerCompanyFormData.name || ''}
          required
          minLength={1}
          name={FormNameEnum.Name}
          width={380}
        />
      </FormProvider>
    </AppTableRow>
  )
}

UpdateTableRow.displayName = 'UpdateTableRow'
export default UpdateTableRow
