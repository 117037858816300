import React, { useEffect, useContext } from 'react'
import { RouteProps, Navigate } from 'react-router-dom'
import { useLocation } from 'react-use'
import Loading from 'src/components/templates/Loading'
import { AuthContext } from 'src/context/auth.context'
import Sidebar from 'src/pages/Sidebar'
import { pushPathStack } from 'src/slices/navigation/navigationSlice'
import { useAppDispatch, useAppSelector } from 'src/store'
import { SIDEBAR_WIDTH } from 'src/styles/constants'
import Path from './path'

type PrivateRouteProps = {
  showSidebar?: boolean
} & RouteProps

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  showSidebar,
}: PrivateRouteProps) => {
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { token, currentAuthorization } = useAppSelector((state) => state.auth)
  const { currentRoot } = useAppSelector((state) => state.navigation)

  useEffect(() => {
    // current がない場合、店舗一覧の path をセットする
    if (currentAuthorization && !currentRoot) {
      dispatch(
        pushPathStack(Path.stores(currentAuthorization.organization.code))
      )
    }
    if (location.pathname) {
      dispatch(pushPathStack(location.pathname))
    }
  }, [currentAuthorization, currentRoot, dispatch, location.pathname])

  // リロードしたときにFirebase Authでログイン中でも、auth.userの初期値がnullなので、
  // リダイレクトしてしまうのを防ぐため
  if (authContext.initializing) {
    return <Loading />
  }

  if (token) {
    if (showSidebar) {
      return (
        <div style={{ display: 'flex' }}>
          <Sidebar />
          <div
            style={{
              width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            }}
          >
            {currentAuthorization?.active ? (
              children
            ) : (
              <div>権限がありません</div>
            )}
          </div>
        </div>
      )
    }
    return children
  }
  return <Navigate replace to="/login" />
}

export default PrivateRoute
