/* eslint-disable react/prop-types */
import React, { PropsWithChildren } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Icon } from 'src/components/atoms'
import BackdropLoading from 'src/components/molecules/BackdropLoading'
import BreadcrumbsPipe, { Path } from 'src/components/molecules/BreadcrumbsPipe'
import { Cell as HeaderCell } from 'src/components/molecules/CalculationTableHeaderRow'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import InputModal from 'src/components/molecules/InputModal'
import Modal from 'src/components/molecules/Modal'
import { Option } from 'src/components/molecules/SelectWithGroupOption'
import EditCalculationDSLTable, {
  BodyRow,
} from '../components/EditCalculationDSLTable'

export type EditCalculationDSLProps = {
  breadcrumbs: Path[]
  isLoading: boolean // テーブル情報の取得/整形中かどうか
  isSubmitting?: boolean // 算出ロジック変更 API のレスポンス待ちかどうか
  columns: HeaderCell[]
  rows: BodyRow[]
  showWarningModal: boolean
  showInputModal: boolean
  inputName: string
  defaultValue?: number
  valueToRenderEmpty?: string
  onClickGoForward: () => void
  onClickGoBack: () => void
  onClickClosePage: () => void
  onClickAddCell: (rowIndex: number) => void
  onClickConfirmClose: () => void
  onClickCancelClose: () => void
  onClickConfirmInput: () => void
  onClickCloseInput: () => void
  onClickClearInput: () => void
  onOpenSelect: (rowIndex: number, cellIndex: number) => void
  onChangeSelect: (option: Option, rowIndex: number, cellIndex: number) => void
}

const HEADER_HEIGHT = 94

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    height: HEADER_HEIGHT,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  breadcrumb: {
    margin: '24px 0 0 20px',
  },
  closeButtonWrapper: {
    margin: '20px 20px 0 0',
  },
  closeButton: {
    width: 20,
    height: 20,
  },
  contentWrapper: {
    display: 'flex',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    width: '80%',
    minWidth: 1084,
    height: '100%',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
  },
  title: {
    marginBottom: 32,
    lineHeight: '36px',
    whiteSpace: 'pre-wrap',

    '@media (width <= 1280px)': {
      marginBottom: 20,
      fontSize: 24,
    },
  },
  table: {
    width: '100%',
    marginBottom: 32,

    '@media (width <= 1280px)': {
      marginBottom: 20,
    },
  },
  buttonWrapper: {
    margin: 'auto 0 72px',
    textAlign: 'center',

    '@media (width <= 1280px)': {
      margin: '0 auto 20px',
    },
  },
  previousTypography: {
    marginTop: 24,
    cursor: 'pointer',

    '@media (width <= 1280px)': {
      marginTop: 12,
    },
  },
})

const EditCalculationDSL: React.StyledFC<EditCalculationDSLProps> = ({
  breadcrumbs,
  isLoading,
  isSubmitting,
  columns,
  rows,
  showWarningModal,
  showInputModal,
  inputName,
  defaultValue,
  onClickGoForward,
  onClickGoBack,
  onClickClosePage,
  onClickAddCell,
  onClickConfirmClose,
  onClickCancelClose,
  onClickConfirmInput,
  onClickCloseInput,
  onClickClearInput,
  onOpenSelect,
  onChangeSelect,
  valueToRenderEmpty,
}: PropsWithChildren<EditCalculationDSLProps>) => {
  const classes = useStyles()

  const renderContent = () => {
    return (
      <Box className={classes.content}>
        <Typography className={classes.title} variant="h1">
          {`最後に売上内容の\n算出ロジックを設定しましょう。`}
        </Typography>
        <EditCalculationDSLTable
          className={classes.table}
          columns={columns}
          rows={rows}
          onClickAdd={onClickAddCell}
          onOpenSelect={onOpenSelect}
          onChangeSelect={onChangeSelect}
          valueToRenderEmpty={valueToRenderEmpty}
        />
        <Box className={classes.buttonWrapper}>
          <Button
            title="つぎへ"
            onClick={onClickGoForward}
            color="primary"
            size="medium"
          />
          <Typography
            color="primary"
            className={classes.previousTypography}
            onClick={onClickGoBack}
            component="p"
            variant="subtitle1"
          >
            前ページに戻る
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header} component="header">
        <BreadcrumbsPipe className={classes.breadcrumb} paths={breadcrumbs} />
        <Box className={classes.closeButtonWrapper}>
          <IconButton
            className={classes.closeButton}
            onClick={onClickClosePage}
            size="large"
          >
            <Icon icon="close" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.contentWrapper}>
        {isLoading ? <CenteringLoading /> : renderContent()}
        <Modal // `disableBackdropClick` is removed by codemod.
          // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)

          open={showWarningModal}
          title={`今中断するとこれまでの設定内容は\n失われてしまいますがよろしいですか？`}
          agreeButtonTitle="このまま中断する"
          disagreeButtonTitle="設定に戻る"
          onClickAgree={onClickConfirmClose}
          onClickDisagree={onClickCancelClose}
        />
        <InputModal
          open={showInputModal}
          name={inputName}
          type="number"
          defaultValue={defaultValue}
          placeholder="数値を入力"
          confirmButtonText="決定"
          closeButtonText="設定に戻る"
          onClickConfirm={onClickConfirmInput}
          onClose={onClickCloseInput}
          onClickClear={onClickClearInput}
        />
      </Box>
      <BackdropLoading open={isSubmitting} />
    </Box>
  )
}

export default EditCalculationDSL
