import React, { memo, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, IconButton, Typography } from '@mui/material'
import { Button, Ellipse, Icon } from 'src/components/atoms'
import TextField from 'src/components/molecules/TextField'
import {
  InvalidInputErrorMessage,
  RegexNoSpaceOnly,
} from 'src/utils/regularExpression'
import styles from './styles.module.scss'

export type MemberDetailProps = {
  name: string
  memberRole: string
  email: string
  onClickUpdate: (name: string) => void
} & React.StyledProps

export interface FieldValue {
  name: string
}

const MemberDetail: React.StyledFC<MemberDetailProps> = memo(
  ({
    className,
    name,
    memberRole,
    email,
    onClickUpdate,
  }: MemberDetailProps) => {
    const [isEdit, setIsEdit] = useState(false)

    // Form
    const formMethods = useForm<FieldValue>({
      defaultValues: {
        name,
      },
    })

    const onSubmit = (formValue: FieldValue) => {
      if (formValue.name !== name) {
        onClickUpdate(formValue.name)
      }
      setIsEdit(false)
    }

    return (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Box className={clsx(className, styles.wrapper)}>
            <Box className={styles.nameWrapper}>
              {!isEdit ? (
                <>
                  <Typography className={styles.name}>{name}</Typography>
                  <IconButton
                    className={styles.editIcon}
                    onClick={() => setIsEdit(true)}
                  >
                    <Icon icon="edit" />
                  </IconButton>
                </>
              ) : (
                <>
                  <Box>
                    <TextField
                      className={styles.textField}
                      name="name"
                      type="text"
                      defaultValue={name}
                      required
                      validations={[
                        {
                          regex: RegexNoSpaceOnly,
                          message: InvalidInputErrorMessage,
                        },
                      ]}
                      onBlur={() => formMethods.trigger('name')}
                    />
                    <ErrorMessage
                      errors={formMethods.formState.errors}
                      name="name"
                      render={({ message }) => {
                        return (
                          <Typography
                            variant="body2"
                            component="p"
                            className={styles.error}
                          >
                            {message}
                          </Typography>
                        )
                      }}
                    />
                  </Box>
                  <Box className={styles.buttonWrapper}>
                    <Button
                      className={styles.updateButton}
                      title="更新"
                      color="secondary"
                      size="small"
                      shape="round"
                      type="submit"
                    />
                    <Button
                      className={styles.cancelButton}
                      title="キャンセル"
                      color="secondary"
                      size="small"
                      shape="round"
                      onClick={() => {
                        setIsEdit(false)
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box className={styles.roleWrapper}>
              <Ellipse className={styles.ellipse} color="green" />
              <Typography className={styles.typography}>
                {memberRole}
              </Typography>
            </Box>
            <Box className={styles.emailWrapper}>
              <Typography className={styles.emailTitle} component="span">
                メールアドレス
              </Typography>
              <Typography className={styles.typography}>{email}</Typography>
            </Box>
          </Box>
        </form>
      </FormProvider>
    )
  }
)
MemberDetail.displayName = 'MemberDetail'
export default MemberDetail
