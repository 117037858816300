import { CheckFormType } from 'src/components/molecules/CheckBoxListWithFilter'
import { TenantStatusEnum } from 'src/slices/services/api'

export type TenantStatusValue = TenantStatusEnum | 'all'

export enum FilterFormTypeEnum {
  tenantStatus = 'tenantStatus',
  searchText = 'searchText',
  storeCodes = 'storeCodes',
}

export type FilterParam = {
  [FilterFormTypeEnum.tenantStatus]?: TenantStatusEnum
  [FilterFormTypeEnum.searchText]?: string
  [FilterFormTypeEnum.storeCodes]?: string[]
}

export type FilterFormType = {
  [FilterFormTypeEnum.tenantStatus]?: TenantStatusValue
  [FilterFormTypeEnum.searchText]?: string
  [FilterFormTypeEnum.storeCodes]?: CheckFormType
}

export type SearchParam = FilterParam & {
  loadOffsetNum: number
}
