import React from 'react'
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  Box,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Icon, QRCode, QRCodeProps } from 'src/components/atoms'
import color from 'src/styles/color'

export type QRCodeModalProps = {
  open: boolean
  title: string
  disableBackdropClick?: boolean
  buttonText: string
  sections: Section[]
  id: string
  onClickDownload: () => void
  onClickClose: () => void
} & React.StyledProps &
  QRCodeProps

export type Section = {
  title: string
  value: string
}

const useStyles = makeStyles({
  backDrop: {
    backgroundColor: color.black50,
  },
  dialog: {
    width: 'auto',
    minHeight: '88vh',
    maxHeight: '96vh',
    paddingBottom: '2%',
    borderRadius: 6,
    aspectRatio: '2/3',
  },
  pdf: {
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  titleTypography: {
    margin: 'auto',
    fontSize: 16,
    fontWeight: 400,

    '@media (height <= 700px)': {
      fontSize: 12,
    },
  },
  title: {
    display: 'flex',
    height: 24,

    '@media (height <= 700px)': {
      height: 16,
    },
  },
  action: {
    display: 'grid',
    justifyContent: 'center',
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 0,
    width: 20,
    height: 20,
    padding: 0,
    margin: 'auto 20px',
    backgroundColor: color.white,

    '@media (height <= 700px)': {
      top: 16,
      width: 16,
      height: 16,
    },
  },
  qrCodeWrapper: {
    margin: '8% auto',

    '@media (height <= 700px)': {
      margin: '4% auto',
    },
  },
  qrCode: {
    padding: 20,
    backgroundColor: color.white,
    boxShadow: '0 0 40px rgba(0, 0, 0, 0.25)',

    '@media (height <= 700px)': {
      padding: 16,
      scale: 0.9,
    },
  },
  sectionTitle: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 400,

    '@media (height <= 700px)': {
      marginBottom: 4,
      fontSize: 12,
    },
  },
  sectionValue: {
    fontSize: 20,
    fontWeight: 700,

    '@media (height <= 700px)': {
      fontSize: 16,
    },
  },
  section: {
    width: '80%',
    margin: '0 auto 6%',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',

    '@media (height <= 700px)': {
      margin: '0 auto 4%',
    },
  },
  downloadButton: {
    width: 320,
    height: 64,
    padding: '16px 36px',
    borderRadius: 16,
    margin: '4% auto 0',
    fontSize: 16,

    '@media (height <= 700px)': {
      width: 240,
      height: 44,
      padding: '16px 36px',
      borderRadius: 12,
      margin: '0 auto',
      fontSize: 12,
    },
  },
})

const QRCodeModal: React.StyledFC<QRCodeModalProps> = ({
  className,
  open,
  title,
  disableBackdropClick,
  buttonText,
  sections,
  id,
  src,
  size,
  onClickDownload,
  onClickClose,
}: QRCodeModalProps) => {
  const classes = useStyles()

  const handleClose = (_: Event, reason: string) => {
    if (reason === 'backdropClick' && disableBackdropClick) return
    onClickClose?.()
  }

  return (
    <Dialog
      classes={{
        container: className,
        paper: classes.dialog,
      }}
      BackdropProps={{
        className: classes.backDrop,
      }}
      open={open}
      data-testid="QRCodeModal"
      onClose={handleClose}
    >
      <div id={id} className={classes.pdf}>
        <DialogTitle className={classes.title}>
          <Typography className={classes.titleTypography}>{title}</Typography>
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={onClickClose}
          size="large"
          data-html2canvas-ignore
        >
          <Icon icon="close" />
        </IconButton>
        <Divider />
        <Box className={classes.qrCodeWrapper}>
          <QRCode className={classes.qrCode} src={src} size={size} />
        </Box>
        {sections.map((section) => {
          return (
            <Box className={classes.section} key={section.title}>
              <Typography className={classes.sectionTitle} variant="h4">
                {section.title}
              </Typography>
              <Typography className={classes.sectionValue} variant="h4">
                {section.value}
              </Typography>
            </Box>
          )
        })}
      </div>
      <Button
        className={classes.downloadButton}
        color="primary"
        size="large"
        title={buttonText}
        onClick={onClickDownload}
      />
    </Dialog>
  )
}

export default QRCodeModal
