import {
  Selector,
  createSelector,
  createSlice,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  GetParams,
  getCalculationDSLs,
  selectCalculationDSLsByParams,
} from 'src/slices/calculationDSLs/calculationDSLsSlice'
import { OCRFormat } from 'src/slices/services/api'
import { RootState } from 'src/store'
import { purge } from 'src/store/action'

type OCRFormatState = EntityState<OCRFormat>

const ocrFormatsAdapter = createEntityAdapter<OCRFormat>()

export const initialState: OCRFormatState = ocrFormatsAdapter.getInitialState()

const slice = createSlice({
  name: 'ocrFormats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(purge, () => {
      return initialState
    })
    builder.addCase(getCalculationDSLs.fulfilled, (state, { payload }) => {
      ocrFormatsAdapter.upsertMany(state, payload.dsls?.ocrFormats ?? {})
    })
  },
})

export default slice.reducer

// Selector
export const {
  selectById: selectOCRFormatById,
  selectEntities: selectOCRFormatEntities,
  selectAll: selectAllOCRFormats,
} = ocrFormatsAdapter.getSelectors<OCRFormatState>((state) => state)

export const selectOCRFormatsByParams = (
  params: GetParams
): Selector<RootState, OCRFormat[] | undefined> => {
  return createSelector(
    [
      (state) => selectCalculationDSLsByParams(params)(state),
      (state) => selectOCRFormatEntities(state.entities.ocrFormats),
    ],
    (dsls, formatEntities) => {
      return dsls
        ?.flatMap((dsl) => dsl.ocrFormats)
        .map((formatId) => formatEntities[formatId])
        .filter((format): format is OCRFormat => format !== undefined)
    }
  )
}
