import React, { UIEvent, useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { Box, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Breadcrumbs from 'src/components/molecules/Breadcrumbs'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import SmallGuide from 'src/components/molecules/SmallGuide'
import TenantFilter, {
  TenantFilterProps,
} from 'src/components/molecules/TenantFilter'
import TenantListWithPagination, {
  TenantListWithPaginationProps,
} from 'src/components/organisms/TenantListWithPagination'
import color from 'src/styles/color'
import { BREADCRUMB_HEIGHT } from 'src/styles/constants'
import listStyles from 'src/styles/list-styles.module.scss'

const breadcrumbs = [{ index: 0, title: 'テナント一覧', to: '' }]

export type TenantsProps = {
  isLoading: boolean
  queryState?: string
} & TenantFilterProps &
  Omit<TenantListWithPaginationProps, 'useStoreName'>

const useStyles = makeStyles({
  breadcrumb: {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    height: BREADCRUMB_HEIGHT,
    backgroundColor: color.white,
  },
  title: {
    paddingTop: 4,
    marginBottom: 20,
    fontSize: 24,
  },
  filterWrapper: {
    position: 'sticky',
    zIndex: 1,
    top: BREADCRUMB_HEIGHT,
    backgroundColor: color.white,
  },
  filter: {
    height: 52,
    boxSizing: 'border-box',
    paddingTop: 18,
  },
  contentArea: {
    flexGrow: 1,
  },
  guide: {
    marginTop: 16,
  },
  stickyPadding: {
    paddingBottom: 6,
  },
  divider: {
    marginTop: 6,
  },
  list: {
    paddingBottom: 34,
    marginTop: 14,
  },
})

const TITLE_HEIGHT = 63

const Tenants: React.StyledFC<TenantsProps> = ({
  isLoading,
  queryState,
  currentPage,
  filters,
  rowsPerPage,
  tenants,
  tenantCount,
  defaultSearchValue,
  isChecked,
  paginationOnClick,
  searchFieldOnChange,
  onChangeCheck,
}: TenantsProps) => {
  const classes = useStyles()
  const [showDivider, setShowDivider] = useState(false)
  const scrollAreaElement = useRef<HTMLDivElement>(null)
  const handleScroll = (e: UIEvent) => {
    setShowDivider(e.currentTarget.scrollTop > TITLE_HEIGHT)
  }

  useEffect(() => {
    if (scrollAreaElement.current?.scroll) {
      scrollAreaElement.current.scroll(0, 0)
    }
  }, [queryState, scrollAreaElement])

  const renderTenantList = () => {
    return tenantCount && tenantCount > 0 ? (
      <TenantListWithPagination
        className={classes.list}
        useStoreName
        {...{
          currentPage,
          rowsPerPage,
          tenantCount,
          tenants,
          paginationOnClick,
        }}
      />
    ) : (
      <SmallGuide
        icon="pray"
        className={classes.guide}
        message="該当の結果はありません"
        description="検索キーワードを確認して再度試してください"
      />
    )
  }

  return (
    <Box
      className={listStyles.container}
      onScroll={handleScroll}
      {...{ ref: scrollAreaElement }}
    >
      <Breadcrumbs className={classes.breadcrumb} paths={breadcrumbs} />
      <Box className={classes.contentArea}>
        <Typography className={classes.title} variant="h1">
          テナント一覧
        </Typography>
        <Box
          className={clsx(
            classes.filterWrapper,
            !showDivider && classes.stickyPadding
          )}
        >
          <TenantFilter
            className={classes.filter}
            {...{
              filters,
              tenantCount,
              searchFieldOnChange,
              defaultSearchValue,
              isChecked,
              onChangeCheck,
            }}
          />
          {showDivider && <Divider className={classes.divider} />}
        </Box>
        {isLoading ? <CenteringLoading /> : renderTenantList()}
      </Box>
    </Box>
  )
}

export default Tenants
