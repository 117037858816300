import React, { useEffect, useRef } from 'react'
import {
  getTenantSalesReports,
  selectTenantSalesReportByParams,
  selectTenantSalesReportStateByParams,
} from 'src/slices/tenantSalesReports/tenantSalesReportsSlice'
import { useAppDispatch, useAppSelector } from 'src/store'

export type NextImageLoaderProps = {
  orgCode: string
  storeCode: string
  selectedDate: string
  nextTenantCode: string
  onLoadComplete: () => void
} & React.StyledProps

const NextImageLoader: React.StyledFC<NextImageLoaderProps> = ({
  orgCode,
  storeCode,
  selectedDate,
  nextTenantCode,
  onLoadComplete,
}: NextImageLoaderProps) => {
  const imageRef = useRef<HTMLElement | null>(null)

  const nextTenantSalesReport = useAppSelector(
    selectTenantSalesReportByParams({
      orgCode,
      storeCode,
      tenantCode: nextTenantCode,
      date: selectedDate,
    })
  )

  const nextTenantSalesReportState = useAppSelector(
    selectTenantSalesReportStateByParams({
      orgCode,
      storeCode,
      tenantCode: nextTenantCode,
      date: selectedDate,
    })
  )

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (
      nextTenantSalesReportState.status === 'idle' &&
      !nextTenantSalesReport
    ) {
      dispatch(
        getTenantSalesReports({
          orgCode,
          storeCode,
          tenantCode: nextTenantCode,
          date: selectedDate,
        })
      )
    }
  }, [
    nextTenantCode,
    selectedDate,
    dispatch,
    orgCode,
    storeCode,
    nextTenantSalesReportState.status,
    nextTenantSalesReport,
  ])

  // NOTE: テナントの全レジの分のレシート画像をimageUrlsに格納（PDFは除く）
  const imageUrls = nextTenantSalesReport?.registers.flatMap((register) => {
    if (register.receiptImage?.isPdf) {
      return []
    }
    return register.receiptImage?.separatedOriginal ?? []
  })

  const loadImage = (src: string): Promise<void> => {
    const image = new Image()
    imageRef.current = image
    image.src = src
    return image
      .decode()
      .then(() => {
        imageRef.current = null
      })
      .catch((error) => {
        imageRef.current = null
        throw error
      })
  }

  useEffect(() => {
    let isCancelled = false
    const loadImages = async () => {
      if (imageUrls && imageUrls.length > 0) {
        try {
          for (let i = 0; i < imageUrls.length; i++) {
            const src = imageUrls[i]
            if (isCancelled) return
            // eslint-disable-next-line no-await-in-loop
            await loadImage(src)
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
      onLoadComplete()
    }

    loadImages()

    return () => {
      isCancelled = true
      if (imageRef.current instanceof HTMLImageElement) {
        imageRef.current.src = '' // ロードを中断
      }
      imageRef.current = null
    }
  }, [nextTenantCode, onLoadComplete, imageUrls])

  return null
}

export default NextImageLoader
